import PersonalData from "./components/PersonalData";
import BankDetails from "./components/BankDetails";
import Instruments from "./components/Instruments";

function acc_content(t) {
    return (
        [
            {
                id: 0,
                title: t("registration.subcategories.personal_data"),
                content: PersonalData
            },
            {
                id: 1,
                title: t("registration.subcategories.bank_details"),
                content: BankDetails
            },
            {
                id: 2,
                title: t("registration.subcategories.instruments"),
                content: Instruments
            },
        ]
    )
}

export default acc_content;