import {useTranslation} from "react-i18next";
import {TextStyle, TitleStyle} from "../../../../../../layouts/styles";
import React from "react";

function PersonContact({details, ...props}) {
    const { t } = useTranslation()

    return (<>
        <div className="row px-1" style={{borderTop:'1px solid gray'}}>
            <div className="col-3">
                <p style={TitleStyle}>
                    {t("company.contact_person")}
                </p>
                <p style={TextStyle}>
                    {details.first_name} {details.last_name}
                </p>
            </div>
            <div className="col-3">
                <p style={TitleStyle}>
                    {t("company.phone")}
                </p>
                <p style={TextStyle}>
                    {details.telephone}
                </p>
            </div>
            <div className="col-3">
                <p style={TitleStyle}>
                    {t("company.email")}
                </p>
                <p style={TextStyle}>
                    {details.email}
                </p>
            </div>
            <div className="col-3">
                <p style={TitleStyle}>
                    {t("company.responsible_desc")}
                </p>
                <p style={TextStyle}>
                    {details.responsible_for ? details.responsible_for : "-"}
                </p>
            </div>
        </div>
    </>);
}

export default PersonContact;

