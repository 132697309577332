import { Navigate } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {ModificationButton} from "../layouts/styles";

const RerirectToPanel = () => <Navigate to="/home" replace={true} />

const RedirectButton = (props) => {
    return (<div className="mt-5 mb-2" style={{overflow: 'hidden'}}>
        <div onClick={props.redirect_to_path}>
            <ModificationButton className="editBtn me-3" style={{float: 'right'}}>
                <FontAwesomeIcon icon={faPenToSquare} className="me-2"/>
                {props.children}
            </ModificationButton>
        </div>
    </div>)
}

export {
    RerirectToPanel,
    RedirectButton,
}
