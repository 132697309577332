import styled from "styled-components";
import { Colors } from "../theme";
import LordIcon from "../ui/LordIcon";
import {useState} from "react";
import LanguageButtons from "../ui/LanguageButtons";

const MobileMenuDiv = styled.div `
    background-color: ${Colors.red};
    margin: 0px 0px 15px 0px;
    padding: 5px 35px 5px 0px;
    z-index: 5;
    color: ${Colors.white};
    position: fixed;
    top: 0px;
    width: 100%;
    text-align: right;
    @media (min-width: 992px) {
        display: none;
    }

`
// animation https://stackoverflow.com/questions/3508605/how-can-i-transition-height-0-to-height-auto-using-css
const MobileMenuContent = styled.div `
    text-align: left;
    padding: 0px 35px 15px 35px;
    position: absolute;
    width: 100%;
    transform: scaleY(0);        
    transform-origin: top;
    transition: transform 0.26s ease;
    background-color: ${Colors.red};
    &.active {
        transform: scaleY(1);
    }
`

export default function MobileMenu({children}) {
    const [active, setActive] = useState(false);

    return (
        <MobileMenuDiv>
            <LordIcon
                active={active}
                changeActive={setActive}
                url='menu' size='50px'
                trigger='custom'
            />
            
            <MobileMenuContent className={active ? 'active' : ''}>
                <LanguageButtons className='mb-3' />
                {children}
            </MobileMenuContent>
        </MobileMenuDiv>
    )
}