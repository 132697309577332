import {createContext} from "react";

export const AuthenticationContext = createContext({
        userName: () => localStorage.getItem('username'),
        setCurrentUserId: localStorage.getItem('userid'),
        currentUser: localStorage.getItem('userid'),
        tokenUser: localStorage.getItem('token'),
        expiredToken: localStorage.getItem('expiry'),
        token_ttl: localStorage.getItem('token_ttl'),
        onLogin: () => {},
        onLogout: () => {},
        onRefreshToken: () => {},
        currentRole: null,
        setCurrentRole: () => {},
        userRoles: [],
        setUserRole: () => {},
        // firmLogo: null,
});




