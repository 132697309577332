import Header from "./Header/Header";
import FullPageBackground from "../../../components/Full_Page_Background";
import {Colors} from "../../../components/theme";
import RoutesMusician from "./RoutesMusician";
import ContentBodyStyle from "../../../components/ContentBodyStyle";
import Menu from "./Menu/Menu";
// import PopupMessage from "../../../components/ui/PopupMessage";

function MainMusician() {

    return (
        <>
            {/*
            <PopupMessage variant='error' title='Are you sure?' message='You have unsaved changes that will be lost' leftBtn='No' rightBtn='Yes'/>
            <PopupMessage variant='success' title='Are you sure?' message='You have unsaved changes that will be lost' leftBtn='Ok, close'/>
            */}
            <Header />
            <ContentBodyStyle menu={<Menu />} routes={<RoutesMusician />}/>
            <FullPageBackground bgColor={Colors.lightBG} />
        </>
    )
};

export default MainMusician;