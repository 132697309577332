const getLocalRefreshToken = () => {
    const user = localStorage.getItem("user");
    return user?.refreshToken;
};

const getLocalAccessToken = () => {
    const token = localStorage.getItem("token");
    return token;
};

const updateLocalAccessToken = (token) => {
    let tokenStorage = JSON.parse(localStorage.getItem("token"));
    tokenStorage.token = token;
    localStorage.setItem("token", JSON.stringify(tokenStorage));
};

const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const setUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = () => {
    localStorage.removeItem("token");
};

const clearLocalStorage = () => {
    localStorage.clear();
};

const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
    clearLocalStorage,
};

export default TokenService;