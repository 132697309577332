import { useTranslation } from "react-i18next";
import MenuButton from "../../../../components/ui/Menu_Button";
import MenuHeader from "../../../../components/menu/MenuHeader";
import MenuFooter from "../../../../components/menu/MenuFooter";
import { Colors } from "../../../../components/theme";
import {AuthenticationContext} from "../../../../contexts/AuthenticationContext";
import {useContext} from "react";
import styled from "styled-components";
import MenuBody from "../../../../components/menu/MenuBody";
import {Link} from "react-router-dom";
import ChangeRole from "../../ChangeRole";

const ListElement = styled.li `
    padding: 10px 0px;
`

const SubMenuElement = styled.ul `
    padding-left: 35px;
    list-style-type: none;
    display: none;
    & > li {
        padding: 3px 0px;
    }
    & > li:hover {
        font-weight: bold;
    }
    & > li a {
        color: ${Colors.white};
    }    
`

export default function Menu() {
    const context = useContext(AuthenticationContext);
    const { t } = useTranslation(['menu']);

    return (
        <>
            <MenuHeader userName={context.userName} persFunction="{userRoles}">
                <b>{context.userName}</b>
                <ChangeRole />
            </MenuHeader>

            <MenuBody>
                <ul className="mainMenu" style={{listStyleType:'none', padding:'0px'}}>
                    <ListElement>
                        <MenuButton type='home' target='.menuLink' to={'home'}>
                            {t("menu.home")}
                        </MenuButton>
                    </ListElement>

                    <ListElement>
                        <MenuButton type='avatar' target='.menuLink'>
                            {t("menu.profile")}
                        </MenuButton>
                        <SubMenuElement className="subMenu">
                            <li><Link to={'profile/personal-data'}>{t("menu.pers_data")}</Link></li>
                            <li><Link>{t("menu.pw_settings")}</Link></li>
                            <li><Link>{t("menu.delete_acc")}</Link></li>
                        </SubMenuElement>
                    </ListElement>

                    <ListElement>
                        <MenuButton type='folder' target='.menuLink'>
                            {t("menu.projects")}
                        </MenuButton>
                        <SubMenuElement className="subMenu">
                            <li><Link>{t("menu.current_projects")}</Link></li>
                            <li><Link>{t("menu.past_projects")}</Link></li>
                        </SubMenuElement>
                    </ListElement>
                    <ListElement>
                        <MenuButton type='wallet' target='.menuLink'>
                        {t("menu.payouts")}
                        </MenuButton>
                        <SubMenuElement className="subMenu">
                            <li><Link>{t("menu.all_payouts")}</Link></li>
                            <li><Link>{t("menu.download_report")}</Link></li>
                        </SubMenuElement>
                    </ListElement>
                    <ListElement>
                        <MenuButton type='doc' target='.menuLink'>
                            {t("menu.contracts")}
                        </MenuButton>
                    </ListElement>
                    <ListElement>
                        <MenuButton type='settings' target='.menuLink' to={'settings'}>
                            {t("menu.calc_settings")}
                        </MenuButton>
                    </ListElement>
                    <ListElement>
                        <MenuButton type='bell' target='.menuLink'>
                            {t("menu.notifications")}
                        </MenuButton>
                    </ListElement>
                </ul>
            </MenuBody>

            <MenuFooter>
                <MenuButton type='arrow' target='.menuLink' rotate='90' onClick={context.onLogout}>
                    {t("menu.log_out")}
                </MenuButton>
            </MenuFooter>
        </>
    )

}