import {Link, useNavigate} from "react-router-dom";
import {Button} from '../../components/ui/Button';
import {FeedbackMessage} from '../../components/ui/Feedback_Message';
import {request} from "../../helpers/request";
import {PasswordResetErrors} from "../../helpers/MessagesErrors/responseErrors";
import {useState} from "react";
import { useTranslation } from "react-i18next";

const reminderAttempt = async (email, navigate) => {
    try {
        const response = await request.post(
            '/api/accounts/password-reset',
            {'email': email}
        );
        return {"response": [response.data], "status": true}


    } catch(error) {
        if (error.request.status === 0) {
            navigate("/network-error");
        };
        const response_err = error.response.data.errors;

        return {"response": [PasswordResetErrors[response_err]], "status": false};
    }
};

function ForgotPassword() {
    const [ errorsMessages, setErrorsMessages ] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleSubmit = async e => {
        e.preventDefault();
        const userData = await reminderAttempt(e.target.email.value, navigate);

        if (userData.status === true) {
            setErrorsMessages([PasswordResetErrors.resetPasswordSend]);
        } else {
            setErrorsMessages(userData.response);
        }

    };

    return (
        <>
            <h1 className="underline">
                {t("forgott_pw.title")}
            </h1>

            <p>
                {t("forgott_pw.description")}
            </p>

            <form onSubmit={handleSubmit}>
                <input className='dataInput' placeholder={t("profile.email_address")} name="email" type="email" id="InputEmail" required/>
                <br/>
                <Button type='submit' bWidth='full'>{t("common.button.submit")}</Button>
            </form>

            <FeedbackMessage variant=''>
                {/*
                    Success-Message: Your request for a password reset has been sent.
                    Error-Message:
                    Your request for a password reset has not been sent. Please try again.
                    If the error persists, please contact the admin on <a href="tel:00352661309742">+352 661 309 742</a>

                */}
                <div>
                    <p>
                        {errorsMessages.map((msg, i) =>
                            <li key={i}>{t(PasswordResetErrors[msg], {'ns': 'glossary'})}</li>
                        )}
                    </p>
                </div>
            </FeedbackMessage>

            <Link to={"/"} style={{float:'right'}} className="text-lowercase"> {t("common.button.back_to_login")}</Link>
        </>
    )
};

export default ForgotPassword;
