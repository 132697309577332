import {useTranslation} from "react-i18next";
import {calculation_sections_sum} from "../tools";

const CountingUp = ({entry, blockAmount}) => {
    const { t } = useTranslation();
    const entrySum = calculation_sections_sum(entry)

    return (<>
        <div className="d-flex flex-row align-items-center my-2">
            <div style={{width:(blockAmount === 4 ? '35%' : '46%'), paddingLeft:'10px'}}>
                <b>{t("common.titles.total")}</b>
            </div>
            {
                blockAmount === 4 && 
                <div style={{width:'15%'}} className="ms-2"/>
            }
            
            <div style={{width:'25%', paddingLeft:'10px'}} className="ms-2">
                <b>{parseFloat(entrySum.client_cost).toFixed(2)} €</b>
            </div>
            <div style={{width:'25%', paddingLeft:'10px'}} className="ms-2">
                <b>{parseFloat(entrySum.estro_cost).toFixed(2)} €</b>
            </div>
        </div>
    </>)
}

export default CountingUp

