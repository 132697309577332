import DarkCard from "../../../../../components/card/DarkCard";
import LightCard from "../../../../../components/card/LightCard";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RedHeader from "../../../../../components/ui/RedBgTitleHeader";
import {useEffect, useState} from "react";
import {getInstrumentsListAPI, getInstrumentsStaffListAPI} from "../../../../../services/api/user";

function InstrumentsList () {
    const { t } = useTranslation();
    const [instruments, setInstruments] = useState([]);

    useEffect(() => {
        const get_instr_list = async () => {
            try {
                const respInstr = await getInstrumentsListAPI();
                setInstruments(respInstr.data);
            } catch (ex) {
                console.log("Failed to download instruments list.");
            };
        };

        get_instr_list()
    }, [])

    return (<>
        <DarkCard classNames='mb-3 row'>
            <p className="m-0 p-0 breadcrumbs">
                <Link to={'/instruments'}>{t("menu.instruments",{ ns: 'menu' })}</Link>
            </p> 
        </DarkCard>

        <DarkCard classNames='mb-3 row'>

            <h1 className="mt-0 p-0">{t("instruments.main_page_title")}</h1>

                {instruments.map(el => (
                        <div key={el.id} className="col-sm-6 col-lg-3 mb-3">
                            <LightCard>
                                <RedHeader title={el.type_name} />
                                <ul>
                                    {el.instruments.map(instr =>
                                            <li key={instr.pk}>
                                                <Link to={`edit/${instr.pk}`}>{instr.name}</Link>
                                            </li>
                                    )}
                                </ul>
                            </LightCard>
                        </div>
                ))}
        </DarkCard>
    </>)
};


export default InstrumentsList;