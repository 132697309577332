import DarkCard from "../../../../../../components/card/DarkCard";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import LightCard from "../../../../../../components/card/LightCard";
import Budget from "./Budget";
import Error404 from "../../../../../Error404";
import LoadingData from "../../../../../LoadingData";
import Estimate from "./Estimate";
import {project} from "../../../../../../services/api/endpoints";
import {useEndpoint} from "../../../../../../hooks/useEndpiont";
import Status from "./Status";
import Quote from "./Quote";
import Staff from "./Staff"
import Events from "./Events";
import FeedsList from "./FeedsList";
import {useTranslation} from "react-i18next";
import {RedirectButton} from "../../../../../../helpers/Redirect";
import ClientOrFirmCard from "./ClientOrFirmCard";
import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import {useSessionStorage} from "usehooks-ts";
import {projectSession} from "../../../../../../services/sessionData";
import styled from "styled-components";
import { Colors } from "../../../../../../components/theme";
import { Link } from "react-router-dom";

const TxtTitles = styled.p`
	font-size: 0.7em;
    text-transform: uppercase;
	width: 100%;
    padding: 0 0 3px 2px;
    margin: 0;
    color: ${Colors.darkRed};
`;

export default function ProjectCard() {
    const params = useParams()
    const [{data, loading, error}] = useEndpoint(project.details(params.id))
    const projectData = data
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [sessionData, setSessionData] = useSessionStorage("CALCULATION", projectSession);
    const [estimate_current, setEstimateCurrent] = useState(0)

    useEffect(() => {
        if (projectData) {
            setEstimateCurrent(projectData.estimates.find(el => el.current).id)
        }
    }, [projectData])

    const redirect_to = (what, calculation_id, estimation_id, currentEst, action="project_edit_estimate") => {
        const estimate_selected = estimation_id ? estimation_id : estimate_current;
        if (what === 'project') {
            // const estimate_id = estimate_selected ? estimate_selected.id : 0
            setSessionData({
                ...sessionData,
                name: "project",
                pathReturn: "/projects/in-preparation/"+params.id,
                projectID: params.id,
                projectName: projectData.name,
                calculationID: projectData.calculation_pk,
                estimateID: estimate_selected,
                estimateCurrent: currentEst,
                action: action
            })
            navigate("/sales/calculator/"+calculation_id)
        } else {    // Create Estimate
            setSessionData({
                ...sessionData,
                name: "estimate",
                pathReturn: "/projects/in-preparation/"+params.id,
                projectID: params.id,
                projectName: projectData.name,
                calculationID: 0,
                estimateID: estimate_selected,
                estimateCurrent: false,
                action: action
            })
            navigate("/sales/calculator")
        }
    }

    if (loading) return <LoadingData/>
    if (error) return <Error404/>
    if (projectData.status === 404) return (
        <DarkCard classNames='mb-3 row'>
            <p>Error: No project data!</p>
        </DarkCard>
    )

    const clientAndFirm = {
        client: projectData.clientData,
        firm: projectData.firmData,
    }

    return (<>
        <DarkCard classNames='mb-3 row'>
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.projects",{ ns: 'menu' })}</span> &#9656; 
                <Link to={'/projects/in-preparation'}>{t("menu.inprep_projects",{ ns: 'menu' })}</Link> &#9656; 
                <Link to={'/projects/in-preparation/'+params.id}>{t("breadcrumbs.project_title")} P{params.id}</Link>
            </p>
        </DarkCard>
        <DarkCard classNames='mb-3 row'>

            <TxtTitles>{t("project.project_name_title")}</TxtTitles>
            <h1 className='mb-0 mt-0 p-0' style={{marginTop:'-5px'}}>
                {projectData.name}
            </h1>
            <p className="mt-3 ps-0" style={{fontSize:'14px'}}>
                {t("project.project_id_title")} P{params.id} <br/>
                {t("project.selected_estimate_title")} E{estimate_current}
            </p>

            { /* SHOW ONLY WHEN READY FOR USE
            <LightCard classNames='mb-3'>
                <Status />
            </LightCard>
            */
            }

            {projectData.estimates.length > 0
                ? (<>
                    <div className='mb-3 px-0'>
                        <RedirectButton redirect_to_path={() => redirect_to(
                            "project",
                            projectData.calculation_pk,
                            undefined,
                            true,
                            "project_edit_estimate"
                        )}>
                            {t("common.button.edit_estimate")}
                        </RedirectButton>
                        
                        <div className="row">
                            <div className="col-6">
                                <LightCard>
                                    <RedHeader title={t('project.budget_title')+' (E'+estimate_current+')'}/>
                                    <Budget projectData={projectData} />
                                </LightCard>
                            </div>
                            <div className="col-6">
                                <LightCard>
                                    <RedHeader title={t('project.client_title')}/>
                                    <ClientOrFirmCard details={clientAndFirm} />
                                </LightCard>
                            </div>
                        </div>
                        
                    </div>

                    <div className='mb-3 px-0'>
                        <div className="mt-5 mb-2" style={{overflow:'hidden'}}>
                            <RedirectButton redirect_to_path={() => redirect_to(
                                "estimate",
                                undefined,
                                undefined,
                                undefined,
                                'project_create_estimate'
                            )}>
                                {t("common.button.create_estimate")}
                            </RedirectButton>
                        </div>
                        <LightCard>
                            <Estimate
                                estimates={projectData.estimates}
                                clientAndFirm={clientAndFirm}
                                grantEstroSum={projectData.totaling.grantEstroSum}
                                project={{name: projectData.name, id: projectData.id}}
                                redirect_to={redirect_to}
                            />
                        </LightCard>
                    </div>

                    <div className='mb-3 px-0'>
                        <LightCard>
                            <Quote
                                quotes={projectData.quotes}
                                project={{name: projectData.name, id: projectData.id}}
                                grantEstroSum={projectData.totaling.grantEstroSum}
                                clientAndFirm={clientAndFirm}
                            />
                        </LightCard>
                    </div>

                    <div className='mb-3 px-0'>
                        <div className="mt-5 mb-2" style={{overflow:'hidden'}}>
                            <RedirectButton redirect_to_path={() => redirect_to(
                                "project",
                                projectData.calculation_pk,
                                undefined,
                                true,
                                "project_edit_estimate"
                            )}>
                                {t("common.button.edit_estimate")}
                            </RedirectButton>
                        </div>
                        <LightCard>
                            <Staff staff={projectData.staff}
                                   titleSummary={" (E" + estimate_current + ")"}/>
                            <div>{t("project.kzm_status")} {projectData.kzmProject ? t("Yes") : t("No")}</div>
                        </LightCard>
                    </div>

                    <div className='mb-3 px-0'>
                        <div className="mt-5 mb-2" style={{overflow:'hidden'}}>
                            <RedirectButton redirect_to_path={() => redirect_to(
                                "project",
                                projectData.calculation_pk,
                                undefined,
                                true,
                                "project_edit_estimate"
                            )}>
                                {t("common.button.edit_estimate")}
                            </RedirectButton>
                        </div>
                        <LightCard>
                            <Events
                                eventDetails={projectData.eventDetails}
                                estimationID={" (E"+estimate_current+")"}
                                events={projectData.current_events}
                            />
                        </LightCard>
                    </div>
                  </>)
                : <div>{t("no data")}</div>
            }
        </DarkCard>
        <DarkCard classNames='mb-3 row'>
            <RedHeader title={t('project.feed_title')}/>
            <div className='mb-3 p-0 col-12'>
                <FeedsList projectID={projectData.id} />
            </div>
        </DarkCard>
    </>)


}