/* eslint-disable no-useless-escape */
import {useParams, useNavigate, Link} from "react-router-dom";
import {request} from "../../helpers/request";
// import {LoginResponseErrors} from "../../helpers/MessagesErrors/responseErrors";
import {Button} from "../../components/ui/Button";
import {FeedbackMessage} from "../../components/ui/Feedback_Message";
import {useEffect, useState} from "react";
import Icon from 'react-icons-kit';
import {basic_eye} from 'react-icons-kit/linea/basic_eye';
import {basic_eye_closed} from 'react-icons-kit/linea/basic_eye_closed';
import {arrows_exclamation} from 'react-icons-kit/linea/arrows_exclamation';
import {arrows_circle_check} from 'react-icons-kit/linea/arrows_circle_check';
import { useTranslation } from "react-i18next";

function ForgotPwdChange() {
    const { t } = useTranslation();

    const params = useParams();
    const navigate = useNavigate();
    const [validateUser, setValidateUser] = useState(false);
    const [email, setEmail] = useState('');
    const [type, setType] = useState('password');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [updatePassword, setUpdatePassword] = useState(false);

    // validated states
    const [lowerValidated, setLowerValidated]=useState(false);
    const [upperValidated, setUpperValidated]=useState(false);
    const [numberValidated, setNumberValidated]=useState(false);
    const [specialValidated, setSpecialValidated]=useState(false);
    const [lengthValidated, setLengthValidated]=useState(false);
    const [comparisonPwd, setComparisonPwd]=useState(false);

    useEffect(() => {
        if ('key' in params) {
            get_email(params.key);
        };
    }, [params]);

    useEffect(() => {
        if((password === passwordRepeat) & (password !== '')) {
            setComparisonPwd(true);
        } else {
            setComparisonPwd(false);
        }
    }, [password, passwordRepeat]);

    const get_email = async (key) => {
        // wyslij zapytanie o usera
        try {
            const response = await request.post(
                '/api/accounts/password-reset-verify',
                {'key': key}
            );
            const response_errors = response.data.errors;

            if (!response_errors) {
                setEmail(response.data.validate_user)
                setValidateUser(true);
            } else {
                setEmail('');
                setValidateUser(false);
            }

        } catch(error) {
            if (error.request.status === 0) {
                navigate("/network-error");
            };
        }
    };

    const handleChangePw = (value) => {
        const lower = new RegExp('(?=.*[a-z])');
        const upper = new RegExp('(?=.*[A-Z])');
        const number = new RegExp('(?=.*[0-9])');
        const special = new RegExp('(?=.*[!@#\$%\^&\*])');
        const length = new RegExp('(?=.{8,})')
        setPassword(value);

        if(lower.test(value)){
            setLowerValidated(true);
        }
        else{
            setLowerValidated(false);
        }
        if(upper.test(value)){
            setUpperValidated(true);
        }
        else{
            setUpperValidated(false);
        }
        if(number.test(value)){
            setNumberValidated(true);
        }
        else{
            setNumberValidated(false);
        }
        if(special.test(value)){
            setSpecialValidated(true);
        }
        else{
            setSpecialValidated(false);
        }
        if(length.test(value)){
            setLengthValidated(true);
        }
        else{
            setLengthValidated(false);
        }
    };

    const handleChangePwRep = (value) => {
        setPasswordRepeat(value);
    };
    const handleSubmit = async e => {
        e.preventDefault();

        // wyslij update password
        try {
            const response = await request.post(
                '/api/accounts/password-reset-update',
                {
                    'email': email,
                    'password': password,
                    'password2': passwordRepeat
                }
            );

            if(response.status === 200) {
                setUpdatePassword(true);
            };
        } catch(error) {
            if (error.request.status === 0) {
                navigate("/network-error");
            };
        };

    };

    return (
        <>
            <h1 className="underline">
                {t("reset_pw.title_before_log_in")}
            </h1>

            {validateUser & !updatePassword
            ? (
                <>
                <form onSubmit={handleSubmit}>
                    <div className='input-with-icon-div form-control'>
                    <input
                        className='dataInput'
                        placeholder={t("reset_pw.new_pw")}
                        name="password"
                        type={type}
                        required
                        value={password}
                        onChange={(e)=>handleChangePw(e.target.value)}
                    />

                    {type==="password"?(
                        <span className='icon-span' onClick={()=>setType("text")}>
                            <Icon icon={basic_eye_closed} size={18}/>
                        </span>
                    ):(
                        <span className='icon-span' onClick={()=>setType("password")}>
                            <Icon icon={basic_eye} size={18}/>
                        </span>
                    )}
                    </div>

                    <div className='input-with-icon-div form-control'>
                    <input
                        className='dataInput'
                        placeholder={t("reset_pw.new_pw_repeat")}
                        name="password1"
                        type={type}
                        required
                        value={passwordRepeat}
                        onChange={(e)=>handleChangePwRep(e.target.value)}
                    />
                    </div>

                    {/*<p className='mb-0'>*/}
                    {/*    <b>Password conditions</b>*/}
                    {/*</p>*/}

                    {/*<ul className='dashedList' style={{margin: '0'}}>*/}
                    {/*    /!*  add "success" class to li if the specifc requiremnt is implemented *!/*/}
                    {/*    <li className="">min 1 Capital letter</li>*/}
                    {/*    <li className="">min 1 Number</li>*/}
                    {/*    <li className="">min 1 Special character</li>*/}
                    {/*    <li className="">min 8 Characters </li>*/}
                    {/*</ul>*/}

                    <Button type="submit" bWidth="full">{t("reset_pw.btn_save_new_pw")}</Button>

              </form>
                <FeedbackMessage variant="">
                    {/*
                        Show success message instead of the form!!
                        Variant Success-Message: New password is saved! You will be redirected to the login page in 7 sec.

                        Show error message under the form!
                        Variant Error-Message: Oops! Something went wrong please try again later to reset your password!

                        ANDEK: Jesli hasla nie sa te same!
                        Show error message under the form!
                        Variant Error-Message: Oops! Your passwords are not matching!
                */}

                <main className='tracker-box'>
                <div className={lowerValidated?'validated':'not-validated'}>
                    {lowerValidated?(
                        <span className='list-icon green'>
                            <Icon icon={arrows_circle_check}/>
                        </span>
                        ):(
                        <span className='list-icon'>
                            <Icon icon={arrows_exclamation}/>
                        </span>
                    )}
                    {t("reset_pw.validation.lowercase_letter")}
                </div>
                <div className={upperValidated?'validated':'not-validated'}>
                    {upperValidated?(
                        <span className='list-icon green'>
                            <Icon icon={arrows_circle_check}/>
                        </span>
                        ):(
                        <span className='list-icon'>
                            <Icon icon={arrows_exclamation}/>
                        </span>
                    )}
                    {t("reset_pw.validation.uppercase_letter")}
                </div>
                <div className={numberValidated?'validated':'not-validated'}>
                    {numberValidated?(
                        <span className='list-icon green'>
                            <Icon icon={arrows_circle_check}/>
                        </span>
                        ):(
                        <span className='list-icon'>
                            <Icon icon={arrows_exclamation}/>
                        </span>
                    )}
                    {t("reset_pw.validation.one_no")}
                </div>
                <div className={specialValidated?'validated':'not-validated'}>
                    {specialValidated?(
                        <span className='list-icon green'>
                            <Icon icon={arrows_circle_check}/>
                        </span>
                        ):(
                        <span className='list-icon'>
                            <Icon icon={arrows_exclamation}/>
                        </span>
                    )}
                    {t("reset_pw.validation.special_character")}
                </div>
                <div className={lengthValidated?'validated':'not-validated'}>
                    {lengthValidated?(
                        <span className='list-icon green'>
                            <Icon icon={arrows_circle_check}/>
                        </span>
                    ):(
                        <span className='list-icon'>
                            <Icon icon={arrows_exclamation}/>
                        </span>
                    )}
                    {t("reset_pw.validation.8_characters")}
                </div>
                <div className={comparisonPwd?'validated':'not-validated'}>
                    {comparisonPwd?(
                        <span className='list-icon green'>
                            <Icon icon={arrows_circle_check}/>
                        </span>
                    ):(
                        <span className='list-icon'>
                            <Icon icon={arrows_exclamation}/>
                        </span>
                    )}
                    {t("reset_pw.validation.pws_match")}
                </div>
                </main>

                </FeedbackMessage>
                </>
                )
            :(<>
              <FeedbackMessage>
                  { updatePassword ? <span>{t("password_saved")}</span> : <span>{t("invalid_key_or_expired")}</span> }
              </FeedbackMessage>
              <Link to={"/login"} style={{ float: 'right' }}>
                {t("common.button.log_in")}
              </Link>
              </>)
            }
        </>
    )
};

export default ForgotPwdChange;


