import RoutesAdmin from "./RoutesAdmin";
import Menu from "./Menu/Menu";
import { Colors } from "../../../components/theme";
import Header from "./Header/Header";
import ContentBodyStyle from "../../../components/ContentBodyStyle";
import FullPageBackground from "../../../components/Full_Page_Background";
import NotificationsPopup from "../../../components/ui/NotificationsPopup/Notifications_Popup";
import { useState } from "react";

function MainAdmin() {
    const [isShow, setIsShow] = useState(false);

    return (
        <>
            {/*<PopupMessage variant='success' title='Are you sure?' message='You have unsaved changes that will be lost' leftBtn='No' rightBtn='Yes'/>*/}
            {
                isShow ? <NotificationsPopup/> : null
            }

            <Header isShow={isShow} setIsShow={setIsShow} />
            <ContentBodyStyle menu={<Menu />} routes={<RoutesAdmin />}/>
            <FullPageBackground bgColor={Colors.lightBG} />
        </>
    )
};

export default MainAdmin;