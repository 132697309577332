import { useTranslation } from "react-i18next";
import OtherCostsItem from "./OtherCostsItem";
import {Link} from "react-router-dom";
import LordIcon from "../ui/LordIcon";
import { Colors } from "../theme";
import SlideButton from "../ui/SlideButton";
import {v4 as uuidv4} from "uuid";
import PopupFormular from "../ui/PopupFormular";
import {Button} from "../ui/Button";
import CountingUp from "../../helpers/components/CountingUp";
import {getProjectSettings} from "../../services/api/user";
import {useState} from "react"
import {musiciansCount} from "../../helpers/tools";


function TransportCosts ({   transport, setTransport,
                             transp30km, setTransp30km,
                             project30kmAway, setProject30kmAway,
                             conductor30kmAway, setConductor30kmAway,
                             type, staff
}) {
    const { t } = useTranslation();
    const [manualTranspCost, setManualTranspCost] = useState(false)
    const [popup, setPopup] = useState({show: false})

    const addMoreChandler = () => {
        const newItem = {
            id: uuidv4(),
            description: '',
            client_cost: 0.00,
            estro_cost: 0.00,
            cost_type: type,
            edit: true,
            display_type: "X"
        };
        setTransport([...transport, newItem])
    };

    const changeHandler = (val, cost_type, item) => {
        const costClone = structuredClone(transport);
        const indexDel = costClone.findIndex(el => (el.cost_type=type && el.id === item.id));
        const newItem = {
            id: item.id,
            description: cost_type === 'description' ? val : item.description,
            client_cost: cost_type === 'client_cost' ? val : item.client_cost,
            estro_cost: cost_type === 'estro_cost' ? val : item.estro_cost,
            cost_type: type,
            edit: true,
            display_type: item.display_type
        };
        costClone.splice(indexDel, 1, newItem);
        setTransport(costClone)
    };

    const removeItem = (itemKey) => {
        const newItem = structuredClone(transport);
        const indexDel = newItem.findIndex(el => el.id === itemKey);
        newItem.splice(indexDel, 1);
        setTransport(newItem)
    };

    const handleManualCost = (obj) => {
        setManualTranspCost(obj)
        setPopup({
            show: true,
            variant: 'editF',
            title: t("popups.edit_price.title"),
            message: t("popups.edit_price.transport"),
            type: 'formular',
            context: {
                description: "Musicians",
                client_cost: project30kmAway.client_cost,
                estro_cost: project30kmAway.estro_cost,
                conductor_client_cost: conductor30kmAway.client_cost,
                conductor_estro_cost: conductor30kmAway.estro_cost,
            },
            leftBtn: t("common.button.cancel"),
            rightBtn: t("common.button.save")
        })
    }

    const handlePopup = (obj) => {
        if (obj.type === 'save') {
            setProject30kmAway({
                ...project30kmAway,
                ['client_cost']: parseFloat(obj.client_cost).toFixed(2),
                ['estro_cost']: parseFloat(obj.estro_cost).toFixed(2),
            })
            setConductor30kmAway({
                ...conductor30kmAway,
                ['client_cost']: parseFloat(obj.conductor_client_cost).toFixed(2),
                ['estro_cost']: parseFloat(obj.conductor_estro_cost).toFixed(2),
            })
            // setPopup({show: false})
            setPopup({
                show: true,
                variant: 'success',
                title: t("popups.save_data.title"),
                message: t("popups.save_data.message"),
                type: 'feedback',
                okBtn: "OK"
            })
        }
        if (obj.type === 'cancel') {
            setPopup({show: false});
            setManualTranspCost(false)
            return
        }
        if (obj.type === 'OK') {
            setPopup({show: false})
            return;
        }
    }

    const handleReset = async () => {
        try {
            const responseSettings = await getProjectSettings()
            const resultTransp = responseSettings.data.other_cost_type.filter(
                type => type.cost_type === "transport"
            )
            const resultTransport = resultTransp.map(el => ({
                ...el,
                ["edit"]: true,
            }))

            const first_entries_transport = []
            const musiciansResultIndex = resultTransport.findIndex(
                el => el.description.toLowerCase() === "musicians"
            )
            if (musiciansResultIndex > -1) {
                const projectItem = resultTransport[musiciansResultIndex];
                setProject30kmAway({
                    description: projectItem.description,
                    client_cost: projectItem.client_cost,
                    estro_cost: projectItem.estro_cost,
                    name: projectItem.description,
                });

                const musiciansSum = musiciansCount(staff)
                const musicians_data = transp30km ?
                    {
                    id: "9999",
                    description: "Musicians (x "+ musiciansSum +")",
                    name: "Musicians",
                } : {}
                if (transp30km) {
                    first_entries_transport.push({...projectItem, ...musicians_data})
                }
            }
            const conductorIndex = resultTransport.findIndex(
                el => el.description.toLowerCase() === "conductor"
            )
            if (conductorIndex > -1) {
                const conductorItem = resultTransport[conductorIndex];
                setConductor30kmAway({
                    description: conductorItem.description,
                    client_cost: conductorItem.client_cost,
                    estro_cost: conductorItem.estro_cost,
                    name: conductorItem.description,
                })
            }
            // przelicz kwoty instrumentow w transport bo zmienilo sie projet30kmAway
            const selected_staff_in_transport = Object.values(staff)
                .filter(el => (
                        el.value > 0) &&
                    (el.transport_client_cost > 0 || el.transport_estro_cost > 0)
                )
                .reduce(function (acc, obj) {
                        const data = {
                            ['cost_type']:"transport",
                            ['description']: `${obj.value}x ${obj.name}`,
                            ['display_type']:"descro",
                            ['edit']:true,
                            ['client_cost']: parseFloat(obj.transport_client_cost * obj.value).toFixed(2),
                            ['estro_cost']: parseFloat(obj.transport_estro_cost * obj.value).toFixed(2),
                            ['id']: obj.name
                        }
                        acc.push(data)
                    return acc
                }, [])
            // rest entries from transport - handles entries
            const transpClone = structuredClone(transport)
            const name_list_selected_staff_in_transport = selected_staff_in_transport.map(el => {
                return el.id
            })
            const rest_entries_transport = transpClone.filter(
                el => (!name_list_selected_staff_in_transport.includes(el.id) && el.id !== "9999" && el.id !== "9988")
            )
            if (rest_entries_transport) {
                selected_staff_in_transport.push(...rest_entries_transport)
            }
            if (selected_staff_in_transport) {
                if (transp30km) {
                    selected_staff_in_transport.unshift(...first_entries_transport)
                }
                setTransport(selected_staff_in_transport)
            }
        } catch(ex) {
            console.log("Failed to download settings")
        }
    }

    return (
        popup.show
            ? <PopupFormular
                handlePopup={handlePopup}
                variant={popup.variant}
                title={t(popup.title)}
                message={t(popup.message)}
                leftBtn={popup.leftBtn}
                rightBtn={popup.rightBtn}
                okBtn={popup.okBtn}
                context={popup.context}
            />
            :<>
                <div className="row px-2">
                    <div className="d-flex flex-row mb-1">
                        <div className="align-self-center">
                            <b>{t("transport.30_km_away")}</b>
                        </div>
                        <div className="ms-3">
                            <SlideButton type='yesNo' val={transp30km} setVal={setTransp30km}/>
                        </div>
                    </div>
                    {transp30km &&
                        <div className="row px-2">
                            <Button
                                onClick={handleManualCost}
                                variant="round"
                                bWidth="small"
                                styling="plain"
                                className='mx-3 py-1 mt-0 mb-1'
                                >  
                                {t("common.button.edit_price")}
                            </Button>
                        </div>
                    }
                </div>

                <div className="row px-2">
                    <div className="col-12">
                        <div className="d-flex flex-row align-items-center my-2">
                            <div style={{width:'46%'}}>
                                <b>{t("common.titles.description")}</b>
                            </div>
                            <div style={{width:'25%'}} className="ms-2">
                                <b>{t("rates.clients_costs")}</b>
                            </div>
                            <div style={{width:'25%'}} className="ms-2">
                                <b>{t("rates.estros_costs")}</b>
                            </div>
                        </div>
                        {transport.map(el => (
                            <OtherCostsItem
                                key={el.id}
                                item={el}
                                setCost={changeHandler}
                                type={type}
                                // disabled={el.edit ? '' : "disabled"}
                                removeItem={removeItem}
                            />
                            )
                        )}
                        <CountingUp entry={transport} blockAmount={3} />
                    </div>
                </div>
                <div className='row px-2 mt-2'>
                    <p className="mt-3 mb-0" style={{textAlign:'right'}}>
                        <a onClick={handleReset}>{t("rates.reset_to_primary_values")}</a>
                    </p>
                    <div className="col-12">
                        <Link className="addGroup pt-1">
                            <LordIcon url='cross' color={`${Colors.red};`} rotate='45' target='.addGroup'/>
                            <span className="ps-2" onClick={addMoreChandler}>{t("common.button.add_more")}</span>
                        </Link>
                    </div>
                </div>
            </>
    )
};
export default TransportCosts;
        