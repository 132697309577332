import {Link, Text, View} from "@react-pdf/renderer";
import {stylesFooter, styles} from "./Styles/Styles";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {getPrintingDocSelected} from "../../../services/functionsAPI";
import {position_admin, position_creative} from "../../dictionary/aplication";

const Footer = ({business}) => {
    const { t } = useTranslation();
    const [printingData, setPrintingData] = useState({[position_admin]: "", [position_creative]: ""})

    useEffect(() => {
        const getPrintingSelected = async () => {
            const response = await getPrintingDocSelected()
            if (response) {
                setPrintingData(response)
            }
        }
        getPrintingSelected()
    }, [])

    return (
        <View fixed style={stylesFooter.section}>

            <View>
                <Text style={styles.pageNumber}
                      render={({ pageNumber, totalPages }) =>
                          `${pageNumber} / ${totalPages}`
                      }
                />
            </View>

            <View style={{flexDirection:'row', borderTop: '2px solid #e0e0e0', marginHorizontal: 30, paddingTop: 15}}>
                {/* 1st Column */}
                <View style={{width:'23%'}}>
                    <Text style={[styles.textBold, {paddingBottom:1}]}>
                        {business.name}
                    </Text>
                    <Text style={{paddingVertical:1}}>
                        RCS: {business.rcs}
                    </Text>
                    <Text style={{paddingVertical:1}}>
                        &nbsp;
                    </Text>
                    <Text style={{paddingVertical:1}}>
                        <Link src="https://estro.lu">www.estro.lu</Link>
                    </Text>
                </View>
                {/* 2nd Column */}
                <View style={{width:'23%'}}>
                    <Text style={[styles.textBold, {paddingBottom:1}]}>
                        {t("offer.pdf_document.texts.art_director_title")}
                    </Text>
                    <Text style={{paddingVertical:1}}>
                        {printingData[position_creative].cn}
                    </Text>
                    <Text style={{paddingVertical:1}}>
                        <Link src={"tel:"+printingData[position_creative].telephone}>{printingData[position_creative].telephone}</Link>
                    </Text>
                    <Text style={{paddingVertical:1}}>
                        <Link src={"mailto:"+printingData[position_creative].email}>{printingData[position_creative].email}</Link>
                    </Text>
                </View>
                {/* 3rd Column */}
                <View style={{width:'23%'}}>
                    <Text style={[styles.textBold, {paddingBottom:1}]}>
                        {t("offer.pdf_document.texts.admin_title")}
                    </Text>
                    <Text style={{paddingVertical:1}}>
                        {printingData[position_admin].cn}
                    </Text>
                    <Text style={{paddingVertical:1}}>
                        <Link src={"tel:"+printingData[position_admin].telephone}>{printingData[position_admin].telephone}</Link>
                    </Text>
                    <Text style={{paddingVertical:1}}>
                        <Link src={"mailto:"+printingData[position_admin].email}>{printingData[position_admin].email}</Link>
                    </Text>
                </View>
                {/* Last Column */}
                <View style={{width:'31%'}}>
                    <Text style={[styles.textBold, {paddingBottom:1}]}>
                        {t("offer.pdf_document.texts.bank_data")}
                    </Text>
                    <Text style={{paddingVertical:1}}>
                        {business.bank_name}
                    </Text>
                    <Text style={{paddingVertical:1}}>
                        IBAN: {business.bank_account_no}
                    </Text>
                    <Text style={{paddingVertical:1}}>
                        BIC: {business.bic}
                    </Text>
                </View>
            </View>

            <View style={{width: "100%",height: 20,backgroundColor: "#b41f23", marginTop:"15px"}}></View>
        </View>
    );
}

export default Footer