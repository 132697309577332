import { useTranslation } from "react-i18next";

const data = {
    staff: undefined,
    events: undefined,
    eventDetail: undefined,
    details: undefined,
    administration: undefined,
    archiving: undefined,
    clientKzm: undefined,
    clientMus: undefined,
    conductor: undefined,
    estroKzm: undefined,
    estroMus: undefined,
    eventDuration: undefined,
    kzmProject: undefined,
    renting: undefined,
    project30kmAway: undefined,
    conductor30kmAway: undefined,
    transp30km: undefined,
    transport: undefined,
    calculationName: undefined,
    calculNameEditInput: undefined,
    locations: undefined,
    totaling: {},
    program: undefined,
}

const INITIAL_OPTIONS = { value: 0, label: "-----" };
const popupInitial = {show: false,}

const INITIAL_renderDataToPDF = {
    project: {}, // {name: "", id: ""}
    estimateFormDataVisible: false,
    clientsList: INITIAL_OPTIONS,
    client: INITIAL_OPTIONS,
    firm: INITIAL_OPTIONS,
    calculation: {},
    program: "",
    comment: "",
    language: INITIAL_OPTIONS,
    estimateDate: new Date(),
    showEstroGrant: false,
    popup: popupInitial,
    isGeneratePDF: false,
    estimateID: undefined,
}

const popupInit = {show: false, title: 'popups.generate_pdf.title', variant: 'error', message: ''}


export {
    data,
    INITIAL_renderDataToPDF,
    popupInitial,
    popupInit,
}