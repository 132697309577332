import { Colors } from "../theme";

function ShowFormDataGroup({label,inputContent}) {
    return (
        <>
            <div className="mb-4">
                <p className="p-0 m-0" style={{color: `${Colors.darkGray}`}}>{label}</p>
                <p className="p-0 m-0">{inputContent}</p>
            </div>
        </>
    )
}

export default ShowFormDataGroup;