import { Colors } from "../theme";

const WhiteCardStyle = {
    backgroundColor: `${Colors.white}`,
    borderRadius: '15px',
    padding: '20px',
    width: '100%'
}

export default function WhiteCard({children, classNames, id}) {
    return (
        <div key={"zzz_"+id} style={WhiteCardStyle} className={classNames}>
            {children}
        </div>
    );
}