import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { languagesListAPI } from "../../../../../helpers/getLanguages";
import React, { useEffect, useState } from "react";
import { getUserProfileAPI } from "../../../../../services/api/user";
import ShowView from "./show_view/ShowView";

function PersonalData() {
	const [profile, setProfile] = useState();
	const [languages, setLanguages] = useState([]);
	const [breadCrumbs, setBreadCrumbs] = useState(["Profile", "Personal Data"]);
	const [editProfile, setEditProfile] = useState(false);

	useEffect(() => {
		getUserProfileAPI().then(response => {
			setProfile(response.data);
		});
		languagesListAPI().then(resp => {
			setLanguages(resp);
		});
	}, []);

	const MainView = () => {
		return (
			<>
				<Breadcrumbs
					breadCrumbs={breadCrumbs}
					setBreadCrumbs={setBreadCrumbs}
					setEditProfile={setEditProfile}
					editProfile={editProfile}
				/>
				<ShowView profile={profile} />
			</>
		);
	};

	return profile && languages && <MainView />;
}

export default PersonalData;
