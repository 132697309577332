import styled from "styled-components";


function Notifications () {
    return (
        <>
            Notifications Page    
        </>
)
};
export default Notifications;

