const actionTypes = {list: "list", details: "details", create: "create", edit: "edit", pathReturn: "pathReturn"}

const field_list = {
    first_name: "",
    last_name: "",
    phoneCountry: "",
    telephone: "",
    email: "",
    responsible: [],
    note: "",
    firms: [],
}

const validationRules = {
    first_name: ["required"],
    last_name: ["required"],
    phoneCountry: ["required"],
    telephone: ["required"],
    email: ["email"],
    responsible: [],
    note: [],
    firms_not_selected: [],
}

const INITIAL_DOC = {
    action: null,
    firms_not_selected: [], // lista firm w options niewybranych
    formData: field_list,
    popup: {show: false},
    breadCrumbsParams: [],
    pathReturn: [],
}

const returnPath = (act) => {
    return act === actionTypes.create ? actionTypes.list : actionTypes.details
}

function clientReducer(prevDoc, action) {
    switch (action.type) {
        case "SET_ACTION":
            return {...prevDoc, action: action.action}
            break
        case "SET_BREADCRUMBS":
            console.log("reducer breadCrumbsParams: ", action)
            return {...prevDoc, breadCrumbsParams: action.params_list}
            break;
        case "SET_PATH_RETURN":
            return {...prevDoc, pathReturn: action.pathReturn}
            break
        case "SET_ACTION_BREADCRUMBS":
            return {...prevDoc, action: action.action, breadCrumbsParams: action.breadCrumbsParams}
            break
        case "CANCEL":
            const storedData = JSON.parse(sessionStorage.getItem("client-data"))
            if (storedData && storedData.pathReturn.length > 0) {
                return {...prevDoc, action: actionTypes.pathReturn}
            } else return {...prevDoc, action: returnPath(prevDoc.action)}
            break;
        case "SET_CLIENT_DATA":
            return {...prevDoc, formData: {...prevDoc.formData, [action.data.fieldName]: action.data.value}}
            break;
        case "INIT_CLIENT_DATA":
            return {
                ...prevDoc,
                formData: action.formData,
                firms_not_selected: action.firms_not_selected,
            }
            break
        case "SET_FIRMS":
            return {...prevDoc, firms_not_selected: action.firms_not_selected}
            break
        case "SET_TYPES_FORM":
            return {...prevDoc, typesFirm: action.data}
            break
        case "SET_POPUP":
            return {...prevDoc, popup: action.popup}
            break
        case "POST_SAVE":
            return {...prevDoc, action: action.action, popup: action.popup}
            break
        default:
            return prevDoc;
    }
    throw Error('Unknown action: ' + action);
}

export {clientReducer, actionTypes, INITIAL_DOC, field_list, validationRules}