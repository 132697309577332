import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import React from "react";
import LightCard from "../../../../../../components/card/LightCard";
import {instrNameCol1, instrNameCol2, instrNameCol3, instrNameCol4} from "../../../../../../helpers/dictionary/users";
import {useTranslation} from "react-i18next";


const contextStaff = (el, i, value) => (
    <div key={i} className="d-flex flex-row align-items-center mb-1">
        <div style={{width:'35%'}}>
            {el} 
        </div>
        <div style={{width:'20%', textAlign:'center'}}>
            {value || "-"}
        </div>
    </div>
)


export default function Staff({staff, titleSummary}) {
    const {t} = useTranslation()
    
    // Calculate the sum of all staff values (numeric values only)
    const totalStaffSum = Object.keys(staff).reduce((sum, key) => {
        const value = Number(staff[key]); // Convert the string to a number
        return !isNaN(value) && value > 0 ? sum + value : sum; // Add value to sum if it's a valid number and greater than 0
    }, 0);

    return (<>
        <RedHeader title={t('project.staff_title')+` (${totalStaffSum})` +titleSummary}/>
            <div className='mb-3 row'>
                <LightCard>
                    <div className='row px-2'>
                        <div className='col-sm-6 col-md-3 d-flex flex-column'>
                            {instrNameCol1.map((el, i) => contextStaff(el, i, staff[el]))}
                        </div>
                        <div className='col-sm-6 col-md-3 d-flex flex-column'>
                            {instrNameCol2.map((el, i) => contextStaff(el, i, staff[el]))}
                        </div>
                        <div className='col-sm-6 col-md-3 d-flex flex-column'>
                            {instrNameCol3.map((el, i) => contextStaff(el, i, staff[el]))}
                        </div>
                        <div className='col-sm-6 col-md-3 d-flex flex-column'>
                            {instrNameCol4.map((el, i) => contextStaff(el, i, staff[el]))}
                        </div>
                    </div>
                </LightCard>
            </div>

    </>)
}