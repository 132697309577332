import Logo from "../../../../../../components/ui/Logo";
import LanguageButtons from "../../../../../../components/ui/LanguageButtons";
import {useTranslation} from "react-i18next";

const LogoStyle = {
    maxWidth: '140px',
    width: '100%',
}

export default function QuoteLinkNotActive() {
    const {t} = useTranslation();
    return (
    <>
        <div className="py-4" style={{backgroundColor:'#f7f4f1', marginTop: '-44px', marginBottom:'-85px', minHeight: '100vh'}}>
            <div className="container">
                <div className='row align-items-center mb-3'>
                    <div className='col px-0'>
                        <a href="https://estro.lu">
                            <Logo styling={LogoStyle}/>
                        </a>
                    </div>
                    <div className='col px-0'>
                        <div style={{maxWidth:'120px', marginLeft:'5px', marginRight:'5px0', float:'right'}}>
                            <LanguageButtons />
                        </div>
                    </div>
                </div>
                
                <div className='row mb-3' style={{textAlign:'center', paddingTop:'80px'}}>
                    <p className="pb-4" style={{fontSize:'1.3em'}}>
                        {t("offer.link_not_active")}
                    </p>
                </div>
            </div>
        </div>
    </>)
}