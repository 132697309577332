import { useTranslation } from "react-i18next";
import React from "react";
import ShowFormDataGroup from "../../../../../../components/form/ShowFormData";
import {format_date_convert} from "../../../../../../helpers/tools";

export const PersonalDataShow = ({profile}) => {
    const { t } = useTranslation();

    const lang_other = profile.languages.other.map((el, i) => {
            const separator = i+1 < profile.languages.other.length ? ', ' : '';
            return el.label + separator;
        });

    return (
        <>
            <div className="col-xxl-6">
                <ShowFormDataGroup label={t("profile.first_name")} inputContent={ profile.first_name }/>
                <ShowFormDataGroup label={t("profile.last_name")} inputContent={ profile.last_name }/>
                <ShowFormDataGroup label={t("profile.birth_date")} inputContent={ format_date_convert(profile.birth_date) }/>
                <ShowFormDataGroup label={t("profile.gender.title")} inputContent={ profile.gender.label }/>
                <ShowFormDataGroup label={t("profile.phone_number")} inputContent={ `${profile.phoneCountry.label} - ${profile.phoneNumber} `}/>
                <ShowFormDataGroup label={t("profile.email_address")} inputContent={ profile.email }/>
            </div>

            <div className="col-xxl-6">
                <ShowFormDataGroup label={t("profile.street")} inputContent={ profile.personal_address.street }/>
                <ShowFormDataGroup label={t("profile.house_apartment_number")} inputContent={ profile.personal_address.number }/>
                <ShowFormDataGroup label={t("profile.post_code")} inputContent={ profile.personal_address.post_code }/>
                <ShowFormDataGroup label={t("profile.city")} inputContent={ profile.personal_address.place }/>
                <ShowFormDataGroup label={t("profile.country")} inputContent={ profile.personal_address.country }/>
                <ShowFormDataGroup label={t("profile.musician_status")} inputContent={ profile.musician_status ? t("Yes") : t("No") } />
                <ShowFormDataGroup label={t("profile.preferred_language")} inputContent={ profile.languages.main.label } />
                <ShowFormDataGroup label={t("profile.other_languages")} inputContent={ lang_other } />
            </div>
        </>
    )


};

