import lottie from 'lottie-web';
import { defineElement } from 'lord-icon-element';
import { Colors } from '../theme';

// START
// https://lordicon.com/docs/web
// https://stackblitz.com/edit/lord-icon-custom-trigger?file=index.html,index.js
import { Element } from 'lord-icon-element/element';

const CLICK_EVENTS = [
  { name: 'mousedown' },
  { name: 'touchstart', options: { passive: true } },
];

class Custom {
  element;
  targetElement;
  player;

  constructor(element, targetElement, player) {
    this.element = element;
    this.targetElement = targetElement;
    this.player = player;
    this.direction = this.reverse ? -1 : 1;
    this.onClick = this.onClick.bind(this);
  }

  onConnected() {
    for (const event of CLICK_EVENTS) {
      this.targetElement.addEventListener(
        event.name,
        this.onClick,
        event.options
      );
    }
  }

  onDisconnected() {
    for (const event of CLICK_EVENTS) {
      this.targetElement.removeEventListener(event.name, this.onClick);
    }

    this.player.direction = 1;
  }

  onReady() {
    this.player.direction = this.direction;

    if (this.reverse) {
      this.player.goToLastFrame();
    }
  }

  onComplete() {
    this.direction = -this.direction;
    this.player.direction = this.direction;
  }

  onClick() {
    if (!this.player.isPlaying) {
      this.player.play();
    }
  }

  get reverse() {
    return this.element.hasAttribute('reverse');
  }
}

Element.defineTrigger('custom', Custom)

// END

defineElement(lottie.loadAnimation);

const handleType = type => {
    switch (type) {
        case "home":
          return "https://cdn.lordicon.com/slduhdil.json";
        case "avatar":
          return "https://cdn.lordicon.com/hbvyhtse.json";
        case "folder":
          return "https://cdn.lordicon.com/xhcrhqyw.json";
        case "wallet":
          return "https://cdn.lordicon.com/gsvbkwao.json";
        case "doc":
          return "https://cdn.lordicon.com/zncllhmn.json";
        case "settings":
          return "https://cdn.lordicon.com/dycatgju.json";
        case "bell":
          return "https://cdn.lordicon.com/msetysan.json";
        case "arrow":
          return "https://cdn.lordicon.com/albqovim.json";
        case "menu":
          return "https://cdn.lordicon.com/wgwcqouc.json";
        case "location":
          return "https://cdn.lordicon.com/oaflahpk.json";
        case "musician":
          return "https://cdn.lordicon.com/nkrqhzkh.json";
        case "sale":
          return "https://cdn.lordicon.com/xcevpeyr.json";
        case "client":
          return "https://cdn.lordicon.com/mkohcysd.json";
        case "instrument":
          return "https://cdn.lordicon.com/zvllgyec.json";
        case "asbl":
          return "https://cdn.lordicon.com/xryjrepg.json";
        case "error":
          return "https://cdn.lordicon.com/jfhbogmw.json";
        case "success":
          return "https://cdn.lordicon.com/yqzmiobz.json";
        case "chat":
          return "https://cdn.lordicon.com/pkmkagva.json";
        case "cross":
          return "https://cdn.lordicon.com/jfhbogmw.json";
        case "editF":
          return "https://cdn.lordicon.com/yxczfiyc.json";
        case "loading":
            return "https://cdn.lordicon.com/yxczfiyc.json";
        default:
          return type;
    }
}

export default function LordIcon({url,rotate,color,target,size,trigger,position,changeActive}) {

  const IconStyle = {
    width: `${size || '25px'}`,
    height: `${size || '25px'}`,
    verticalAlign: `${position || 'top'}`,
    transform: `rotate(${rotate || 0}deg)`,
  };

  return (
      <lord-icon
          src={handleType(url)}
          trigger={`${trigger || 'hover'}`}
          target={target}
          colors={`primary:${color?color:Colors.white},secondary:${Colors.white}`}
          style={IconStyle}
          onClick={changeActive}
      >
      </lord-icon>
  )
}