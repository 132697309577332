import { Colors } from "../../../../../../components/theme"
import {useTranslation} from "react-i18next";
import { EditButton } from "../../../../../../components/ui/EditButton";
import {Link} from "react-router-dom";
import LordIcon from "../../../../../../components/ui/LordIcon";
import React from "react";
import Address from "./Address";
import {TextStyle, TitleStyle} from "../../../../../../layouts/styles";


const ClientDetails = ({details, client, handleEditClient}) => {
    const { t } = useTranslation()

    return (<>
        <div>
            <div className="row">
                <EditButton gotoName={"/clients/contacts/edit/"+client.value} handleClickProps={() => handleEditClient(true)}/>
            </div>
            <div className="row px-1">
                <div className="col-6">
                    <p style={TitleStyle}>
                        {t("profile.first_name")}
                    </p>
                    <p style={TextStyle}>
                        {details.first_name}
                    </p>
                </div>
                <div className="col-6">
                    <p style={TitleStyle}>
                        {t("profile.last_name")}
                    </p>
                    <p style={TextStyle}>
                        {details.last_name}
                    </p>
                </div>
            </div>
            <div className="row px-1">
                <div className="col-6">
                    <p style={TitleStyle}>
                        {t("profile.phone_number")}
                    </p>
                    <p style={TextStyle}>
                        {details.phoneCountry} {details.telephone}
                    </p>
                </div>

                <div className="col-6">
                    <p style={TitleStyle}>
                        {t("profile.email_address")}
                    </p>
                    <p style={TextStyle}>
                        {details.email}
                    </p>
                </div>
            </div>
            <Address details={details} />
        </div>
    </>)
}

export default ClientDetails
