import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import React from "react";
import {useTranslation} from "react-i18next";
import QuoteItem from "./QuoteItem";

export default function Quote({quotes, project, grantEstroSum, clientAndFirm}) {
    const {t} = useTranslation()

    return (
    <>
        <RedHeader title={t('project.quotes_title')}/>
        <div className='row mx-0'>
            {quotes.length > 0 ? (
                quotes.map((quote, index) => (
                    <QuoteItem
                        key={index}
                        quote={quote}
                        index={index + 1}
                        project={project}
                        grantEstroSum={grantEstroSum}
                        clientAndFirm={clientAndFirm}
                    />
                ))
            ) : (
                <span className="ps-2">{t("project.quote_no_data")}</span>
            )}
        </div>
    </>)
}

