// import { useTranslation } from "react-i18next";
import React, {useEffect, useState} from "react";
import {fi} from "date-fns/locale";
import {decimalValidate} from "../../../../../../helpers/validate";
import styled from "styled-components";
import {Colors} from "../../../../../../components/theme";

const RemoveIcon = styled.i`
	position: absolute;
	right: -8px;
	top: -12px;
	padding: 3px 5px 3px 6px;
	border-radius: 50%;
	height: 23px;
	width: 23px;
	font-size: 0.9rem;
	vertical-align: middle;
	color: white;
	background-color: ${Colors.darkestGray};
	&:hover {
		background-color: ${Colors.red};
	}
`;

const OtherCostsItem = ({   unitType, keyId, description, cCosts, eCosts, amountType, amount,
                            readOnly, disabled, changeUnitInfo, removeItem, display_type
}) => {
    const obj_inform = {
        key: keyId,
        description: description,
        client_cost: cCosts,
        estro_cost: eCosts,
        readOnly: readOnly,
        display_type: display_type,
    };
    const [unitInfo, setUnitInfo] = useState(obj_inform);

    const changeCostHandler = (val, fieldName) => {
        const newEntry = {...unitInfo, [fieldName]: val};
        setUnitInfo(newEntry);
        changeUnitInfo(newEntry, unitType);
    };

    const changeHandler = (val, fieldName) => {
        if (fieldName === "client_cost" || fieldName === "estro_cost") {
            if (decimalValidate(val)) {
                changeCostHandler(val, fieldName)
            }
        } else {
            const newEntry = {...unitInfo, [fieldName]: val};
            setUnitInfo(newEntry);
            changeUnitInfo(newEntry, unitType);
        };
    }

    return (<>
        <div style={{width: amountType ? '35%' : '46%'}}>
            <input
                readOnly={readOnly}
                value={unitInfo.description}
                onChange={val => changeHandler(val.target.value, 'description')}
                style={{width:'100%'}}
            />
        </div>
        {
            amountType != null &&
            <div style={{width:'11%'}} className="ms-2">
                {
                    amountType &&
                    <input style={{width:"65%", maxWidth:"80px"}} value={amount} disabled={disabled} />
                }
                &nbsp;{amountType}
            </div>
        }
        <div style={{width:'27%'}} className="ms-2">
            <input
                value={unitInfo.client_cost}
                onChange={val => changeHandler(val.target.value, 'client_cost')}
                style={{width:'100%', maxWidth:'80px'}}
            /> €
        </div>
        <div style={{width:'27%'}} className="ms-2">
            <input
                value={unitInfo.estro_cost}
                onChange={val => changeHandler(val.target.value, 'estro_cost')}
                style={{width:'100%', maxWidth:'80px'}}
            /> €
        </div>

        <div
            className='customSelectParent'
            style={{ position: "relative" }}>
            {!readOnly &&
                <RemoveIcon
                    className='fa fa-times'
                    onClick={() => removeItem(keyId, unitType)}
                />
            }
        </div>
    </>)
};
export default OtherCostsItem;
        