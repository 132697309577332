import { Colors } from "../theme";

const DarkCardStyle = {
    backgroundColor: `${Colors.mediumBG}`,
    borderRadius: '15px',
    padding: '20px',
    width: '100%'
}

export default function DarkCard({children, classNames}) {
    return (
        <div style={DarkCardStyle} className={classNames}>
            {children}
        </div>
    );
}