import {defaultFormPD, defaultFormBank, defaultFormInstr} from "../components/user/registrationMapData";
import _ from "lodash";
// import { da } from "date-fns/locale";
// import { v4 as uuidv4 } from 'uuid';
// import {format, parseISO} from "date-fns";

const personal_data_to_formular = (data) => {
    const formPD = _.cloneDeep(defaultFormPD);
    // const birth_date = format(parseISO(data.birth_date), "dd-MM-yyyy");
    formPD.firstName.value = data.first_name;
    formPD.lastName.value = data.last_name;
    formPD.gender.value = data.gender;
    formPD.birthDate.value = new Date(data.birth_date);
    formPD.phoneCountry.value = {
        value: data.phoneCountry.value,
        label: data.phoneCountry.label
    };
    formPD.phoneNumber.value = data.phoneNumber;
    formPD.email.value = data.email;
    formPD.street.value = data.personal_address.street;
    formPD.houseNumber.value = data.personal_address.number;
    formPD.postCode.value = data.personal_address.post_code;
    formPD.city.value = data.personal_address.place;
    formPD.country.value = {
        value: data.personal_address.country,
        label: data.personal_address.country
    };
    formPD.mainLang.value = data.languages.main;
    formPD.additionalLang.value = data.languages.additional;

    formPD.musicianStatus.value = data.musician_status;

    Object.keys(formPD).forEach((key) => {
        formPD[key].error = false;
    });

    return formPD
};

const bank_details_to_formular = (data) => {
    const formBD = _.cloneDeep(defaultFormBank);
    formBD.bankName.value = data.bank_name;
    formBD.accountNumber.value = data.bank_account_no;
    formBD.accountHolder.value = data.bank_account_holder;
    formBD.bicNumber.value = data.bic;

    Object.keys(formBD).forEach((key) => {
        formBD[key].error = false;
    });

    return formBD;
};

const instruments_to_formular = (data) => {
    const formInstr = []; // _.cloneDeep(defaultFormInstr);

    data.forEach(el => {
        formInstr.push(
            {
                key: el.pk_instr, // ? el.pk_iud : uuidv4(),
                // key: uuidv4(),
                pk_iud: el.pk_iud,
                selected_type: true,
                selected_instr: true,
                value_type: {value: el.pk_type, label: el.name_type},
                value_instr: {value: el.pk_instr, label: el.name_instr},
                instrTypeOptions: [],
                instrOptions: [],
            }
        );

    })
    return formInstr
};

export {
    personal_data_to_formular,
    bank_details_to_formular,
    instruments_to_formular,
};
