const calculateInstruments = (obj) => {
    const valid = Object.entries(obj).every(entry => {
        return entry[1].selected_instr
    });
    return valid
};

const calculateNext = (obj) => {
    const valid = Object.entries(obj).every(entry => {
        return !obj[entry[0]].error
    });
    return valid
};

export {calculateInstruments, calculateNext}

