import {useContext} from "react";
import {AuthenticationContext} from "../contexts/AuthenticationContext";

export default function useAuth() {
    const authContext = useContext(AuthenticationContext)
    const auth = authContext.tokenUser && authContext.currentUser
    const setAuth = (value) => {
        if (value) {
            authContext.onLogin()
        } else {
            authContext.onLogout()
        }
    }
    return [auth, setAuth]
}