import {type} from "@testing-library/user-event/dist/type";

const phoneCountryList = [
    { value: "+359", label: "Bulgaria (+359)" },
    { value: "+48", label: "Polska (+48)" },
    { value: "+32", label: "België (+32)" },
    { value: "+49", label: "Deutschland (+49)" },
    { value: "+352", label: "Lëtzebuerg (+352)" },
    { value: "+33", label: "Françe (+33)" },
]

// lista zmiany jezykow
const languagesList = [
    // { value: "", label: "Main communication language *", isDisabled: true },
    { value: "1", label: "Lëtzebuergesch" },
    { value: "2", label: "Deutsch" },
    { value: "3", label: "Français" },
    { value: "4", label: "English" },
    { value: "5", label: "Polski" },
    { value: "6", label: "Pусский" },
];

const genderList = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
];

// kraje zamieszkania w personal data
const countryList = [
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Germany", label: "Germany" },
    { value: "France", label: "France" },
    { value: "Belgium", label: "Belgium" },
];

const musician_status = [
    // { value: "1", label: t("common.boolean.yes") },
    // { value: "0", label: t("common.boolean.no") },
    { value: "1", label: "yes" },
    { value: "0", label: "no" },
];

const radio_button_options = [
    { value: "1", label: "yes" },
    { value: "0", label: "no" },
];


const fieldMap = {
    'firstName': 'first_name',
    'lastName': 'last_name',
    'birthDate': 'birth_date',
    'phoneNumber': 'phone',
    'phoneCountry': 'phone',
    'musicianStatus': 'musician_status',
    'street': (val) => ({'personal_address': {'street': val}}),
    'houseNumber': (val) => ({'personal_address': {'number': val}}),
    'postCode': (val) => ({'personal_address': {'post_code': val}}),
    'city': (val) => ({'personal_address': {'place': val}}),
    'country': (val) => ({'personal_address': {'country': val.value}}),
    'mainLang': (val) => ({'languages': {'main': val.value}}),
    'additionalLang': (val) => ({'languages': {'additional': val.map(pk => pk.value)}}),
    'accountHolder': (val) => ({'bank_details': {'bank_account_holder': val}}),
    'accountNumber': (val) => ({'bank_details': {'bank_account_no': val}}),
    'bankName': (val) => ({'bank_details': {'bank_name': val}}),
    'bicNumber': (val) => ({'bank_details': {'bic': val}}),
};

const instrNameCol1 = ["Violin1", "Violin2", "Viola", "Cello", "Bass"];
const instrNameCol2 = ["Flute", "Oboe", "Clarinet", "Bassoon"];
const instrNameCol3 = ["French horn", "Trumpet", "Trombone", "Tuba"];
const instrNameCol4 = ["Organ", "Harpsichord", "Piano", "Harp", "Timpani", "Percussion"];
const instrNameOther = ['Conductor',]

const popupData = {
    saveOK: {
        show: true,
        variant: 'success',
        title: "popups.save_data.title",
        message: "popups.save_data.message",
        okBtn: "OK"
    },
    // createQuoteOK: {
    //     show: true,
    //     variant: 'success',
    //     title: "popups.save_data.title",
    //     message: "popups.save_data.message",
    //     okBtn: "OK"
    // },
    saveError: {
        show: true,
        variant: 'error',
        title: "popups.error_save_data.title",
        message: "popups.error_save_data.message",
        okBtn: "OK"
    },
    deleteError: {
        show: true,
        variant: 'error',
        title: "popups.error_delete_data.title",
        message: "popups.error_delete_data.message",
        okBtn: "OK"
    },
    infoError: {
        show: true,
        variant: 'error',
        title: "popups.error_get_data.title",
        message: "popups.error_get_data.message",
        okBtn: "OK"
    }
}

const calculationType = {
    calculation: 'calculation',
    estimate: 'estimate',
    quote: 'quote',
}

export {
    languagesList,
    genderList,
    countryList,
    phoneCountryList,
    musician_status,
    radio_button_options,
    fieldMap,
    instrNameCol1,
    instrNameCol2,
    instrNameCol3,
    instrNameCol4,
    instrNameOther,
    popupData,
    calculationType,
}

