const MenuHeaderStyle = {
    padding: '0px 10px 20px 10px',
    borderBottom: '1px solid white'
}

export default function MenuHeader({children}) {
    return (
        <div style={MenuHeaderStyle}>
            {children}
        </div>
    )
}