import {Button} from "../../../../../../components/ui/Button";
import {Colors} from "../../../../../../components/theme";
import {useTranslation} from "react-i18next";
import {Input, InputWrapperDiv} from "../../../../../../components/ui/Input";
import React, {useEffect, useState} from "react";
import {asblBusinessUpdateAPI} from "../../../../../../services/api/user";
import FormAddress from "../../Clients/FormAddress";
import LightCard from "../../../../../../components/card/LightCard";
import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import PopupMessage from "../../../../../../components/ui/PopupMessage";

const popupInit = {show: false, title: '', variant: 'error', message: ''}

const BusinesDataForm = ({data, setData, setEdit}) => {
    const {t} = useTranslation()
    const [form, setForm] = useState({})
    const [popup, setPopup] = useState(popupInit);

    useEffect(() => {
        setForm(structuredClone(data))
    }, [])

    const updateEvent = async (event) => {
        event.preventDefault()
        const response = await asblBusinessUpdateAPI(data.id, form)
        if (response.status === 200) {
            setPopup({show: true, title: t("popups.save_data.title"), variant: 'success', message: t("popups.save_data.message")})
            setData(response.data)
        } else {
            setPopup({show: true, title: t("popups.error_save_data.title"), variant: 'error', message: t("popups.error_save_data.message")})
        }
    }

    const handlePopup = (obj) => {
        if (popup.variant === 'success') {
            setEdit(false)
        }
        setPopup(popupInit)
    }

    const changeHandler = (val, fieldName) => {
        setForm({
            ...form,
            [fieldName]: val,
        })
    }

    const cancelHandler = () => {
        setEdit(false)
    }

    return (<>
        {popup.show
        ? <PopupMessage
            setPopup={handlePopup}
            variant={popup.variant}
            title={popup.title}
            message={popup.message}
            okBtn={t("common.button.ok")}
        />
        :
        <form name="update-form" onSubmit={updateEvent} className="ps-0">
            <div className="row">
                <div className="col-lg-6 col-xl-4 mb-4">
                    <LightCard>
                        <RedHeader title={t("asbl.details_title")}/>
                        <div className="row pb-2">
                            <div className='container'>
                                <InputWrapperDiv>
                                    {t("asbl.official_name")+' *'}
                                    <Input
                                        name="name"
                                        value={form.name}
                                        onChange={val => changeHandler(val, 'name')}
                                        required
                                    />
                                </InputWrapperDiv>
                            </div>

                            <div className='container py-3'>
                                <FormAddress form={form} changeHandler={changeHandler} required={true} />
                            </div>

                            <InputWrapperDiv>
                                {t("asbl.rcs_number")+' *'}
                                <Input
                                    name="rcs"
                                    value={form.rcs}
                                    onChange={val => changeHandler(val, 'rcs')}
                                    required
                                />
                            </InputWrapperDiv>
                        </div>
                    </LightCard>
                </div>
                <div className="col-lg-6 col-xl-4 mb-4">
                    <LightCard>
                        <RedHeader title={t("asbl.bank_details_title")}/>

                        <InputWrapperDiv>
                            {t("profile.bank.name")+' *'}
                            <Input
                                name="bank_name"
                                value={form.bank_name}
                                onChange={val => changeHandler(val, 'bank_name')}
                                required
                            />
                        </InputWrapperDiv>

                        <InputWrapperDiv>
                            {t("profile.bank.account_no")+' *'}
                            <Input
                                name="bank_account_no"
                                value={form.bank_account_no}
                                onChange={val => changeHandler(val, 'bank_account_no')}
                                required
                            />
                        </InputWrapperDiv>

                        <InputWrapperDiv>
                            {t("profile.bank.bic")+' *'}
                            <Input
                                name="bank_bic"
                                value={form.bic}
                                onChange={val => changeHandler(val, 'bic')}
                                required
                            />
                        </InputWrapperDiv>

                        <InputWrapperDiv>
                            {t("profile.bank.account_holder_name")+' *'}
                            <Input
                                name="bank_account_holder"
                                value={form.bank_account_holder}
                                onChange={val => changeHandler(val, 'bank_account_holder')}
                                required
                            />
                        </InputWrapperDiv>
                    </LightCard>
                </div>
            </div>
            <div>
                <Button
                    type="submit"
                    variant='round'
                    style={{backgroundColor:`${Colors.green}`}}
                    className={'me-3'}
                >
                    {t("common.button.save")}
                </Button>
                
                <Button
                    variant="round"
                    onClick={cancelHandler}
                    styling="bordered"
                    className={'me-3'}
                >
                    {t("common.button.cancel")}
                </Button>
            </div>
        </form>}
    </>)
}

export default BusinesDataForm

