import {Link} from "react-router-dom";
import Logo from "../../../../components/ui/Logo";
import {Colors} from "../../../../components/theme";
import LordIcon from "../../../../components/ui/LordIcon";
import LanguageButtons from "../../../../components/ui/LanguageButtons";
import styled from "styled-components";
import _ToDelete_DashboardMenu from "../../../dashboard/_ToDelete_DashboardMenu";
import MobileMenu from "../../../../components/menu/MobileMenu";
import Menu from "../Menu/Menu";
import React from "react";
import { useState } from "react";

const LogoStyle = {
    maxWidth: '140px',
    width: '100%',
}

const PageHeader = styled.div `
    padding: 20px 30px 15px 30px;
    position: fixed;
    z-index: 1;
    top: 0;
    height: 95px;
    display: none;
    background-color: ${Colors.lightBG};
    @media (min-height: 451px) and (min-width: 992px) {
        display: inline;
    }
`

const NotificationsBell = styled.div `
    position: relative;
    &:hover {
        cursor: pointer;
    }
`

function Header ({isShow, setIsShow}) {

    function handleNotificationsPopup (value) {
        setIsShow(!isShow);
        // Tutaj instrukcja jak zrobić aby okno znikneło gdy się kliknie poza
        // https://www.robinwieruch.de/react-hook-detect-click-outside-component/
    }

    return (
        <>
        <MobileMenu>
            <Menu />
        </MobileMenu>

        <PageHeader className='container-fluid'>
            <div className='row align-items-center'>
                <div className='col'>
                    <Link to={"/home"}>
                        <Logo styling={LogoStyle}/>
                    </Link>
                </div>
                <div className='col'>
                    <div className="d-flex flex-row-reverse">
                        <NotificationsBell onClick={handleNotificationsPopup}>
                            <div style={{position:'absolute', backgroundColor:Colors.darkRed, color:'white', padding:'3px 10px', borderRadius:'25px', fontSize:'13px', top:'0', left:'30px'}}>
                                1
                            </div>
                            <div className='menuLink' style={{padding:'0px 35px 0px 15px', lineHeight:'60px'}}>
                                <LordIcon url='bell' color='#2e2e2e' position='center'/>
                            </div>
                        </NotificationsBell>
                        <div style={{maxWidth:'120px', marginLeft:'5px', marginRight:'5px'}}>
                            <LanguageButtons />
                        </div>
                    </div>
                </div>
            </div>
        </PageHeader>        
        </>
    )
};
export default Header;
