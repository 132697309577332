import {Text, View} from "@react-pdf/renderer";
import React from "react";
import {styles} from "./Styles/Styles";
import {useTranslation} from "react-i18next";

const Intro = ({ project, estimateID, quoteID, typeData }) => {
        const { t } = useTranslation();

        return (
            <View style={{marginHorizontal: 30}}>
                    <Text style={styles.documentRef}>
                        {t("offer.pdf_document.texts.reference_short")}{"\u00A0"}
                        {typeData === 'estimate' ? (
                                <Text>{t("offer.pdf_document.texts.estimate")} E{estimateID}</Text>
                        ) : typeData === 'quote' ? (
                                <Text>{t("offer.pdf_document.texts.quote")} Q{quoteID}</Text>
                        ) : null}
                    </Text>
                    <Text style={styles.text}>
                        <Text>{t("offer.pdf_document.texts.project_id")} P{project.id} &nbsp; &nbsp; &nbsp;</Text>
                        <Text>{t("offer.pdf_document.texts.project_name")} {project.name}</Text>
                    </Text>
                    <Text style={styles.txtSection}>
                            {t("offer.pdf_document.texts.entry")}
                    </Text>
                    <Text style={[styles.txtSection, {marginBottom:"15px"}]}>
                            {t("offer.pdf_document.texts.all_details")}
                    </Text>
            </View>
        );
}

export default Intro
