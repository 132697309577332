import {Route, Routes} from "react-router-dom";
import MusiciansList from "../Musicians/MusiciansList";
import MusicianApproval from "../Musicians/MusicianApproval";
import MusicianProfile from "../Musicians/MusicianProfile";
import MusicianMap from "../Musicians/Map";

const MusiciansRoutes = () => {
    return (<>
    <Routes>
        <Route exact path={"/musicians/contacts"} element={<MusiciansList />}/>
        <Route exact path={"/musicians/contacts/profile/approval"} element={<MusicianApproval />}/>
        <Route exact path={"/musicians/contacts/profile"} element={<MusicianProfile />}/>
        <Route exact path={"/musicians/map"} element={<MusicianMap />}/>
    </Routes>
    </>)
}

export default MusiciansRoutes
