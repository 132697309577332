import {Link} from "react-router-dom";
import React from "react";
import styled from "styled-components";
import {Colors} from "../components/theme";

const routesCalcBreadCrumbs = (t, name, projectID=0, estimateID=0, calcID=0) => {

    const routesList = {
        project_edit_estimate: 
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.projects",{ ns: 'menu' })}</span> &#9656;
                <Link to={'/projects/in-preparation'}>{t("menu.inprep_projects",{ ns: 'menu' })}</Link> &#9656;
                <Link to={`/projects/in-preparation/${projectID}`}>{t("breadcrumbs.project_title")} P{projectID}</Link> &#9656;
                <span className="txt">{t("breadcrumbs.edit_estimate_title")} E{estimateID}</span>
            </p>,

        project_create_estimate: 
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.projects",{ ns: 'menu' })}</span> &#9656;
                <Link to={'/projects/in-preparation'}>{t("menu.inprep_projects",{ ns: 'menu' })}</Link> &#9656;
                <Link to={`/projects/in-preparation/${projectID}`}>{t("breadcrumbs.project_title")} P{projectID}</Link> &#9656;
                <span className="txt">{t("breadcrumbs.new_estimate_title")}</span>
            </p>,

        saved_calculation: 
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.sales", {ns: 'menu'})}</span> &#9656;
                <Link to={'/sales/saved_calculations'}>{t("menu.saved_calculations", {ns: 'menu'})} </Link> &#9656;
                <span className="txt">{t("breadcrumbs.calculation_title")} C{calcID}</span>
            </p>,

        new_calculation: 
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.sales", {ns: 'menu'})}</span> &#9656;
                <span className="txt">{t("menu.calculator", {ns: 'menu'})} </span>
            </p>,
    }
    return routesList[name]
}

const TxtTitles = styled.p`
	font-size: 0.7em;
    text-transform: uppercase;
	width: 100%;
    padding: 0 0 3px 0;
    margin: 0;
    color: ${Colors.darkRed};
`;

const titleHeadlineDict = (t, name) => ({
    project_edit_estimate: <TxtTitles>{t("project.estimate_name_title")}</TxtTitles>,
    // project_create_estimate: <TxtTitles>{t("project.create_estimate_name_title")}</TxtTitles>,
    saved_calculation: <TxtTitles>{t("project.calculation_name_title")}</TxtTitles>
})[name]

const titleHeadLineText = (t, session, calcID) => {
    let name = ""
    if (session) {
        name = session.action
    } else {
        name = calcID ? "saved_calculation" : "new_calculation"
    }
    return titleHeadlineDict(t, name)
}

const routesClientsBreadCrumbs = (t, name, contactID=0, projectID=0, compID=0, calcID=0) => {
    const routesList = {
        offer_add_contact:
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.sales", {ns: 'menu'})}</span> &#9656;
                <Link to={'/sales/saved_calculations'}>{t("menu.saved_calculations", {ns: 'menu'})} </Link> &#9656;
                <Link to={'/sales/calculator/'+calcID}>{t("breadcrumbs.calculation_title")} C{calcID} </Link> &#9656;
                <Link to={'/projects/offer/'+calcID}>{t("breadcrumbs.create_project_offer")} </Link> &#9656;
                <span className="txt">{t("breadcrumbs.clients_add_contact")}</span>
            </p>,
        offer_edit_contact:
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.sales", {ns: 'menu'})}</span> &#9656;
                <Link to={'/sales/saved_calculations'}>{t("menu.saved_calculations", {ns: 'menu'})} </Link> &#9656;
                <Link to={'/sales/calculator/'+calcID}>{t("breadcrumbs.calculation_title")} C{calcID} </Link> &#9656;
                <Link to={'/projects/offer/'+calcID}>{t("breadcrumbs.create_project_offer")} </Link> &#9656;
                <span className="txt">{t("breadcrumbs.clients_edit_contact")}</span>
            </p>,
        offer_add_contact_add_company:
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.sales", {ns: 'menu'})}</span> &#9656;
                <Link to={'/sales/saved_calculations'}>{t("menu.saved_calculations", {ns: 'menu'})} </Link> &#9656;
                <Link to={'/sales/calculator/'+calcID}>{t("breadcrumbs.calculation_title")} C{calcID} </Link> &#9656;
                <Link to={'/projects/offer/'+calcID}>{t("breadcrumbs.create_project_offer")} </Link> &#9656;
                <Link to={'/clients/contacts/create'}>{t("breadcrumbs.clients_add_contact")}</Link> &#9656;
                <span className="txt">{t("breadcrumbs.clients_add_company")}</span>
            </p>,
        offer_edit_contact_add_company:
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.sales", {ns: 'menu'})}</span> &#9656;
                <Link to={'/sales/saved_calculations'}>{t("menu.saved_calculations", {ns: 'menu'})} </Link> &#9656;
                <Link to={'/sales/calculator/'+calcID}>{t("breadcrumbs.calculation_title")} C{calcID} </Link> &#9656;
                <Link to={'/projects/offer/'+calcID}>{t("breadcrumbs.create_project_offer")} </Link> &#9656;
                <Link to={'/clients/contacts/edit/'+contactID}>{t("breadcrumbs.clients_edit_contact")}</Link> &#9656;
                <span className="txt">{t("breadcrumbs.clients_add_company")}</span>
            </p>,
        offer_edit_company:
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.sales", {ns: 'menu'})}</span> &#9656;
                <Link to={'/sales/saved_calculations'}>{t("menu.saved_calculations", {ns: 'menu'})} </Link> &#9656;
                <Link to={'/sales/calculator/'+calcID}>{t("breadcrumbs.calculation_title")} C{calcID} </Link> &#9656;
                <Link to={'/projects/offer/'+calcID}>{t("breadcrumbs.create_project_offer")} </Link> &#9656;
                <span className="txt">{t("breadcrumbs.clients_edit_company")}</span>
            </p>,
        /*ten aktualnie nie uzywany nie ma takiego przycisku na razie*/    
        offer_add_company:
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.sales", {ns: 'menu'})}</span> &#9656;
                <Link to={'/sales/saved_calculations'}>{t("menu.saved_calculations", {ns: 'menu'})} </Link> &#9656;
                <Link to={'/sales/calculator/'+calcID}>{t("breadcrumbs.calculation_title")} C{calcID} </Link> &#9656;
                <Link to={'/projects/offer/'+calcID}>{t("breadcrumbs.create_project_offer")} </Link> &#9656;
                <span className="txt">{t("breadcrumbs.clients_add_company")}</span>
            </p>,
        clients_contacts_add_contact:
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.clients",{ ns: 'menu' })}</span> &#9656; 
                <Link to={'/clients/contacts'}>{t("menu.contacts",{ ns: 'menu' })}</Link> &#9656;
                <span className="txt">{t("breadcrumbs.clients_add_contact")}</span> 
            </p>,
        clients_contacts_edit_contact:
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.clients",{ ns: 'menu' })}</span> &#9656; 
                <Link to={'/clients/contacts'}>{t("menu.contacts",{ ns: 'menu' })}</Link> &#9656;
                <Link to={'/clients/contacts/details/'+contactID}>{t("breadcrumbs.clients_contact")} {contactID}</Link> &#9656;
                <span className="txt">{t("breadcrumbs.clients_edit_contact")}</span> 
            </p>,
        clients_contacts_add_contact_add_company:
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.clients",{ ns: 'menu' })}</span> &#9656; 
                <Link to={'/clients/contacts'}>{t("menu.contacts",{ ns: 'menu' })}</Link> &#9656;
                <Link to={'/clients/contacts/create'}>{t("breadcrumbs.clients_add_contact")}</Link> &#9656;
                <span className="txt">{t("breadcrumbs.clients_add_company")}</span>
            </p>,
        clients_contacts_edit_contact_add_company:
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.clients",{ ns: 'menu' })}</span> &#9656; 
                <Link to={'/clients/contacts'}>{t("menu.contacts",{ ns: 'menu' })}</Link> &#9656;
                <Link to={'/clients/contacts/details/'+contactID}>{t("breadcrumbs.clients_contact")} {contactID}</Link> &#9656;
                <Link to={'/clients/contacts/edit/'+contactID}>{t("breadcrumbs.clients_edit_contact")} {contactID}</Link> &#9656;
                <span className="txt">{t("breadcrumbs.clients_add_company")}</span>
            </p>,
        clients_companies_add_company:
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.clients",{ ns: 'menu' })}</span> &#9656; 
                <Link to={'/clients/companies'}>{t("menu.companies",{ ns: 'menu' })}</Link> &#9656; 
                <span className="txt">{t("breadcrumbs.clients_add_company")}</span>
            </p>,
        clients_companies_edit_company:
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.clients",{ ns: 'menu' })}</span> &#9656; 
                <Link to={'/clients/companies'}>{t("menu.companies",{ ns: 'menu' })}</Link> &#9656;
                <Link to={'/clients/companies/details/'+compID}>{t("breadcrumbs.clients_company")} {compID}</Link> &#9656;
                <span className="txt">{t("breadcrumbs.clients_edit_company")}</span>
            </p>,
        /*ten aktualnie nie uzywany nie ma takiego przycisku na razie*/     
        project_edit_contact:
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.projects",{ ns: 'menu' })}</span> &#9656; 
                <Link to={'/projects/in-preparation'}>{t("menu.inprep_projects",{ ns: 'menu' })}</Link> &#9656; 
                <Link to={'/projects/in-preparation/'+projectID}>{t("breadcrumbs.project_title")} P{projectID}</Link> &#9656;
                <span className="txt">{t("breadcrumbs.clients_contact")} {contactID}</span> &#9656;
                <span className="txt">{t("breadcrumbs.clients_edit_contact")}</span>
            </p>,
        /*ten aktualnie nie uzywany nie ma takiego przycisku na razie*/  
        project_edit_contact_company:
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.projects",{ ns: 'menu' })}</span> &#9656; 
                <Link to={'/projects/in-preparation'}>{t("menu.inprep_projects",{ ns: 'menu' })}</Link> &#9656; 
                <Link to={'/projects/in-preparation/'+projectID}>{t("breadcrumbs.project_title")} P{projectID}</Link> &#9656;
                <Link to={'/clients/companies/details/'+compID}>{t("breadcrumbs.clients_company")} {compID}</Link> &#9656;
                <span className="txt">{t("breadcrumbs.clients_edit_company")}</span>
            </p>
    }
    return routesList[name]
}

const routesLocationsBreadCrumbs = (t, name, locationID=0, roomID=0) => {
    const routesList = {
        add_location:
            <p className="m-0 p-0 breadcrumbs">
                <Link to={'/locations'}>{t("menu.locations",{ ns: 'menu' })}</Link> &#9656;
                <span className="txt" style={{paddingLeft:'3px'}}>{t("breadcrumbs.add_location")}</span>
            </p>,

        edit_location:
            <p className="m-0 p-0 breadcrumbs">
                <Link to={'/locations'}>{t("menu.locations",{ ns: 'menu' })}</Link> &#9656;
                <Link to={'/locations/location/'+locationID}>{t("breadcrumbs.location")} {locationID}</Link> &#9656;
                <span className="txt" style={{paddingLeft:'3px'}}>{t("breadcrumbs.edit_location")}</span>
            </p>,

        add_room:
            <p className="m-0 p-0 breadcrumbs">
                <Link to={'/locations'}>{t("menu.locations",{ ns: 'menu' })}</Link> &#9656;
                <Link to={'/locations/location/'+locationID}>{t("breadcrumbs.location")} {locationID}</Link> &#9656;
                <span className="txt" style={{paddingLeft:'3px'}}>{t("breadcrumbs.location_rooms")}</span> &#9656;
                <span className="txt">{t("breadcrumbs.location_add_room")}</span>
            </p>,

        edit_room:
            <p className="m-0 p-0 breadcrumbs">
                <Link to={'/locations'}>{t("menu.locations",{ ns: 'menu' })}</Link> &#9656;
                <Link to={'/locations/location/'+locationID}>{t("breadcrumbs.location")} {locationID}</Link> &#9656;
                <span className="txt" style={{paddingLeft:'3px'}}>{t("breadcrumbs.location_rooms")}</span> &#9656;
                <span className="txt">{t("breadcrumbs.location_edit_room")}{roomID}</span>
            </p>   
    }    
    return routesList[name]
}



export {
    routesCalcBreadCrumbs,
    routesClientsBreadCrumbs,
    routesLocationsBreadCrumbs,
    titleHeadLineText,
    TxtTitles,
}