import {Input, InputWrapperDiv} from "../../../../../components/ui/Input";
import React from "react";
import {useTranslation} from "react-i18next";
import FormAddress from "./FormAddress";
import Select from "react-select";
import select_styles from "../../../../../layouts/elements";
import {phoneCountryList} from "../../../../../helpers/dictionary/users";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faRemove, faCircleXmark} from "@fortawesome/free-solid-svg-icons";


const FormClient = ({form, changeHandler, children}) => {
    const { t } = useTranslation()

    const phone_country = [
        { value: "", label: t("profile.phone_country_code")+' *', isDisabled: true },
        ...phoneCountryList,
    ]

    return (<>
        <div className="row pb-2">
            <InputWrapperDiv>
                {t("profile.first_name")+' *'}
                <Input
                    name="first_name"
                    value={form.first_name}
                    onChange={val => changeHandler(val, 'first_name')}
                    required
                />
            </InputWrapperDiv>
            <InputWrapperDiv>
                {t("profile.last_name")+' *'}
                <Input
                    name="last_name"
                    value={form.last_name}
                    onChange={val => changeHandler(val, 'last_name')}
                    required
                />
            </InputWrapperDiv>
        </div>

        <div className="row pb-2">
            <InputWrapperDiv className='col-5'>
                <div className='customSelectParent'>
                    {t("profile.dialling_code")+' *'}
                    <Select
                        name="phoneCountry"
                        options={phone_country}
                        value={form.phoneCountry}
                        onChange={val => changeHandler(val, "phoneCountry")}
                        styles={select_styles}
                        unstyled
                        required
                    />
                </div>
            </InputWrapperDiv>
            <InputWrapperDiv className='col-7'>
                {t("profile.phone_number")+' *'}
                <Input
                    name="telephone"
                    value={form.telephone}
                    onChange={val => changeHandler(val, 'telephone')}
                    required
                />
            </InputWrapperDiv>
        </div>

        <div className="row pb-2">
            <InputWrapperDiv>
                {t("profile.email_address")+' *'}
                <Input
                    type={'email'}
                    name="email"
                    value={form.email}
                    onChange={val => changeHandler(val, 'email')}
                    required
                />
            </InputWrapperDiv>
        </div>
        
        <div className='container py-3 px-0'>
            <FormAddress form={form} changeHandler={changeHandler} required={true} />
        </div>
        {children}
    </>)
}

export default FormClient;

