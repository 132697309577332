import styled from "styled-components";
import { Colors } from "../../../../../components/theme";
import LordIcon from "../../../../../components/ui/LordIcon";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    CityStyle,
    CountryStyle,
    DetailsStyle,
    ElementStyle,
    FieldStyle,
    NoteParagraphStyle,
    NoteStyle
} from "../../../../../layouts/styles";


function LocationListElement ({lDetailsUrl,lName,lCity,lCountry,lMap,lNote, countries}) {
    const { t } = useTranslation();

    return (
        <>
            <ElementStyle className={"d-flex flex-column"}>
                <DetailsStyle className="d-flex flex-row flex-wrap flex-md-nowrap">
                    <div className="d-flex flex-row flex-wrap flex-md-nowrap" style={{width: '95%'}}>
                        <FieldStyle>
                            <Link to={lDetailsUrl}><b>{lName}</b></Link>
                        </FieldStyle>
                        <CityStyle>
                            {lCity}
                        </CityStyle>
                        {countries.length>0 && lCountry &&
                        <CountryStyle>
                            {countries.find(el => el.pk === lCountry).label}
                        </CountryStyle>
                        }
                    </div>
                    
                    <div>
                        {
                            lMap &&
                            <Link to={lMap} target="_blank"><LordIcon url='location' color={`${Colors.black}`}/></Link>
                        }
                    </div>
                </DetailsStyle>
                {
                    lNote
                        ?   <NoteStyle>
                                <NoteParagraphStyle>
                                    <b>{t("location.details.location_note")}:</b> {lNote}
                                </NoteParagraphStyle>
                            </NoteStyle>
                        : null
                }
            </ElementStyle>
        </>
    )
}

export default LocationListElement;