import { useTranslation } from "react-i18next";
import React from "react";
import {decimalValidate} from "../../helpers/validate";

function RatesEntries ({rates, setRates}) {
    const { t } = useTranslation();

    const changeHandler = (val, fieldName) => {
        if (decimalValidate(val)) {
            const newEntry = {
                ...rates,
                [fieldName]: val,
            };
            setRates(newEntry);
        };
    };

    return (
        <>
            <div className="d-flex flex-row align-items-center my-2">
                <div style={{width:'60%'}}>
                    {t("events.rehearsals")}
                </div>
                <div style={{width:'50%'}} className="ms-2">
                    {/*<input style={{width:'100%', maxWidth:'60px'}}/> €*/}
                    <input
                        style={{width:'100%', maxWidth:'80px'}}
                        value={rates.rehearsals}
                        onChange={val => changeHandler(val.target.value, 'rehearsals')}
                    /> €
                </div>
            </div>
            <div className="d-flex flex-row align-items-center my-2">
                <div style={{width:'60%'}}>
                    {t("events.raccords")}
                </div>
                <div style={{width:'50%'}} className="ms-2">
                    {/*<input style={{width:'100%', maxWidth:'60px'}}/> €*/}
                    <input
                        style={{width:'100%', maxWidth:'80px'}}
                        value={rates.raccords}
                        onChange={val => changeHandler(val.target.value, 'raccords')}
                    /> €
                </div>
            </div>
            <div className="d-flex flex-row align-items-center my-2">
                <div style={{width:'60%'}}>
                    {t("events.concerts")}
                </div>
                <div style={{width:'50%'}} className="ms-2">
                    {/*<input style={{width:'100%', maxWidth:'60px'}}/> €*/}
                    <input
                        style={{width:'100%', maxWidth:'80px'}}
                        value={rates.concerts}
                        onChange={val => changeHandler(val.target.value, 'concerts')}
                    /> €
                </div>
            </div>
        </>
    )
};
export default RatesEntries;
        