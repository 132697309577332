import axiosClient from "./api";
import {clients, firms,} from "./endpoints";

const axiosClientJSON = axiosClient()
// const axiosClientForm = axiosClient('multipart/form-data')

const getClientsListFirmNameAPI = async () => {
    return await axiosClientJSON.get(clients.clientsList);
}
const clientCreateAPI = async (data) => {
    return await axiosClientJSON.post(clients.clientsCreate, data);
}
const clientUpdateAPI = async (data, id) => {
    return await axiosClientJSON.put(clients.clientUpdate(id), data);
}
const firmsListAPI = async () => {
    return await axiosClientJSON.get(firms.firmsList);
}

const firmDetailAPI = async (id) => {
    return await axiosClientJSON.get(firms.firmDetails(id));
}

const firmCreateAPI = async (data) => {
    return await axiosClientJSON.post(firms.firmsCreate, data)
}

const firmUpdateAPI = async (data, id) => {
    return await axiosClientJSON.put(firms.firmsUpdate(id), data)
}

const typesFirmListAPI = async () => {
    return await axiosClientJSON.get(firms.typesFirmList)
}
const clientDetailAPI = async (id) => {
    return await axiosClientJSON.get(clients.clientDetail(id))
}

const clientProjectCreateAPI = async (data) => {
    try {
        return await axiosClientJSON.post(clients.clientProjectCreate, data)
    } catch {
        return false
    }
}


export {
    getClientsListFirmNameAPI,
    clientCreateAPI,
    clientUpdateAPI,
    clientDetailAPI,
    clientProjectCreateAPI,
    firmsListAPI,
    typesFirmListAPI,
    firmDetailAPI,
    firmCreateAPI,
    firmUpdateAPI,
}