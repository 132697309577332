import {Route, Routes} from "react-router-dom";
import ClientsList from "../Clients/ClientsList";
import ClientDetails from "../Clients/ClientDetails";
import ClientManager from "../Clients/ClientManager";
import FirmDetails from "../Firms/FirmDetails";
import FirmManager from "../Firms/FirmManager";
import FirmsList from "../Firms/FirmsList";

const ClientsRoutes = () => {
    return (<>
        <Routes >
            <Route exact path={"/clients/contacts"} element={<ClientsList />}/>
            <Route exact path={"/clients/contacts/details/:key"} element={<ClientDetails />}/>
            <Route exact path={"/clients/contacts/:action"} element={<ClientManager />}/>
            <Route exact path={"/clients/contacts/:action/:key"} element={<ClientManager />}/>
            {/*<Route exact path={"/clients/contacts/:key"} element={<ClientManager />}/>*/}
            <Route exact path={"/clients/companies"} element={<FirmsList />}/>
            <Route exact path={"/clients/companies/details/:key"} element={<FirmDetails />}/>
            <Route exact path={"/clients/companies/:action"} element={<FirmManager />}/>
            <Route exact path={"/clients/companies/:action/:key"} element={<FirmManager />}/>
        </Routes>
    </>)
}

export default ClientsRoutes


