import React, {useEffect, useState} from "react";
import DarkCard from "../../../../../../components/card/DarkCard";
import {useTranslation} from "react-i18next";
import BusinesDataDetails from "./BusinesDataDetails";
import {
    getBusinessData,
    getPrintingDocPersonsList,
    getPrintingDocSelected
} from "../../../../../../services/functionsAPI";
import PrintingOnDocumentForm from "./PrintingOnDocumentForm";
import BusinesDataForm from "./BusinessDataForm";
import Addresses from "../../../../../../components/ui/Addresses";
import {position_admin, position_creative} from "../../../../../../helpers/dictionary/aplication";
import { Link } from "react-router-dom";

const opt_list = [{value: 0, label: "-----"}]
const ImageStyle = {
    width: '200px',
    height: 'auto',
};

const BusinessData = () => {
    const [details, setDetails] = useState({})
    const [printingData, setPrintingData] = useState({[position_admin]: "", [position_creative]: "", logo: ""})
    const [adminPersonsOpt, setAdminPersonsOpt] = useState(opt_list)
    const [creativePersonsOpt, setCreativePersonsOpt] = useState(opt_list)
    const [edit, setEdit] = useState(false)
    const [printing, setPrinting] = useState(false)
    const { t } = useTranslation()

    const printingOnDocHeader ={
        name: t("asbl.information_doc_title")
    }

    useEffect(() => {
        const getData = async () => {
            const response = await getBusinessData()
            if (response) {
                setDetails(response)
            }
        }
        const getPrintingPersonsList = async () => {
            const response = await getPrintingDocPersonsList()
            if (response) {
                const admin_options_data = response[position_admin].map(el => ({
                        value: el.pk,
                        label: el.persons.first_name + " " + el.persons.last_name
                }))
                const creative_options_data = response[position_creative].map(el => ({
                        value: el.pk,
                        label: el.persons.first_name + " " + el.persons.last_name
                }))

                setAdminPersonsOpt(admin_options_data)
                setCreativePersonsOpt(creative_options_data)
            }
        }
        const getPrintingSelected = async () => {
            const response = await getPrintingDocSelected()
            if (response) {
                setPrintingData(response)
            }
        }
        getPrintingPersonsList()
        getPrintingSelected()
        getData()
    }, [])

    const printingHandler = (status) => {
        setEdit(status)
        setPrinting(status)
    }

    let dataFormular
    if (printing) {
        dataFormular = <PrintingOnDocumentForm
            setPrinting={printingHandler}
            adminPersonsOpt={adminPersonsOpt}
            creativePersonsOpt={creativePersonsOpt}
            setPrintingData={setPrintingData}
            printingData={printingData}
        />
    } else {
        dataFormular = <BusinesDataForm data={details} setData={setDetails} setEdit={setEdit} />
    }

    const logo_src = () => {
        const logo = printingData.logo
        if (logo === 'remove') {
            return ""
        }
        return typeof logo==="object" ? URL.createObjectURL(logo) : logo
    }

    return (<>
        <DarkCard classNames='mb-3 row'>
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.asbl",{ ns: 'menu' })}</span> &#9656;
                <Link to={'/asbl/business-data'}>{t("menu.business_data",{ ns: 'menu' })}</Link>
                {edit ? 
                <>&#9656;<span className="txt">{t("asbl.edit_asbl")}</span></> 
                : null }
            </p>
        </DarkCard>

        <DarkCard classNames='mb-3 row'>
            {edit
                ? dataFormular
                : (<>
                    <BusinesDataDetails data={details} setEdit={setEdit} addClass={'ps-0'}>
                        <div className="px-2">
                            <b><Addresses obj={details} /></b>
                        </div>
                        <div className="px-2">
                            <p>
                                {t("asbl.rcs_number")+': '} <b>{details.rcs}</b>
                            </p>
                            <p className="mb-2">
                                {t("profile.bank.name")+': '} <b>{details.bank_name}</b>
                            </p>
                            <p className="my-2">
                                {t("profile.bank.account_no")+': '} <b>{details.bank_account_holder}</b>
                            </p>
                            <p className="my-2">
                                {t("profile.bank.bic")+': '} <b>{details.bank_account_no}</b>
                            </p>
                        </div>
                    </BusinesDataDetails>

                    {/*Administrator and Creative*/}
                    <BusinesDataDetails data={printingOnDocHeader} setEdit={printingHandler} setPrinting={setPrinting}>
                        <div className="px-2"><p>Administrator: {printingData[position_admin].cn}</p></div>
                        <div className="px-2"><p>Creative: {printingData[position_creative].cn}</p></div>
                        <div className="px-2">
                            <p>Logo:</p>
                            {(printingData.logo) &&
                                <div className="col-md-6">
                                    <img src={logo_src()} alt='' style={ImageStyle} />
                                </div>
                            }
                        </div>
                    </BusinesDataDetails>
                </>)
            }
        </DarkCard>
    </>)
}

export {BusinessData, getBusinessData}