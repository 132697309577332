import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FeedDetails from "./FeedDetails";
import LightCard from "../../../../../../components/card/LightCard";
import {Button} from "../../../../../../components/ui/Button";
import {Colors} from "../../../../../../components/theme";
import {feedAPI, feedListAPI} from "../../../../../../services/api/project";
// import dateFormat from "dateformat";
// dateFormat(row.eventDate, "dd.mm.yyyy")

export default function FeedsList({projectID}) {
    const {t} = useTranslation()
    const [note, setNote] = React.useState("")
    const [files, setFiles] = useState([]);
    const [feedData, setFeedData] = useState([]);
    const [newFeed, setNewFeed] = useState(false);

    useEffect(() => {
        const getFeedList = async () => {
            const response = await feedListAPI(projectID)
            if (response.data.length > 0) {
                setFeedData(response.data)
            }
        }
        getFeedList()
    }, [newFeed])

    const changeHandler = (n) => {
        setNote(n)
    }

    const handleFileChange = event => {
        setFiles([...event.target.files]);
    }

    const handleClickUpdate = async (e) => {
        const formData = new FormData();
        await files.forEach((file) => {
            formData.append(`files`, file, file.name)
        })

        formData.append("project", projectID)
        formData.append("note", note)

        const response = await feedAPI(formData)

        setNote("")
        setFiles([])
        setNewFeed(prev => !prev)
    }

    return (<>
        <div className='mb-3 p-0 col-12'>
            <LightCard>
                <div className=''>
                    {t("project.feed_message_title")} * <br/>
                    <textarea
                        name="note"
                        value={note}
                        onChange={val => changeHandler(val.target.value)}
                        style={{width: '100%', height: '100px'}}
                    />
                </div>

                <div className='mt-2'>
                    {t("project.feed_message_attachmnt")} <br/>
                    <input type="file" id="files" name="files" multiple onChange={handleFileChange}/>
                </div>

                <div className='mt-2' style={{textAlign: 'right'}}>
                    <Button
                        onClick={handleClickUpdate}
                        disabled={!note.trim()}
                        bWidth='small'
                        className=''
                        variant="round"
                        style={{backgroundColor: `${Colors.green}`}}>
                        {t("common.button.save")}
                    </Button>
                </div>
            </LightCard>
        </div>

        {feedData.map((box, index) =>
            <div id={"day_card_" + index} className='mt-3' key={index}>
                <div className="mt-4">
                    <p className="ps-3 m-0"><b>{Object.keys(box)[0]}</b></p>
                </div>
                {Object.values(box).map((feed, index) =>
                    <div id={"day_card_" + index} className='mt-2' key={index}>
                        <div>
                            <FeedDetails data={feed}/>
                        </div>
                    </div>
                )}
            </div>
        )}
    </>)
}
