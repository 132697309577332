import styled from "styled-components";
import { Colors } from "../theme";
import { useTranslation } from "react-i18next";

const SlideBoxWrapper = styled.div`
	position: relative;
`;
const SlideBoxLabel = styled.label`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 42px;
	height: 26px;
	border-radius: 15px;
	background: ${Colors.mediumGray};
	cursor: pointer;
	&::after {
		content: "";
		display: block;
		border-radius: 50%;
		width: 18px;
		height: 18px;
		margin: 3px;
		background: ${Colors.white};
		box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
		transition: 0.2s;
	}
`;
const SlideBox = styled.input`
	opacity: 0;
	z-index: 1;
	border-radius: 15px;
	width: 42px;
	height: 26px;
	&:checked + ${SlideBoxLabel} {
		background: ${Colors.green};
		&::after {
			content: "";
			display: block;
			border-radius: 50%;
			width: 18px;
			height: 18px;
			margin-left: 21px;
			transition: 0.2s;
		}
	}
`;

function SlideButton({ type, val, setVal }) {
	const { t } = useTranslation();

	// Stworzylem to tutaj tylko aby kazdy slider mial indywidualne ID aby mozna bylo przelaczac, moze byc ze to nie bedzie potrzebne jak juz zedytujesz kod
	const alphabet = "abcdefghijklmnopqrstuvwxyz";
	const btnID =
		Math.floor(Math.random() * 1000) +
		1 +
		alphabet[Math.floor(Math.random() * alphabet.length)];

	const onChange = () => {
		setVal(!val);
	};

	return (
		<>
			<div className='d-flex flex-row'>
				<div className='p-1 align-self-center'>
					{type === "yesNo"
						? t("common.boolean.no")
						: type === false
						? null
						: t("common.boolean.off")}
				</div>
				<div className='p-1'>
					<SlideBoxWrapper>
						<SlideBox
							id={"slide" + btnID}
							type='checkbox'
							name={"name_" + btnID}
							value={val}
							// disabled={disabled}
							checked={val}
							onChange={onChange}
						/>
						<SlideBoxLabel htmlFor={"slide" + btnID} />
					</SlideBoxWrapper>
				</div>
				<div className='p-1 align-self-center'>
					{type === "yesNo"
						? t("common.boolean.yes")
						: type === false
						? null
						: t("common.boolean.on")}
				</div>
			</div>
		</>
	);
}

export default SlideButton;
