import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/login/login";
import SignUp from "./pages/sign_up/SignUp";
import ForgotPassword from "./pages/forgot_password/ForgotPassword";
import ForgotPwdChange from "./pages/forgot_password/ForgotPwdChange";
import ResetPassword from "./pages/reset_password/ResetPassword";
import { AuthenticationContext } from "./contexts/AuthenticationContext";
import React, {Suspense, useEffect, useState} from "react";
import LayoutUserAuth from "./layouts/UserAuth";
import LayoutPwReset from "./layouts/PwReset";
import SuccessRegister from "./pages/sign_up/success";
import NetworkError from "./pages/NetworkError";
import { rolesDict } from "./pages/roles/Roles";
import {AppContext} from "./contexts/AppContext";
import {getFirmLogoApi} from "./services/api/user";
import QuoteClientForm from "./pages/roles/admin/PageContent/Projects/Projects/QuoteClientForm";
import LightCard from "./components/card/LightCard";
import RedHeader from "./components/ui/RedBgTitleHeader";
import QuoteAcceptanceForm from "./pages/roles/admin/PageContent/Projects/Projects/QuoteAcceptanceForm";
import {useTranslation} from "react-i18next";
import QuoteClientConfirm from "./pages/roles/admin/PageContent/Projects/Projects/QuoteClientConfirm";

export default function App() {
	const [currentUser, setCurrentUser] = useState(
		localStorage.getItem("userid")
	);
	const token = localStorage.getItem("token")
	const expiry = localStorage.getItem("expiry")
	const token_ttl = localStorage.getItem("token_ttl")
	const userName = localStorage.getItem("username")
	const roleLocalStorage = localStorage.getItem("currentRole")
	const [currentRole, setCurrentRole] = useState(roleLocalStorage)
	const [userRoles, setUserRoles] = useState(Object.keys(rolesDict))
	const [isLogo, setIsLogo] = useState(false)
	const [firmLogo, setFirmLogo] = useState("")
	const {t} = useTranslation();

	useEffect(() => {

		(async () => {
			const response = await getFirmLogoApi()
			const resp_logo = response.status === 200 ? response.data.logo : ""
			if (resp_logo) {
				setFirmLogo(resp_logo)
				setIsLogo(true)
			}
		})()
	}, [])

	return (
		<div className='App'>
			<AppContext.Provider
				value={{
					firmLogo: firmLogo,
					project: {},
			}}>
				<AuthenticationContext.Provider
					value={{
						currentUser: currentUser,
						setCurrentUserId: setCurrentUser,
						userName: userName,
						tokenUser: token,
						expiredToken: expiry,
						token_ttl: token_ttl,
						onLogin: setCurrentUser,
						onLogout: () => {
							localStorage.clear();
							setCurrentUser("");
						},
						currentRole: currentRole,
						setCurrentRole: setCurrentRole,
						userRoles: userRoles,
						setUserRoles: setUserRoles,
					}}>
					<Router>
						<Routes>
							<Route exact path='/network-error' element={<NetworkError />} />
							<Route
								exact
								path='/reset-password'
								element={
									<LayoutPwReset variant='small'>
										<ResetPassword />
									</LayoutPwReset>
								}
							/>
							<Route
								exact
								path='/forgot-password'
								element={
									<LayoutUserAuth>
										<ForgotPassword />
									</LayoutUserAuth>
								}
							/>
							<Route
								exact
								path='/forgot-password-change/:key'
								element={
									<LayoutUserAuth>
										<ForgotPwdChange />
									</LayoutUserAuth>
								}
							/>
							<Route
								exact
								path='/sign-up/:key'
								element={
									<LayoutPwReset>
										<SignUp />
									</LayoutPwReset>
								}
							/>
							<Route
								exact
								path='/sign-up'
								element={
									<LayoutPwReset>
										<SignUp />
									</LayoutPwReset>
								}
							/>
							<Route
								exact
								path='/login'
								element={
									<Suspense fallback='... is loading data'>
										<LayoutUserAuth>
											<Login />
										</LayoutUserAuth>
									</Suspense>
								}
							/>
							<Route
								exact
								path='/success-registration'
								element={<SuccessRegister />}
							/>
							<Route
								exact
								path='/'
								element={
									<LayoutUserAuth>
										<Login />
									</LayoutUserAuth>
								}
							/>
							<Route
								exact
								path="/client-quote-accept/:quote_link"
								element={
									<QuoteClientForm>
										<LightCard>
											<RedHeader title={t('project.quote_acceptance_title')}/>
											<QuoteAcceptanceForm />
										</LightCard>
									</QuoteClientForm>
								}
							/>
							<Route
								exact
								path='/client-quote-confirmation/:quote_link'
								element={<QuoteClientConfirm />}
							/>

							<Route path='/*' element={<Dashboard />} />
						</Routes>
					</Router>
				</AuthenticationContext.Provider>
			</AppContext.Provider>
		</div>
	);
}
