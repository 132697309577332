const rolesDict = {
    //'admin': t("common.roles.titles.admin"),
    //'musician': t("common.roles.titles.musician"),
    //'treasurer': t("common.roles.titles.treasurer"),
    //'secretariat': t("common.roles.titles.secretariat")
    'admin':'Admin',
    'musician':'Musician',
    'treasurer':'Treasurer',
    'secretariat':'Secretariat',
};

const Roles = (role) => {
    return rolesDict[role]
};

export {rolesDict, Roles};
