import {useTranslation} from "react-i18next";
import {DetailsStyle, ElementStyle, FieldStyle} from "../../../../../../layouts/styles";
import {Colors} from "../../../../../../components/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import dateFormat from "dateformat";
import {useNavigate} from "react-router-dom";
import Popup from "../../../../../../components/ui/Popup/Popup";
import ParametersPdfForm from "./ParametersPdfForm";
import {Button} from "../../../../../../components/ui/Button";
import {RenderDataToPDF} from "../../../../../../helpers/PDFSupport/RenderDataToPDF";
import {popupError} from "../../../../../../components/ui/Popup/feedBack";
import {INITIAL_renderDataToPDF, popupInit, popupInitial} from "../../../../../../helpers/PDFSupport/offers/tools";
import get_quote_api from "../../../../../../helpers/PDFSupport/offers/get_quote_api";
import {useSessionStorage} from "usehooks-ts";
import {parametersSessionINIT} from "../../../../../../helpers/tools";
import {changeStatusQuote, save_parametersAPI} from "../../../../../../services/api/project";
import {languagesList} from "../../../../../../helpers/dictionary/users";
import {toInteger} from "lodash";


const TxtTitles = styled.p`
	font-size: 0.7em;
    text-transform: uppercase;
	width: 100%;
    padding: 0 0 3px 0;
    margin: 0;
    color: ${Colors.darkRed};
`;

const processName = 'quote'

const QuoteItem = ({quote, index, project, grantEstroSum, clientAndFirm}) => {
    const {t} = useTranslation()
    const public_link_domain = `${document.location.origin}/client-quote-accept/`
    const navigete = useNavigate()
    const [createDate, setCreateDate] = useState(new Date());
    const [language, setLanguage] = useState({ value: "4", label: "English" });
    const [showEstroGrant, setShowEstroGrant] = useState(false);
    const [isGeneratePDF, setIsGeneratePDF] = useState(false)
    const [comment, setComment] = useState("")
    // const [program, setProgram] = useState("")
    const [pdfData, setPdfData] = useState(INITIAL_renderDataToPDF);
    const [popup, setPopup] = useState(popupInit);
    const [sessionData, setSessionData] = useSessionStorage("project_quote_formular", parametersSessionINIT);
    const [quoteItem, setQuoteItem] = useState(quote)

    useEffect(() => {
        setShowEstroGrant(quote.showEstroGrant)
        if (quote.comment) {
            setComment(quote.comment)
        }
    }, [])

    const copyToClipboard = async (content) => {
        await navigator.clipboard.writeText(content);
        if (quoteItem.status === "waiting") return
        try {
            const change_status_quote = await changeStatusQuote(quote.id, {status: "waiting"})

            if (change_status_quote.status !== 200) {
                navigete("/network-error")
            }
            setQuoteItem({...quoteItem, status: "waiting"})

        } catch (error) {
            console.error('Unable to copy to clipboard:', error);
        }
    };

    const handleCopyToClipboard = (content) => {
        copyToClipboard(content);
    }

    const handleChangeData = (data) => {
        if (data.type === "SET_DATE") {
            setCreateDate(data.value)
        }
        if (data.type === "SET_SHOW_ESTRO_GRANT") {
            setShowEstroGrant(data.value)
        }
        if (data.type === "SET_LANGUAGE") {
            setLanguage(data.value)
        }
        if (data.type === "SET_ESTIMATE_COMMENT") {
            setComment(data.value)
        }
    }

    const handlePopup = (e) => {
        const buttonName = e.target.name

        if (buttonName === 'save') {
            const getCalculationAPI = async () => {
                // zapis parametrow z form do DB
                const data = {
                    pdf_date: createDate.toISOString().slice(0,10),
                    language: language.value,
                    showEstroGrant: showEstroGrant,
                    comment: comment,
                    type: "quote",
                }
                await save_parametersAPI(quote.id, data)
                const response = await get_quote_api(quote.public_link) // get caclulation quote

                const dataEst = {
                    ...pdfData,
                    calculation: response,
                    project: project,
                    client: {client_data: clientAndFirm.client},
                    firm: clientAndFirm.firm,
                    estimate: quote.estimate,
                    estimateDate: createDate, //.toLocaleDateString("de-DE"),
                    comment: comment,
                    // documentID: quote.id,
                    quoteID: quote.id,
                    quote_acceptance: {
                        status: quote.status,
                        person: quote.acceptance_cn,
                        date_time: quote.acceptance_datetime,
                        reason: quote.acceptance_reason
                    },
                    typeData:"quote",
                    showEstroGrant: showEstroGrant
                }
                setPopup({show: false})
                setPdfData(dataEst)
                setIsGeneratePDF(true)
            }
            getCalculationAPI()
        } else {
            setPopup(popupInit);
        }
    }

    const handleQuoteClick = (q_link) => {
        const parametersSession = {
                quoteID: quote.id,
                projectID: project.id,
                projectName: project.name,
                estimateID: quote.estimate,
                clientView: false
            }
        setSessionData(parametersSession)
        navigete("/projects/client-quote-accept/"+quote.public_link)
    }

    const handlePDFClick = () => {
        setPopup({...popupInit, show: true, variant: "editF", 
            title: t('popups.generate_pdf.title')});
    }

    if (popup.show) {
        return (<Popup message={popup.message} title={popup.title} variant={popup.variant} >
            <ParametersPdfForm
                formData={{
                    createDate: createDate,
                    readOnly: true,
                    language: language,
                    showEstroGrant: showEstroGrant,
                    grantEstroSum: grantEstroSum,
                    comment: comment,
                }}
                dispatch={handleChangeData}
            />
            <Button
                name="save"
                onClick={(e) => handlePopup(e)}
                variant="round"
                bWidth="small"
                styling="plain"
                className='me-3 mt-4'
                style={{backgroundColor: `${Colors.green}`}}>
                {t("Generate")}
            </Button>
            <Button
                name="cancel"
                onClick={(e) => handlePopup(e)}
                variant="round"
                bWidth="small"
                styling="bordered"
                className='mx-3 mt-4'>
                {t("Cancel")}
            </Button>
        </Popup>)
    }

    return (<>
        <ElementStyle className={"d-flex flex-column"} style={{backgroundColor:'white', marginTop:'10px', marginBottom:'10px'}}>
            <DetailsStyle className="d-flex flex-row flex-wrap flex-md-nowrap">
                <div className="d-flex flex-row flex-wrap flex-md-nowrap" style={{width: '100%'}}>
                    <FieldStyle width='20px'>
                        {index}
                    </FieldStyle>
                    <FieldStyle>
                        <div>
                            <TxtTitles>{t("project.id_title")}</TxtTitles>
                            <a onClick={handleQuoteClick}><b>Q{quote.id}</b> / {quote.calculation__name}</a>
                        </div>
                    </FieldStyle>
                    <FieldStyle>
                        <div>
                            <TxtTitles>{t("project.client_estimate_title")}</TxtTitles>
                            {clientAndFirm && clientAndFirm.firm && clientAndFirm.firm.name && (
                                clientAndFirm.firm.name + ", "
                            )}
                            {quote.client__first_name} {quote.client__last_name}
                        </div>
                    </FieldStyle>
                    <FieldStyle>
                        <div>
                            <TxtTitles>{t("project.creation_date")}</TxtTitles>
                            {dateFormat(quote.created, "dd.mm.yyyy")}
                        </div>
                    </FieldStyle>
                    <FieldStyle>
                        <div>
                            <TxtTitles>{t("project.quote_status")}</TxtTitles>
                            {quoteItem.status}
                        </div>
                    </FieldStyle>

                    <FieldStyle>
                        {quote.status === 'new' || quote.status === 'waiting'
                            ?  <a onClick={() => handleCopyToClipboard(public_link_domain+quote.public_link)}>Copy public link</a>
                            : null
                        }
                    </FieldStyle>

                    <FieldStyle>
                        <a onClick={handlePDFClick}>
                            <FontAwesomeIcon icon={faFilePdf} className="me-2"/>
                            {t("common.button.download_pdf")}
                        </a>
                    </FieldStyle>
                </div>
            </DetailsStyle>
        </ElementStyle>
        {isGeneratePDF
            ? <RenderDataToPDF
                dataToRender={pdfData}
                processName={processName}
                dispatch={setIsGeneratePDF}
                popupError={popupError}
                popupInitial={popupInitial}
            />
            : null
        }
    </>)
}

export default QuoteItem;
