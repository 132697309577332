import {project} from "./endpoints";
import axiosClient from "./api";

const axiosClientJSON = axiosClient()
const axiosClientForm = axiosClient('multipart/form-data')

const estimateAPI = async (data) => {
    return await axiosClientForm.post(project.estimate, data);
};

const projectListAPI = async (data) => {
    return await axiosClientJSON.get(project.shortData);
}

const feedAPI = async (data) => {
    return await axiosClientForm.post(project.feed, data);
};

const feedListAPI = async (projectId) => {
    return await axiosClientJSON.get(project.feedList(projectId));
};

const quoteDataAPI = async (quote_link) => {
    return await axiosClientJSON.get(project.quotDetails(quote_link));
};

// const showEstroGrantSaveAPI = async (quote_link, data) => {
//     return await axiosClientJSON.patch(project.showEstroGrant(quote_link), data);
// };

const QuoteAcceptanceFormSave = async (quote_link, data) => {
    return await axiosClientJSON.post(project.quoteFormSave(quote_link), data);
};

const changeStatusQuote = async (id, status) => {
    return await axiosClientJSON.post(project.changeStatusQuote(id), status);
}

const quoteDeleteAPI = async (id) => {
    return await axiosClientJSON.delete(project.deleteQuote(id));
}

const save_parametersAPI = async (id, data) => {
    return await axiosClientJSON.patch(project.saveParameters(id), data);
}

const clientQuoteDetailPDFAPI = async (quote_link) => {
    return await axiosClientJSON.get(project.quotePDFDetails(quote_link));
}

const quoteIsCompanyAPI = async (quote_link) => {
    return await axiosClientJSON.get(project.isCompany(quote_link));
}

export {
    estimateAPI,
    projectListAPI,
    feedAPI,
    feedListAPI,
    quoteDataAPI,
    QuoteAcceptanceFormSave,
    changeStatusQuote,
    quoteDeleteAPI,
    save_parametersAPI,
    // showEstroGrantSaveAPI,
    clientQuoteDetailPDFAPI,
    quoteIsCompanyAPI,
}