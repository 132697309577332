const LOCAL_STORAGE_TOKEN = 'token';
const LOCAL_STORAGE_USER = 'userid';

const setToken = (token) => localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
const clearToken = () => localStorage.removeItem(LOCAL_STORAGE_TOKEN);
const getToken = () => localStorage.getItem(LOCAL_STORAGE_TOKEN);


const getUserId = () => localStorage.getItem(LOCAL_STORAGE_USER);
const setUserId = (token) => localStorage.setItem(LOCAL_STORAGE_USER, token);
const clearUserId = () => localStorage.removeItem(LOCAL_STORAGE_USER);

const options_in_session = JSON.parse(sessionStorage.getItem("options-in-session"))

const projectSession = {
    pathReturn: "",
    projectID: 0,
    estimateID: 0,
}


export {
    setToken,
    clearToken,
    getToken,
    setUserId,
    clearUserId,
    getUserId,
    options_in_session,
    projectSession
};