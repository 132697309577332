import DarkCard from "../../../../../components/card/DarkCard";
import LightCard from "../../../../../components/card/LightCard";
import { useTranslation } from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";
import RedHeader from "../../../../../components/ui/RedBgTitleHeader";
import { Button } from "../../../../../components/ui/Button";
import { Input, InputWrapperDiv } from "../../../../../components/ui/Input";
import React, {useEffect, useState} from "react";
import {
    getInstrumentDetailAPI,
    putInstrumentDetailAPI
} from "../../../../../services/api/user";
import Select from "react-select";
import select_styles from "../../../../../layouts/elements";
import PopupMessage from "../../../../../components/ui/PopupMessage";
import { Colors } from "../../../../../components/theme";

function InstrumentEdit () {
    const { t } = useTranslation();
    const param = useParams();
    const [instrument, setInstrument] = useState({});
    const navigate = useNavigate();
    const [popup, setPopup] = useState({show: false, title: '', variant: 'error', message: ''});

    useEffect(() => {
        const instrDetails = async (pk) => {
            try {
                const respInstr = await getInstrumentDetailAPI(pk);
                setInstrument(respInstr.data);
            } catch (ex) {
                console.log("Failed to download instruments list.", ex);
            };
        };

        if ('key' in param) {
            instrDetails(param.key);
        };
    }, [param]);

    const changeHandler = (val, fieldName) => {
        if (fieldName === 'instrument_classification') {
            val = val.id
        };

        const newEntry = {
            ...instrument,
            [fieldName]: val
        };
        setInstrument(newEntry);
    };

    const classificationOptions = () => {
        let option = {};
        const selectedClassification = instrument.instrument_classification;
        if (selectedClassification > 0) {
            option = instrument.classifications.find(obj => obj.id === instrument.instrument_classification)
        }
        return option
    };

    const saveInstrument = async () => {
        const data = {
            ...instrument
        };
        try {
            const response = await putInstrumentDetailAPI(param.key, data);
            if (response.status === 200) {
                setPopup({show: true, title: t("popups.save_data.title"), variant: 'success', message: t("popups.save_data.message")})
            } else {
                setPopup({show: true, title: t("popups.error_save_data.title"), variant: 'error', message: t("popups.error_save_data.message")})
            }
        } catch (e) {
            setPopup({show: true, title: t("popups.error_save_data.title"), variant: 'error', message: t("popups.error_save_data.message")})
        }
    };

    const handlePopup = (obj) => {
        navigate('/instruments')
    }

    return (<>
        {popup.show
        ? <PopupMessage
            setPopup={handlePopup}
            variant={popup.variant}
            title={popup.title}
            message={popup.message}
            // leftBtn={t("common.boolean.no")}
            //rightBtn={t("common.boolean.yes")}
            okBtn={t("common.button.ok")}
        />
        : <>
            <DarkCard classNames='mb-3 row'>
                <p className="m-0 p-0 breadcrumbs">
                    <Link to={'/instruments'}>{t("menu.instruments",{ ns: 'menu' })}</Link> &#9656;
                    <span className="txt">{t("breadcrumbs.edit_instrument")} {instrument.name}</span>
                </p>
            </DarkCard>

            <DarkCard classNames='mb-3 row'>
                <h1 className="mt-0 p-0">
                    {instrument.name}
                </h1>
                

                <LightCard>
                    <div className="row">
                        <div className="col">
                            <RedHeader title={t("instruments.edit_title")}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-md-4">
                            {t("instruments.type")}<br/>
                            <Select
                                options={instrument.classifications}
                                value={classificationOptions()}
                                styles={select_styles}
                                unstyled
                                onChange={val => changeHandler(val, 'instrument_classification')}
                            />
                        </div>
                    </div>
                </LightCard>

                <div className="row mt-4">
                    <div className="col-sm-6 col-md-4 ps-0">
                        <LightCard>
                            <RedHeader title={t("rates.clients")} />
                            <InputWrapperDiv className={'mb-2'}>
                                {t("instruments.renting_costs")}
                                <Input
                                    value={instrument.renting_client_cost}
                                    type='text'
                                    style={{width:'96%'}}
                                    onChange={val => changeHandler(val, 'renting_client_cost')}
                                /> €
                            </InputWrapperDiv>

                            <InputWrapperDiv className={'mb-2'}>
                                {t("instruments.transport_costs")}
                                <Input
                                    value={instrument.transport_client_cost}
                                    type='text'
                                    style={{width:'96%'}}
                                    onChange={val => changeHandler(val, 'transport_client_cost')}
                                /> €
                            </InputWrapperDiv>
                        </LightCard>
                    </div>



                    <div className="col-sm-6 col-md-4">
                        <LightCard>
                            <RedHeader title={t("rates.estros")} />
                            <InputWrapperDiv className={'mb-2'}>
                                {t("instruments.renting_costs")}
                                <Input
                                    value={instrument.renting_estro_cost}
                                    type='text'
                                    style={{width:'96%'}}
                                    onChange={val => changeHandler(val, 'renting_estro_cost')}                            /> €
                            </InputWrapperDiv>

                            <InputWrapperDiv className={'mb-2'}>
                                {t("instruments.transport_costs")}
                                <Input
                                    value={instrument.transport_estro_cost}
                                    type='text'
                                    style={{width:'96%'}}
                                    onChange={val => changeHandler(val, 'transport_estro_cost')}
                                /> €
                            </InputWrapperDiv>
                        </LightCard>
                    </div>
                </div>

                <div className="row mt-2">
                    <Button
                        type='submit'
                        variant="round"
                        style={{backgroundColor:`${Colors.green}`}}
                        onClick={() => saveInstrument()}
                        className={'me-3'}
                    >
                        {t("common.button.save")}
                    </Button>

                    <Button
                        type='submit'
                        variant="round"
                        onClick={() => {navigate("/instruments")}}
                        styling="bordered"
                        className={'me-3'}>

                        {t("common.button.cancel")}
                    </Button>
                </div>
            </DarkCard>
        </>
    }
    </>)
};


export default InstrumentEdit;