import {format, parseISO} from 'date-fns'

// personal data
const processing_personalData_to_api = (obj) => {
    const json_personal_api = { "personal_address": {}, "languages": {} };
    for (const [key, val] of Object.entries(obj)) {
        switch (key) {
            case 'street':
            case 'houseNumber':
            case 'postCode':
            case 'city':
                json_personal_api['personal_address'][key] = val.value;
                break;
            case 'country':
                json_personal_api['personal_address'][key] = val.value.value;
                break;
            case 'birthDate':
                json_personal_api['birthDate'] = format(parseISO(val.value), 'yyyy-MM-dd');
                break;
            case 'mainLang':
                json_personal_api['languages']['main'] = val.value;
                break;
            case 'additionalLang':
                json_personal_api['languages']['additional'] = val.value;
                break;
            default:
                json_personal_api[key] = val.value;
        }
    };
    return json_personal_api
};
function jsonPersonalAPI(personal_data=null) {
    if (!personal_data) {
        personal_data = JSON.parse(localStorage.getItem('registrationData'));
    };
    return processing_personalData_to_api(personal_data);
};

// function jsonPersonalDataProfile(personal_data) {
//     return processing_to_api(personal_data)
// };

// bank data
const processing_bandData_to_api = (data=null) => {
    const json_bank_api = {};
    for (const [key, val] of Object.entries(data)) {
        json_bank_api[key] = val.value;
    };

    return json_bank_api
};
function jsonBankAPI(bank_data=null) {
    if (!bank_data) {
        bank_data = JSON.parse(localStorage.getItem('registrationBankData'));
    };

    return processing_personalData_to_api(bank_data)
};

// function jsonBankProfile(bank_data) {
//     const json_bank = {};
//     for (const [key, val] of Object.entries(bank_data)) {
//         json_bank[key] = val.value;
//     }
//     return json_bank
// };

// instruments data
const processing_instrData_to_api = (instr_data) => {
    const json_instr_api = (instr_data.map(el => {
        return {
            "type": el.value_type,
            "instr": el.value_instr,
        }
    }));
    return json_instr_api
};
function jsonInstrAPI(instr_data=null) {
    if (!instr_data) {
        instr_data = JSON.parse(localStorage.getItem('registrationInstrData'));
    };

    return processing_instrData_to_api(instr_data)
};

// function jsonInstrProfile(instr_data) {
//     const json_instr = (instr_data.map(el => {
//         return {
//             "type": el.value_type,
//             "instr": el.value_instr,
//         }
//     }));
//     return json_instr
// };

// API data to json
function jsonRegistrationData() {
    const registration_data = jsonPersonalAPI();
    registration_data["bank_details"] = jsonBankAPI();
    registration_data["instruments"] = jsonInstrAPI();
    return registration_data;
};

// API data to json
function jsonProfileSave(formPD, formBD, formInstr) {
    const registration_data = jsonPersonalAPI(formPD);
    registration_data["bank_details"] = jsonBankAPI(formBD);
    registration_data["instruments"] = jsonInstrAPI(formInstr);
    return registration_data;
};

export {
    jsonRegistrationData,
    jsonProfileSave,
};