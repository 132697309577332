import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "../../components/ui/Button";
import {FeedbackMessage} from "../../components/ui/Feedback_Message";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";

export default function ResetPassword() {
    const [auth, setAuth] = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (auth) navigate("/home")
    })

    return (
        <>
            <h1 className="underline">
                {t("reset_pw.title_before_log_in")}
            </h1>

            <form>

                <input className='dataInput' placeholder={t("reset_pw.new_pw")} name="password" type="password" id="password" required/>
                <input className='dataInput' placeholder={t("reset_pw.new_pw_repeat")} name="password1" type="password" id="repeat-password" required/>

                <p className='mb-0'>
                    <b>{t("reset_pw.conditions_title")}</b>
                </p>

                <ul className='dashedList' style={{margin: '0'}}>
                    {/*  add "success" class to li if the specifc requiremnt is implemented */}
                    <li className="">{t("reset_pw.validation.lowercase_letter")}</li>
                    <li className="">{t("reset_pw.validation.uppercase_letter")}</li>
                    <li className="">{t("reset_pw.validation.one_no")}</li>
                    <li className="">{t("reset_pw.validation.special_character")}</li>
                    <li className="">{t("reset_pw.validation.8_characters")}</li>
                    <li className="">{t("reset_pw.validation.pws_match")}</li>
                </ul>

                    <Button type="submit" bWidth="full">{t("reset_pw.btn_save_new_pw")}</Button>

            </form>

                <FeedbackMessage variant="">
                        {/*
                        Show success message instead of the form!!
                        Variant Success-Message: New password is saved! You will be redirected to the login page in 7 sec.

                        Show error message under the form!
                        Variant Error-Message: Oops! Something went wrong please try again later to reset your password!

                        ANDEK: Jesli hasla nie sa te same!
                        Show error message under the form!
                        Variant Error-Message: Oops! Your passwords are not matching!
                        */}
                </FeedbackMessage>
        </>
    )
};