function integerValidate(val) {
    if ( (val.includes('.') || val.includes(' ')) ) {
        return false
    };

    if ( Number(val) || (val === "") || (val == 0) ) {
        return true
    };
    return false;
};

function decimalValidate(val) {
    if (val.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
        return true
    };
    return false;
};

export {integerValidate, decimalValidate}