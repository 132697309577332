import { Input, InputWrapperDiv } from "../../../../../components/ui/Input";
import { useTranslation } from "react-i18next";

function LocationContactPerson({person, onChangeHandler}) {
    const { t } = useTranslation();

    return (
        <>
            <div className="mb-5">
                <InputWrapperDiv className='mt-2'>
                    {t("location.contact_person.responsibility")}
                    <Input
                        value={person.responsible_for}
                        onChange={e => onChangeHandler(e, 'responsible_for', 'persons', person.key)}
                        name='responsible_for'
                    />
                </InputWrapperDiv>

                <div className="row mt-2">
                    <div className="col-xs-12 col-6">
                        <InputWrapperDiv>
                            {t("location.contact_person.f_name")}
                            <Input
                                value={person.first_name}
                                onChange={e => onChangeHandler(e, 'first_name', 'persons', person.key)}
                                name='first_name'
                            />
                        </InputWrapperDiv>
                        <InputWrapperDiv className="mt-2">
                            {t("location.contact_person.e-mail")}
                            <Input
                                value={person.email}
                                onChange={e => onChangeHandler(e, 'email', 'persons', person.key)}
                                name='email'
                            />
                        </InputWrapperDiv>
                    </div>
                    <div className="col-xs-12 col-6">
                        <InputWrapperDiv>
                            {t("location.contact_person.l_name")}
                            <Input
                                value={person.last_name}
                                onChange={e => onChangeHandler(e, 'last_name', 'persons', person.key)}
                                name='last_name'
                            />
                        </InputWrapperDiv>
                        <InputWrapperDiv className="mt-2">
                            {t("location.contact_person.phone")}
                            <Input
                                value={person.phone}
                                onChange={e => onChangeHandler(e, 'phone', 'persons', person.key)}
                                name='phone'
                            />
                        </InputWrapperDiv>
                    </div>
                </div>

                <InputWrapperDiv className='mt-2'>
                    {t("location.contact_person.person_note")}
                    <Input
                        value={person.note}
                        onChange={e => onChangeHandler(e, 'note', 'persons', person.key)}
                        name='note'
                    />
                </InputWrapperDiv>
            </div>
        </>
    )
}
export default LocationContactPerson;