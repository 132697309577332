import {Route, Routes} from "react-router-dom";
import LocationsList from "../Locations/LocationsList";
import LocationDetails from "../Locations/LocationDetails";
import LocationAddEdit from "../Locations/LocationAddEdit";
import RoomAddEdit from "../Locations/RoomAddEdit";

const LocationsRoutes = () => {
    return(<>
        <Routes>
            <Route exact path={"/locations"} element={<LocationsList />}/>
            <Route exact path={"/locations/location/:key"} element={<LocationDetails />}/>
            <Route exact path={"/locations/location/:key/edit"} element={<LocationAddEdit />}/>
            <Route exact path={"/locations/location/:key/new/room"} element={<RoomAddEdit />}/>
            <Route exact path={"/locations/location/:key/edit/room/:idroom"} element={<RoomAddEdit />}/>
            <Route exact path={"/locations/new"} element={<LocationAddEdit />} />
        </Routes>
    </>)
}

export default LocationsRoutes
