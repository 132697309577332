import React from "react";
import LightCard from "../../../../../../components/card/LightCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../../../../components/theme";


const PositionsCard = ({data}) => {
    const { t } = useTranslation();
    const navigate = useNavigate()

    return(<>
            {data && data.map((position, index) => {
                return (
                    <div key={`"position_${index}"`} className="col-xl-3">
                        <LightCard>
                            <p className="mt-0"><b>{position[0]}</b></p>
                            {position[1].length >0
                                ? position[1].map((members, index) => {
                                    return(
                                        <p className="my-1 ps-2" key={`"member_${index}"`}>
                                            <a style={{fontSize:''}} name="editName"
                                                onClick={() => navigate("details/member/"+members.pk)} >
                                                    {members.first_name} {members.last_name}
                                                {/* <FontAwesomeIcon icon={faPenToSquare}/> */}
                                            </a>
                                        </p>
                                    )
                                })
                                :  <p className="my-1 ps-2"><i style={{color:Colors.red}}>{t("system_messages.no_members")}</i></p>
                            }
                        </LightCard><br/>
                    </div>
                )
            })}
    </>)
}

export default PositionsCard

