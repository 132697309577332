import React from "react";
import {useTranslation} from "react-i18next";
import { Colors } from "../theme";

const Addresses = ({obj}) => {
    const { t } = useTranslation()

    const address = [
        obj.street,
        obj.number,
        obj.place,
        obj.post_code,
        obj.country,
    ]
    const emptyAddress = address.every(el => !Boolean(el))

    return (<>
        { !emptyAddress ?
            <p className="mt-1">
                {obj.number} {obj.street} <br/>
                {obj.post_code} {obj.place} <br/>
                {obj.country}
            </p>
            : <i style={{color:`${Colors.darkRed}`}}>{t("profile.no_address_yet")}</i>
        }
    </>)
}

export default Addresses

