import LoginForm from "./loginForm";
// import {AuthenticationContext} from "../../contexts/AuthenticationContext";
// import {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";
import {FeedbackMessage} from '../../components/ui/Feedback_Message';
import { useTranslation } from "react-i18next";
import LanguageButtons from "../../components/ui/LanguageButtons";
import {useEffect, useState} from "react";
import { LoginResponseErrors } from '../../helpers/MessagesErrors/responseErrors';
import useAuth from "../../hooks/useAuth";

function Login() {
    const [auth, setAuth] = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [ errorsMessages, setErrorsMessages ] = useState([]);

    useEffect(() => {
        auth ? navigate("/home") : navigate('/login')
    }, [auth]);

    return (
        <>
        <div style={{marginBottom:'35px', overflow:'hidden'}}>
            <h1 className="underline">
                {t("login.title")}
            </h1>

            <LoginForm setErrorsMessages={setErrorsMessages} />
            {errorsMessages.length > 0
                ? <FeedbackMessage variant="error">
                    {/*
                1 Error-Message: E-mail or password is wrong!
                2 Error-Message: E-mail or password is wrong! Don't forget you can reset your password :)
                3 Error-Message: E-mail or password is wrong! You can contact the admin under <a href="tel:00352661309742">+352 661 309 742</a>
                */}
                    <div>
                        <p>
                            {errorsMessages.map((msg, i) =>
                                <li key={i}>{t(LoginResponseErrors[msg], {'ns': 'glossary'})}</li>
                            )}
                        </p>
                    </div>
                 </FeedbackMessage>
                : null
            }

            
            <Link to={"/sign-up"} style={{ float: 'right', marginTop: '10px'}}>{t("common.button.sign_up")}</Link>
        </div>
        
        <LanguageButtons />
                
        </>
    )
}

export default Login;

// {t("welcome")} <br />
// {t("cancel", {'ns': 'common'})} <br/>
