import { useTranslation } from "react-i18next";
import React from "react";

function EventsSettings({event, setEvent}){
    const { t } = useTranslation();

    const changeHandler = (val, fieldName) => {
        if(val.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
            const newEntry = {
                ...event,
                [fieldName]: val
            };

            console.log("new entry events", newEntry)

            setEvent(newEntry);
        }
    };

    return (
        <>
            <div className='row px-2'>
                <div className="col-sm-6 col-md-4">
                    <div className="d-flex flex-row align-items-center my-1">
                        <div>
                            <b>1 {t("events.rehearsal")}</b> =
                        </div>
                        <div className="ms-3">
                            <input
                                value={event.rehearsal}
                                onChange={val => changeHandler(val.target.value, 'rehearsal')}
                                style={{width:'100%', maxWidth:'60px'}}
                                name='events.rehearsal'
                            /> h
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4">
                    <div className="d-flex flex-row align-items-center my-1">
                        <div>
                            <abbr title={t("events.raccord_comment")}>
                                <b>1 {t("events.raccord")}</b>
                            </abbr> =
                        </div>
                        <div className="ms-3">
                            <input
                                value={event.raccord}
                                onChange={val => changeHandler(val.target.value, 'raccord')}
                                style={{width:'100%', maxWidth:'60px'}}
                                name='events.raccord'
                                // disabled
                            /> h
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
export default EventsSettings;