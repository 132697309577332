import {memberDetailsApi} from "../../../../../../services/api/user";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import LightCard from "../../../../../../components/card/LightCard";
import {EditButton} from "../../../../../../components/ui/EditButton";
import Addresses from "../../../../../../components/ui/Addresses";
import {useTranslation} from "react-i18next";
import DarkCard from "../../../../../../components/card/DarkCard";
import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import { Link } from "react-router-dom";


const getDetailsAPI = async (pk=0) => {
    try {
        const clientDetailData = await memberDetailsApi(pk)

        if (clientDetailData.status === 200) {
            return {details: clientDetailData.data, status: 200}
        }
    } catch (e) {
        console.log("Failed to download member details...")
        return {status: 400}
    }
}

const MemberDetails = () => {
    const [data, setData] = useState({})
    const params = useParams()
    const { t } = useTranslation()

    useEffect(() => {
        const detailsAPI = async () => {
            const response = await getDetailsAPI(params.key)
            setData(response.details)
        }
        detailsAPI()
    }, [])

    return (<>

        <DarkCard classNames='mb-3 row'>
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.asbl",{ ns: 'menu' })}</span> &#9656;
                <Link to={'/asbl/association'}>{t("menu.management",{ ns: 'menu' })}</Link> &#9656;
                <Link to={'/asbl/association/details/member/'+params.key}>{t("asbl.member_details")}</Link>
            </p>
        </DarkCard>

        <DarkCard classNames='mb-3 row'>
            <h1 className="mt-0 p-0">
                <span className="me-3">{data.first_name} {data.last_name}</span>
                <EditButton gotoName={"/asbl/association/edit/member/"+params.key}/>
            </h1>
            <div className="col-lg-6 col-xl-4 mb-4 ps-0">
                <LightCard>
                    <RedHeader title={t("asbl.member_details")}/>
                    <div className="px-2">
                        <p className="mb-2" style={{fontWeight:'bold'}}>
                            {data.first_name} <span style={{textTransform: 'uppercase'}}>{data.last_name}</span>
                        </p>

                        <Addresses obj={data} />

                        <p>
                            {t("profile.tel")+': '} <a href={"tel:" + data.telephone}>{data.telephone}</a>
                            <br/>
                            {t("profile.mail")+': '} <a href={"mailto:"+ data.email}>{data.email}</a>
                        </p>

                        <div>
                            <b>{t("Positions")+': '}</b>

                            <ul className="m-0">
                            {data.positions ?
                                data.positions.map(el => {
                                    return <li key={el.id}> {el.name} </li>
                                })
                                : null
                            }
                            </ul>
                        </div>

                        
                        
                    </div>
                </LightCard>
            </div>
        </DarkCard>

        <div className="row">
            <div className="col-lg-6 col-xl-4 mb-4">
                
            </div>
        </div>
    </>)
}

export default MemberDetails
