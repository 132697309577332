import { Colors } from "../theme";
import styled from "styled-components"

const StatusCircle = styled.div `
    margin-top: 6px !important;
    margin-right: 10px !important;
    width: 10px;
    height: 10px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    float: left;
    background: ${({status}) => 
        (status ==='approved' && Colors.green) ||
        (status ==='waiting' && Colors.orange) ||
        (status !==null && status) || 
        Colors.red
    };
`

export default StatusCircle;