
function validateEmail(email) {
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return re.test(email);
}

const availableRules = {
    required(value) {
        if (Array.isArray(value)) {
            return value.length === 0
        } else {
            return value ? false : true
        }
    },
    email(value) {
        return validateEmail(value) ? false : true
    },
};

export function validate(rules=[], value) {
    let error = false;

    rules.forEach(rule => {
        const errorMessage = availableRules[rule](value);

        if (errorMessage) {
            error = errorMessage;
        }
    });

    return error;
}