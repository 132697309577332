import { useTranslation } from "react-i18next";
import React from "react";

export const InstrumentsShow = ({profile}) => {
    const { t } = useTranslation();

    const groupByCategory = profile.reduce((group, item) => {
        const { name_type, name_instr } = item;
        group[name_type] = group[name_type] ?? [];
        group[name_type].push(name_instr);
        return group;
    }, {});

    return (
        <>
            <div className="col-xxl-6">
                {Object.entries(groupByCategory).map(([key,instr],i) => (
                    <div key={i} instr={key}>
                        <p className="mb-0">
                            <b>{t("instruments.classification."+key)}</b>
                        </p>
                        <ul className="mt-0">
                            { instr.map(val =>
                                <li key={val}>
                                    {val}
                                </li>
                            )}
                        </ul>
                    </div>
                ))}
                {/*}) }<br/>*/}
            </div>
         </>
    )


};

