import LordIcon from "./LordIcon"
import { Link } from "react-router-dom";
import { Colors } from "../theme";
import styled from "styled-components"

const MenuLinkStyle = {
    color: `${Colors.white}`,
    display: 'block'
}

const NavbarLink = styled(Link) `{
    &:hover {
        font-weight: bold;
    }
}`;

const MenuTitleText = styled.span` {
    font-size: 1.1rem; 
    font-weight: bold;
    vertical-align: bottom;
    line-height: 25px;
    padding-left: 10px;
    &:hover {
        font-weight: bold;
    }
}`;


export default function MenuButton({type,children,target,rotate,onClick, to}) {
    /**
    @param {{ 
        type: "home" | "avatar" | "folder" | "wallet" | "doc" | "settings" | "bell" | "arrow" | "own url"
    }} props
    */

    return (
        <>
            <NavbarLink to={to} className='menuLink' style={MenuLinkStyle} onClick={onClick}>
                <LordIcon url={type} target={target} rotate={rotate} />
                <MenuTitleText>
                    {children}
                </MenuTitleText>
            </NavbarLink>            

            {/*
            <LordIcon url='https://cdn.lordicon.com/slduhdil.json' />
            <LordIcon url='https://cdn.lordicon.com/hbvyhtse.json' />
            <LordIcon url='https://cdn.lordicon.com/xhcrhqyw.json' />
            <LordIcon url='https://cdn.lordicon.com/gsvbkwao.json' />
            <LordIcon url='https://cdn.lordicon.com/zncllhmn.json' />
            <LordIcon url='https://cdn.lordicon.com/dycatgju.json' />
            <LordIcon url='https://cdn.lordicon.com/msetysan.json' />
            <LordIcon url='https://cdn.lordicon.com/albqovim.json' />
            */}
        </>
    )
}