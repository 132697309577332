import LayoutDashboard from "../../../layouts/_ToDelete_Dashboard";
import _ToDelete_DashboardMenu from "../../dashboard/_ToDelete_DashboardMenu";
import _ToDelete_DashboardContent from "../../dashboard/_ToDelete_DashboardContent";

function MainTreasurer() {
    return (
        <div>
            Witaj Treasure - rola

        <LayoutDashboard
            menu={<_ToDelete_DashboardMenu/>}
            pageContent={<_ToDelete_DashboardContent/>}
        />

        </div>
    )
};

export default MainTreasurer;