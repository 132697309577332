import { useTranslation } from "react-i18next";
import { Colors } from "../../../../../../components/theme";
import LordIcon from "../../../../../../components/ui/LordIcon";
import React from "react";
import OtherCostsSettingsItem from "./OtherCostsSettingsItem";

function OtherCostsSettings ({otherEntries, changeUnitInfo, addMoreHandler, unitType, removeItem}) {
    const { t } = useTranslation()

    return (
        <>
            <div className="row px-2">
                <div className="col-12">
                    <div className="d-flex flex-row align-items-center my-2">
                        <div style={{width:'46%'}}>
                            <b>{t("common.titles.description")}</b>
                        </div>
                        <div style={{width:'27%'}} className="ms-2">
                            <b>{t("rates.clients_costs")}</b>
                        </div>
                        <div style={{width:'27%'}} className="ms-2">
                            <b>{t("rates.estros_costs")}</b>
                        </div>
                    </div>
                    {
                        otherEntries && otherEntries.map( el => (
                            <div className="d-flex flex-row align-items-center my-2 " key={`"keyId-${el.key}"`}>
                                <OtherCostsSettingsItem
                                    description={el.description}
                                    cCosts={el.client_cost}
                                    eCosts={el.estro_cost}
                                    keyId={el.key}
                                    readOnly={el.readOnly}
                                    display_type={el.display_type}
                                    changeUnitInfo={changeUnitInfo}
                                    unitType={unitType}
                                    removeItem={removeItem}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='row px-2 mt-2'>
                <div className="col-12">
                    <div className="addGroup pt-1 hover" style={{width:'fit-content'}}>
                        <LordIcon url='cross' color={`${Colors.red};`} rotate='45' target='.addGroup' changeActive={() => addMoreHandler(unitType)} />
                        <span className="ps-2" onClick={() => addMoreHandler(unitType)}>{t("common.button.add_more")}</span>
                    </div>
                </div>
            </div>
        </>
    )
};
export default OtherCostsSettings;
        