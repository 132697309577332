import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import { useTranslation } from "react-i18next";
import RatesEntriesSettings from "./RatesEntriesSettings";

function RatesSettings ({rates, setRates}) {
    const { t } = useTranslation();

    return (
        <>
        <div className="row">
            <div className="col-md-6">
                <RedHeader title={t("rates.clients")} />
                <div className="row px-2">
                    <div className="col-md-6">
                        <u>{t("rates.konzertmeister")}</u>
                        <RatesEntriesSettings
                            ratesAll={rates}
                            rates={[
                                rates['client_rate_kzm_rehearsal'],
                                rates['client_rate_kzm_raccord'],
                                rates['client_rate_kzm_concert'],
                            ]}
                            setRates={setRates}
                        />
                    </div>
                    <div className="col-md-6">
                        <u>{t("rates.musician")}</u>
                        <RatesEntriesSettings
                            ratesAll={rates}
                            rates={[
                                rates['client_rate_mus_rehearsal'],
                                rates['client_rate_mus_raccord'],
                                rates['client_rate_mus_concert'],
                            ]}
                            setRates={setRates}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <RedHeader title={t("rates.estros")} />
                <div className="row px-2">
                    <div className="col-md-6">
                        <u>{t("rates.konzertmeister")}</u>
                        <RatesEntriesSettings
                            ratesAll={rates}
                            rates={[
                                rates['estro_rate_kzm_rehearsal'],
                                rates['estro_rate_kzm_raccord'],
                                rates['estro_rate_kzm_concert'],
                            ]}
                            setRates={setRates}
                        />
                    </div>
                    <div className="col-md-6">
                        <u>{t("rates.musician")}</u>
                        <RatesEntriesSettings
                            ratesAll={rates}
                            rates={[
                                rates['estro_rate_mus_rehearsal'],
                                rates['estro_rate_mus_raccord'],
                                rates['estro_rate_mus_concert'],
                            ]}
                            setRates={setRates}
                        />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};
export default RatesSettings;
        