import styled from "styled-components"
import { Colors } from "../theme";

const TitleHeader = styled.div `
    background-color: ${Colors.red};
    color: ${Colors.white};
    font-weight: bold;
    border-radius: 3px;
    padding: 5px 10px;
    margin-top: 13px;
    margin-bottom: 13px;
`

function  RedHeader({title}) {
    return (
        <TitleHeader>
            {title}
        </TitleHeader>
    )
};

export default RedHeader;