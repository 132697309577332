import {Route, Routes, useParams} from "react-router-dom";
import PersonalData from "./PageContent/Profile/PersonalData";
import React from "react";
import Home from "./PageContent/Home";
import UserSettings from "./PageContent/UserSettings";
import EditView from "./PageContent/Profile/edit_view/EditView";

function RoutesMusician () {
    return (
        <Routes>
            <Route exact path={"/home"} element={<Home />}/>
            <Route
                exact
                path={"/profile/personal-data"}
                loader={({ params }) => {
                    // console.log("Sciezka:::>>>>", params["*"]);
                }}
                action={({ params }) => {}}
                element={<PersonalData />}
            />

            <Route exact path={"/profile/personal-data/edit"} element={<EditView />}/>
            <Route exact path={"/settings"} element={<UserSettings />}/>
            <Route path={"*"} element={<Home />}/>
        </Routes>
    )
};

export default RoutesMusician;