import Select from "react-select";
import React, {useContext, useState} from "react";
import {AuthenticationContext} from "../../contexts/AuthenticationContext";
import {Colors} from "../../components/theme";
import axiosClient from "../../services/api/api";
import {useNavigate} from "react-router-dom";

const select_styles = {
    container: state => ({
        position: "relative",
        margin: "0px 0px",
        backgroundColor: "rgba(0,0,0,0)",
        padding: "0px 0px",
        fontSize: "12px",
        //border: state.isFocused
        //    ? "1px solid " + Colors.darkRed
        //    : "1px solid " + Colors.darkGray,
        "&:hover": {
            borderColor: Colors.darkRed,
        },
        "div[id$='listbox']": {
            position: "relative",
        },
    }),
    valueContainer: (baseStyles, state) => ({
        ...baseStyles,
        padding: "0px 1px",
        cursor: "pointer",
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        color: Colors.white,
        cursor: "pointer",
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        padding: state.isDisabled ? "5px 5px" : "5px 15px",
        "&:hover": {
            borderLeft: state.isDisabled ? "" : "10px solid" + Colors.white,
            paddingLeft: state.isDisabled ? "5px" : "5px",
            //backgroundColor: state.isDisabled ? "" : Colors.lightBG,
            cursor: state.isDisabled ? "" : "pointer",
        },
        color: state.isDisabled ? Colors.black : Colors.white,
        borderTop: "1px dashed" + Colors.lightGray,
        fontSize: "12px",
    }),
    multiValue: baseStyles => ({
        ...baseStyles,
        backgroundColor: Colors.red,
        color: Colors.white,
        margin: "5px 10px 5px 0px",
        "&:hover": {
            //backgroundColor: Colors.darkRed,
            cursor: "pointer",
        },
    }),
    multiValueLabel: baseStyles => ({
        padding: "2px 5px",
    }),
    multiValueRemove: baseStyles => ({
        ...baseStyles,
        padding: "2px 5px",
        backgroundColor: Colors.darkRed,
        "&:hover": {
            backgroundColor: Colors.darkGray,
        },
    }),
};

const changeRoleAPI = async (role, navigate) => {
    const response = await axiosClient().post("api/accounts/change-role", {role: role});

    if (response.status !== 200 && Object.keys(response).length > 0) {
        // localStorage.clear();
        // navigate("/login");

    } else if (response.status === 200) {
        return true
    }

    if (response === false) {
        navigate("/network-error");
    }

    // console.log("Resp 200".response.status);
};

function ChangeRole() {
    const navigate = useNavigate();
    const context = useContext(AuthenticationContext);
    const [role, setRole] = useState({
        value: context.currentRole,
        label: context.currentRole.charAt(0).toUpperCase() + context.currentRole.slice(1)
    });

    const changeHandler = async (value) => {
        // save to db and context and localStorage
        // const resp = await changeRoleAPI(value.value, navigate);

        const response = await axiosClient().post("api/accounts/change-role", {role: role.value});

        if (response.status !== 200) {
                localStorage.clear();
                context.setCurrentRole('');
                setRole(value);

            // localStorage.clear();
            // navigate("/login");

        } else if (response.status === 200) {
                localStorage.setItem('currentRole', value.value);
                context.setCurrentRole(value.value);
                setRole(value);
            return
        }

        if (response === false) {
            navigate("/network-error");
        }



        // if (response) {
        //     localStorage.setItem('currentRole', value.value);
        //     context.setCurrentRole(value.value);
        //     setRole(value);
        // };
    };

    const userRoles = [];
    const rolesLocalStorage = JSON.parse(localStorage.getItem('userRoles'));
    if (rolesLocalStorage) {
        rolesLocalStorage.forEach(
            key => userRoles.push({value: key, label: key.charAt(0).toUpperCase() + key.slice(1)})
        );
    };

    return (
            userRoles.length > 1
            ? <Select
                    options={userRoles}
                    value={role}
                    onChange={val => changeHandler(val)}
                    styles={select_styles}
                    isSearchable={false}
                    unstyled
                />
            : <div>{role.label}</div>
    )
};

export default ChangeRole;
