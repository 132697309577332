// import estroLogo from '../../assets/images/Logo_250px.png';
import {useContext, useEffect} from "react";
import {AppContext} from "../../contexts/AppContext";

export default function Logo({styling}) {
    const {firmLogo} = useContext(AppContext)

    return (
        <img
            src={firmLogo}
            style={styling}
            alt=''
        />
    )
}