import styled from "styled-components";
import DarkCard from "../../../../../components/card/DarkCard";

const MapStyle = styled.div `
  
`
function MusicianMap () {
    return (
        <DarkCard>
            Musicians MAP
        </DarkCard>
    )
};


export default MusicianMap;