import axios from "axios";

// const baseURL = "https://dev.api.app.estro.lu";
const baseURL = process.env.REACT_APP_API_HOST;

const request = axios.create({
	baseURL,
});

// const tokenString = localStorage.getItem('token');
// detail: "Invalid token." ==> 401 ==> code: "ERR_BAD_REQUEST"

const requestGET = axios.create({
	baseURL,
	responseType: "json",
	headers: {},
});

const getDataAPI = async (urlGet = "") => {
	const url = `${baseURL}/${urlGet}`;
	try {
		const response = await requestGET.get(url);
		return response.data;
	} catch (error) {
		console.log("ERROR:", error);
		return false;
	}
};

const postDataAPI = async (urlPost = "", data) => {
	const url = `${baseURL}/${urlPost}`;
	try {
		const response = await requestGET.post(url, data);
		return response;
	} catch (error) {
		console.log("ERROR:", error);
		return error.response.data;
	}
};

export { request, getDataAPI, postDataAPI, baseURL };
