import DarkCard from "../../../../components/card/DarkCard";
import RedHeader from "../../../../components/ui/RedBgTitleHeader";
import SlideButtonCard from "../../../../components/card/SlideButtonCard";

function UserSettings () {
    return (
      <>
        <DarkCard className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="row" style={{padding:'0px 10px', marginBottom:'50px'}}>
                <div className="col-xxl-6">
                  <RedHeader title={'Notifications'} />
                  <SlideButtonCard 
                    title={'New user registration'}
                    description={'Every time a new person registers I would like to receive an email informing me about it.'}
                  />
                </div>
                <div className="col-xxl-6">
                  <RedHeader title={'Security'} />
                  <SlideButtonCard 
                    title={'2 Factor Security'}
                    description={'All information related to money is protected with an additional password. This password will be sent to me by e-mail if I wish to view this data.'}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-xxl-6">
                  <div style={{padding:'0px 10px', marginBottom:'50px'}}>
                    col-B1
                  </div>
                </div>
                <div className="col-xxl-6">
                  <div style={{padding:'0px 10px', marginBottom:'50px'}}>
                    col-B2-a
                  </div>

                  <div style={{padding:'0px 10px'}}>
                    col-B2-b
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DarkCard>
      </>
    )
};


export default UserSettings;