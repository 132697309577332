import { Colors } from "../theme";
import SlideButton from "../ui/SlideButton";

const SlideButtonCardStyle = {
    backgroundColor: `${Colors.white}`,
    borderRadius: '15px',
    padding: '15px 20px 20px 20px',
    width: '100%'
}

export default function SlideButtonCard({title, description}) {
    return (
        <div style={SlideButtonCardStyle} className="d-flex flex-column mb-4">
            <div className="d-flex flex-row">
                <div className="flex-fill align-self-center">
                    <h3 className="m-0">{title}</h3>
                </div>
                <div>
                    <SlideButton/>
                </div>
            </div>
            <div>
                {description}
            </div>
        </div>
    );
}