import addIcon from '../../../assets/images/icons/add.png';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import styled from "styled-components";
import { Colors } from '../../../components/theme';
import { storageInstrToForm } from '../../../helpers/components/user/registrationMapData';
import useLocalStorageState from 'use-local-storage-state';
import { calculateInstruments } from '../utils/filledField';
import Select from 'react-select'
import { useTranslation } from "react-i18next";

const RemoveIcon = styled.i`
    position: absolute;
    right: -11px;
    top: -7px;
    padding: 3px 5px 3px 6px;
    border-radius: 50%;
    height: 23px;
    width: 23px;
    font-size: 0.9rem;
    vertical-align: middle;
    color: white;
    background-color: ${Colors.darkestGray};
    &:hover {
        background-color: ${Colors.red};
    };
`

const select_styles = {
    container: (state) => ({
        position: 'relative',
        margin: '5px 0px',
        backgroundColor: 'white',
        padding: '2.5px 0px',
        border: state.isFocused ? ('1px solid ' + Colors.darkRed) : ('1px solid ' + Colors.darkGray),
        "&:hover": {
            borderColor: Colors.darkRed,
        },
        "div[id$='listbox']": {
            position:'relative'
        }
    }),
    valueContainer: (baseStyles, state) => ({
        ...baseStyles,
        padding: '4px 10px',
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        color: Colors.darkGray,
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        padding: state.isDisabled ? '5px 5px' : '5px 15px',
        "&:hover": {
            borderLeft: state.isDisabled ? '' : '10px solid'+Colors.darkRed,
            paddingLeft: state.isDisabled ? '5px' : '5px',
            backgroundColor: state.isDisabled ? '' : Colors.lightBG,
            cursor: state.isDisabled ? '' :'pointer'
        },
        color: state.isDisabled ? Colors.lightGray : Colors.darkGray,
        borderTop: '1px solid'+Colors.lightGray
    }),
    multiValue: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: Colors.red,
        color: Colors.white,
        margin: '5px 10px 5px 0px',
        "&:hover": {
            //backgroundColor: Colors.darkRed,
            cursor: 'pointer'
        },
    }),
    multiValueLabel: (baseStyles) => ({
        padding: '2px 5px',
    }),
    multiValueRemove: (baseStyles) => ({
        ...baseStyles,
        padding: '2px 5px',
        backgroundColor: Colors.darkRed,
        "&:hover": {
            backgroundColor: Colors.darkGray,
        },
    })
};



const Instruments = ({ setValid, setFilledInstrData,instrumentsData}) => {
    const localStorageData = localStorage.getItem('registrationInstrData');
    const [formList, setFormList] = useState(storageInstrToForm(localStorageData));
    const [stgInstrData, setStgInstrData] = useLocalStorageState("registrationInstrData", formList);

    useEffect(() => {
        setStgInstrData(formList);
    }, [formList]);

    useEffect(() => {
        const isValid = calculateInstruments(formList);
        setValid(isValid);
        setFilledInstrData(isValid);
    });

    function handleAddForms() {
        const new_forms = {
            key: uuidv4(),
            selected_type: false,
            selected_instr: false,
            value_type: '',
            value_instr: '',
        };
        const old_formList = [...formList];
        setFormList([...old_formList, new_forms]);
    };

    function handleRemoveForm(itemKey, formState) {
        const copy_formState = _.cloneDeep(formState);
        const item_index = copy_formState.findIndex((el) => el.key === itemKey);
        copy_formState.splice(item_index, 1);
        setFormList(copy_formState);
    };

    return (
      <>
        {formList.map((form_item) => (
                <FormItem
                    key={form_item.key}
                    form={form_item} // wystarczy przeslac klucz
                    formState={formList}
                    instrumentsData={instrumentsData}
                    handleInstr={setFormList}
                    handleRemoveForm={handleRemoveForm}
                />
        ))}
            <AddIconInstrument
                formList={formList}
                handleAddForms={handleAddForms}
            />
      </>
    )
  };

export default Instruments;

//////////___________________///////

function InstrumentType({ formsItem, formState, instrumentsData, handleInstr, handleRemoveForm }) {
    const { t } = useTranslation();
    const optionsData = instrumentsData ? [
        ...instrumentsData.map(op => ({value: op.id, label: op.type_name}))
    ] : [];

    const changeTypeInstr = (val) => {
        const copyformList = _.cloneDeep(formState);
        const new_item_index = copyformList.findIndex(({ key }) => key === formsItem.key);
        let new_item = copyformList[new_item_index];

        new_item.value_type = val;
        new_item.selected_type = val ? true : false;
        new_item.selected_instr = false;
        new_item.value_instr = '';

        const list_without_item = _.cloneDeep(formState).filter(({ key }) => key !== formsItem.key);

        if (list_without_item.length > 0) {
            list_without_item.splice(new_item_index, 0, new_item);
            handleInstr(list_without_item);
        } else {
            handleInstr([new_item]);
        }
    };

    return (
        <div className="row pb-2">
            <div className={`col  `}>
                <div className="customSelectParent" style={{position: 'relative'}}>
                    <Select
                        placeholder={t("instruments.type")+' *'}
                        name='instrumentType'
                        options={optionsData}
                        value={formsItem.value_type}
                        onChange={val => changeTypeInstr(val)}
                        styles={select_styles}
                        unstyled
                    />
                    {formState.length > 1 && <RemoveIcon className="fa fa-times" onClick={() => handleRemoveForm(formsItem.key, formState)}></RemoveIcon>}
                </div>
            </div>
        </div>
    );
};

function Instrument({ formsItem, formState, instrumentsData, handleInstr }) {
    const { t } = useTranslation();
    let optionsData = [];
    const instruments = instrumentsData.find(({id}) => id == formsItem.value_type.value);

    if (instruments) {
        const instrList = instruments.instruments.map((instr) => {
            const form_state_obj = formState.find(({ value_type, value_instr }) => (
                value_type.value == instruments.id && value_instr.value == instr.pk && value_instr.value !== formsItem.value_instr.value
            ));
            if (!form_state_obj) {
                return { value: instr.pk, label: instr.name }
            }
        }).filter(el => el !== undefined);

        optionsData = [...instrList];
    };

    const changeInstr = (val) => {
        const copyformList = _.cloneDeep(formState);
        const new_item_index = copyformList.findIndex(({ key }) => key === formsItem.key);
        let new_item = copyformList[new_item_index];

        new_item.value_instr = val;
        new_item.selected_instr = val ? true : false;

        const list_without_item = _.cloneDeep(formState).filter(({ key }) => key !== formsItem.key);

        if (list_without_item.length > 0) {
            list_without_item.splice(new_item_index, 0, new_item);
            handleInstr(list_without_item);
        } else {
            handleInstr([new_item]);
        }
    };

    return (
        <>
            { formsItem.selected_type &&
            <div className="row pb-2">
                <div className={`col  `}>
                    <div className="customSelectParent">
                        <Select
                            placeholder={t("instruments.intrument")+' *'}
                            name='instrument'
                            options={optionsData}
                            value={formsItem.value_instr}
                            onChange={val => changeInstr(val)}
                            styles={select_styles}
                            unstyled
                        />
                    </div>
                </div>
            </div>
            }
        </>
    );
};

function AddIconInstrument({ formList, handleAddForms }) {
    const { t } = useTranslation();
    const not_selected_type = formList.find( ({selected_type}) => !selected_type);
    const not_selected_instr = formList.find( ({ selected_instr }) => !selected_instr);

    return (
        <>
            {!not_selected_instr && !not_selected_type &&
            <div className='container' onClick={() => handleAddForms()}>
                <img
                    src={addIcon}
                    alt=''
                    className='txt_vertical_center' />
                <span className='mx-2 txt_vertical_center'>{t("common.button.add_instrument")}</span>
            </div>
            }
        </>
    );
};

function FormItem({ form, formState, instrumentsData, changeTypeInstr, handleInstr, handleRemoveForm }) {
    return (
        <div className='container pb-3 px-0'>
            <InstrumentType
                formsItem={form}
                formState={formState}
                instrumentsData={instrumentsData}
                changeTypeInstr={changeTypeInstr}
                handleInstr={handleInstr}
                handleRemoveForm={handleRemoveForm}
            />
            <Instrument
                formsItem={form}
                formState={formState}
                instrumentsData={instrumentsData}
                handleInstr={handleInstr}
            />
        </div>
    )
};

