import React from "react";
import {useTranslation} from "react-i18next";
import {EditButton} from "../../../../../../components/ui/EditButton";
import Address from "./Address";
import {TextStyle, TitleStyle} from "../../../../../../layouts/styles";


const FirmDetails = ({firm, handleNewEdit}) => {
    const { t } = useTranslation()

    return (<>
        <div className="row">
            <EditButton
                gotoName={"/clients/companies/edit/"+firm.value}
                handleClickProps={() => handleNewEdit(true, "firm")}
            />
        </div>
        <div className="row px-1">
            <div className="col-6">
                <p style={TitleStyle}>
                    {t("company.name")}
                </p>
                <p style={TextStyle}>
                    {firm.label}
                </p>
            </div>
            <div className="col-6">
                <p style={TitleStyle}>
                    {t("company.type")}
                </p>
                <p style={TextStyle}>
                    {firm.details.type}
                </p>
            </div>
        </div>
        <Address details={firm.details.address}/>
    </>)

}

export default FirmDetails

