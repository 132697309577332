import styled from "styled-components";

const PersonalDataStyle = styled.div `
  margin: 0px 30px 0px 30px;
  padding-bottom: 5px;
  @media (min-width: 880px) {
    margin: 0px 30px 0px 265px;
  }
`
function PersonalData () {
    return (
        <PersonalDataStyle>
            Personal Data Admin
        </PersonalDataStyle>
    )
};


export default PersonalData;