import FullPageBackground from '../components/Full_Page_Background';
import Logo from '../components/ui/Logo';
import {Link} from "react-router-dom";
import { Colors } from '../components/theme';
import LordIcon from '../components/ui/LordIcon';
import styled from "styled-components";
import MobileMenu from '../components/menu/MobileMenu';
import _ToDelete_DashboardMenu from '../pages/dashboard/_ToDelete_DashboardMenu';
import LanguageButtons from '../components/ui/LanguageButtons';

const LogoStyle = {
    maxWidth: '140px',
    width: '100%',
}

const PageHeader = styled.div `
    padding: 20px 30px 15px 30px;
    position: fixed;
    //overflow-x: hidden;
    z-index: 1;
    top: 0;
    height: 95px;
    display: none;
    background-color: ${Colors.lightBG};
    @media (min-height: 451px) and (min-width: 880px) {
        display: inline;
    }
`

const PageBody = styled.div `
    top: 90px;
    position: relative;
    @media (min-height: 651px) and (min-width: 880px) {
        top: 95px;
    }
`

const SideMenu = styled.div `
    background-color: ${Colors.red};
    display: none;
    position: fixed;
    overflow-x: hidden;
    z-index: 1;
    min-height: 100px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 30px 30px;
    color: ${Colors.white};
    @media (min-width: 880px) {
        width: 235px;
        display: inline;
    }
`

const PageContent = styled.div `
    margin: 0px 30px 0px 30px;
    padding-bottom: 5px;
    @media (min-width: 880px) {
        margin: 0px 30px 0px 265px;
    }
`

// slots in react components https://daveceddia.com/pluggable-slots-in-react-components/
export default function LayoutDashboard({menu,pageContent}) {
    return (
        <>
            <PageHeader className='container-fluid'>
                <div className='row align-items-center'>
                    <div className='col'>
                        <Link to={"/dashboard"}>
                            <Logo styling={LogoStyle}/>
                        </Link>
                    </div>
                    <div className='col'>
                        <div className="d-flex flex-row-reverse">
                            <div style={{position:'relative'}}>
                                <div style={{position:'absolute', backgroundColor:Colors.darkRed, color:'white', padding:'3px 10px', borderRadius:'25px', fontSize:'13px', top:'0', left:'30px'}}>
                                    1
                                </div>
                                <Link to={"/dashboard"} className='menuLink' style={{padding:'0px 35px 0px 15px', lineHeight:'60px'}}>
                                    <LordIcon url='bell' color='#2e2e2e' position='center'/>
                                </Link>
                            </div>
                            <div style={{maxWidth:'120px', marginLeft:'5px', marginRight:'5px'}}>
                                <LanguageButtons />
                            </div>
                        </div>
                    </div>
                </div>
            </PageHeader>

            <MobileMenu>
                <_ToDelete_DashboardMenu/>
            </MobileMenu>

            <PageBody>
                <SideMenu>
                    {/* Here comes the menu */}
                    {menu}
                </SideMenu>
                <PageContent>
                    {/* Here comes the page content */}
                    {pageContent}
                    {pageContent}
                    {pageContent}
                </PageContent>
            </PageBody>
            
            <FullPageBackground 
                bgColor={Colors.lightBG}
            />
        </>
    );
}