import { Colors } from "../../theme";
import PopupEntry from "./Popup_Entry";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PopupStyle = styled.div`
    background-color: ${Colors.darkRed};
    color: ${Colors.white};
    position: fixed;
    top: 75px;
    right: 46px;
    text-align: center;
    z-index: 3;
    border-radius: 5px;
    max-width: 325px;
    &:after {
        content: ' ';
        width: 0px;
        height: 0px;
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid ${Colors.darkRed};
        border-right: 10px solid transparent;
        position: absolute;
        left: 90%;
        bottom: 100%;
        margin-left: -10px;
    }
    > p a {
        color: ${Colors.white}!important;
    }
    @media (max-width: 992px) {
        display: none;
    }
`

function NotificationsPopup (){
    const { t } = useTranslation();

    return (
        <PopupStyle>
            {/* Entries example how to fill notifications with entries*/}
            <Link to={'notifications'}>
                <PopupEntry content='This is a message inside the popup'/>
            </Link>
            <Link to={'notifications'}>
                <PopupEntry content='This is a 2nd message inside the popup, longer'/>
            </Link>
            <Link to={'notifications'}>
                <PopupEntry content='This is a 3nd message inside the popup, super dupa long'/>
            </Link>
            {/* END Entries example */}

            <p> 
                <Link to={'notifications'}>
                    {t("common.button.show_all")}
                </Link>
            </p>
        </PopupStyle>
    )
}

export default NotificationsPopup;