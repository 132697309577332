export const Colors = {
    "red": "#B41F23",
    "darkRed": "#971A1E",
    "white": "#FFFFFF",
    "black": "#000000",
    "orange": "#E49815",
    "darkOrange": "#9f6a0e",
    "green": "#69B41F",
    "skyBlue": "#4d88d2",
    "purple": "#881fb4",
    "darkGreen": "#497d15",
    "darkestGray": "#333333",
    "darkGray": "#707070",
    "mediumGray": "#BCBCBC",
    "lightGray": "#e9e9e9",
    "lightBG": "#F7F4F1",
    "mediumBG": "#EFE9E3",
    "darkBG": "#b79d81",
    "success": "#146c43",
    "successBG": "#d1e7dd",
    "error": "#b02a37",
    "errorBG": "#f8d7da",
    "info": "#087990",
    "infoBG": "#cff4fc",
    "edit": "#ffc107",
    "editBG": "#fff3cd",
    "rehearsal":"#cc9a06",
    "rehearsal_dark":"#997404",
    "raccord":"#ab296a",
    "raccord_dark":"#842029",
    "concert":"#1aa179",
    "concert_dark":"#13795b"
}