import LordIcon from "../ui/LordIcon";
import {Colors} from "../theme";
import {useTranslation} from "react-i18next";
import EventEntry from "./EventsEntry";
import {integerValidate} from "../../helpers/validate";
import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import _ from "lodash";
import {EventsTypesCount} from "./calculator_components/EventsTypesCount";
import {diffTime} from "../../pages/roles/admin/PageContent/Sales/helpers/func_helpers";
import {roomsListAPI} from "../../services/api/locations";
import {isFreeEventDateAPI} from "../../services/api/calculator";

const timeStartEnd = type => {
	switch (type) {
		case "rehearsals":
			return ["19:30", "22:00"];
		case "raccords":
			return ["17:00", "17:45"];
		case "concerts":
			return ["20:00", "22:00"];
	}
};

const isFreeEventDate = async (eventDate) => {
	const response = await isFreeEventDateAPI(eventDate)
	return response.data
}

const compositionDefault = [{ value: "Tutti", label: "Tutti", color: "#00ace6"},]


function Events({
				events,
				setEvents,
				eventDetail,
				setEventDetail,
				staff,
				compositionOptions,
				setCompositionOptions,
				details,
				setDetails,
				kzmProject,
				locations,
				params,
				conductor,
}) {
	const { t } = useTranslation();
	const [roomsList, setRoomsList] = useState([]) // rooms list in location from backend

	useEffect(() => {
		const getRoomsList = async () => {
			try {
				const response = await roomsListAPI()
				const data = response.data.map(el => {
					return {
						value: el.name,
						label: el.name,
						room_id: el.pk,
						location_id: el.location
					}
				})
				setRoomsList(data)
			} catch (ex) {
				console.error("Failed to download rooms list.");
			}
		}
		getRoomsList()
	}, [])

	useEffect(() => {
		if (details) {
			if (eventDetail.length === 0) {
				addEventCountView()
			}
		}
	}, [details]);

	const setCompositionsMarkedInstr = (data) => {
		const evKey = data.key;
		const evDetailCopy = structuredClone(eventDetail);
		const evSelected = evDetailCopy.find(({key}) => key === evKey)
		if (evSelected) {
			evSelected.composition = data.composition;
		}
		setEventDetail(evDetailCopy);
	};

	const eventDetailUnit = (key, type) => ({
		key: key,
		eventDate: new Date(),
		startTime: timeStartEnd(type)[0],
		endTime: timeStartEnd(type)[1],
		eventLength: diffTime(timeStartEnd(type)[0], timeStartEnd(type)[1]),
		type: type,
		composition: compositionDefault,
		musReduction: 0,
		isConductor: true,
		kzmEvent: kzmProject,
		location: "",
		room: "",
	});

	const removeEventDetail = (eventDetailKey, evType) => {
		const eventCopy = structuredClone(eventDetail);
		const indexDel = eventCopy.findIndex(
			el => el.key === eventDetailKey
		);
		eventCopy.splice(indexDel, 1);

		const newEvents = _.cloneDeep(events);
		newEvents[evType] -= 1;

		setEvents(newEvents);
		setEventDetail(eventCopy);
	};

	const changeDetailsHandler = async (val, fieldName, eventDetailKey) => {
		const evDetailCopy = structuredClone(eventDetail);
		const elementChange = evDetailCopy.find(
			el => el.key === eventDetailKey
		)

		if (elementChange) {
			elementChange.isFree = true
			elementChange.noFreeLinks = ''

			if (fieldName !== 'eventLength') {
				elementChange[fieldName] = val
			}

			if (fieldName === "startTime" || fieldName === "endTime") {
				const duration = diffTime(elementChange["startTime"], elementChange["endTime"])
				elementChange["eventLength"] = duration
			}

			if (fieldName === 'location') {
				elementChange.room = ""
			}
			// usun elem z tablicy i wstaw zmieniony
			const indexElem = evDetailCopy.findIndex(el => el.key === eventDetailKey)
			evDetailCopy.splice(indexElem, 1, elementChange)
			// check is free event date
			if (details && fieldName === 'eventDate' && elementChange['eventDate']) {
				elementChange.booked = await isFreeEventDate({
					event_date: val,
					calculation: params.key,
				})
			}
			setEventDetail(evDetailCopy)
		}
	};

	const changeDetailsTypeHandler = (val, eventDetailKey) => {
		// zmienia typ eventu np rehearsals/raccords/concerts
		const eventDetCopy = structuredClone(eventDetail)
		const elementChange = eventDetCopy.find(
			el => el.key === eventDetailKey
		)
		// events couters modify
		const type_old = elementChange.type
		const eventsCopy = structuredClone(events)

		const old_value = +eventsCopy[type_old] -1
		const new_value = +eventsCopy[val] +1
		eventsCopy[type_old] = old_value
		eventsCopy[val] = new_value

		elementChange.type = val
		elementChange['startTime'] = timeStartEnd(val)[0]
		elementChange['endTime'] = timeStartEnd(val)[1]

		const indexEventDet = eventDetCopy.findIndex(
			el => el.key === eventDetailKey
		)
		eventDetCopy.splice(indexEventDet, 1, elementChange)

		setEvents(eventsCopy)
		setEventDetail(eventDetCopy)
	};

	const addEventCountView = () => {
		const evDetails = []
		let key = "";

		Array.from(
			{ length: events.rehearsals },
			(_, index) => {
				key = uuidv4();
				const evDetailUnit = eventDetailUnit(key, "rehearsals");
				evDetails.push(evDetailUnit);
			}
		);
		Array.from(
			{ length: events.raccords },
			(_, index) => {
				key = uuidv4();
				const evDetailUnit = eventDetailUnit(key, "raccords");
				evDetails.push(evDetailUnit);
			}
		);
		Array.from(
			{ length: events.concerts },
			(_, index) => {
				key = uuidv4();
				const evDetailUnit = eventDetailUnit(key, "concerts");
				evDetails.push(evDetailUnit);
			}
		);
		setEventDetail(evDetails);
	}

	const changeEventsHandler = (val, fieldName) => {
		if (integerValidate(val)) {
			const newEntry = {
				...events,
				[fieldName]: val,
			};
			setEvents(newEntry);

			if (details) {
				const newEntry = _.cloneDeep(eventDetail);
				newEntry.unshift(eventDetailUnit(uuidv4(), fieldName));
				setEventDetail(newEntry);
			}
		}
	};

	const changeDetailsView = () => {setDetails(!details)}

	return (
		<>
			<div className='row px-2'>
				<div className='col-sm-6 col-md-4'>
					<div className='d-flex flex-row align-items-center my-1'>
						<div>
							{events.rehearsals || null } {t("events.rehearsals")}
						</div>
						<div className='ms-3'>
							<EventsTypesCount
								evValue={events.rehearsals}
								type={"rehearsals"}
								changeHandler={changeEventsHandler}
								moreDetails={details}
							/>
						</div>
					</div>
				</div>
				<div className='col-sm-6 col-md-4'>
					<div className='d-flex flex-row align-items-center my-1'>
						<div>
							{events.raccords || null} {t("events.raccords")}
						</div>
						<div className='ms-3'>
							<EventsTypesCount
								evValue={events.raccords}
								type={"raccords"}
								changeHandler={changeEventsHandler}
								moreDetails={details}
							/>
						</div>
					</div>
				</div>
				<div className='col-sm-6 col-md-4'>
					<div className='d-flex flex-row align-items-center my-1'>
						<div>
							{events.concerts || null} {t("events.concerts")}
						</div>
						<div className='ms-3'>
							<EventsTypesCount
								evValue={events.concerts}
								type={"concerts"}
								changeHandler={changeEventsHandler}
								moreDetails={details}
							/>
						</div>
					</div>
				</div>
			</div>
			{details ? null : (
				<div className='row px-2 mt-4'>
					<div className='addGroup'>
						<LordIcon
							url='cross'
							color={`${Colors.red};`}
							rotate='45'
							target='.addGroup'
							changeActive={changeDetailsView}
						/>
						<a className='ps-2' onClick={changeDetailsView}>
							{t("common.button.add_more_details")}
						</a>
					</div>
				</div>
			)}

			{details ? (
				<>
					<div className='row mt-3 px-2'>
						<div className="col">
							{eventDetail.map(val =>
								<EventEntry
									key={val.key}
									eventDetail={val}
									changeDetailsHandler={changeDetailsHandler}
									changeDetailsTypeHandler={changeDetailsTypeHandler}
									removeEventDetail={removeEventDetail}
									setMarkedInstr={setCompositionsMarkedInstr}
									staff={staff}
									compositionOptions={compositionOptions}
									setCompositionOptions={setCompositionOptions}
									kzmProject={kzmProject}
									locations={locations}
									roomsOptions={roomsList}
									conductor={conductor}
								/>)
							}
						</div>
					</div>
				</>
			) : null}
		</>
	);
}
export default Events;
