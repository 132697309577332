import {Text, View} from "@react-pdf/renderer";
import React from "react";
import {useTranslation} from "react-i18next";
import { Colors } from "../../../components/theme";
import { format_datetime_convert } from "../../tools";

const OtherDataPDF = ({note, quote_acceptance}) => {
    const {t} = useTranslation()

    return(<>
        {quote_acceptance && typeof quote_acceptance === 'object' && Object.keys(quote_acceptance).length > 0 &&
            <View style={{marginHorizontal: 30}}>
                {quote_acceptance.status && (
                    <Text style={{ marginTop: '10px' }}>
                        {t("offer.pdf_document.status.title")}
                    </Text>
                )}
                {quote_acceptance.status === 'new' && (
                    <Text style={{ color: Colors.darkOrange }}>
                        {t("offer.pdf_document.status.waiting_pdf_message")}
                    </Text>
                )}
                {quote_acceptance.status === 'waiting' && (
                    <Text style={{ color: Colors.darkOrange }}>
                        {t("offer.pdf_document.status.waiting_pdf_message")}
                    </Text>
                )}
                {quote_acceptance.status === 'accepted' && (<>
                    <Text style={{ color: Colors.darkGreen }}>
                        {t("offer.pdf_document.status.accepted_message", {
                            person: `${quote_acceptance.person}`,
                        })}
                    </Text>
                    <Text>{"\n"}</Text>
                    <Text>{t("offer.pdf_document.status.signature_date", {
                            dateTime: format_datetime_convert(new Date(quote_acceptance.date_time)),
                        })}
                    </Text>
                </>)}
                {quote_acceptance.status === 'rejected' && (
                    <Text style={{ color: Colors.darkRed }}>
                        <Text>{t("offer.pdf_document.status.rejected_message", {
                            person: `${quote_acceptance.person}`,
                            })}
                        </Text>
                        <Text>{"\n"}</Text>
                        <Text>{t("offer.pdf_document.status.signature_date", {
                            dateTime: format_datetime_convert(new Date(quote_acceptance.date_time)),
                            })}
                        </Text>
                        <Text>{"\n"}</Text>
                        <Text>{t("offer.pdf_document.status.reason_title")}</Text>
                        <Text>{"\n"}</Text>
                        <Text>{quote_acceptance.reason}</Text>
                    </Text>
                )}
            </View>
        }
    </>)
}

export default OtherDataPDF

