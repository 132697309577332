import axiosClient from "./api";
import {configEndpoints} from "./endpoints";

const axiosClientJSON = axiosClient()
const axiosClientForm = axiosClient('multipart/form-data')

const calcLocationListAPI = () => {
    return axiosClientJSON.get(configEndpoints.calcLocationList)
}

const locationsListAPI = () => {
    return axiosClientJSON.get(configEndpoints.location)
}

const locationDetailAPI = (key) => {
    return axiosClientJSON.get(configEndpoints.locationDetail(key))
}

const locationCreateAPI = (data) => {
    return axiosClientJSON.post(configEndpoints.locationCreate, data)
}

const locationUpdateAPI = (data, id) => {
    return axiosClientJSON.put(configEndpoints.locationUpdate(id), data)
}

const roomsListAPI = () => {
    return axiosClientJSON.get(configEndpoints.room)
}

const roomDetailAPI = (idroom) => {
    return axiosClientJSON.get(configEndpoints.roomItem(idroom))
}

const roomCreateAPI = (data) => {
    return axiosClientForm.post(configEndpoints.room, data)
}

const roomUpdateAPI = (data, id) => {
    return axiosClientForm.put(configEndpoints.roomItem(id), data)
}

export {
    calcLocationListAPI,
    locationsListAPI,
    locationDetailAPI,
    locationCreateAPI,
    locationUpdateAPI,
    roomsListAPI,
    roomDetailAPI,
    roomCreateAPI,
    roomUpdateAPI,
}