import { Input, InputWrapperDiv } from "../../../../../../components/ui/Input";
import { validate } from "../../../../../sign_up/components/validations";
import {useContext, useEffect, useState} from "react";
import {draftProfileUpdateAPI} from "../../../../../../services/api/user";
import {fieldMap} from "../../../../../../helpers/dictionary/users";


const BankDetailsForm = ({form, setForm}) => {
    const [formBD, setFormBD] = useState(form);
    const [field, setField] = useState({fieldName: "", value: ""});

    useEffect(() => {
        setFormBD(form);
    }, [form]);

    useEffect(() => {
        const field_name_change = () => {
            const field_map_backend = fieldMap[field.fieldName];

            if (field_map_backend) {
                return field_map_backend(field.value);
            };
            // return {'bank_details': {[field.fieldName]: field.value}};
        };
        const updateDraftData = async () => {
            try {
                const draftResponse = await draftProfileUpdateAPI(field_name_change());
            } catch(ex) {
                console.error("Nie udalo sie zmodyfikowac draftu...", ex);
                return
            };
        }
        updateDraftData();
    }, [field]);

    const changeHandler = (value, fieldName) => {
        const error = validate(formBD[fieldName].rules, value);

        const js_data = new Object();
        js_data[fieldName] = value;

        const newEntry = {
            ...formBD,
            [fieldName]: {
                ...formBD[fieldName],
                value: Array.isArray(value) ? value.map(x => x.value) : value,
                error
            },
        };
        setField({"fieldName": fieldName, "value": value});
        setFormBD(newEntry);
    };


    return (<>
        <div className='row pb-2'>
            <InputWrapperDiv>
                <Input
                    placeholder='Bank name *'
                    name='bankName'
                    value={formBD.bankName.value}
                    onChange={val => changeHandler(val, "bankName")}
                    readOnly={true}
                />
            </InputWrapperDiv>
        </div>

        <div className="row pb-2">
                <InputWrapperDiv><Input
                    placeholder="Bank number (IBAN) *"
                    name="accountNumber"
                    value={formBD.accountNumber.value}
                    onChange={val => changeHandler(val, 'accountNumber')}
                />
                </InputWrapperDiv>
            </div>
            <div className="row pb-2">
                <InputWrapperDiv><Input
                    placeholder="BIC*"
                    name="bicNumber"
                    value={formBD.bicNumber.value}
                    onChange={val => changeHandler(val, 'bicNumber')}
                />
                </InputWrapperDiv>
            </div>
            <div className="row pb-2">
                <InputWrapperDiv><Input
                    placeholder="Account holder name *"
                    name="accountHolder"
                    value={formBD.accountHolder.value}
                    onChange={val => changeHandler(val, 'accountHolder')}
                />
                </InputWrapperDiv>
            </div>
    </>)


};

export default BankDetailsForm;

