import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import DarkCard from "../../../../../../components/card/DarkCard";
import React from "react";
import {PersonalDataShow} from "./PersonalDataShow";
import {BankDetailsShow} from "./BankDetailsShow";
import {InstrumentsShow} from "./InstrumentsShow";
import {useTranslation} from "react-i18next";
import {format_date_convert} from "../../../../../../helpers/tools";

const ShowView = ({profile}) => {
    const { t } = useTranslation();
    
    return (
        <DarkCard className='container-fluid'>
            <div className='row'>
                <div className='col-md-6'>
                    <RedHeader title={"Personal data"}/>
                    <div
                        className='row mb-3 mb-sm-1 px-2'>
                        <PersonalDataShow profile={profile}/>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='row'>
                        <div className='col-xxl-6'>
                            <RedHeader title={"Bank details"}/>
                            <div className="mb-3 mb-sm-1 px-2">
                                <BankDetailsShow profile={profile.bank_details}/>
                            </div>
                        </div>
                        <div className='col-xxl-6'>
                            <RedHeader title={"Instruments"}/>
                            <div className="mb-3 mb-sm-1 px-2">
                                <InstrumentsShow profile={profile.instruments}/>
                            </div>

                            <RedHeader title={"Mandatory data check"}/>
                            <div className="px-2">
                                {t("profile.last_check_message_date", {date: format_date_convert(profile.expiration_date)})}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DarkCard>
    )
};

export default ShowView;
