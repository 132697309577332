import { useEffect, useState } from "react";
import { calculationsListAPI } from "../../../../../../services/api/calculator";
import { format_datetime_convert } from "../../../../../../helpers/tools";
import DarkCard from "../../../../../../components/card/DarkCard";
import styled from "styled-components";
import { Colors } from "../../../../../../components/theme";
import { useTranslation } from "react-i18next";
// import { ProjectContext } from "../../../../../../contexts/AppContext";
import { ElementStyle } from "../../../../../../layouts/styles";
import { FieldStyle } from "../../../../../../layouts/styles";
import {useNavigate} from "react-router-dom";

const ListElement = styled.div`
	border-radius: 5px;
	padding: 8px 15px;
	margin: 15px 0px;
	background-color: ${Colors.lightBG};
`;

const TxtTitles = styled.p`
	font-size: 0.7em;
    text-transform: uppercase;
	width: 100%;
    padding: 0 0 3px 0;
    margin: 0;
    color: ${Colors.darkRed};
`;

const SavedCalculations = () => {
	const { t } = useTranslation();
	const [calcList, setCalcList] = useState([]);

	useEffect(() => {
		const getCalculationsList = async () => {
			try {
				const response = await calculationsListAPI();
				const new_data = response.data.map(el => ({
					...el,
					created: format_datetime_convert(el.created),
					modify: format_datetime_convert(el.modify),
				}));
				setCalcList(new_data);
			} catch (e) {
				console.error(e);
				navigate('/network-error');
			}
		};

		getCalculationsList();
	}, []);

	const navigate = useNavigate()
	const handleClick = (pk) => {navigate("/sales/calculator/"+pk)}
	

	return (
		<>
			<DarkCard classNames='mb-3 row'>
				<p className="m-0 p-0 breadcrumbs">
					<span className="txt">{t("menu.sales",{ ns: 'menu' })}</span> &#9656;
					<span className="txt">{t("menu.saved_calculations",{ ns: 'menu' })}</span>
				</p>
			</DarkCard>
			<DarkCard classNames='mb-3 row'>
				<h1 className='mt-0 p-0'>{t("calculator.saved_list_title")}</h1>
							
				
				{calcList.length < 1
					? <p>{t("calculator.no_data")}</p>
					:	calcList.map(el => (
							/*<ListElement key={el.pk}>
								<Link to={`/sales/calculator/${el.pk}`}>
									<b style={{ fontSize: "18px" }}>{el.name}</b>&nbsp;-&nbsp;
									{t("calculator.created_date_time")} {el.created}&nbsp;-&nbsp;
									{t("calculator.modified_date_time")} {el.modify}
								</Link>
							</ListElement>*/
							<ElementStyle className={"d-flex flex-column"} key={el.pk}>
								<div className="d-flex flex-row flex-wrap flex-md-nowrap py-1" style={{width: '95%'}}>
									<FieldStyle width="13%" className="p-1">
										<div>
											<TxtTitles>{t("calculator.id_title")}</TxtTitles>
											<a onClick={() =>{handleClick(el.pk)}}><b>C{el.pk}</b></a>
										</div>
									</FieldStyle>

									<FieldStyle className="p-1">
										<div>
											<TxtTitles>{t("calculator.name_title")}</TxtTitles>
											<a onClick={() =>{handleClick(el.pk)}}><b>{el.name}</b></a>
										</div>
									</FieldStyle>

									<FieldStyle className="p-1">
										<div>
											<TxtTitles>{t("calculator.created_date_time")}</TxtTitles>
											{el.created}
										</div>
									</FieldStyle>

									<FieldStyle className="p-1">
										<div>
											<TxtTitles>{t("calculator.modified_date_time")}</TxtTitles>
											{el.modify}
										</div>
									</FieldStyle>
								</div>
							</ElementStyle>
						))
				}
			</DarkCard>
		</>
	);
};

export default SavedCalculations;
