import React from 'react';
import {createRoot} from 'react-dom/client';
import App from "./App";
import '../node_modules/bootstrap/dist/css/bootstrap-grid.min.css';
import '../node_modules/animate.css/animate.min.css';
import '../src/global.css';

const root = createRoot(document.getElementById('root'));

document.documentElement.style.setProperty('--original-viewport-height', window.innerHeight+"px");

root.render(
    // <React.StrictMode>
        <React.Suspense fallback={<div>loading data...</div>}>
            <App />
        </React.Suspense>
    // </React.StrictMode>
);