import { useState } from "react";
import styled from "styled-components";
import { Button, PropouseButtons } from "../ui/Button";
import { useTranslation } from "react-i18next";

const LiComp = styled.li`
    margin-bottom: 25px;
    .open {
       height: auto;
    }
`;

const AnswerWrapperComp = styled.div`
    height: 0;
    overflow: hidden;
`;

const AnswerComp = styled.div`
    padding: 10px 0px;
`;

const AccordionItem = ({
        acc_section,
        onToggle,
        active,
        last,
        goToNext,
        setFilledPersonalData,
        setFilledBankData,
        setFilledInstrData,
        instrumentsData,
        clicked,
        itemId
    }) => {
        
	const { t } = useTranslation();
    const { title, content } = acc_section;
    const [disabled, setDisabled] = useState(true);

    const Content = content;

    if (disabled) {
        onToggle = () => {};
    };

    return (
        <LiComp className={`accordion_item ${active ? "active" : ""}`}>

            <Button proposueButton={PropouseButtons.TITLE} bWidth="full" onClick={onToggle} itemId={itemId} clicked={clicked} >
                {title}
            </Button>

            <AnswerWrapperComp className={`${active ? "open" : ""}`} style={{padding: '0px 15px'}}>
                <AnswerComp>
                    <Content
                        setValid={val => setDisabled(!val)}
                        setFilledPersonalData={setFilledPersonalData}
                        setFilledBankData={setFilledBankData}
                        setFilledInstrData={setFilledInstrData}
                        instrumentsData={instrumentsData}
                    />

                    {!last && <div className="row pb-2">
                        <div className={`col`}>
                            <Button proposueButton={PropouseButtons.NEXT} onClick={goToNext} bWidth='half' disabled={disabled}>
					            {t("common.button.next")}
                            </Button>
                        </div>
                    </div>}
                </AnswerComp>
            </AnswerWrapperComp>
        </LiComp>
    );
};

export default AccordionItem;