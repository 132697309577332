import i18n from '../../translations/i18n';
import Select from 'react-select'
import { Colors } from '../theme';

const language_select_style = {
    container: (state) => ({
        margin: '5px 0px',
        backgroundColor: Colors.white,
        padding: '2.5px 7px',
        borderRadius: '0px',
        position: 'relative',
        "div[id$='listbox']": {
            position:'absolute',
            left: '0px',
            backgroundColor: Colors.white,
            border:'1px solid'+Colors.lightGray,
            borderTop:'none'
        }
    }),
    valueContainer: (baseStyles, state) => ({
        ...baseStyles,
        padding: '4px 10px',
        "&:hover": {
            cursor: state.isDisabled ? '' :'pointer'
        },
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        color: Colors.darkGray,
        "&:hover": {
            cursor: state.isDisabled ? '' :'pointer'
        },
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        padding: state.isDisabled ? '5px 5px' : '5px 15px',
        "&:hover": {
            borderLeft: state.isDisabled ? '' : '10px solid'+Colors.darkRed,
            paddingLeft: state.isDisabled ? '5px' : '5px',
            backgroundColor: state.isDisabled ? '' : Colors.lightBG,
            cursor: state.isDisabled ? '' :'pointer'
        },
        color: state.isDisabled ? Colors.lightGray : Colors.darkGray,
        borderTop: '1px solid'+Colors.lightGray,
    })
};

export default function LanguageButtons({className}) {

    const handleOnclick = (e) => {
        i18n.changeLanguage(e.value);
    };

    const option_lang = [
        { value: "en", label: "English" },
        { value: "de", label: "Deutsch" },
        { value: "fr", label: "Français" },
    ];

    let locales = {};
    option_lang.forEach((el) => {
        locales[el.value] = {'langName': el.label}
    });

    return (
            <div className={`languageSelect `+className}>
                <Select
                    // menuPlacement="auto" //top bottom auto
                    placeholder='select language'
                    defaultValue={{ value: "fr", label: "Français" }}
                    options={option_lang}
                    value={{'value': i18n.resolvedLanguage, 'label': i18n.resolvedLanguage ? locales[i18n.resolvedLanguage].langName : 'Français'}}
                    onChange={handleOnclick}
                    styles={language_select_style}
                    isSearchable={false} //powoduje ze input jest w trybie readonly dzieki czemu nie otwiera sie klawiatura na telefonie
                    unstyled
                />
            </div>
    );
}
