import { useTranslation } from "react-i18next";
import { InputBlock } from "../ui/Input";
import { integerValidate } from "../../helpers/validate";
import { v4 as uuidv4 } from "uuid";
import SlideButton from "../ui/SlideButton";
import {
	instrNameCol1,
	instrNameCol2,
	instrNameCol3,
	instrNameCol4
} from "../../helpers/dictionary/users";
import {getNameColumnTypeStaff} from "../../helpers/tools";

function Effectif({
	fieldNameList,
	setStaff,
	eventDetail,
	setEventDetail,
	renting,
	setRenting,
	transport,
	setTransport,
	kzmProject,
	setKzmProject,
	conductor,
	setConductor,
}) {
	const { t } = useTranslation();

	const InstrumentsView = name => {
		if (name in fieldNameList) {
			const instrItem = {...fieldNameList[name]}

			return (
				<InputBlock
					key={uuidv4()}
					fieldName={instrItem}
					handler={changeHandler}
					t={t}
				/>
			);
		}
		return null;
	};

	const changeHandlerConductor = el => {
		setConductor(el)
	}

	const changeHandler = (el, fieldName) => {
		// wybierz oznaczenie typu kolumny
		const instrTypeName = getNameColumnTypeStaff(fieldName)

		if (integerValidate(el)) {
			const staffCopy = structuredClone(fieldNameList)
			const currentEntry = staffCopy[fieldName]
			currentEntry.value = el
			currentEntry.type = instrTypeName

			const newEntry = {
				...fieldNameList,
				[fieldName]: currentEntry,
			}

			// dopisz do renting gdy jest cost (renging_client/estro_cost)
			const entry = newEntry[fieldName]
			const client_cost = entry.renting_client_cost
			const estro_cost = entry.renting_estro_cost
			const sum_renting_client = client_cost * currentEntry.value
			const sum_renting_estro = estro_cost * currentEntry.value

			if (entry.renting_client_cost > 0 || entry.renting_estro_cost > 0) {
				// usun z renting wpis z name=fieldName gdy istnieje w renting
				const rentingClone = structuredClone(renting);
				const indexDel = rentingClone.findIndex(
					el => (el.cost_type = "renting" && el.id === fieldName)
				)

				if (indexDel > -1) {
					rentingClone.splice(indexDel, 1);
				}

				if (el < 1 || el === "" || el === undefined) {
					setRenting(rentingClone);
				} else {
					const newRentingItem = {
						id: fieldName,
						description: `${el}x ${fieldName}`,
						client_cost: parseFloat(sum_renting_client).toFixed(2),
						estro_cost: parseFloat(sum_renting_estro).toFixed(2),
						cost_type: "renting",
						display_type: "descro"
					};
					setRenting([...rentingClone, newRentingItem]);
				}
			};

			// transport support
			const test_transport_cost = (
				parseFloat(currentEntry.transport_client_cost)>0 ||
				parseFloat(currentEntry.transport_estro_cost)>0
			)
			if (test_transport_cost) {
				const transportClone = structuredClone(transport);
				const indexItem = transportClone.findIndex(el => el.id === fieldName)

				const descName = desc => {
					return `${el}x ${desc}`;
				};

				if (indexItem > -1) {
					transportClone.splice(indexItem, 1);
				}

				if (el < 1 || el === "" || el === undefined) {
					setTransport(transportClone);
				} else {
					const newTransportItem = {
						id: fieldName,
						client_cost: parseFloat(currentEntry.transport_client_cost * el).toFixed(2),
						cost_type: "transport",
						description: descName(fieldName),
						edit: true,
						estro_cost: parseFloat(currentEntry.transport_estro_cost * el).toFixed(2),
						display_type: "descro"
					}
					setTransport([...transportClone, newTransportItem]);
				}
			};

			// zmien w eventDetail[key].composition
			if (el == 0) {
				const evDetailCopy = structuredClone(eventDetail)
				// usun w petli z composition
				evDetailCopy.forEach(elem => {
					if (elem.composition !=0 && elem.composition.map(instr => instr.value).includes(fieldName)) {
						const elemIndex = elem.composition.findIndex(nameInstr => nameInstr.value === fieldName);
						if (elemIndex > -1) {
							elem.composition.splice(elemIndex, 1);
						}
					}
				})
				setEventDetail(evDetailCopy)
			}
			setStaff(newEntry);
		}
	}

	return (<>
		<div className='row px-2'>
			<div className='col-sm-6 col-md-3 d-flex flex-column'>
				{instrNameCol1.map(el => InstrumentsView(el))}
			</div>
			<div className='col-sm-6 col-md-3 d-flex flex-column'>
				{instrNameCol2.map(el => InstrumentsView(el))}
			</div>
			<div className='col-sm-6 col-md-3 d-flex flex-column'>
				{instrNameCol3.map(el => InstrumentsView(el))}
			</div>
			<div className='col-sm-6 col-md-3 d-flex flex-column'>
				{instrNameCol4.map(el => InstrumentsView(el))}
				<InputBlock
					key={uuidv4()}
					fieldName={{name: "Conductor", value: conductor, label: "effectif.Conductor"}}
					handler={changeHandlerConductor}
					t={t}
				/>
			</div>
		</div>
		<div className='row mt-3 px-2'>
			<div>{t("events.kzm_participating")}</div>
			<SlideButton
				type='yesNo'
				val={kzmProject}
				setVal={val => setKzmProject(val)}
			/>
		</div>
	</>);
}
export default Effectif;
