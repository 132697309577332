import {Text, View} from "@react-pdf/renderer";
import {styles, stylesTotal} from "./Styles/Styles";
import {useTranslation, Trans} from "react-i18next";
import {calculation_sections_sum} from "../../tools";

const Total = ({projProgram, projectData, musiciansSum, showGrant}) => {
    const { t } = useTranslation();
    const renting = calculation_sections_sum(projectData.renting).client_cost
    const transport = calculation_sections_sum(projectData.transport).client_cost
    const archiving = calculation_sections_sum(projectData.archiving).client_cost
    const administration = calculation_sections_sum(projectData.administration).client_cost + archiving
    let musicians_summary = musiciansSum

    const total = musicians_summary + renting + transport + administration  //administration includes archiving

    // Przechowujemy licznik i mapujemy typy na ich liczby
    let explanationCounter = 1;
    const typeWithSuperscript = {};

    if (renting > 0) {
        typeWithSuperscript["renting"] = explanationCounter++;
    }
    if (administration > 0) {
        typeWithSuperscript["administration"] = explanationCounter++;
    }

    return (
        <View style={{paddingVertical:15, marginHorizontal:30, flexDirection: 'row', wrap: 'nowrap'}}>
            <View style={{width: '50%'}}>
                <Text style={[styles.redTitle,{marginBottom:'9px'}]}>{t("offer.pdf_document.texts.programme_title")}</Text>
                <Text>{projProgram ? projProgram : t("offer.pdf_document.texts.to_define")}</Text>
            </View>

            <View style={{width: '50%'}}>
                <Text style={[stylesTotal.service, styles.redTitle, {marginBottom:'5px'}]}>
                    {t("offer.pdf_document.texts.budget")}
                </Text>
                {showGrant &&
                    <View style={{borderBottom:'1px solid #ccc', paddingBottom: '5px'}}>
                        <View style={stylesTotal.entryLine}>
                            <Text style={[stylesTotal.service,{fontFamily:'Helvetica-Bold'}]}>
                                {t('offer.pdf_document.texts.real_project_costs')}
                            </Text>
                            <Text style={[stylesTotal.costs,{fontFamily:'Helvetica-Bold'}]}>
                                {parseFloat(projectData.totaling.estroSum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
                            </Text>
                        </View>

                        <View style={stylesTotal.entryLine}>
                            <Text style={{fontSize:'9px'}}>
                                <Trans
                                    i18nKey="offer.pdf_document.texts.real_project_costs_desc"
                                    values={{
                                        grantEstro: `${parseFloat(projectData.totaling.grantEstroSum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`
                                    }}
                                    components={{ strong: <Text style={{ fontFamily:'Helvetica-Bold' }}/> }}
                                />
                            </Text>
                        </View>
                    </View>
                }
                <View style={{paddingTop: '5px'}}>
                    <View style={stylesTotal.entryLine}>
                        <Text style={[stylesTotal.service, styles.redTitle, {marginBottom:'5px'}]}>
                            {t("offer.pdf_document.texts.service_title")}
                        </Text>
                        <Text style={[styles.redTitle,{width:'100%', textAlign:'right', marginBottom:'5px'}]}>
                            {t("offer.pdf_document.texts.costs")}
                        </Text>
                    </View>
                    <View style={stylesTotal.entryLine}>
                        <Text style={stylesTotal.service}>
                            {t("offer.pdf_document.texts.musicians")}
                        </Text>
                        <Text style={stylesTotal.costs}>
                            {parseFloat(musicians_summary).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
                        </Text>
                    </View>
                    {renting > 0
                        ? <View style={[stylesTotal.entryLine,{borderTop:"1px dashed #000"}]}>
                            <Text style={stylesTotal.service}>
                                {t("offer.pdf_document.texts.renting")}
                                {typeWithSuperscript["renting"] && 
                                    <Text style={{fontSize: '6px', verticalAlign: 'super'}}> 
                                        {" "}{typeWithSuperscript["renting"]}
                                    </Text>
                                }
                            </Text> 
                            <Text style={stylesTotal.costs}>
                                {renting.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
                            </Text>
                        </View>
                        : null
                    }
                    {transport > 0
                    ? <View style={[stylesTotal.entryLine,{borderTop:"1px dashed #000"}]}>
                            <Text style={stylesTotal.service}>
                                {t("offer.pdf_document.texts.transport")}
                            </Text>
                            <Text style={stylesTotal.costs}>
                                {transport.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
                            </Text>
                    </View>
                    : null}

                    <View style={[stylesTotal.entryLine,{borderTop:"1px dashed #000"}]}>
                        <Text style={stylesTotal.service}>
                            {t("offer.pdf_document.texts.administration")}
                            
                            {typeWithSuperscript["administration"] && 
                                <Text style={{fontSize: '6px', verticalAlign: 'super'}}> 
                                    {" "}{typeWithSuperscript["administration"]}
                                </Text>
                            }
                        </Text> 
                        
                        <Text style={stylesTotal.costs}>{administration.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €</Text>
                    </View>

                    <View style={[stylesTotal.entryLine,{borderTop:"1px solid #000", paddingBottom: '10px'}]}>
                        <Text style={[stylesTotal.service,{fontFamily:'Helvetica-Bold'}]}>
                            {t("offer.pdf_document.texts.total")}
                        </Text>
                        <Text style={[stylesTotal.costs,{fontFamily:'Helvetica-Bold'}]}>
                            {total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
                        </Text>
                    </View>

                    {typeWithSuperscript["renting"] && (
                        <View style={[stylesTotal.entryLine, { paddingTop: "10px", borderTop:'1px solid #ccc' }]}>
                            <Text style={{fontSize: '6px', lineHeight: 1}}>{typeWithSuperscript["renting"]}</Text>
                            <Text style={[stylesTotal.service, { fontSize: '8px', width: '100%' }]}>
                                 {t(`offer.pdf_document.texts.renting_message`)}
                            </Text>
                        </View>
                    )}
                    
                    {typeWithSuperscript["administration"] && (
                        <View style={[stylesTotal.entryLine,{paddingTop:"3px"}]}>
                            <Text style={{fontSize: '6px', lineHeight: 1}}>{typeWithSuperscript["administration"]}</Text>
                            <Text style={[stylesTotal.service, { fontSize: '8px', width: '100%' }]}>
                                {t(`offer.pdf_document.texts.admin_message`)}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        </View>
    );
}

export default Total
