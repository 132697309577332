import RedHeader from "../ui/RedBgTitleHeader";
import { useTranslation } from "react-i18next";
import RatesEntries from "./RatesEntries";
import {useEffect, useState} from "react";
import {getProjectSettings} from "../../services/api/user";

function Rates ({
                    clientKzm,
                    estroMus,
                    estroKzm,
                    clientMus,
                    setClientKzm,
                    setClientMus,
                    setEstroKzm,
                    setEstroMus,
                    ratesCalculation
}) {
    const { t } = useTranslation();

    const handleReset = async (typeOperation) => {
        try {
            const responseSettings = await getProjectSettings();
            const settingsResp = responseSettings.data;

            if (typeOperation==='client') {
                setClientKzm({
                    rehearsals: parseFloat(settingsResp.client_rate_kzm_rehearsal),
                    raccords: parseFloat(settingsResp.client_rate_kzm_raccord),
                    concerts: parseFloat(settingsResp.client_rate_kzm_concert),
                });
                setClientMus({
                    rehearsals: parseFloat(settingsResp.client_rate_mus_rehearsal),
                    raccords: parseFloat(settingsResp.client_rate_mus_raccord),
                    concerts: parseFloat(settingsResp.client_rate_mus_concert),
                });
            } else {
                setEstroKzm({
                    rehearsals: parseFloat(settingsResp.estro_rate_kzm_rehearsal),
                    raccords: parseFloat(settingsResp.estro_rate_kzm_raccord),
                    concerts: parseFloat(settingsResp.estro_rate_kzm_concert),
                });
                setEstroMus({
                    rehearsals: parseFloat(settingsResp.estro_rate_mus_rehearsal),
                    raccords: parseFloat(settingsResp.estro_rate_mus_raccord),
                    concerts: parseFloat(settingsResp.estro_rate_mus_concert),
                });
            }
        } catch(ex) {
            console.log("Failed to download settings")
        }
    }

    return (
        <div className="row">
            <div className="col-md-6">
                <RedHeader title={t("rates.clients")} />
                <div className="row px-2">
                    <div className="col-xxl-6">
                        <u>{t("rates.konzertmeister")}</u>
                        <RatesEntries rates={clientKzm} setRates={setClientKzm} />
                    </div>
                    <div className="col-xxl-6">
                        <u>{t("rates.musician")}</u>
                        <RatesEntries rates={clientMus} setRates={setClientMus} />
                    </div>
                </div>
                <div className="row px-2">
                    <p className="mt-3 mb-0" style={{textAlign:'right'}}>
                        <a onClick={()=>handleReset("client")}>{t("rates.reset_to_primary_values")}</a>
                    </p>
                    <p className="mb-0" style={{fontWeight:'bold'}}>
                        {t("rates.total_events_costs")}: {ratesCalculation[0] ? ratesCalculation[0] : 0}
                    </p>
                </div>
            </div>
            <div className="col-md-6">
                <RedHeader title={t("rates.estros")} />
                <div className="row px-2">
                    <div className="col-xxl-6">
                        <u>{t("rates.konzertmeister")}</u>
                        <RatesEntries rates={estroKzm} setRates={setEstroKzm} />
                    </div>
                    <div className="col-xxl-6">
                        <u>{t("rates.musician")}</u>
                        <RatesEntries rates={estroMus} setRates={setEstroMus} />
                    </div>
                </div>
                <div className="row px-2">
                    <p className="mt-3 mb-0" style={{textAlign:'right'}}>
                        <a onClick={()=>handleReset("estro")}>{t("rates.reset_to_primary_values")}</a>
                    </p>
                    <p className="mb-0" style={{fontWeight:'bold'}}>
                        {t("rates.total_events_costs")}: {ratesCalculation[1] ? ratesCalculation[1] : 0}
                    </p>
                </div>
            </div>
        </div>
    )
};
export default Rates;
        