import React, {useEffect, useState} from "react";
import DarkCard from "../../../../../../components/card/DarkCard";
import {useTranslation} from "react-i18next";
import PositionsCard from "./PositionsCard";
import {Link} from "react-router-dom";
import LordIcon from "../../../../../../components/ui/LordIcon";
import {Colors} from "../../../../../../components/theme";
import {getMamagementData} from "../../../../../../services/functionsAPI";


const Management = () => {
    const [management, setManagement] = useState({})
    const { t } = useTranslation();

    useEffect(() => {
        const getData = async () => {
            const response = await getMamagementData()
            if (response) {
                setManagement(response)
            } else {
                // alert popup
                console.log("Failed to download clients list.")
            }
        }
        getData()
    }, [])

    const managementList = Object.entries(management)

    return (<>
        <DarkCard classNames='mb-3 row'>
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.asbl",{ ns: 'menu' })}</span> &#9656;
                <Link to={'/asbl/association'}>{t("menu.management",{ ns: 'menu' })}</Link>
            </p>
        </DarkCard>

        <DarkCard classNames='mb-3 row'>

            <h1 className="mt-0 p-0">{t("menu.asbl",{ ns: 'menu' })} {t("menu.management",{ ns: 'menu' })}</h1>

                <div className="row ps-0">
                    <div className="col">
                        <Link className="addGroup" to={'new/member'}>
                            <LordIcon url='cross' color={`${Colors.red};`} rotate='45' target='.addGroup'/>
                            <span className="ps-2">{t("common.button.add_association_members")}</span>
                        </Link>
                    </div>
                </div>

                <div className="row mt-4 ps-0">
                    <PositionsCard data={managementList} />
                </div>
        </DarkCard>
    </>)

}

export default Management

