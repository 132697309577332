import { da } from 'date-fns/locale';
import _ from 'lodash';

const defaultFormPD = {
    firstName: {
        value: '',
        error: true,
        showError: false,
        rules: ['required']
    },
    lastName: {
        value: '',
        error: true,
        showError: false,
        rules: ['required']
    },
    gender: {
        value: '',
        error: true,
        showError: false,
        rules: ['required']
    },
    birthDate: {
        value: null,
        error: true,
        showError: false,
        rules: ['required']
    },
    phoneCountry: {
        value: '',
        error: true,
        showError: false,
        rules: ['required']
    },
    phoneNumber: {
        value: '',
        error: true,
        showError: false,
        rules: ['required']
    },
    email: {
        value: '',
        error: true,
        showError: false,
        rules: ['required', 'email']
    },
    street: {
        value: '',
        error: true,
        showError: false,
        rules: ['required']
    },
    houseNumber: {
        value: '',
        error: true,
        showError: false,
        rules: ['required']
    },
    postCode: {
        value: '',
        error: true,
        showError: false,
        rules: ['required']
    },
    city: {
        value: '',
        error: true,
        showError: false,
        rules: ['required']
    },
    country: {
        value: '',
        error: true,
        showError: false,
        rules: ['required']
    },
    musicianStatus: {
        value: '',
        error: true,
        showError: false,
        rules: ['required']
    },
    mainLang: {
        value: '',
        error: true,
        showError: false,
        rules: ['required']
    },
    additionalLang: {
        value: [],
        error: true,
        showError: false,
        rules: ['required']
    },
};

const defaultFormBank = {
        bankName: {
            value: '',
            error: true,
            showError: false,
            rules: ['required']
        },
        accountNumber: {
            value: '',
            error: true,
            showError: false,
            rules: ['required']
        },
        bicNumber: {
            value: '',
            error: true,
            showError: false,
            rules: ['required']
        },
        accountHolder: {
            value: '',
            error: true,
            showError: false,
            rules: ['required']
        },
};


const defaultFormInstr = [{
    key: '0000',
    pk_iud: '0000',
    selected_type: false,
    selected_instr: false,
    value_type: '',
    value_instr: '',
    instrTypeOptions: [],
    instrOptions: [],
}];


const storageToForm = (stg) => {
    // Funkcja zwraca pusty obiekt z domyslnymi polami, gdy brak w localStorage
    let storage = JSON.parse(stg);

    if (!storage) {
        return _.cloneDeep(defaultFormPD);
    } else {
        let form_data = _.cloneDeep(storage);

        if (form_data.birthDate.value) {
            form_data.birthDate.value = new Date(storage.birthDate.value);
        }
        return form_data;
    };
};


const storageBankToForm = (stg) => {
    const defaultFormBank = {
        bankName: {
            value: '',
            error: true,
            showError: false,
            rules: ['required']
        },
        accountNumber: {
            value: '',
            error: true,
            showError: false,
            rules: ['required']
        },
        bicNumber: {
            value: '',
            error: true,
            showError: false,
            rules: ['required']
        },
        accountHolder: {
            value: '',
            error: true,
            showError: false,
            rules: ['required']
        },
    };

    let storage = JSON.parse(stg);
    if (!storage) {
        return defaultFormBank
    } else {
        return storage;
    };
}


const storageInstrToForm = (stg) => {
    let storage = JSON.parse(stg);
    if (!storage) {
        return [{
            key: '0000',
            pk_iud: 0,
            selected_type: false,
            selected_instr: false,
            value_type: '',
            value_instr: '',
            instrTypeOptions: [],
            instrOptions: [],
        }];
    }
    else {
        return storage;
    };
};

export {
    storageToForm,
    storageBankToForm,
    storageInstrToForm,
    defaultFormPD,
    defaultFormBank,
    defaultFormInstr,
};

