import DarkCard from "../../../../../components/card/DarkCard";
import LightCard from "../../../../../components/card/LightCard";
import RedHeader from "../../../../../components/ui/RedBgTitleHeader";
import { useTranslation } from "react-i18next";
import { Input, InputWrapperDiv } from "../../../../../components/ui/Input";
import LordIcon from "../../../../../components/ui/LordIcon";
import {Link, useParams} from "react-router-dom";
import { Colors } from "../../../../../components/theme";
import RoomDetails from "./RoomDetails";
import ContactPersonDetails from "./ContactPersonDetails";
import { EditButton } from "../../../../../components/ui/EditButton";
import {useEffect, useState} from "react";
import {locationDetailAPI} from "../../../../../services/api/locations";
import {getCountriesListAPI} from "../../../../../services/api/user";


function LocationDetails () {
    const { t } = useTranslation();
    const params = useParams()
    const [location, setLocation] = useState({contact_persons: []})
    const [countries, setCountries] = useState([])

    useEffect(() => {
        const getLocationItem = async () => {
            try {
                const response = await locationDetailAPI(params.key)
                setLocation(response.data)
            } catch (e) {
                console.log("Failed to download location details data")
            }

            try {
                const resp_list = await getCountriesListAPI()
                const data = resp_list.data.qs.map(el => {
                    return {
                        value: el.name,
                        label: el.name,
                        pk: el.pk,
                    }
                })
                setCountries(data)
            } catch (ex) {
                console.log("Failed to download locations list.", ex);
            }
        }
        getLocationItem()
    }, [params.key])

    return (
            <>
                {location &&
                 <>
                     <DarkCard classNames='mb-3 row'>
                        <p className="m-0 p-0 breadcrumbs">
                            <Link to={'/locations'}>{t("menu.locations",{ ns: 'menu' })}</Link> &#9656;
                            <Link to={'/locations/location/'+params.key}>{t("breadcrumbs.location")} {params.key}</Link>
                        </p>
                     </DarkCard>
                     <DarkCard classNames='mb-3 row'>
                        <h1 className="mt-0 p-0">
                            <span className="me-3">{location.name}</span>
                             <EditButton />
                        </h1>
                         <div className="row">
                             <div className="col-lg-6 col-xl-4 mb-4 ps-0">
                                 <LightCard>
                                     <RedHeader title={t("location.address.title")}/>
                                     <InputWrapperDiv addClass="px-2">
                                         <p className="mb-0" style={{fontWeight:'bold'}}>
                                             {location.name}
                                         </p>
                                         <p className="mt-1">
                                             {location.number} {location.street} <br/>
                                             {location.post_code} {location.city} <br/>
                                             {
                                                 countries.length>0 &&
                                                 location.country &&
                                                 countries.find(el => el.pk === location.country).label
                                             }
                                         </p>
                                         <p>
                                             { location.map_url &&
                                             <Link className="mapLink" to={location.map_url} target="_blank">
                                                 <LordIcon url='location' color={`${Colors.black}`} target='.mapLink'/>
                                                 {t("common.button.show_on_map")}
                                             </Link>
                                             }
                                         </p>
                                     </InputWrapperDiv>
                                 </LightCard>
                             </div>

                             <div className="col-lg-6 col-xl-4 mb-4">
                                 <LightCard>
                                     <RedHeader title={t("location.details.title")}/>
                                     <div className="row mt-2 px-2">
                                         <div className="col-12">
                                             <InputWrapperDiv>
                                                 <b>{t("location.details.toilet")}:</b> {location.toilet}
                                             </InputWrapperDiv>
                                             <InputWrapperDiv className='mt-3'>
                                                 <b>{t("location.details.parking")}:</b> {location.parking}
                                             </InputWrapperDiv>
                                             <InputWrapperDiv className='mt-3'>
                                                 <b>{t("location.details.parking_details")}:</b>
                                                 <br/>
                                                 { location.parking_details}
                                             </InputWrapperDiv>
                                             {
                                                location.note ?
                                                    <InputWrapperDiv className='mt-3'>
                                                        <b>{t("location.details.location_note")}:</b>
                                                        <br/>
                                                        {location.note}
                                                    </InputWrapperDiv>
                                                : null
                                             }
                                         </div>
                                     </div>
                                 </LightCard>
                             </div>
                         </div>
                         <div className="row mt-4">
                             <div className="col-lg-8 ps-0">
                                 <LightCard>
                                     <RedHeader title={t("location.contact_person.title")}/>
                                     <div className="row px-2">
                                        {
                                            location.contact_persons.length >0 ?
                                                <>
                                                    {location.contact_persons.map(person =>
                                                        <ContactPersonDetails
                                                            person={person}
                                                            key={person.id}
                                                        />
                                                    )}
                                                </>
                                            : <i style={{color:`${Colors.darkRed}`}}>{t("system_messages.no_entries")}</i>
                                        }
                                     </div>
                                 </LightCard>
                             </div>
                         </div>

                         <div className="row mt-4">
                             <div className="col-lg-8 ps-0">
                                 <LightCard>
                                     <RedHeader title={t("location.rooms.title")}/>
                                     { location.hasOwnProperty('rooms') &&
                                         location.rooms.length >0
                                         ? location.rooms.map(room =>
                                             <RoomDetails
                                                key={room.id.toString()}
                                                 room={room}
                                                 locationId={params.key}
                                             />
                                            )
                                         : <div className="mb-3 ps-2">{t("system_messages.no_entries")}<br /></div>
                                     }
                                     <Link className="addGroup" to={'new/room'}>
                                         <LordIcon url='cross' color={`${Colors.red};`} rotate='45' target='.addGroup'/>
                                         <span className="ps-2">{t("common.button.add_room")}</span>
                                     </Link>
                                 </LightCard>
                             </div>
                         </div>
                     </DarkCard>
                 </>
                }
                </>
    )}

export default LocationDetails;