import React from "react";
import {Colors} from "../../../../../../components/theme";
import {useTranslation} from "react-i18next";

const TitleStyle = {
    marginBottom: '5px'
}

const TextStyle = {
    marginTop: '0px',
    color: `${Colors.darkGray}`,
}

export default function Address({details}) {
    const {t} = useTranslation()

    return(<>
        <div className="row px-1">
            <div className="col-6">
                <p style={TitleStyle}>
                    {t("profile.street")}
                </p>
                <p style={TextStyle}>
                    {details.street}
                </p>
            </div>
            <div className="col-6">
                <p style={TitleStyle}>
                    {t("profile.house_apartment_number")}
                </p>
                <p style={TextStyle}>
                    {details.number}
                </p>
            </div>
        </div>
        <div className="row px-1">
            <div className="col-6">
                <p style={TitleStyle}>
                    {t("profile.post_code")}
                </p>
                <p style={TextStyle}>
                    {details.post_code}
                </p>
            </div>
            <div className="col-6">
                <p style={TitleStyle}>
                    {t("profile.city")}
                </p>
                <p style={TextStyle}>
                    {details.place}
                </p>
            </div>
        </div>
        <div className="row px-1">
            <div className="col-6">
                <p style={TitleStyle}>
                    {t("profile.country")}
                </p>
                <p style={TextStyle}>
                    {details.country}
                </p>
            </div>
        </div>
    </>)
}