import {
    calculationDeleteAPI,
    calculationSaveAPI,
    calculationSaveItemAPI, quoteCreateAPI
} from "../../../../../../services/api/calculator";
import {popupData} from "../../../../../../helpers/dictionary/users";

const separatorTime = 'h'
const diffTime = (start, end) => {
    start = start.split(":");
    end = end.split(":");
    const startDate = new Date(0, 0, 0, start[0], start[1], 0);
    const endDate = new Date(0, 0, 0, end[0], end[1], 0);
    let diff = endDate.getTime() - startDate.getTime();
    const hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(diff / 1000 / 60);

    return hours + separatorTime + (minutes < 9 ? "0" : "") + minutes;
};

const durationTime = (val) => {
    if (val !== 0) {
        const diff_h = val.split(separatorTime)[0];
        const diff_m = val.split(separatorTime)[1];
        return +diff_h + (diff_m/60)
    };
    return 0
};

const staffValueSum = (staff) => Object.values(staff).reduce((acc, obj) => {
    return acc += +obj.value
}, 0)


const sumAdminCosts = (data) => {
    const calculation = (data.count_rehearsals * data.client_rehearsals) +
        (data.count_raccords * data.client_raccords) +
        (data.count_concerts * data.client_concerts);
    return parseFloat(calculation).toFixed(2)
};

const sumAdminCostDetails = (data) => {
    let adminCostSum = 0;

        data.events.forEach(el => {
            let sumCost = 0;
            const duration = durationTime(el.eventLength);
            if (el.type === 'rehearsals') {
                sumCost = (data.client_rehearsals / data.rehearsals_duration) * duration
            } else if (el.type === 'raccords' && duration > 0) {
                sumCost = (data.client_raccords / (data.raccords_duration + 0.5)) * (duration + 0.5)
            } else if (el.type === 'concerts' && duration > 0) {
                sumCost = +data.client_concerts
            };
            adminCostSum += sumCost;
        })

    return parseFloat(adminCostSum).toFixed(2)
};

const displayType = (type) => {
    switch (type) {
        case 'archivro':
            return {
                description: true,
                amount: false,
                client_cost: true,
                estro_cost: true
            }
        case 'descro':
            return {
                description: true,
                amount: false,
                client_cost: false,
                estro_cost: false
            }
        case 'fullro':
            return {
                description: true,
                amount: true,
                client_cost: true,
                estro_cost: true
            }
        default:
            return {
                description: false,
                amount: false,
                client_cost: false,
                estro_cost: false
            }
    }
}

const archiving_entry = (t, desc) => {
    switch (desc.toLowerCase()) {
        case '1h of filling'.toLowerCase():
            return {entity: 'h', desc: t("archiving.calculator.filling_entry_title"), costNull: true}
        case "1 copy".toLowerCase():
            return {entity: 'pcs', desc: t("archiving.calculator.copy_entry_title"), costNull: true}
        case '1h of bow strokes'.toLowerCase():
            return {entity: 'h', desc: t("archiving.calculator.bowStrokes_entry_title"), costNull: true}
        default:
            return {entity: '', desc: desc, costNull: false}
    }
}

const archiving_entry_obj = (t, el, display="without") => {
    return {
        id: el.id,
        name: el.description,
        description: archiving_entry(t, el.description).desc,
        display_type: display,
        cost_type: "archiving",
        readOnly: false,
        amount: 0,
        entity: archiving_entry(t, el.description).entity,
        edit: false,
        client_cost: archiving_entry(t, el.description).costNull ? "0.00" : el.client_cost,
        estro_cost: archiving_entry(t, el.description).costNull ? "0.00" : el.estro_cost,
        unit_client_cost: el.client_cost,
        unit_estro_cost: el.estro_cost,
    }
}

const name_list_archiving = ['1 copy', '1h of filling', '1h of bow strokes',]

const archiving_entry_revers = (desc) => {
    switch (desc.toLowerCase()) {
        case 'filling'.toLowerCase():
            return '1h of filling'
        case "copies".toLowerCase():
            return "1 copy"
        case 'bow strokes'.toLowerCase():
            return '1h of bow strokes'
        default:
            return desc
    }
}

const saveCalculation = async (calculation_data, calculationName) => {
    // zapis kalkulacji nowej
    try {
        calculation_data['calculation_name'] = calculationName
        const response = await calculationSaveAPI(calculation_data) // create calkulation

        if (response.status === 201) {
            console.log("Calculation save 201:", response.data)
            return {popupData: popupData.saveOK, id: response.data.calculation_id}
        }
    } catch (e) {
        console.log(e)
        return {popupData: popupData.saveError, id: 0}
    }
}

const updateCalculation = async (calculation_data, calculationName, id) => {
    // aktualizacja zapisanej kalkulacji
    try {
        const response = await calculationSaveItemAPI(
            {data:calculation_data, name: calculationName}, id
        )
        
        if (response.status === 200) {
            return {popupData: popupData.infoError, id: "", events_no_free_links: response.data.events_no_free_links}
        }

        if (response.status === 204) {
            return {popupData: popupData.saveOK, id: ""}
        }
    } catch (e) {
        console.log(e)
        return {popupData: popupData.saveError, id: 0}
    }
}

const deleteCalculation = async (navigate, id) => {
    try {
        // throw new TypeError("Error net...")
        const response = await calculationDeleteAPI(id)
        return {popupData: popupData.saveOK, id: "", status: response.status}
    } catch (e) {
        console.error(e)
        return {popupData: popupData.deleteError}
    }
}

const quoteCreate = async (estimate_id) => {
    try {
        const response = await quoteCreateAPI(estimate_id) // create calkulation

        if (response.status === 201) {
            return {popupData: popupData.saveOK}
        }
    } catch (e) {
        console.log(e)
        return {popupData: popupData.saveError}
    }
}

const date_range_restriction = () => {
    const year = new Date().getFullYear()
    const startDate = new Date(`${year-1}/01/01`);
    const endDate = new Date(`${year+3}/12/31`);
    return {startDate: startDate, endDate: endDate};
}

export {
    diffTime,
    durationTime,
    staffValueSum,
    sumAdminCosts,
    sumAdminCostDetails,
    displayType,
    archiving_entry,
    name_list_archiving,
    archiving_entry_obj,
    archiving_entry_revers,
    saveCalculation,
    updateCalculation,
    deleteCalculation,
    quoteCreate,
    date_range_restriction
}