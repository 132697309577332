import axiosClient from "./api";
import {configEndpoints, instruments, project, userEndpoints} from "./endpoints";

const axiosClientJSON = axiosClient()
const axiosClientForm = axiosClient('multipart/form-data')


const getUserProfileAPI = () => {
    return axiosClientJSON.get(userEndpoints.profile);
};

const getUserRoleAPI = () => {
    return axiosClientJSON.get(userEndpoints.currentRole);
};

const getCountriesListAPI = () => {
    return axiosClientJSON.get(configEndpoints.countriesList);
};

const getInstrumentsListAPI = () => {
    return axiosClientJSON.get(instruments.instrumentsList);
};

const getInstrumentDetailAPI = (pk) => {
    return axiosClientJSON.get(instruments.instrumentEdit(pk));
};

const putInstrumentDetailAPI = (pk, data) => {
    return axiosClientJSON.put(instruments.instrumentEdit(pk), data);
};

const getProfileDraftAPI = () => {
  return axiosClientJSON.get(userEndpoints.getProfileDraft);
};

const draftProfileUpdateAPI = (data) => {
    return axiosClientJSON.patch(userEndpoints.draftProfileUpdate, data);
};

const profileDraftAPI = (id, data) => {
    return axiosClientJSON.patch(userEndpoints.profileUpdate(id), data);
};

const profileToDraftAPI = async () => {
    return await axiosClientJSON.get(userEndpoints.profileToDraft);
};

const profileDraftInstrumentsUpdateAPI = async (data) => {
    return await axiosClientJSON.post(instruments.draftInstrumentsUpdate, data);
};

const draftToProfileAPI = async () => {
    return await axiosClientJSON.post(userEndpoints.draftToProfile);
};

const getProjectSettings = async () => {
  return await axiosClientJSON.get(project.asblSettings);
};

const abslSaveSettingsAPI = async (data) => {
  return await axiosClientJSON.put(project.abslSaveSettings, data);
};

const asblManagementDataAPI = async () => {
    return await axiosClientJSON.get(project.asblManagement);
};
const positionsListApi = async () => {
    return await axiosClientJSON.get(project.asblManagementPositions)
}

const memberDetailsApi = async (id) => {
    return await axiosClientJSON.get(project.associationMemberDetails(id))
}

const associationMemberCreateAPI = async (data) => {
    return await axiosClientForm.post(project.associationMemberCreate, data)
}

const associationMemberUpdateAPI = async (data, id) => {
    return await axiosClientForm.put(project.associationMemberUpdate(id), data)
}

const associationMemberDeleteAPI = async (id) => {
    return await axiosClientJSON.delete(project.associationMemberDelete(id))
}

const asblBusinessDetailsAPI = async () => {
    return await axiosClientJSON.get(project.asblBusinessDataDetails);
};

const asblBusinessUpdateAPI = async (id, data) => {
    return await axiosClientJSON.put(project.asblBusinessDataUpdate(id), data);
};

const getInstrumentsStaffListAPI = async () => {
    return await axiosClientJSON.get(instruments.instrumentsStaffList);
};

const getPrintingOnDocPersonsAPI = async () => {
    return await axiosClientJSON.get(project.positionsOnDocument);
};

const getPrintingOnDocumentAPI = async () => {
    return await axiosClientJSON.get(project.printingOnDocument);
};

const savePrintingOnDocumentAPI = async (data) => {
    return await axiosClientForm.post(project.printingOnDocumentSave, data);
};

const getFirmLogoApi = async () => {
    return await axiosClientJSON.get(project.firmLogo);
}


export {
    getUserRoleAPI,
    getUserProfileAPI,
    getCountriesListAPI,
    getInstrumentsListAPI,
    getProfileDraftAPI,
    draftProfileUpdateAPI,
    profileDraftAPI,
    profileToDraftAPI,
    profileDraftInstrumentsUpdateAPI,
    draftToProfileAPI,
    getProjectSettings,
    abslSaveSettingsAPI,
    asblManagementDataAPI,
    positionsListApi,
    associationMemberCreateAPI,
    getPrintingOnDocPersonsAPI,
    asblBusinessDetailsAPI,
    asblBusinessUpdateAPI,
    memberDetailsApi,
    associationMemberUpdateAPI,
    associationMemberDeleteAPI,
    getInstrumentsStaffListAPI,
    getInstrumentDetailAPI,
    putInstrumentDetailAPI,
    getPrintingOnDocumentAPI,
    savePrintingOnDocumentAPI,
    getFirmLogoApi,
};
