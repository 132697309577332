import styled from "styled-components";
import { useTranslation } from "react-i18next";
import DarkCard from "../../../../../components/card/DarkCard";
import RedHeader from "../../../../../components/ui/RedBgTitleHeader";
import { Button } from "../../../../../components/ui/Button";
import { Colors } from "../../../../../components/theme";
import LordIcon from "../../../../../components/ui/LordIcon";
import { Link } from "react-router-dom";

const GroupElementStyle = styled.div `
  position: relative;
  margin-right: 30px;
`
const GroupNameStyle = styled.div `
  font-weight: bold;
  background-color: ${Colors.white};
  padding: 8px 15px;
  margin: 5px 5px 5px 0px;
  border-radius: 10px;
`
const GroupNameIcon = styled.div `
    position: absolute;
    right: -7px;
    top: -7px;
    &:hover {
        cursor: pointer;
    }
`


function MusicianApproval () {
    const { t } = useTranslation();

    return (
        <>
            <DarkCard>
                Musician Data
            </DarkCard>
            <DarkCard>
                <RedHeader title={t("profile.groups")} />
                <div className="d-flex flex-row flex-wrap flex-xl-nowrap">
                    {/* Example of the result after loop */}
                    <GroupElementStyle>
                        <GroupNameIcon>
                            <LordIcon url='cross' color={`${Colors.darkGray};`}/>
                        </GroupNameIcon>
                        <GroupNameStyle>
                            Groupname
                        </GroupNameStyle>
                    </GroupElementStyle>
                    <GroupElementStyle>
                        <GroupNameIcon>
                            <LordIcon url='cross' color={`${Colors.darkGray};`}/>
                        </GroupNameIcon>
                        <GroupNameStyle>
                            Groupname
                        </GroupNameStyle>
                    </GroupElementStyle>
                    {/* End Example of the result after loop */}

                    {/* This Group needs to stay to be able to add / remove groups */}
                    <GroupElementStyle style={{paddingTop:'12px'}}>
                        <Link className="addGroup">
                            <LordIcon url='cross' color={`${Colors.red};`} rotate='45' target='.addGroup'/>
                            <span className="ps-2">{t("common.button.add_edit_group")}</span>
                        </Link>
                    </GroupElementStyle>
                </div>
            </DarkCard>
            <DarkCard>
                <RedHeader title={t("registration.user_approval.title")} />
                <ul className="noListStyle">
                    <li>
                        {t("registration.user_approval.full_acceptance")}
                    </li>
                    <li>
                        {t("registration.user_approval.conditional_acceptance")}
                        <br/>
                        <textarea></textarea>
                    </li>
                    <li>
                        {t("registration.user_approval.refusal")}
                        <br/>
                        <textarea></textarea>
                    </li>
                </ul>
                <Button type="submit">
                    {t("common.button.submit")}
                </Button>

            </DarkCard>
        </>
    )
};


export default MusicianApproval;