import styled from "styled-components";
import { Colors } from "../theme";
import LordIcon from "./LordIcon"
import { Link } from "react-router-dom";
import StatusCircle from "./StatusCircle";

const ContactElement = styled.div `
    border-radius: 5px;
    padding: 5px 15px;
    margin: 15px 0px;
`

const ContactComment = styled.div `
    border-top: 1px dashed ${Colors.black};
    width: 100%;
`

const CommentParagraph = styled.p `
    font-size: 0.9rem;
    margin: 5px 0px 0px 0px;
`

const ContactDetails = styled.div `
    div {
        margin: 3px 15px;

        &:first-child {
            margin-left: 0px
        }
        
        &:last-child {
            margin-right: 0px
        }
    }
`

const PositionNumber = styled.div `
    @media (min-width: 992px) {
        width: 8%;
    }
`

const FirstName = styled.div `
    @media (min-width: 992px) {
        width: 19%;
    }
`

const SecondName = styled.div `
    @media (min-width: 992px) {
        width: 19%;
    }
`

const PhoneNumber = styled.div `
    @media (min-width: 992px) {
        width: 18%;
    }
`

const MailAdress = styled.div `
    @media (min-width: 992px) {
        width: 18%;
    }
`

const InstrumentsList = styled.div `
    @media (min-width: 992px) {
        width: 18%;
    }
`

const ContactMusician = styled.div `
    text-align: center;
    &:hover {
        cursor: pointer;
    }
    @media (min-width: 992px) {
        width: 5%;
    }
`


function ContactListElement ({positionNr,fName,lName,phone,mail,instruments,comment,musicianClass,status,linkto}) {
    return (
        <>
            <ContactElement className={"d-flex flex-column "+musicianClass}>
                <ContactDetails className="d-flex flex-row flex-wrap flex-md-nowrap">
                    <div className="d-flex flex-row flex-wrap flex-md-nowrap" style={{width: '95%'}}>
                        <PositionNumber>
                            <StatusCircle status={status} />
                            {positionNr}
                        </PositionNumber>
                        <FirstName>
                            {fName}
                        </FirstName>
                        <SecondName>
                            {lName}
                        </SecondName>
                        <PhoneNumber>
                            {/* Onclick tel function in link */}
                            <Link>{phone}</Link>
                        </PhoneNumber>
                        <MailAdress>
                            {/* Onclick mail Adress is copied to clipboard */}
                            <Link>{mail}</Link>
                        </MailAdress>
                        <InstrumentsList>
                            {instruments}
                        </InstrumentsList>
                    </div>
                    
                    <div>
                        {/* Onclick redirect to Profile */}
                        <Link to={linkto}><LordIcon url='avatar' color={`${Colors.black}`}/></Link>
                    </div>
                    
                    <ContactMusician>
                        {/* Onclick Mail window is opening, receiver is the clicked musician */}
                        <LordIcon url='chat' color={`${Colors.black}`}/>
                    </ContactMusician>
                </ContactDetails>
                
                {
                    comment ? <ContactComment className="contactComment"><CommentParagraph><b>Comment:</b> {comment}</CommentParagraph></ContactComment> : null
                }
                
            </ContactElement>
        </>
)
};
export default ContactListElement;
