import LightCard from "../../../../../../components/card/LightCard";
import React from "react";
import {EditButtonStyle, IconStyle} from "../../../../../../components/ui/EditButton";
import {useTranslation} from "react-i18next";
import {Colors} from "../../../../../../components/theme";

const BusinesDataDetails = ({data, setEdit, setPrinting, addClass, children}) => {
    const {t} = useTranslation()

    const clickHandler = () => {
        if (typeof setPrinting === 'function') {
            setEdit(true)
            setPrinting(true)
        } else setEdit(true)
    }

    return (<>
        <div className={"col-lg-6 col-xl-4 mb-4" + addClass}>
            <LightCard>
                <div className="px-2">
                    <h1 className="mt-0">
                        {data.name}
                        <EditButtonStyle className="ms-3 editBtn" onClick={clickHandler}>
                            <lord-icon
                                src='https://cdn.lordicon.com/hnvrjtda.json'
                                trigger='hover'
                                target='.editBtn'
                                colors={`primary:${Colors.mediumBG},secondary:${Colors.mediumBG}`}
                                style={IconStyle}
                            >
                            </lord-icon>
                            {t("common.button.edit")}
                        </EditButtonStyle>
                    </h1>

                </div>

                {children}
            </LightCard>
        </div>
    </>)

}

export default BusinesDataDetails



