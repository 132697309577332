import React from "react";
import {useTranslation} from "react-i18next";
import PopupForm from "../../../../../components/ui/PopupForm";
import DarkCard from "../../../../../components/card/DarkCard";
import LightCard from "../../../../../components/card/LightCard";
import RedHeader from "../../../../../components/ui/RedBgTitleHeader";
import {Button} from "../../../../../components/ui/Button";
import FormFirm from "./FormFirm";
import { Colors } from "../../../../../components/theme";
import {actionTypes} from "../Clients/ReducerClient";


const FirmNewEditView = (props) => {
    const {popup, breadCrumbs, params, firmData, typesFirm, dispatch, handleSave, pathReturn, isOfferSession} = props;
    const { t } = useTranslation()

    const handleCancel = () => {
        if (pathReturn || isOfferSession) {
            dispatch({type: "SET_ACTION", action: "pathReturn"});
        } else dispatch({
            type: "SET_ACTION",
            action: params.action === actionTypes.create ? actionTypes.list : actionTypes.details
        })
    }

    const handlePopup = () => {
        if (popup.variant === "success") {
            dispatch({type: "SET_POPUP", popup: {show: false}});
            if (pathReturn || isOfferSession) {
                dispatch({type: "SET_ACTION", action: "pathReturn"});
                return
            }
            dispatch({type: "SET_ACTION", action: "details"})
        } else {
            dispatch({type: "SET_POPUP", popup: {show: false}});
        }
    }

    return (<>
        {popup.show
            ? <PopupForm
                handlePopup={handlePopup}
                variant={popup.variant}
                title={t(popup.title)}
                message={t(popup.message)}
                leftBtn={popup.leftBtn}
                rightBtn={popup.rightBtn}
                okBtn={popup.okBtn}
                context={popup.context}
            />
            : <>
                <form name="client" onSubmit={handleSave}>
                <DarkCard classNames='mb-3 row'>
                {breadCrumbs()}
                </DarkCard>
                <DarkCard classNames='mb-3 row'>
                    {params.key ?
                        <h1 className="mt-0 p-0">
                            {t("clients.comp_edit_page_title")}
                        </h1>
                    :
                        <h1 className="mt-0 p-0">
                            {t("clients.create_new_comp")}
                        </h1>
                    }

                    <div className="col-lg-6 col-xxl-4 mb-4 ps-0">
                        <LightCard>
                            <RedHeader title={t("company.details")}/>

                            <FormFirm
                                form={firmData}
                                typesFirm={typesFirm}
                                dispatch={dispatch}
                                required={true}
                            />

                        </LightCard>
                    </div>

                    <div className="ps-0">
                        <Button
                            style={{backgroundColor:`${Colors.green}`}}
                            variant="round"
                            type="submit"
                            className={'me-3'}
                        >
                            {t("common.button.save")}
                        </Button>
                        <Button
                            variant="round"
                            onClick={handleCancel}
                            styling="bordered"
                            className={'me-3'}
                        >
                            {t("common.button.cancel")}
                        </Button>
                    </div>
                </DarkCard>
            </form>
            </>}
    </>)
}

export default FirmNewEditView