import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Colors } from "../theme";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr } from "date-fns/locale";
import TimeInput from "react-time-picker-input";
import "react-time-picker-input/dist/components/TimeInput.css";
import Select from "react-select";
import select_styles from "../../layouts/elements";
import { Input, InputWrapperDiv } from "../ui/Input";
import SlideButton from "../ui/SlideButton";
import {columnTypeStaff, getTypeListStaff, orderTypeStaff} from "../../helpers/tools";
import {date_range_restriction} from "../../pages/roles/admin/PageContent/Sales/helpers/func_helpers";

const handleType = typeEvent => {
    switch (typeEvent) {
        case "rehearsals":
          return `3px solid ${Colors.rehearsal}`;
        case "raccords":
          return `3px solid ${Colors.raccord}`;
        case "concerts":
          return `3px solid ${Colors.concert}`;
        default:
          return `0px solid ${Colors.black}`;
    }
}

const RemoveIcon = styled.i`
	position: absolute;
	right: -11px;
	top: -7px;
	padding: 3px 5px 3px 6px;
	border-radius: 50%;
	height: 23px;
	width: 23px;
	font-size: 0.9rem;
	vertical-align: middle;
	color: white;
	background-color: ${Colors.darkestGray};
	&:hover {
		background-color: ${Colors.red};
	}
`;

const EventEntryStyle = styled.div`
	background-color: ${Colors.white};
	border-radius: 7px;
	border: ${({ typeEvent }) => handleType(typeEvent)};
`;

const EventEntryMessageStyle = styled.ul`
	margin-left: 20px;
	margin-bottom: 0px;
`;


function EventEntry({
	eventDetail,
	setMarkedInstr,
	changeDetailsHandler,
	changeDetailsTypeHandler,
	removeEventDetail,
	staff,
	compositionOptions,
	setCompositionOptions,
	kzmProject,
	locations,
	roomsOptions,
	conductor
}) {
	const { t } = useTranslation();
	const [detailType, setDetailType] = useState([]);
	const [isTutti, setIsTutti] = useState(false);
	const detailTypeList = [
		{ label: t("events.rehearsal"), value: "rehearsals" },
		{ label: t("events.raccord"), value: "raccords" },
		{ label: t("events.concert"), value: "concerts" },
	];

	useEffect(() => {
		let selectedStaff = getTypeListStaff(staff)
		if (eventDetail.composition.length > 0) {
			// selected Strings -> modify select list
			const isStringsSelected = eventDetail.composition.findIndex(el => el.value === orderTypeStaff.c1)
			if (isStringsSelected > -1) {
				selectedStaff = selectedStaff.filter(el => !columnTypeStaff.Strings.includes(el.value))
			}
			// selected Woods -> modify select list
			const isWoodsSelected = eventDetail.composition.findIndex(el => el.value === orderTypeStaff.c3)
			if (isWoodsSelected > -1) {
				selectedStaff = selectedStaff.filter(el => !columnTypeStaff.Woods.includes(el.value))
			}
			// selected Brass -> modify select list
			const isBrassSelected = eventDetail.composition.findIndex(el => el.value === orderTypeStaff.c4)
			if (isBrassSelected > -1) {
				selectedStaff = selectedStaff.filter(el => !columnTypeStaff.Brass.includes(el.value))
			}
			// selected Harmony -> modify select list
			const isHarmonySelected = eventDetail.composition.findIndex(el => el.value === orderTypeStaff.c2)
			if (isHarmonySelected > -1) {
				const harmonyWoodsBrass = ['Woods', 'Brass', ...columnTypeStaff.Harmony]
				selectedStaff = selectedStaff.filter(el => !harmonyWoodsBrass.includes(el.value))
			}

			setCompositionOptions(selectedStaff);
		}
		setDetailType(detailTypeList.find(el => el.value === eventDetail.type));
	}, [eventDetail]);

	const changeCompositionHandler = (val, tutti) => {
		let newComposition = []
		let newVal = Array.isArray(val) ? val : [val]
		if (!tutti) {
			const isHarmonySelected = newVal.findIndex(el => el.value === orderTypeStaff.c2)
			if (isHarmonySelected > -1) { // czy wybrano Harmony
				const harmonyWoodsBrass = ['Woods', 'Brass', ...columnTypeStaff.Harmony]
				newVal = newVal.filter(el => !harmonyWoodsBrass.includes(el.value))
				newComposition = compositionOptions.filter(el => !harmonyWoodsBrass.includes(el.value))
			} else {
				// Strings selected
				const isStringsSelected = newVal.findIndex(el => el.value === orderTypeStaff.c1)
				if (isStringsSelected > -1) { // wybrano Strings
					newVal = newVal.filter(el => !columnTypeStaff.Strings.includes(el.value))
					newComposition = compositionOptions.filter(el => !columnTypeStaff.Strings.includes(el.value))
				}
				// Woods selected
				const isWoodsSelected = newVal.findIndex(el => el.value === orderTypeStaff.c3)
				if (isWoodsSelected > -1) { // wybrano Woods
					newVal = newVal.filter(el => !columnTypeStaff.Woods.includes(el.value))
					newComposition = compositionOptions.filter(el => !columnTypeStaff.Woods.includes(el.value))
				}
				// Brass selected
				const isBrassSelected = newVal.findIndex(el => el.value === orderTypeStaff.c4)
				if (isBrassSelected > -1) { // wybrano Brass
					newVal = newVal.filter(el => !columnTypeStaff.Brass.includes(el.value))
					newComposition = compositionOptions.filter(el => !columnTypeStaff.Brass.includes(el.value))
				}
			}
		}

		let entry = newVal
		if (!Array.isArray(val)) {
			entry = [val]
		}
		let newEntry = {}
		if (newVal.length === 0) {
			//usun klucz z markedInstr
			newEntry = structuredClone(eventDetail)
			delete newEntry[eventDetail.key]
		} else {
			newEntry = {
				...eventDetail,
				composition: entry,
			};
		}

		if (tutti) {
			setIsTutti(true)
		} else {
			if (newComposition.length > 0) {
				setCompositionOptions(newComposition)
			}
			setIsTutti(false)
		}

		setMarkedInstr(newEntry)
	}

	return (
		<>
			<EventEntryStyle className='row py-3 mb-4' typeEvent={eventDetail.type}>
				<div
					className='customSelectParent'
					style={{ position: "relative" }}>
					<RemoveIcon
						className='fa fa-times'
						onClick={() =>
							removeEventDetail(eventDetail.key, eventDetail.type)
						}
					/>
				</div>
				<div className="col">
					<div className="row">
						<div className="col-sm-12 col-xl-2 mb-2">
							<InputWrapperDiv>
								<b>{t("events.date")}</b>
								<br/>
								<DatePicker
									name='event_date'
									selected={eventDetail.eventDate}
									onChange={val =>
										changeDetailsHandler(val, "eventDate", eventDetail.key)
									}
									minDate={date_range_restriction().startDate}
									maxDate={date_range_restriction().endDate}
									dateFormat='dd.MM.yyyy'
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode='select'
									locale={fr}
									wrapperClassName='dataInputWrapper'
									className='dataInput'
								/>
							</InputWrapperDiv>
						</div>
						<div className="col-sm-12 col-xl-2 mb-2">
							<InputWrapperDiv>
								<b>{t("events.start_time")}</b>
								<br/>
								<TimeInput
									value={eventDetail.startTime}
									onChange={val =>
										changeDetailsHandler(val, "startTime", eventDetail.key)
									}
								/>
							</InputWrapperDiv>
						</div>
						<div className="col-sm-12 col-xl-2 mb-2">
							<InputWrapperDiv>
								<b>{t("events.end_time")}</b>
								<br/>
								<TimeInput
									value={eventDetail.endTime}
									onChange={val =>
										changeDetailsHandler(val, "endTime", eventDetail.key)
									}
								/>
							</InputWrapperDiv>
						</div>
						<div className="col-sm-12 col-xl-2 mb-2">
							<InputWrapperDiv >
								<b>{t("events.lenght")}</b>
								<br/>
								<Input
									name='eventLength'
									value={eventDetail.eventLength}
									onChange={() => {}}
									style={{ width: "100%", border:'none' }}
									readonly
								/>
							</InputWrapperDiv>
						</div>
								
						{kzmProject ? (
							<div className="col-sm-12 col-xl-2 mb-2">
								<InputWrapperDiv>
									<b>{t("events.kzm_short")}</b>
									<br/>
								<SlideButton
									type='yesNo'
									val={eventDetail.kzmEvent}
									setVal={val =>
										changeDetailsHandler(val, "kzmEvent", eventDetail.key)
									}
								/>
								</InputWrapperDiv>
							</div>
						) : null}

						{conductor > 0 ? (
							<div className="col-sm-12 col-xl-2 mb-2">
								<InputWrapperDiv>
									<b>{t("events.conductor")}</b>
									<br/>
									<SlideButton
										type='yesNo'
										val={eventDetail.isConductor}
										setVal={val =>
											changeDetailsHandler(val, "isConductor", eventDetail.key)
										}
									/>
								</InputWrapperDiv>
							</div>
						) : null}
							
						<div className="col-sm-12 col-xl-2 mb-2">
							<InputWrapperDiv>
								<b>{t("events.type")}</b>
								<br/>
								<Select
									options={detailTypeList}
									value={detailType}
									defaultValue={{ value: "", label: "Choose *" }}
									onChange={val =>
										changeDetailsTypeHandler(val.value, eventDetail.key)
									}
									styles={select_styles}
									unstyled
									isClearable={false}
									isSearchable={true}
								/>
							</InputWrapperDiv>
						</div>

						<div className="col-sm-12 col-xl-3 mb-2">
							<InputWrapperDiv>
								<b>{t("effectif.Composition")} *</b>
								<br/>
								<Select
									placeholder={t("effectif.Staff_Composition") + " *"}
									closeMenuOnSelect={false}
									isMulti={!isTutti}
									options={compositionOptions}
									value={eventDetail.composition}
									onChange={val => {
										if (
											Array.isArray(val) &&
											val.map(el => el.value).includes(orderTypeStaff.c0)
										) {
											changeCompositionHandler([{value: orderTypeStaff.c0, label: orderTypeStaff.c0, color: '#00ace6'}], true);
										} else {
											changeCompositionHandler(val, false);
										}
									}}
									styles={select_styles}
									unstyled
								/>
							</InputWrapperDiv>
						</div>

						<div className="col-sm-12 col-xl-2 mb-2">
							<InputWrapperDiv>
								<b>{t("events.comp_reduction")}</b>
								<br/>
								<Input
									name='musReduction'
									value={eventDetail.musReduction}
									onChange={val => changeDetailsHandler(val, "musReduction", eventDetail.key)}
									style={{width: "100%"}}
								/>
							</InputWrapperDiv>
						</div>

						<div className="col-sm-12 col-xl-3 mb-2">
							<InputWrapperDiv>
								<b>{t("events.location")}</b>
								<br/>
								<Select
									name={'locations'}
									options={locations}
									styles={select_styles}
									unstyled
									value={locations.find(obj => obj.pk === eventDetail.location)}
									onChange={val =>
										changeDetailsHandler(val.pk, "location", eventDetail.key)
									}
								/>
							</InputWrapperDiv>
						</div>
						{
							locations.some(() => eventDetail.location > 0) &&
							roomsOptions.some(el => el.location_id === eventDetail.location)
							?
							<div className="col-sm-12 col-xl-3 mb-2">
								<InputWrapperDiv>
									<b>{t("events.room")}</b>
									<br/>
									<Select
										name={'rooms'}
										options={roomsOptions.filter(el => el.location_id === eventDetail.location)}
										placeholder={t("location.rooms.choose_room") + " *"}
										styles={select_styles}
										unstyled
										value={
											roomsOptions.find(obj => obj.room_id === eventDetail.room) || []
										}
										onChange={val =>
											changeDetailsHandler(val.room_id, "room", eventDetail.key)
										}
									/>
								</InputWrapperDiv>
							</div>
							: null
						}

					</div>
					{eventDetail.booked === undefined || eventDetail.booked.length === 0
						? null
						: <div className="row pt-3">
							<p style={{color: Colors.orange }}>{t("events.event_date_used_msg")}</p>
							<EventEntryMessageStyle>
								{eventDetail.booked.map(
									(el, index) => <li key={index}><a href={el.link} target="_blank">{el.name}</a></li>
								)}
							</EventEntryMessageStyle>
						</div>
					}
					
				</div>
			</EventEntryStyle>
		</>
	);
}

export default EventEntry;
