import styled from "styled-components"
import { Colors } from "../theme";


const handleVariant = variant => {
    switch (variant) {
        case "info":
            return `
                background-color: ${Colors.infoBG};
                color: ${Colors.info};
                border: 1px solid ${Colors.info};
            `;
        case "error":
            return `
                background-color: ${Colors.errorBG};
                color: ${Colors.error};
                border: 1px solid ${Colors.error};
            `;
        case "success":
            return `
                background-color: ${Colors.successBG};
                color: ${Colors.success};
                border: 1px solid ${Colors.success};
            `;
        default:
            return `
                background-color: ${Colors.infoBG};
                color: ${Colors.info};
                border: 1px solid ${Colors.info};
            `;
    }
}

const FeedbackMessageComponent = styled.div`
    border-radius: 5px;
    padding: 10px 15px;
    margin: 10px 0px 15px 0px;
    ${({ variant }) => handleVariant(variant)};
`

/**
 @param {{
    variant: "info" | "error" | "success";
 }} props
*/

const FeedbackMessage = ({variant,className, children}) => {
    return (
        <FeedbackMessageComponent
            variant={variant}
            className={className}
        >
            {children}
        </FeedbackMessageComponent>
    )
}

const ErrorMessages = ({ formSaveState }) => {
    const messages_list = [];

    if (Object.keys(formSaveState).length > 0) {
        for (const [key, value] of Object.entries(formSaveState)) {
            if (key === 'email') {
                const result_value = value[0].split(/(?=[A-Z])/).join(" ");
                messages_list.push(
                    <div key={key}>
                        < span >{key}: {result_value}</span ><br />
                        < span >Solution: Change your email in Personal Data</span ><br />
                    </div>)
            } else {
                const result_value = value[0].split(/(?=[A-Z])/).join(" ");
                messages_list.push(<div key={key}>< span >{key}: {result_value}</span ><br /></div>);
            }
        }
    };

    return (
        <FeedbackMessage variant='error'>
            {messages_list}
        </FeedbackMessage>
    )
};

export {FeedbackMessage, ErrorMessages};