import {Text, View} from "@react-pdf/renderer";
import {styles} from "./Styles/Styles";
import React from "react";
import {useTranslation} from "react-i18next";

const AdditionalInfo = ({note}) => {
    const {t} = useTranslation()

    return(<>
        
        {note ?
            <View style={{paddingTop:15, marginHorizontal: 30, breakInside: 'avoid'}}>
                <Text style={[styles.redTitle,{padding:'5px',paddingLeft: 0}]}>
                    {t("offer.pdf_document.texts.additional_infos_title")}
                </Text>

                <Text style={{marginTop:'5px', textAlign: "justify"}}>{note}</Text>
            </View>
            : null
        }
    </>)
}

export default AdditionalInfo