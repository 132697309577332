import { createContext } from "react";

const AppContext = createContext({
	language: "en",
	setLanguage: () => {},
	firmLogo: "",
	project: {},
});

// const ProjectContext = createContext({
// 	data: {},
// 	setData: () => {},
// 	visible: false,
// 	projectName: null,
// 	projectId: null,
// 	clientId: null,
// 	firm: "AAAA",
// 	pathReturn: null,
// });

export { AppContext };

