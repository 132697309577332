import {durationTime, staffValueSum} from "./func_helpers"
import {columnTypeStaff, orderTypeStaff} from "../../../../../../helpers/tools";

const getInstrsValue = (evComposition, staff, staffAllSum) => {
	if (!evComposition || evComposition == 0) return 1

	const tutti = evComposition.map(el => el.value).includes(orderTypeStaff.c0)

	if (tutti) {
		return staffAllSum
	}
	const sumInstrCompos = evComposition.map(el => el.label).reduce((sum, instr) => {
		if ( instr in staff ) {
			sum += +staff[instr].value
			return sum
		}
		const groupInstr = columnTypeStaff[instr] // [Woods, Brass...]
		// ktore z groupInstr jest w Staff
		groupInstr.forEach(el => {
			if (staff[el].value > 0) {
				sum += +staff[el].value
			}
		})
		return sum
	}, 0)
	return sumInstrCompos
}

	
// if more details = false
const sumRehearsals = (data, staffSum) => {
	if (staffSum === 0) {
		return [0, 0]
	}
	// conductor is always double price of a musician
	const conductor = data.conductor ? data.conductor : 0

	if (data.kzmProject) {
		const sum_events_opt1_client =
			(data.events.rehearsals * parseFloat(data.clientKzm.rehearsals)) +
			(data.events.rehearsals * parseFloat(data.clientMus.rehearsals) * (staffSum - 1)) +
			(data.events.rehearsals * parseFloat(data.clientMus.rehearsals) * 2 * conductor)

		const sum_events_opt1_estro =
			(data.events.rehearsals * parseFloat(data.estroKzm.rehearsals)) +
			(data.events.rehearsals * parseFloat(data.estroMus.rehearsals) * (staffSum - 1)) +
			(data.events.rehearsals * parseFloat(data.estroMus.rehearsals) * 2 * conductor)
		return [sum_events_opt1_client, sum_events_opt1_estro];
	}

	const sum_events_opt1_client = (data.events.rehearsals * parseFloat(data.clientMus.rehearsals) * staffSum) +
		(data.events.rehearsals * parseFloat(data.clientMus.rehearsals) * 2 * conductor)

	const sum_events_opt1_estro = (data.events.rehearsals * parseFloat(data.estroMus.rehearsals) * staffSum) +
		(data.events.rehearsals * parseFloat(data.estroMus.rehearsals) * 2 * conductor)

	return [sum_events_opt1_client, sum_events_opt1_estro];
};

const sumRaccords = (data, staffSum) => {
	if (staffSum === 0) {
		return [0, 0]
	}

	const conductor = data.conductor ? data.conductor : 0

	if (data.kzmProject) {
		const sum_events_opt1_client =
			data.events.raccords * parseFloat(data.clientKzm.raccords) +
			data.events.raccords * parseFloat(data.clientMus.raccords) * (staffSum - 1) +
			(data.events.raccords * parseFloat(data.clientMus.raccords) * 2 * conductor)

		const sum_events_opt1_estro =
			data.events.raccords * parseFloat(data.estroKzm.raccords) +
			data.events.raccords * parseFloat(data.estroMus.raccords) * (staffSum - 1) +
			(data.events.raccords * parseFloat(data.estroMus.raccords) * 2 * conductor)
		return [sum_events_opt1_client, sum_events_opt1_estro];
	}

	const sum_events_opt1_client = (data.events.raccords * parseFloat(data.clientMus.raccords) * staffSum) +
		(data.events.raccords * parseFloat(data.clientMus.raccords) * 2 * conductor)

	const sum_events_opt1_estro = (data.events.raccords * parseFloat(data.estroMus.raccords) * staffSum) +
		(data.events.raccords * parseFloat(data.estroMus.raccords) * 2 * conductor)

	return [sum_events_opt1_client, sum_events_opt1_estro];
};

const sumConcerts = (data, staffSum) => {
	if (staffSum === 0) {
		return [0, 0]
	}

	const conductor = data.conductor ? data.conductor : 0

	if (data.kzmProject) {
		const sum_events_opt1_client =
			data.events.concerts * parseFloat(data.clientKzm.concerts) +
			data.events.concerts * parseFloat(data.clientMus.concerts) * (staffSum - 1) +
			(data.events.concerts * parseFloat(data.clientMus.concerts) * 2 * conductor)

		const sum_events_opt1_estro =
			data.events.concerts * parseFloat(data.estroKzm.concerts) +
			data.events.concerts * parseFloat(data.estroMus.concerts) * (staffSum - 1) +
			(data.events.concerts * parseFloat(data.estroMus.concerts) * 2 * conductor)
		return [sum_events_opt1_client, sum_events_opt1_estro];
	}

	const sum_events_opt1_client = (data.events.concerts * parseFloat(data.clientMus.concerts) * staffSum) +
		(data.events.concerts * parseFloat(data.clientKzm.concerts) * data.conductor)
	const sum_events_opt1_estro = (data.events.concerts * parseFloat(data.estroMus.concerts) * staffSum) +
		(data.events.concerts * parseFloat(data.estroMus.concerts) * 2 * conductor)
	return [sum_events_opt1_client, sum_events_opt1_estro];
};

const eventsOptional1 = (data, staffSum) => {
	const [sum_rehearsals_client, sum_rehearsals_estro] = sumRehearsals(data, staffSum)
	const [sum_raccords_client, sum_raccords_estro] = sumRaccords(data, staffSum)
	const [sum_concerts_client, sum_concerts_estro] = sumConcerts(data, staffSum)
	const sum_client = parseFloat(sum_rehearsals_client + sum_raccords_client + sum_concerts_client).toFixed(2)
	const sum_estro = parseFloat(sum_rehearsals_estro + sum_raccords_estro + sum_concerts_estro).toFixed(2)
	return [sum_client, sum_estro]
}

// if more details = true
const eventsOptional2 = (data, staffSum) => {
	const ClientOpt2Sum = {
		rehearsalsSum: 0,
		raccordsSum: 0,
		concertsSum: 0
	}

	const EstroOpt2Sum = {
		rehearsalsSum: 0,
		raccordsSum: 0,
		concertsSum: 0
	}

	data.eventDetail
		.filter(elem => elem.type === 'rehearsals')
		.forEach(el => {
			const musReduction = el.musReduction ? el.musReduction : 0
			let staff_sum = getInstrsValue(el.composition, data.staff, staffSum)
			
			if (musReduction < staff_sum)
				staff_sum = staff_sum - musReduction
			else staff_sum = 0
			
			const conductor = el.isConductor ? data.conductor : 0
			const staff_sum_limit = staff_sum > 0 ? staff_sum -1 : 0

			if (el.kzmEvent) {
				ClientOpt2Sum.rehearsalsSum +=
					(data.clientKzm.rehearsals / data.eventDuration.rehearsal_duration) *
						durationTime(el.eventLength) +
					(data.clientMus.rehearsals / data.eventDuration.rehearsal_duration) *
						durationTime(el.eventLength) * staff_sum_limit +
					(((data.clientMus.rehearsals * 2) / data.eventDuration.rehearsal_duration) *
					durationTime(el.eventLength) * conductor)

				EstroOpt2Sum.rehearsalsSum +=
					(data.estroKzm.rehearsals / data.eventDuration.rehearsal_duration) *
					durationTime(el.eventLength) +
					(data.estroMus.rehearsals / data.eventDuration.rehearsal_duration) *
					durationTime(el.eventLength) * staff_sum_limit +
					(((data.estroMus.rehearsals * 2) / data.eventDuration.rehearsal_duration) *
						durationTime(el.eventLength) * conductor)
			} else {
				ClientOpt2Sum.rehearsalsSum +=
					(data.clientMus.rehearsals / data.eventDuration.rehearsal_duration) *
					durationTime(el.eventLength) * staff_sum +
					(((data.clientMus.rehearsals * 2) / data.eventDuration.rehearsal_duration) *
						durationTime(el.eventLength) * conductor)
				EstroOpt2Sum.rehearsalsSum +=
					(data.estroMus.rehearsals / data.eventDuration.rehearsal_duration) *
					durationTime(el.eventLength) * staff_sum +
					(((data.estroMus.rehearsals * 2) / data.eventDuration.rehearsal_duration) *
						durationTime(el.eventLength) * conductor)
			}
		})

	data.eventDetail
		.filter(elem => elem.type === 'raccords')
		.forEach(el => {
			const musReduction = el.musReduction ? el.musReduction : 0
			let staff_sum = getInstrsValue(el.composition, data.staff, staffSum)
			
			if (musReduction < staff_sum)
				staff_sum = staff_sum - musReduction
			else staff_sum = 0
			
			const conductor = el.isConductor ? data.conductor : 0
			const staff_sum_limit = staff_sum > 0 ? staff_sum -1 : 0

			if (el.kzmEvent) {
				ClientOpt2Sum.raccordsSum +=
					((data.clientKzm.raccords /	(data.eventDuration.raccord_duration + 0.5)) *
						(durationTime(el.eventLength) + 0.5)) +
					((data.clientMus.raccords / (data.eventDuration.raccord_duration + 0.5)) *
						(durationTime(el.eventLength) + 0.5)) * staff_sum_limit +
					(((data.clientMus.raccords * 2) / (data.eventDuration.raccord_duration + 0.5)) *
						(durationTime(el.eventLength) + 0.5) * conductor)
				EstroOpt2Sum.raccordsSum +=
					((data.estroKzm.raccords /	(data.eventDuration.raccord_duration + 0.5)) *
						(durationTime(el.eventLength) + 0.5)) +
					((data.estroMus.raccords / (data.eventDuration.raccord_duration + 0.5)) *
						(durationTime(el.eventLength) + 0.5)) * staff_sum_limit +
					(((data.estroMus.raccords * 2) / (data.eventDuration.raccord_duration + 0.5)) *
						(durationTime(el.eventLength) + 0.5) * conductor)
			} else {
				ClientOpt2Sum.raccordsSum +=
					(data.clientMus.raccords / (data.eventDuration.raccord_duration + 0.5)) *
					(durationTime(el.eventLength) + 0.5) * staff_sum +
					(((data.clientMus.raccords * 2) / (data.eventDuration.raccord_duration + 0.5)) *
						(durationTime(el.eventLength) + 0.5) * conductor)
				EstroOpt2Sum.raccordsSum +=
					(data.estroMus.raccords / (data.eventDuration.raccord_duration + 0.5)) *
					(durationTime(el.eventLength) + 0.5) * staff_sum +
					(((data.estroMus.raccords * 2) / (data.eventDuration.raccord_duration + 0.5)) *
						(durationTime(el.eventLength) + 0.5) * conductor)
			}
	})

	data.eventDetail
		.filter(elem => elem.type === 'concerts')
		.forEach(el => {
			const musReduction = el.musReduction ? el.musReduction : 0
			let staff_sum = getInstrsValue(el.composition, data.staff, staffSum)
			if (musReduction < staff_sum)
				staff_sum = staff_sum - musReduction
			else staff_sum = 0
			const conductor = el.isConductor ? data.conductor : 0
			const staff_sum_limit = staff_sum > 0 ? staff_sum -1 : 0

			if (el.kzmEvent) {
				ClientOpt2Sum.concertsSum += parseFloat(data.clientKzm.concerts) +
					(parseFloat(data.clientMus.concerts) * staff_sum_limit) +
					(parseFloat(data.clientMus.concerts) * 2 * conductor)
				EstroOpt2Sum.concertsSum += parseFloat(data.estroKzm.concerts) +
					(parseFloat(data.estroMus.concerts) * staff_sum_limit) +
					(parseFloat(data.estroMus.concerts) * 2 * conductor)
			} else {
				ClientOpt2Sum.concertsSum += (parseFloat(data.clientMus.concerts) * staff_sum) +
					(parseFloat(data.clientKzm.concerts) * conductor)
				EstroOpt2Sum.concertsSum += (parseFloat(data.estroMus.concerts) * staff_sum) +
					(parseFloat(data.estroKzm.concerts) * 2 * conductor)
			}
		})

	const client_sum = parseFloat(
		ClientOpt2Sum.rehearsalsSum + ClientOpt2Sum.raccordsSum + ClientOpt2Sum.concertsSum
	).toFixed(2)
	const estro_sum = parseFloat(
		EstroOpt2Sum.rehearsalsSum + EstroOpt2Sum.raccordsSum + EstroOpt2Sum.concertsSum
	).toFixed(2)
	return [client_sum, estro_sum]
}

const sumObject = (data) => {
	let sumCostClient = 0
	let sumCostEstro = 0
	data.forEach( el => {
		sumCostClient += parseFloat(el.client_cost)
		sumCostEstro += parseFloat(el.estro_cost)
	})
	return [parseFloat(sumCostClient), parseFloat(sumCostEstro)]
}

const ratesCalculation = (data, staffSum) => {
	const [sum_events_client, sum_events_estro] = data.details
		? eventsOptional2(data, staffSum)
		: eventsOptional1(data, staffSum)
	return [sum_events_client, sum_events_estro]
}

const sumRatesClientCost = (data, staffSum, ratesSumCalculate) => {
	const [sum_events_client, sum_events_estro] = ratesSumCalculate // ratesCalculation(data, staffSum)
	const [sum_renting_client, sum_renting_estro] = sumObject(data.renting)
	const [sum_transport_client, sum_transport_estro] = sumObject(data.transport)
	const [sum_administration_client, sum_administration_estro] = sumObject(data.administration)
	const [sum_archiving_client, sum_archiving_estro] = sumObject(data.archiving)

	const sumClientCosts =
		parseFloat(sum_events_client) +
		parseFloat(sum_renting_client) +
		parseFloat(sum_transport_client) +
		parseFloat(sum_administration_client) +
		parseFloat(sum_archiving_client)

	const sumEstroCosts =
		parseFloat(sum_events_estro) +
		parseFloat(sum_renting_estro) +
		parseFloat(sum_transport_estro) +
		parseFloat(sum_administration_estro) +
		parseFloat(sum_archiving_estro)

	return {
		clientSum: sumClientCosts.toFixed(2),
		estroSum: sumEstroCosts.toFixed(2),
		totalSum: sumClientCosts > sumEstroCosts ? sumClientCosts.toFixed(2) : sumEstroCosts.toFixed(2)
	}
}


const pdf_calculation_estimate = (data, estro_cost=false) => {
	const staffSum = staffValueSum(data.staff);
	//const costType = estro_cost ? "estro" : "client"
	const costType = "client"
	let events_all = {}

	if (data.eventDetail.length > 0) {
		data.eventDetail.forEach((event) => {
			const musiciansSum = +getInstrsValue(event.composition, data.staff, staffSum)
			const conductor = event.isConductor ? data.conductor : 0
			
			if (data.details) {
				if (event.type === 'rehearsals') {
					if (event.kzmEvent) {
						events_all[event.key] = ((data[costType+"Kzm"].rehearsals / data.eventDuration.rehearsal_duration) * durationTime(event.eventLength)) +
							((data[costType+"Mus"].rehearsals / data.eventDuration.rehearsal_duration) * durationTime(event.eventLength)) *
							(musiciansSum - 1) +
							(((data[costType+"Mus"].rehearsals * 2 / data.eventDuration.rehearsal_duration) * durationTime(event.eventLength)) * conductor)
					} else {
						events_all[event.key] = ((data[costType+"Mus"].rehearsals / data.eventDuration.rehearsal_duration) * durationTime(event.eventLength)) *
							(musiciansSum - 1) *
							(((data[costType+"Mus"].rehearsals * 2 / data.eventDuration.rehearsal_duration) * durationTime(event.eventLength) * conductor))
					}
				}

				if (event.type === 'raccords') {
					if (event.kzmEvent) {
						events_all[event.key] = ((data[costType+"Kzm"].raccords / (data.eventDuration.raccord_duration + 0.5)) *
								(durationTime(event.eventLength) + 0.5)) +
							((data[costType+"Mus"].raccords / (data.eventDuration.raccord_duration + 0.5)) *
								(durationTime(event.eventLength) + 0.5)) * (musiciansSum - 1) +
							(((data[costType+"Mus"].raccords * 2 / (data.eventDuration.raccord_duration + 0.5)) *
								(durationTime(event.eventLength) + 0.5)) * conductor)
					} else {
						events_all[event.key] = ((data[costType+"Mus"].raccords / (data.eventDuration.raccord_duration + 0.5)) *
								(durationTime(event.eventLength) + 0.5)) * musiciansSum +
							(((data[costType+"Mus"].raccords * 2 / data.eventDuration.raccord_duration + 0.5) *
								(durationTime(event.eventLength) + 0.5)) * conductor)
					}
				}

				if (event.type === 'concerts') {
					if (event.kzmEvent) {
						events_all[event.key] = data[costType+"Kzm"].concerts + (data[costType+"Mus"].concerts *
							(musiciansSum -1)) +
						(data[costType+"Mus"].concerts * 2 * conductor)
					} else {
						events_all[event.key] = (data[costType+"Mus"].concerts * musiciansSum) +
							(data[costType+"Mus"] * 2 * conductor)
					}
				}
			}
		})

		return events_all

	} else {
		let costTypeValue = (costType === 'client' ? 0 : 1);

		const musicisanCosts = sumRehearsals(data,staffSum)[costTypeValue] + sumRaccords(data,staffSum)[costTypeValue] + sumConcerts(data,staffSum)[costTypeValue]
		
		return musicisanCosts
	}
	
		// events_all[event.key] = {rehersals: rehersals_sum, raccords: raccords_sum, concerts: concerts_sum}
	
}


export { sumRatesClientCost, ratesCalculation, pdf_calculation_estimate }




