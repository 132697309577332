import { useTranslation } from "react-i18next";
import styled from "styled-components"
import { Colors } from "../theme";
import { Button } from "./Button";
import LordIcon from "./LordIcon";
import {useEffect, useState} from "react";

const handleVariant = variant => {
    switch (variant) {
        case "error":
            return `
                background-color: ${Colors.infoBG};
                color: ${Colors.info};
                border: 1px solid ${Colors.info};
            `;
        case "success":
            return `
                background-color: ${Colors.errorBG};
                color: ${Colors.error};
                border: 1px solid ${Colors.error};
            `;
        case "editF":
            return `
                background-color: ${Colors.editBG};
                color: ${Colors.edit};
                border: 1px solid ${Colors.edit};
            `;
        default:
            return `
                background-color: ${Colors.infoBG};
                color: ${Colors.info};
                border: 1px solid ${Colors.info};
            `;
    }
}

const PopupMessageStyle = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    z-index: 10;
`

const PopupMessageContent = styled.div`
    position: absolute;
    max-width: 400px;
    width: 100%;
    min-height: 150px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 45px 25px 25px 25px;
    text-align: center;
    background-color: ${Colors.white};
`

const PopupMessageIcon = styled.div`
    border-radius: 50%;
    border: 5px solid ${Colors.white};
    height: 70px;
    width: 70px;
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${Colors.white};
`

const PopupMessageTitle = styled.div`
    font-size: 1.7rem;
    font-weight: bold;
    color: ${({variant}) =>
    variant === 'error' && `${Colors.red}` ||
    variant === 'success' && `${Colors.green}` ||
    variant === 'editF' && `${Colors.orange}` ||
    `${Colors.black}`
};
`

const PopupMessageDescription = styled.div`
    font-size: 1.1rem;
    margin: 10px 0px;
`

/**
 @param {{
    variant: "error" | "success";
    title: title of popup
    message: what text do you want to show
    leftBtn: light gray bordered button content
    rightBtn: filled red button content
 }} props
 */

const PopupMessage = ({handlePopup, variant, title, message, leftBtn, rightBtn, okBtn, context}) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState({
            client_cost: '',
            estro_cost: '',
            conductor_client_cost: '',
            conductor_estro_cost: ''
    })

    useEffect(() => {
        setInputValue({
            client_cost: context.client_cost,
            estro_cost: context.estro_cost,
            conductor_client_cost: context.conductor_client_cost,
            conductor_estro_cost: context.conductor_estro_cost
        })
    }, [])

    var colorType;

    if(variant==='error'){
        colorType = `${Colors.red}`;
    }
    else if (variant==='success'){
        colorType = `${Colors.green}`;
    }
    else if (variant==='editF'){
        colorType = `${Colors.orange}`;
    }
    else {
        colorType = `${Colors.black}`;
    };

    const handleChange = (val, fieldName) => {
        setInputValue({
            ...inputValue,
            [fieldName]: val
        })
    }

    const handleClickFactory = (e) => {
        const buttonName = e.target.name

        if (buttonName === 'save') {
            return handlePopup({
                show: true,
                type: "save",
                client_cost: inputValue.client_cost || context.client_cost,
                estro_cost: inputValue.estro_cost || context.estro_cost,
                conductor_client_cost: inputValue.conductor_client_cost || context.conductor_client_cost,
                conductor_estro_cost: inputValue.conductor_estro_cost || context.conductor_estro_cost
            })
        }

        if (buttonName == 'cancel') {
            return handlePopup({type: "cancel"})
        }

        if (buttonName === 'OK') {
            return handlePopup({type: "OK"})
        }
        // return e => setPopup({show: false, e});
    };

    return (
        <PopupMessageStyle>
            <PopupMessageContent className="popup">
                <PopupMessageIcon variant={variant}>
                    <LordIcon url={variant} color={colorType} target='.popup' size='60px'/>
                </PopupMessageIcon>

                <PopupMessageTitle variant={variant}>
                    {title}
                </PopupMessageTitle>

                <PopupMessageDescription>
                    {message}
                    {context ?
                        <div>
                            <div className="mt-3 me-1">
                               <b>{t("rates.musician")}</b>
                            </div>
                            <div className="mt-3 mb-1">
                                <span className="me-1">{t("rates.clients_costs")}:</span>
                                <input
                                    name="client_cost"
                                    style={{width:'100px'}}
                                    type={'text'}
                                    value={inputValue.client_cost}
                                    onChange={e => handleChange(e.target.value, 'client_cost')}
                                />
                            </div>
                            <span className="me-1">{t("rates.estros_costs")}:</span>
                            <input
                                name="estro_cost"
                                style={{width:'100px'}}
                                type={'text'}
                                value={inputValue.estro_cost}
                                onChange={e => handleChange(e.target.value, 'estro_cost')}
                                // defaultValue={context.estro_cost}
                            />

                            <div className="mt-3 me-1">
                                <b>{t("effectif.Conductor")}</b>
                            </div>
                            <div className="mt-3 mb-1">
                                <span className="me-1">{t("rates.clients_costs")}:</span>
                                <input
                                    name="conductor_client_cost"
                                    style={{width:'100px'}}
                                    type={'text'}
                                    value={inputValue.conductor_client_cost}
                                    onChange={e => handleChange(e.target.value, 'conductor_client_cost')}
                                />
                            </div>
                            <span className="me-1">{t("rates.estros_costs")}:</span>
                            <input
                                name="conductor_estro_cost"
                                style={{width:'100px'}}
                                type={'text'}
                                value={inputValue.conductor_estro_cost}
                                onChange={e => handleChange(e.target.value, 'conductor_estro_cost')}
                                // defaultValue={context.estro_cost}
                            />
                        </div>
                        : null
                    }
                </PopupMessageDescription>

                {leftBtn ? (<Button name="cancel" onClick={(e) => handleClickFactory(e)} variant="round" bWidth="small" styling="bordered" className='mx-3'>{leftBtn}</Button>):null}
                {rightBtn ? (<Button name="save" onClick={(e) => handleClickFactory(e)} variant="round" bWidth="small" styling="plain" className='mx-3'>{rightBtn}</Button>):null}
                {okBtn ? (<Button name="OK" onClick={(e) => handleClickFactory(e)} variant="round" bWidth="small" styling="plain" className='mx-3'>{okBtn}</Button>):('')}

            </PopupMessageContent>
        </PopupMessageStyle>
    )
};

export default PopupMessage;
