import styled from "styled-components"
import { Colors } from "../theme";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import React from "react";

const EditButtonStyle = styled.button`
    font-family: brandon-grotesque, sans-serif;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    vertical-align: middle;
    text-align: center;
    border: 1px solid ${Colors.darkRed};
    background-color: ${Colors.darkRed};
    border-radius: 5px;
    padding: 0px 8px 0px 0px;
    color: ${Colors.mediumBG};
    &:hover {
        cursor: pointer;
        background-color: ${Colors.red};
        color: ${Colors.white};
    }
    width: 75px;
`

const IconStyle = {
    width: '20px',
    height: '20px',
    verticalAlign: 'top',
    paddingTop: '0px',
    transform: 'rotate(0deg)',
    cursor: 'pointer'
};

const EditButton = ({gotoName="edit", handleClickProps}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = () => {
        if (typeof handleClickProps === 'function') {
            handleClickProps()
        }
        navigate(gotoName)
    };

    return (
        <EditButtonStyle className="editBtn ms-3" onClick={handleClick}>
            <lord-icon
                /*src='https://cdn.lordicon.com/wloilxuq.json'*/
                src='https://cdn.lordicon.com/hnvrjtda.json'
                trigger='hover'
                target='.editBtn'
                colors={`primary:${Colors.mediumBG},secondary:${Colors.mediumBG}`}
                style={IconStyle}
            >
            </lord-icon>
            {t("common.button.edit")}
        </EditButtonStyle>
    )
};

export {
    EditButton,
    EditButtonStyle,
    IconStyle,
};