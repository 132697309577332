// import {StyleSheet} from "@react-pdf/renderer/lib/react-pdf";
import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    page: {
        fontFamily: "Helvetica",
        fontSize: "10px",
        height: "100%",
        width:"100%",
        paddingBottom: 120
    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: "10px",
        borderBottomWidth: 1,
        borderBottomColor: "#bfbfbf",
        marginTop: 30,
        marginHorizontal: 30,
        marginBottom: 25
    },
    logo: {
        maxWidth: "140px",
        width: "100%",
    },
    textItalic: {
        fontFamily: "Times-Italic"
    },
    textBold: {
        fontFamily: 'Helvetica-Bold',
    },
    rightContent: {
        alignItems: "flex-end",
    },
    redTitle: {
        color: "#b41f23",
        fontSize: "8px",
        textTransform: "uppercase"
    },
    titleContainer: {
        flexDirection: "row",
        marginHorizontal: 30
    },
    pageNumber: {
        textAlign: "center",
        color: "grey",
        paddingBottom: 5
    },
    documentRef: {
        textDecoration: "underline",
        marginBottom: "20px",
        marginTop: "20px",
        fontSize: "12px",
        color: "#707070"
    },
    txtSection: {
        textAlign: "justify",
        marginTop: "20px",
    },
});

const stylesEventsTable = StyleSheet.create({
    table: {
        marginHorizontal: 30,
        display: "table",
        width: '90%',
        borderStyle: "none",
        borderWidth: 0,
        borderColor: "#fff",
        borderCollapse: "collapse",
        fontSize: "10px",
        fontFamily: "Helvetica"
    },
    tableRow: {
        flexDirection: "row",
        borderBottomWidth: 0,
        borderBottomColor: "#fff"
    },
    tableHeader: {
        backgroundColor: "#fff",
        flexGrow: 1,
        color: "#b41f23",
        fontSize: "8px",
        textTransform: "uppercase"
    },
    tableCell: {
        padding: 5,
    },
});

const stylesStaffTable = StyleSheet.create({
    section: {
        flexDirection: 'row',
        width: '100%',
    },
    column: {
        flex: 1,
        borderRightWidth: 1,
        borderColor: 'black',
        padding: 5,
        paddingLeft: 10,
        paddingBottom: 0,
    },
    lastColumn: {
        flex: 1,
        padding: 5,
        paddingLeft: 10,
        paddingBottom: 0,
    },
    staffLine: {
        flexDirection: 'row',
        paddingBottom: 5
    },
    staffName: {
        width:'80%'
    },
    staffNumber: {
        width:'20%',
        fontFamily: 'Helvetica-Bold',
        textAlign: 'center'
    }
});

const stylesTotal = StyleSheet.create({
    entryLine: {
        flexDirection: 'row',
        paddingTop: '4px',
        paddingBottom: '4px',
    },
    service: {
        width: '80%'
    },
    costs: {
        textAlign: 'right',
        width: '20%'
    },
    total: {
        fontFamily: 'Helvetica-Bold',
        textAlign: 'right'
    }
});

const stylesFooter = StyleSheet.create({
    section: {
        fontSize: 8,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0
    }
});

export {
    styles,
    stylesFooter,
    stylesTotal,
    stylesStaffTable,
    stylesEventsTable
};
