import DarkCard from "../../../../../../components/card/DarkCard";
import {InputWrapperDiv} from "../../../../../../components/ui/Input";
import {useNavigate, useParams} from "react-router-dom";
import {Colors} from "../../../../../../components/theme";
import {Button} from "../../../../../../components/ui/Button";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import FormClient from "../../Clients/FormClient";
import select_styles from "../../../../../../layouts/elements";
import {
    associationMemberCreateAPI, associationMemberDeleteAPI,
    associationMemberUpdateAPI, memberDetailsApi,
    positionsListApi
} from "../../../../../../services/api/user";
import PopupMessage from "../../../../../../components/ui/PopupMessage";
import LightCard from "../../../../../../components/card/LightCard";
import {phoneCountryList} from "../../../../../../helpers/dictionary/users";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";

const field_list = {
    first_name: "",
    last_name: "",
    phoneCountry: "",
    telephone: "",
    email: "",
    positions: [],
    signature: "",
}

const ImageStyle = {
    width: '200px',
    height: '200px',
};

const popupInit = {show: false, title: '', variant: 'error', message: ''}
// const returnPath = (id) => '/asbl/association/details/member/'+id

const getDataAPI = async (pk=0) => {
    try {
        const positionsData = await positionsListApi()
        if (pk) {
            const clientDetailData = await memberDetailsApi(pk)

            if (clientDetailData.status === 200 && positionsData.status === 200) {

                console.log("SSSSSSSSSSS", clientDetailData.data)

                return {details: clientDetailData.data, positionsData: positionsData.data, status: 200}
            } else {
                return {positionsData: false, status: 400}
            }
        }
        if (positionsData.status === 200) {
            return {positionsData: positionsData.data, status: 200}
        } else {
            return {positionsData: false, status: 400}
        }
    } catch (e) {
        console.log("Failed to download member/positions data...")
        return {status: 400}
    }
}

const MemberAddEdit = () => {
    const [form, setForm] = useState(field_list)
    const [positionsOptions, setPositionsOptions] = useState([])
    const [popup, setPopup] = useState(popupInit);
    const { t } = useTranslation();
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        const positionsNames = async () => {
            try {
                const response = await getDataAPI(params.key)
                if (response.status === 200) {
                    setPositionsOptions(response.positionsData)
                } else {
                    setPopup({
                        show: true,
                        title: t("popups.error_get_data.title"),
                        variant: 'error',
                        message: t("popups.error_get_data"),
                        okBtn: "OK",
                        handlerPopup: "handlePopup",
                    })
                }
                if (params.key && response.status === 200) {
                    const positionsData = response.details.positions.map(el => {
                        return response.positionsData.find(pos => pos.value === el.id)
                    })

                    const diallingCode = response.details.telephone.split("-")[0]
                    const phonePrefix = phoneCountryList.find(el => el.value === diallingCode)

                    setForm({
                        ...response.details,
                        "positions": positionsData,
                        phoneCountry: phonePrefix,
                    })
                }
            } catch (e) {
                console.log("Failed to download positions names", e)
            }
        }
        positionsNames()
    }, [params.key])

    const changeHandler = (val, fieldName) => {
        let newEntry = {}
        if (fieldName === "remove-signature") {
            newEntry = {
                ...form,
                signature: field_list.signature
            }
        } else {
            newEntry = {
                ...form,
                [fieldName]: val
            }
        }
        setForm(newEntry)
    }


    const saveHandler = async (event) => {
        event.preventDefault()
        try {
            const phoneArray = form.telephone.split("-")
            const phone = phoneArray.length > 1 ? phoneArray[1] : phoneArray[0]
            const telephone = `${form.phoneCountry.value}-${phone}`

            const formData = new FormData()
            Object.entries(form).forEach((obj) => {
                if (obj[0] === 'telephone') {
                    formData.append(obj[0], telephone)
                } else if (obj[0] === 'positions') {
                    obj[1].forEach(el => {
                        formData.append(obj[0], el.value)
                    })
                } else if (obj[0] === 'signature') {
                    if (!form.signature) {
                        formData.append('signature', "")
                    }
                    if (typeof form.signature === 'object') {
                        formData.append('signature', form.signature, form.signature.name)
                    }
                } else {
                    formData.append(obj[0], obj[1])
                }

            })
            // for (var [key, value] of formData.entries()){
            //     console.log("FormData:>>> ", key, value);
            // }
            const response = params.key
                ? await associationMemberUpdateAPI(formData, params.key)
                : await associationMemberCreateAPI(formData)

            if (response.status === 200 || response.status === 201) {
                params.key = response.data.id
                setPositionsOptions(response.data)
                setPopup({
                    show: true,
                    title: t("popups.save_data.title"),
                    variant: 'success',
                    message: t("popups.save_data.message"),
                    delete: false,
                    okBtn: t("common.button.ok"),
                    handlerPopup: "handleOkClick",
                })
            } else {
                setPopup({
                    show: true,
                    title: t("popups.error_save_data.title"),
                    variant: 'error',
                    message: t("popups.error_save_data.member_not_added"),
                    delete: false,
                    okBtn: t("common.button.ok"),
                    handlerPopup: "handleOkClick",
                })
            }
        } catch (e) {
            console.log("Failed to save data. ", e)
            setPopup({
                show: true,
                title: t("popups.error_save_data.title"),
                variant: 'error',
                message: t("popups.error_save_data.member_not_added"),
                okBtn: t("common.button.ok"),
                handlerPopup: "handleOkClick",
            })
        }
    }

    const cancelHandler = () => {
        if (params.key) {
            navigate("/asbl/association/details/member/"+params.key)
        } else {
            navigate("/asbl/association")
        }

    }

    const delete_obj = async () => {
        try {
            const response = await associationMemberDeleteAPI(params.key)
            if (response.status === 204) {
                setPopup({
                    show: true,
                    title: t("popups.delete_data.title"),
                    variant: 'success',
                    message: t("popups.delete_data.member_remove"),
                    type: 'delete',
                    okBtn: t("common.button.ok"),
                    handlerPopup: "handleOkClick",
                })
            }
        } catch (e) {
            console.log("Failed to delete. ", e)
            setPopup({
                show: true,
                title: t("popups.error_delete_data.title"),
                variant: 'error',
                message: t("popups.error_delete_data.member_not_removed"),
                okBtn: t("common.button.ok"),
                handlerPopup: "handleOkClick",
            })
        }
    }

    const deleteHandler = () => {
        setPopup({
            show: true,
            variant: 'editF',
            title: t("management.delete_member_title"),
            message: t("management.delete_member_message"),
            type: 'delete',
            leftBtn: t("common.button.cancel"),
            okBtn: t("common.button.delete"),
            handlerPopup: "handleSelectClick",
        })
    }

    const handleSelectClick = async (obj) => {
        if (obj.decision) { // delete
            await delete_obj()
        } else setPopup(popupInit) // cancel
    }

    const handleOkClick = (obj) => {
        if (popup.variant === 'error') {
            setPopup(popupInit)
            return;
        }
        if (params.key && popup.type === 'delete') {
            if (obj.decision) {
                navigate("/asbl/association")
                return;
            } else {
                setPopup(popupInit)
                return
            }
        }
        navigate("/asbl/association/details/member/"+params.key)
    }

    return(<>
        {popup.show
            ? <PopupMessage
                setPopup={popup.handlerPopup === "handleOkClick" ? handleOkClick : handleSelectClick}
                variant={popup.variant}
                title={popup.title}
                message={popup.message}
                leftBtn={popup.leftBtn}
                rightBtn={popup.rightBtn}
                okBtn={popup.okBtn}
            />
            : <>
            <DarkCard classNames='mb-3 row'>
                Breadcrumbs: Association list /
                {params.key ? " edit member" : " Add new member"}
            </DarkCard>

            <DarkCard classNames='mb-3 row'>
                {params.key ? <h1 className="mt-0 p-0">{t("asbl.edit_member_title")}</h1> : <h1 className="mt-0 p-0">{t("asbl.add_member_title")}</h1>}
            
                <form name="member" onSubmit={saveHandler} className="ps-0">

                    <div className="col-lg-6 mb-4">
                        <LightCard>
                            <InputWrapperDiv addClass="mb-4">
                                <b>{t("effectif.Composition")} *</b>
                                <br/>
                                <Select
                                    name="positions"
                                    placeholder={t("position.select") + " *"}
                                    closeMenuOnSelect={false}
                                    isMulti={true}
                                    options={positionsOptions}
                                    value={form.positions}
                                    onChange={ val => {changeHandler(val, "positions")} }
                                    styles={select_styles}
                                    unstyled
                                    required
                                />
                            </InputWrapperDiv>
                            <FormClient form={form} changeHandler={changeHandler}>
                                <InputWrapperDiv className="px-2 mt-2">
                                    {(form.signature) &&
                                        <div className="col-md-6">
                                            <img src={typeof form.signature !== 'string' ? URL.createObjectURL(form.signature) : form.signature} alt='' style={ImageStyle} />
                                        </div>
                                    }
                                    {/*<div>*/}
                                    {/*    {form.signature &&*/}
                                    {/*        typeof form.signature === 'object' &&*/}
                                    {/*        `${form.signature.name} - ${form.signature.type}`*/}
                                    {/*    }*/}
                                    {/*</div>*/}
                                    <a className="ms-3" style={{fontSize:'20px'}} name="editName"
                                       onClick={e => changeHandler(e.target, "remove-signature")} >
                                        <FontAwesomeIcon icon={faCircleXmark}/>
                                    </a>
                                    <input
                                        type="file"
                                        name={'signature'}
                                        onChange={e => changeHandler(e.target.files[0], 'signature')}
                                    />
                                </InputWrapperDiv>
                            </FormClient>
                        </LightCard>

                        <div className="mt-4">
                            <Button
                                type="submit"
                                style={{backgroundColor:`${Colors.green}`}}
                                variant="round"
                                className={'me-3'}
                            >
                                {t("common.button.save")}
                            </Button>

                            <Button
                                variant="round"
                                onClick={cancelHandler}
                                styling="bordered"
                                className={'me-5'}
                            >
                                {t("common.button.cancel")}
                            </Button>

                            {params.key ?
                            <Button
                                variant="round"
                                onClick={deleteHandler}
                                style={{backgroundColor:`${Colors.red}`}}
                            >
                                {t("common.button.delete")}
                            </Button>
                            : null }
                        </div>
                    </div>
                </form>
            </DarkCard>
        </>
        }
    </>)
}

export default MemberAddEdit

