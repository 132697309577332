import { useTranslation } from "react-i18next"
import OtherCostsItem from "./OtherCostsItem"
import { Link } from "react-router-dom"
import LordIcon from "../ui/LordIcon"
import { Colors } from "../theme"
import {v4 as uuidv4} from "uuid"
import {decimalValidate} from "../../helpers/validate"
import React from "react"
import CountingUp from "../../helpers/components/CountingUp"
import {
    archiving_entry,
    archiving_entry_obj,
    displayType, name_list_archiving,
} from "../../pages/roles/admin/PageContent/Sales/helpers/func_helpers"
import {getProjectSettings} from "../../services/api/user"

function ArchivingCosts ({archiving, setArchiving, type}) {
    const { t } = useTranslation();

    const addMoreChandler = () => {
        const newItem = {
            id: uuidv4(),
            description: '',
            client_cost: "0.00",
            estro_cost: "0.00",
            amount: 0,
            cost_type: type,
            entity: '',
            edit: true,
            unit_client_cost: 1.00,
            unit_estro_cost: 1.00,
            readOnly: false,
            display_type: "X",
            name: "X"
        };
        setArchiving([...archiving, newItem]);
    };

    const changeHandler = (val, cost_type, item) => {
        if (cost_type !== 'description' && !decimalValidate(val)) {
            return
        }
        const archivingClone = structuredClone(archiving);
        const indexDel = archivingClone.findIndex(el => (el.cost_type=type && el.id === item.id));
        let newItem = {};

        if (displayType(item.display_type).description) {
            let cCost = 0
            let eCost = 0
            if (cost_type === 'amount') {
                cCost = parseFloat(val * item.unit_client_cost).toFixed(2);
                eCost = parseFloat(val * item.unit_estro_cost).toFixed(2);
            } else {
                cCost = cost_type==='client_cost' ? val : item.client_cost
                eCost = cost_type==='estro_cost' ? val : item.estro_cost
            }

            newItem = {
                id: item.id,
                description: item.description,
                client_cost: cCost,
                estro_cost: eCost,
                amount: cost_type==="amount" ? val : 0,
                cost_type: type,
                entity: item.entity,
                edit: false,
                unit_client_cost: item.unit_client_cost,
                unit_estro_cost: item.unit_estro_cost,
                readOnly: item.readOnly,
                display_type: item.display_type,
                name: item.name
            }
        } else {
            newItem = {
                id: item.id,
                description: cost_type === 'description' ? val : item.description,
                client_cost: cost_type === 'client_cost' ? val : item.client_cost,
                estro_cost: cost_type === 'estro_cost' ? val : item.estro_cost,
                amount: cost_type === 'amount' ? val : item.amount,
                cost_type: type,
                entity: item.entity,
                edit: true,
                unit_client_cost: item.unit_client_cost,
                unit_estro_cost: item.unit_estro_cost,
                readOnly: item.readOnly,
                display_type: item.display_type,
                name: cost_type === 'description' ? val : item.name
            }
        }
        archivingClone.splice(indexDel, 1, newItem);
        setArchiving(archivingClone)
    };

    const removeItem = (itemKey) => {
        const newItem = structuredClone(archiving);
        const indexDel = newItem.findIndex(el => el.id === itemKey);
        newItem.splice(indexDel, 1);

        setArchiving(newItem)
    }

    const handleReset = async () => {
        try {
            const responseSettings = await getProjectSettings()
            const result = responseSettings.data.other_cost_type.filter(
                type => type.cost_type === "archiving"
            )
            const archivingCopy = structuredClone(archiving)


            const archiving_data = []
            name_list_archiving.forEach((name) => {
                const result_item = result.find(el => el.description === name)
                if (result_item) {
                    result_item.name = name
                    result_item.description = archiving_entry(t, name).desc
                    result_item.display_type = "archivro"
                    result_item.amount = 0
                    result_item.entity = archiving_entry(t, name).entity
                    result_item.edit = false
                    result_item.unit_client_cost = result_item.client_cost
                    result_item.unit_estro_cost = result_item.estro_cost
                    result_item.client_cost = "0.00"
                    result_item.estro_cost = "0.00"

                    // czy item name jest w Archiving
                    const archiving_index = archivingCopy.findIndex(el => el.name === name)
                    if (archiving_index > -1) {
                        const archiving_item = archivingCopy[archiving_index]
                        result_item.amount = archiving_item.amount
                        result_item.client_cost = parseFloat(archiving_item.amount * result_item.unit_client_cost).toFixed(2);
                        result_item.estro_cost = parseFloat(archiving_item.amount * result_item.unit_estro_cost).toFixed(2);

                        archivingCopy.splice(archiving_index, 1)
                    }
                    archiving_data.push(result_item)
                }
            })
            // dodaje reszte desc z result do listy
            const result_rest_name = result.filter(el => !name_list_archiving.includes(el.name))
            const rest_archiving_data = result_rest_name.map(el => {
                const obj = archiving_entry_obj(t, el, el.display_type)
                // czy item name z result jest w Archiving
                const archiving_index = archivingCopy.findIndex(elem => elem.name === el.description)
                if (archiving_index > -1) {
                    archivingCopy.splice(archiving_index, 1)
                }
                return obj
            })
            archiving_data.push(...rest_archiving_data, ...archivingCopy)
            setArchiving(archiving_data)
        } catch(ex) {
            console.log("Failed to download settings")
        }
    }

    return (
        <>
            <div className="row px-2">
                <div className="col-12">
                    <div className="d-flex flex-row align-items-center my-2">
                        <div style={{width:'30%'}}>
                            <b>{t("common.titles.description")}</b>
                        </div>
                        <div style={{width:'20%'}} className="ms-2">
                            <b>{t("rates.amount")}</b>
                        </div>
                        <div style={{width:'25%'}} className="ms-2">
                            <b>{t("rates.clients_costs")}</b>
                        </div>
                        <div style={{width:'25%'}} className="ms-2">
                            <b>{t("rates.estros_costs")}</b>
                        </div>
                    </div>
                    {archiving.map(el => <OtherCostsItem
                            key={el.id}
                            item={el}
                            cost={archiving}
                            setCost={changeHandler}
                            amountType={el.entity}
                            amount={el.amount}
                            type={type}
                            removeItem={removeItem}
                            archive={true}
                        />
                    )}
                    <CountingUp entry={archiving} blockAmount={4} />
                </div>
            </div>
            <div className='row px-2 mt-2'>
                <p className="mt-3 mb-0" style={{textAlign:'right'}}>
                    <a onClick={handleReset}>{t("rates.reset_to_primary_values")}</a>
                </p>
                <div className="col-12">
                    <Link className="addGroup pt-1">
                        <LordIcon url='cross' color={`${Colors.red};`} rotate='45' target='.addGroup'/>
                        <span className="ps-2" onClick={addMoreChandler}>{t("common.button.add_more")}</span>
                    </Link>
                </div>
            </div>
        </>
    )
};
export default ArchivingCosts;