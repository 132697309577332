import { useTranslation } from "react-i18next";
import OtherCostsItem from "./OtherCostsItem";
import { Link } from "react-router-dom";
import LordIcon from "../ui/LordIcon";
import { Colors } from "../theme";
import {v4 as uuidv4} from "uuid";
import React from "react";
import CountingUp from "../../helpers/components/CountingUp";
import {getProjectSettings} from "../../services/api/user";

function AdministrationCosts ({administration, setAdministration, type, administrationReset, setAdministrationReset}) {
    const { t } = useTranslation();

    const addMoreChandler = () => {
        const newItem = {
            id: uuidv4(),
            description: '',
            client_cost: 0.00,
            estro_cost: 0.00,
            cost_type: type,
            edit: true,
            display_type: "X"
        };
        setAdministration([...administration, newItem])

    };

    const changeHandler = (val, cost_type, item) => {
        const costClone = structuredClone(administration);
        const indexDel = costClone.findIndex(el => (el.cost_type=type && el.id === item.id));
        const newItem = {
            id: item.id,
            description: cost_type === 'description' ? val : item.description,
            client_cost: cost_type === 'client_cost' ? val : item.client_cost,
            estro_cost: cost_type === 'estro_cost' ? val : item.estro_cost,
            cost_type: type,
            edit: item.edit,
            display_type: item.display_type
        };
        costClone.splice(indexDel, 1, newItem);
        setAdministration(costClone)
    };

    const removeItem = (itemKey) => {
        const newItem = structuredClone(administration);
        const indexDel = newItem.findIndex(el => el.id === itemKey);
        newItem.splice(indexDel, 1);

        setAdministration(newItem)
    };

    const handleReset = async () => {
        try {
            const responseSettings = await getProjectSettings()
            const result = responseSettings.data.other_cost_type.filter(
                type => type.cost_type === "administration"
            )
            const administration_data = []
            const administration_admin_work = administration.find(el => el.id === "ADMIN_WORK")
            if (administration_admin_work) {
                administration_data.push(administration_admin_work)
            } else {
                const data = {
                    client_cost: "0.00",
                    cost_type: false,
                    description: "Administrator work",
                    display_type: "archivro",
                    edit: true,
                    estro_cost: "0.00",
                    id: "ADMIN_WORK",
                }
                administration_data.push(data)
            }
            if (result) {
                result.forEach(res => {
                    const data = {
                        client_cost: parseFloat(res.client_cost).toFixed(2),
                        cost_type: false,
                        description: res.description,
                        display_type: "descro",
                        edit: true,
                        estro_cost: parseFloat(res.estro_cost).toFixed(2),
                        id: res.id,
                        readOnly: false,
                    }
                    administration_data.push(data)
                })
                // administration rest
                const adm_data_desc_list = administration_data.map(el => el.description)
                const adminis_rest = administration.filter(el => !adm_data_desc_list.includes(el.description))

                administration_data.push(...adminis_rest)
                setAdministration(administration_data)
                setAdministrationReset(!administrationReset) // przelicza ADMIN_WORK pozycje
            }
        } catch(ex) {
            console.log("Failed to download settings")
        }
    }

    return (
        <>
            <div className="row px-2">
                <div className="col-12">
                    <div className="d-flex flex-row align-items-center my-2">
                        <div style={{width:'46%'}}>
                            <b>{t("common.titles.description")}</b>
                        </div>
                        <div style={{width:'25%'}} className="ms-2">
                            <b>{t("rates.clients_costs")}</b>
                        </div>
                        <div style={{width:'25%'}} className="ms-2">
                            <b>{t("rates.estros_costs")}</b>
                        </div>
                    </div>
                    {administration.map(el =>
                        <OtherCostsItem
                            key={el.id}
                            item={el}
                            cost={administration}
                            setCost={changeHandler}
                            // disabled={el.edit ? '' : 'disabled'}
                            type={type}
                            removeItem={removeItem}
                        />
                    )}
                    <CountingUp entry={administration} blockAmount={3} />
                </div>
            </div>
            <div className='row px-2 mt-2'>
                <p className="mt-3 mb-0" style={{textAlign:'right'}}>
                    <a onClick={handleReset}>{t("rates.reset_to_primary_values")}</a>
                </p>
                <div className="col-12">
                    <Link className="addGroup pt-1">
                        <LordIcon url='cross' color={`${Colors.red};`} rotate='45' target='.addGroup'/>
                        <span className="ps-2" onClick={addMoreChandler}>{t("common.button.add_more")}</span>
                    </Link>
                </div>
            </div>
        </>
    )
};
export default AdministrationCosts;
        