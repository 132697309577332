import {request} from "../../helpers/request";
import {AuthenticationContext} from "../../contexts/AuthenticationContext";
import {useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button} from '../../components/ui/Button';
import { useTranslation } from "react-i18next";
import { LoginResponseErrors } from '../../helpers/MessagesErrors/responseErrors';

const loginAttempt = async (email, password, navigate) => {
    try {
        const response = await request.post(
            '/api/accounts/login',
            {'email': email, 'password': password}
        );

        if ('token' in response.data) {
            return {
                response: response.data,
                status: true,
                expiry: new Date(response.data.expiry).getTime() + response.data.token_ttl,
                token_ttl: response.data.token_ttl
            }
        } else {
            // show message error
            // console.log("RESP_FAIL", response.data)
            return {"response": [LoginResponseErrors.NotToken], "status": false}
        }
    } catch(error) {
        // console.log("ERROR:", error);
        if (error.request.status === 0) {
            navigate("/network-error");
        };

        const response = error.response;

        if (response.status === 400) {
            return {"response": response.data.error, "status": false}
        };

        return {"response": [LoginResponseErrors.UnknownError], "status": false};
    }
};

const LoginForm = ({setErrorsMessages}) => {
    const authContext = useContext(AuthenticationContext);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = async e => {
        e.preventDefault();
        const userData = await loginAttempt(e.target.email.value, e.target.password.value, navigate);

        // save to storage
        if (userData.status === true) {
            // save in localStorage
            Object.keys(userData.response).map((key) => {
                if (key === 'userRoles') {
                    localStorage.setItem(key, JSON.stringify(userData.response[key]));
                } else localStorage.setItem(key, userData.response[key])
            });
            localStorage.setItem("expiry", userData.expiry)
            localStorage.setItem("token_ttl", userData.token_ttl)

            // change in context
            authContext.userName = userData.response.username;
            authContext.currentUser = userData.response.username;  // nie potrzebne
            authContext.setCurrentUserId(userData.response.userid);
            authContext.tokenUser = userData.response.token;
            authContext.expiredToken = userData.expiry;
            authContext.token_ttl = userData.token_ttl;
            // set role in context
            authContext.setCurrentRole(userData.response.currentRole);
            const respUserRoles = userData.response.userRoles;
            authContext.setUserRoles(respUserRoles);
            // czyszczenie danych w formularzu ??????
            e.target.reset();
        } else {
            setErrorsMessages(userData.response);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input className='dataInput' placeholder={t("profile.email_address")} name="email" type="email" required/>
                <input className='dataInput' placeholder={t("profile.account_pw")} name="password" type="password" required/>
                <br/>
                <Link to={"/forgot-password"} style={{ float: 'right', marginBottom: '10px' }}>{t("common.button.forgott_pw")}</Link>
                <br/>
                <Button type='submit' bWidth='full'>{t("common.button.log_in")}</Button>
            </form>
        </div>
    )
};

export default LoginForm;
