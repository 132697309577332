import {Input, InputWrapperDiv} from "../../../../../components/ui/Input";
import React from "react";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {countryList} from "../../../../../helpers/dictionary/users";
import select_styles from "../../../../../layouts/elements";

const FormAddress = ({form, changeHandler, required=false}) => {
    const { t } = useTranslation()
    const req = required ? " *" : ""

 return(<>
     <div className="row pb-2">
         <InputWrapperDiv>
            {t("profile.street")+req}
            <Input
                name="street"
                value={form.street || ""}
                onChange={val => changeHandler(val, 'street')}
                required={required}
            />
         </InputWrapperDiv>
         <InputWrapperDiv>
            {t("profile.house_apartment_number")+req}
            <Input
                name="number"
                value={form.number || ""}
                onChange={val => changeHandler(val, 'number')}
                required={required}
            />
         </InputWrapperDiv>
     </div>

     <div className="row pb-2">
         <InputWrapperDiv>
            {t("profile.post_code")+req}
            <Input
                name="post_code"
                value={form.post_code || ""}
                onChange={val => changeHandler(val, 'post_code')}
                required={required}
            />
         </InputWrapperDiv>
         <InputWrapperDiv>
            {t("profile.city")+req}
            <Input
                name="place"
                value={form.place || ""}
                onChange={val => changeHandler(val, 'place')}
                required={required}
            />
         </InputWrapperDiv>
     </div>

     <div className="row pb-2">
         <InputWrapperDiv>
             <div className='customSelectParent'>
                 {t("profile.country")+req}
                 <Select
                    name='country'
                    options={countryList}
                    value={countryList.find(el => el.value === form.country)}
                    onChange={val => changeHandler(val.value, "country")}
                    styles={select_styles}
                    unstyled
                    required={required}
                 />
             </div>
         </InputWrapperDiv>
     </div>
 </>)
}

export default FormAddress;

