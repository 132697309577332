import { useTranslation } from "react-i18next";
import React from "react";
import ShowFormDataGroup from "../../../../../../components/form/ShowFormData";

export const BankDetailsShow = ({profile}) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="col-xxl-6">
                <ShowFormDataGroup label={t("profile.bank.name")} inputContent={ profile.bank_name }/>
                <ShowFormDataGroup label={t("profile.bank.account_no")} inputContent={ profile.bank_account_no }/>
                <ShowFormDataGroup label={t("profile.bank.bic")} inputContent={ profile.bic }/>
                <ShowFormDataGroup label={t("profile.bank.account_holder_name")} inputContent={ profile.bank_account_holder }/>
            </div>
         </>
    )


};

