import styled from "styled-components"

const FullPageBackgroundComponent = styled.div`
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -10;
    min-height: calc(100px + var(--original-viewport-height));

    ${props => {
        if (props.url) {
            return `
                background: url(${props.url}) no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            `}}
    }

    ${props => {
        if (props.fade) {
            return `
                opacity: ${props.fade}; 
            `}}
    }

    ${props => {
        if (props.colorize) {
            return `
                background-color: ${props.colorize}; 
            `}}
    }
`;

/**
 @param {{ 
    url: "provide url from bg image";
    opacity: "set opacity f.ex. 0.8";
    bgColor: "provide f.ex. a hex value";
 }} props
*/

const FullPageBackground = ({url, opacity, bgColor, children}) => {
    return (
        <FullPageBackgroundComponent
            url={url}
            fade={opacity}
            colorize={bgColor}
        >
            {children}
        </FullPageBackgroundComponent>
    )
}

export default FullPageBackground;