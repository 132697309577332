import {decimalValidate} from "../../../../../../helpers/validate";

function RatesEntriesSettings ({rates, setRates, ratesAll}) {
    const changeHandler = (val, fieldName) => {
        if (decimalValidate(val)) {
            const currentEntry = ratesAll[fieldName];
            currentEntry.value = val;

            const newEntry = {
                ...ratesAll,
                currentEntry
            };
            setRates(newEntry);
        }
    };

    return (
        <>
        {rates.map(el => { return (
                <div className="d-flex flex-row align-items-center my-2" key={el.key}>
                    <div style={{width:'50%'}}>
                        {el.label}
                    </div>
                    <div style={{width:'50%'}} className="ms-2">
                        <input
                            value={el.value}
                            onChange={val => changeHandler(val.target.value, el.name)}
                            style={{width:'100%', maxWidth:'70px'}}
                        /> €
                    </div>
                </div>
        )})}
        </>
    )
};
export default RatesEntriesSettings;
        