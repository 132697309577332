import React from "react";
import {useTranslation} from "react-i18next";
import {TextStyle, TitleStyle} from "../../../../../../layouts/styles";
import Address from "../Offer/Address";


const FirmDetails = (props) => {
    const { t } = useTranslation()
    const details = props.details

    return (<>
        <div className="row px-1">
            <div className="col-6">
                <p style={TitleStyle}>
                    {t("company.name")}
                </p>
                <p style={TextStyle}>
                    {details.name}
                </p>
            </div>
            <div className="col-6">
                <p style={TitleStyle}>
                    {t("company.type")}
                </p>
                <p style={TextStyle}>
                    {details.type.name}
                </p>
            </div>
        </div>
        <Address details={details}/>
        <div className="row px-1">
            <div className="col-12">
                {props.children}
            </div>
        </div>
    </>)

}

export default FirmDetails

