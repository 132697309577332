import PopupForm from "../../../../../components/ui/PopupForm";
import DarkCard from "../../../../../components/card/DarkCard";
import LightCard from "../../../../../components/card/LightCard";
import RedHeader from "../../../../../components/ui/RedBgTitleHeader";
import FormClient from "./FormClient";
import {Input, InputWrapperDiv} from "../../../../../components/ui/Input";
import Select from "react-select";
import select_styles from "../../../../../layouts/elements";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRemove} from "@fortawesome/free-solid-svg-icons";
import {Button} from "../../../../../components/ui/Button";
import {Colors} from "../../../../../components/theme";
import React from "react";
import {useTranslation} from "react-i18next";
import {actionTypes} from "./ReducerClient";


const ClientNewEditView = (props) => {
    const {dispatch, params, popup, breadCrumbs, formData, handleSave, deleteHandler,
        handleCreateFirm, firms_not_selected, pathReturn, isOfferSession} = props
    const { t } = useTranslation();

    const changeHandler = (val, fieldName, firm_pk) => {
        if (fieldName === 'responsible') {
            const formFirmsCp = structuredClone(formData.firms)
            const firmSelectedObj = formFirmsCp.find(el => el.firm_pk === firm_pk)
            if (firmSelectedObj) {
                firmSelectedObj.info = val
                dispatch({type: "SET_CLIENT_DATA", data: {fieldName: "firms", value: formFirmsCp}});
            }
        } else if (fieldName === 'firms') { // dodaj z firms do form.firms - usun z firms
            if (val.value === 0) return
            // dodaj do form.firms
            const clientFirmsCp = structuredClone(formData.firms)
            clientFirmsCp.push({
                info: "",
                firm_name: val.label,
                firm_pk: val.value,
            })
            // usun z firms_not_selected
            const firmsCp = structuredClone(firms_not_selected)
            const withOutVAL = firmsCp.filter(el => el.value !== val.value)
            dispatch({type: "SET_CLIENT_DATA", data: {fieldName: "firms", value: clientFirmsCp}});
            dispatch({type: "SET_FIRMS", firms_not_selected: withOutVAL});
        } else {
            dispatch({type: "SET_CLIENT_DATA", data: {fieldName: fieldName, value: val}});
        }
    }

    const handlePopup = () => {
        if (popup.variant === "success") {
            dispatch({type: "SET_POPUP", popup: {show: false}});
            if ((pathReturn && pathReturn.length > 0) || isOfferSession) {
                dispatch({type: "SET_ACTION", action: "pathReturn"});
                return
            }
            dispatch({type: "SET_ACTION", action: "details"})
        } else {
            dispatch({type: "SET_POPUP", popup: {show: false}});
        }
    }

    const handleCancel = () => {
        if ((pathReturn && pathReturn.length > 0) || isOfferSession) {
            dispatch({type: "SET_ACTION", action: "pathReturn"});
        } else dispatch({
            type: "SET_ACTION",
            action: params.action === actionTypes.create ? actionTypes.list : actionTypes.details
        })
    }

    return (<>
        {popup.show
            ? <PopupForm
                handlePopup={handlePopup}
                variant={popup.variant}
                title={t(popup.title)}
                message={t(popup.message)}
                leftBtn={popup.leftBtn}
                rightBtn={popup.rightBtn}
                okBtn={popup.okBtn}
                context={popup.context}
            />
            : <>
                <form name="client" onSubmit={handleSave}>
                    <DarkCard classNames='mb-3 row'>
                        {breadCrumbs()}
                    </DarkCard>
                    <DarkCard classNames='mb-3 row'>
                        {params.key ?
                            <h1 className="mt-0 p-0">
                                {t("clients.contact_edit_page_title")}
                            </h1>
                            :
                            <h1 className="mt-0 p-0">
                                {t("clients.contact_add_page_title")}
                            </h1>
                        }
                        <div className="col-lg-6 col-xxl-4 mb-4 ps-0">
                            <LightCard>
                                <RedHeader title={t("clients.details_title")}/>
                                <FormClient
                                    form={formData}
                                    changeHandler={changeHandler}
                                />
                            </LightCard>
                        </div>

                        <div className="col-lg-6 col-xxl-4 mb-4">
                            <LightCard>
                                <RedHeader title={t("clients.additionals")}/>
                                <div className="row pb-2">
                                    <div>
                                        {t("profile.note")}
                                        <textarea
                                            name="note"
                                            value={formData.note}
                                            onChange={val => changeHandler(val.target.value, 'note')}
                                            style={{width: '100%'}}
                                        />
                                    </div>
                                </div>
                                <InputWrapperDiv>
                                    <div className="customSelectParent" style={{position: 'relative'}}>
                                        {t("profile.select_company")}
                                        <Select
                                            name='firmsSelect'
                                            options={firms_not_selected}
                                            value=""
                                            onChange={val => changeHandler(val, 'firms')}
                                            styles={select_styles}
                                            unstyled
                                        />
                                    </div>
                                    <div>
                                        {formData.firms &&
                                            formData.firms.map(firm => (
                                                <div key={firm.firm_pk} className="mt-3" style={{backgroundColor:'rgba(255,255,255,0.8)', padding:'10px', borderRadius:'15px'}}>
                                                    <div className="row pb-2">
                                                        <InputWrapperDiv>
                                                            {t("profile.responsibility")} in &nbsp;
                                                            <a
                                                                name="firmSelName"
                                                                onClick={() => deleteHandler(firm.firm_pk)} >
                                                                {firm.firm_name} &nbsp;
                                                                <FontAwesomeIcon icon={faRemove} />
                                                            </a> :
                                                            <Input
                                                                name="responsible"
                                                                value={firm.info}
                                                                onChange={val => changeHandler(
                                                                    val, 'responsible', firm.firm_pk
                                                                )}
                                                            />
                                                        </InputWrapperDiv>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </InputWrapperDiv>
                                <div style={{position:'relative', overflow:'hidden'}}>
                                    <Button onClick={handleCreateFirm} variant="round" style={{float:'right'}}>
                                        {t("clients.create_new_comp")}
                                    </Button>
                                </div>
                            </LightCard>
                        </div>
                        <div className="ps-0">
                            <Button
                                type="submit"
                                style={{backgroundColor:`${Colors.green}`}}
                                variant="round"
                                className={'me-3'}
                            >
                                {t("common.button.save")}
                            </Button>
                            <Button
                                variant="round"
                                onClick={handleCancel}
                                styling="bordered"
                                className={'me-3'}
                            >
                                {t("common.button.cancel")}
                            </Button>
                        </div>
                    </DarkCard>
                </form>
            </>}
    </>)
}

export default ClientNewEditView

