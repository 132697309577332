import React, {useEffect, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {clientCreateAPI, clientDetailAPI, clientUpdateAPI, firmsListAPI} from "../../../../../services/api/clients";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {phoneCountryList, popupData} from "../../../../../helpers/dictionary/users";
import {routesClientsBreadCrumbs} from "../../../../../helpers/breadCrumbs";
import {actionTypes, clientReducer, field_list, INITIAL_DOC, validationRules} from "./ReducerClient";
import ClientNewEditView from "./ClientNewEditView";
import {validate} from "../../../../sign_up/components/validations";
import {useSessionStorage} from "usehooks-ts";


const clientSave = async (data, id) => {
    let response
    try {
        if (id) {
            response = await clientUpdateAPI(data, id)
        } else {
            response = await clientCreateAPI(data)
        }
        if (response.status === 201 || response.status === 204) {
            return {popupData: popupData.saveOK, id: response.data.client_pk}
        }
    } catch (e) {
        console.log("Failed to download clients list", e);
        return {popupData: popupData.saveError}
    }
}

const optionsFormat = (elem) => {
    return { label: elem.name, value: elem.id }
}

const firmList = async (params) => {
    try {
        if (params) {
            const [firmsData, clientDetailData] = await Promise.all([firmsListAPI(), clientDetailAPI(params)])
            const firms = firmsData.data.map(
                el => { return optionsFormat(el) }
            )
            return {firmsData: firms, clientDetailData: clientDetailData.data}
        }
        const response = await firmsListAPI()
        const firms_list_options = await response.data.map(
            el => { return optionsFormat(el) }
        )
        return firms_list_options
    } catch (e) {
        console.log("Failed to download clients list", e);
    }
}


const ClientManager = () => {
    const [state, dispatch] = useReducer(clientReducer, INITIAL_DOC, undefined)
    const params = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const { t } = useTranslation()
    const [storageData, setStorageData] = useSessionStorage(
        "client-data", JSON.parse(localStorage.getItem("client-data"))
    );
    const [offerSessionData, setOfferSessionData] = useSessionStorage(
        "offer-data", JSON.parse(localStorage.getItem("offer-data"))
    );

    useEffect(() => {
        const importDataState = async () => {
            const dataResult = await firmList(params.key)
            if (params.key) {
                const detailAPI = () => {
                    try {
                        const details = dataResult.clientDetailData
                        details.phoneCountry = phoneCountryList.find(el => el.value === details.phoneCountry)
                        const Allfirms = dataResult.firmsData
                        const firmsNotSelected = Allfirms.filter(
                            el => !details.firms.some(({firm_pk}) => firm_pk === el.value )
                        )
                        dispatch({
                            type: "INIT_CLIENT_DATA",
                            formData: details,
                            firms_not_selected: firmsNotSelected,
                        })
                    } catch (e) {
                        console.log("Failed to download client detail", e);
                    }
                }
                detailAPI()
            } else {
                dispatch({
                    type: "INIT_CLIENT_DATA",
                    formData: field_list,
                    firms_not_selected: dataResult,
                })
            }
        }
        //////////////////  SET BREADCRUMBS - INITIAL
        if (storageData) {
            dispatch({
                type: "INIT_CLIENT_DATA",
                formData: storageData.formData,
                firms_not_selected: storageData.firms_not_selected,
            })
            dispatch({
                type: "SET_ACTION_BREADCRUMBS",
                action: storageData.action,
                breadCrumbsParams: storageData.breadCrumbsParams,
            })
        }
        else if (offerSessionData) {
            dispatch({
                type: "SET_PATH_RETURN",
                pathReturn: offerSessionData.pathReturn
            })
            dispatch({
                type: "SET_ACTION_BREADCRUMBS",
                action: offerSessionData.action,
                breadCrumbsParams: offerSessionData.breadCrumbsParams,
            })
            importDataState()
        }
        else { // bez sesji
            dispatch({
                type: "SET_ACTION_BREADCRUMBS",
                action: params.action,
                breadCrumbsParams: params.action === "edit"
                    ? ['clients_contacts_edit_contact', params.key]
                    : ['clients_contacts_add_contact',],
            })
            importDataState()
        }

        if (storageData && storageData.pathReturn.length === 0) {
            setStorageData(null)
        }
    }, [])

    useEffect(() => {
        if (!state.action) return
        if (state.action === actionTypes.list) navigate('/clients/contacts/')
        if (state.action === actionTypes.details) navigate('/clients/contacts/details/'+state.formData.id)
        if (state.action === actionTypes.pathReturn) {
            if (storageData && storageData.pathReturn.length > 0) {
                const lastPathReturn = storageData.pathReturn.pop()
                navigate(lastPathReturn)
            } else if (offerSessionData && offerSessionData.pathReturn.length > 0) {
                const lastPathReturn = offerSessionData.pathReturn.pop()
                navigate(lastPathReturn)
            }
            else navigate('/clients/contacts/')
        }
    }, [state.action])

    useEffect(() => {
        const handleRouterChange = () => {
            sessionStorage.removeItem('client-data')
        }
        return () => {
            const storedData = JSON.parse(sessionStorage.getItem('client-data'))
            if (storedData && storedData.pathReturn.length === 0) handleRouterChange()
        }
    }, [location])

    const handlerDelete = (val) => {
        const formFirmsCP = structuredClone(state.formData.firms)
        const formFirmUpdate = formFirmsCP.filter(firm =>firm.firm_pk !== val)

        const firmsItem = formFirmsCP.find(firm => firm.firm_pk === val)
        const firmsCp = structuredClone(state.firms_not_selected)
        firmsCp.push({
            label: firmsItem.firm_name,
            value: firmsItem.firm_pk,
        })
        dispatch({type: "SET_CLIENT_DATA", data: {fieldName: "firms", value: formFirmUpdate}});
        dispatch({type: "SET_FIRMS", firms_not_selected: firmsCp});
    }

    const handleSave = async (event) => {
        event.preventDefault()
        const error = Object.entries(state.formData).some(([key, value]) => {
            const test = validate(validationRules[key], value);
            if (test) {
                return true
            }
        })
        if (error) {return}

        const phone = `${state.formData.phoneCountry.value}-${state.formData.telephone}`

        const response = await clientSave({
            ...state.formData,
            telephone: phone,
        }, params.key)

        if (response.popupData.variant === 'success') {
            dispatch({type: "SET_CLIENT_DATA", data: {fieldName: "id", value: response.id}})
            dispatch({type: "SET_POPUP", popup: response.popupData})

        } else dispatch({type: "SET_POPUP", popup: {show: false}})
    }

    const handleCreateFirm = () => {
        let path_return = []

        if (storageData && storageData.pathReturn) {
            path_return = structuredClone(storageData.pathReturn)
        }

        path_return.push("/"+params['*'])

        let bread_crumbs_firm = []
        if (offerSessionData) {
            const calCID = offerSessionData.breadCrumbsParams[4]
            bread_crumbs_firm = state.action === 'create'
                ? ['offer_add_contact_add_company', 0, 0, 0, calCID]
                : ['offer_edit_contact_add_company', 0, 0, 0, calCID]
        } else {
            bread_crumbs_firm = state.action === 'create'
                ? ['clients_contacts_add_contact_add_company', params.key]
                : ['clients_contacts_edit_contact_add_company', params.key]
        }

        let level_up = state.breadCrumbsParams
        setStorageData({...state, pathReturn: path_return, breadCrumbsParams: bread_crumbs_firm, breadCrumbsLevelUp: level_up})
        navigate("/clients/companies/create")
    }

    const breadCrumbsManager = () => {
        return routesClientsBreadCrumbs(t, ...state.breadCrumbsParams)
    }

    return (<ClientNewEditView
        dispatch={dispatch}
        breadCrumbs={breadCrumbsManager}
        handleSave={handleSave}
        deleteHandler={handlerDelete}
        handleCreateFirm={handleCreateFirm}
        popup={state.popup}
        params={params}
        formData={state.formData}
        firms_not_selected={state.firms_not_selected}
        pathReturn={state.pathReturn}
        isOfferSession={!!offerSessionData}
    />)
}

export default ClientManager

