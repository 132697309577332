import { Colors } from "../../theme";
import LordIcon from "../LordIcon";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const PopupEntryStyle = styled.div`
    background-color: ${Colors.white};
    margin: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    color: ${Colors.black};

`

function PopupEntry ({content}){
    const { t } = useTranslation();
    return (
        <PopupEntryStyle className="d-flex flex-row mb-2 entry">
            <div style={{width:'92%', textAlign:'left'}}>
                {content}
            </div>
            <div style={{width:'8%'}} className="align-self-center">
                <LordIcon url='arrow' color={`${Colors.darkRed}`} rotate='-95' target='.entry'/>
            </div>
        </PopupEntryStyle>
    )
}

export default PopupEntry;