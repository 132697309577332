import {Route, Routes} from "react-router-dom";
import ProjectCard from "../Projects/Projects/ProjectCard";
import ProjectsList from "../Projects/Projects/ProjectsList";
import Offer from "../Projects/Offer/Offer";
import QuoteClientForm from "../Projects/Projects/QuoteClientForm";
import React from "react";
import {useTranslation} from "react-i18next";
import QuoteLinkNotActive from "../Projects/Projects/QuoteLinkNotActive";

const ProjectsRoutes = () => {
    const {t} = useTranslation();

    return (<>
        <Routes >
            <Route exact path={"/projects/offer/:key"} element={<Offer />} />
            <Route exact path={"/projects/in-preparation"} element={<ProjectsList />}/>
            <Route exact path={"/projects/in-preparation/:id"} element={<ProjectCard />}/>
            <Route
                exact
                path={"/projects/client-quote-accept/:quote_link"}
                element={<QuoteClientForm />}
            />
            <Route exact path={"/project/quote-link/not-active"} element={<QuoteLinkNotActive />}/>
        </Routes>
    </>)
}

export default ProjectsRoutes