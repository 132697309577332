import {Colors} from "../components/theme";

const select_styles = {
    container: state => ({
        position: "relative",
        margin: "5px 0px",
        backgroundColor: "white",
        padding: "2.5px 0px",
        border: state.isFocused
            ? "1px solid " + Colors.darkRed
            : "1px solid " + Colors.darkGray,
        "&:hover": {
            borderColor: Colors.darkRed,
        },
        "div[id$='listbox']": {
            position: "relative",
        },
    }),
    valueContainer: (baseStyles, state) => ({
        ...baseStyles,
        padding: "4px 10px",
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        color: Colors.darkGray,
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        padding: state.isDisabled ? "5px 5px" : "5px 15px",
        "&:hover": {
            borderLeft: state.isDisabled ? "" : "10px solid" + Colors.darkRed,
            paddingLeft: state.isDisabled ? "5px" : "5px",
            backgroundColor: state.isDisabled ? "" : Colors.lightBG,
            cursor: state.isDisabled ? "" : "pointer",
        },
        // color: state.isDisabled ? Colors.lightGray : Colors.darkGray,
        color: state.data.color,
        borderTop: "1px solid" + Colors.lightGray,
    }),
    multiValue: baseStyles => ({
        ...baseStyles,
        backgroundColor: Colors.red,
        color: Colors.white,
        margin: "5px 10px 5px 0px",
        "&:hover": {
            //backgroundColor: Colors.darkRed,
            cursor: "pointer",
        },
    }),
    multiValueLabel: baseStyles => ({
        padding: "2px 5px",
    }),
    multiValueRemove: baseStyles => ({
        ...baseStyles,
        padding: "2px 5px",
        backgroundColor: Colors.darkRed,
        "&:hover": {
            backgroundColor: Colors.darkGray,
        },
    }),
};

export default select_styles;
