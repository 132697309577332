import React from "react";

export function Input(props) {
    if (props.type === 'number') {
        return (
            <input
                className={props.className}
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                placeholder={props.placeholder}
                name={props.name}
                type={props.type}
                error={props.error}
                required={props.required}
                readOnly={props.readonly}
                style={props.style}
            />
        )
    }

    if (props.type === 'text' || props.type === 'email') {
        return (
            <input
                className={props.className}
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                placeholder={props.placeholder}
                name={props.name}
                type={props.type}
                required={props.required}
                readOnly={props.readonly}
                // disabled={props.disabled}
                style={props.style}
            />
        )
    }
    if (props.type === 'date') {
        return (
            <input
                className={props.className}
                defaultValue={props.value}
                onChange={e => props.onChange(e.target.value)}
                placeholder={props.placeholder}
                name={props.name}
                type={props.type}
                required={props.required}
                disabled={props.disabled}
                style={props.style}
            />
        )
    }
    if (props.type === 'select') {
        return (
            <select
                defaultValue={props.value}
                onChange={e => props.onChange(e.target.value)}
                className={props.className}
                checked={props.value}
                name={props.name}
                required={props.required}
                style={props.style}
                >
                {props.options.map(option =>
                    <option key={option.value} value={option.value}>{option.label}</option>
                )}
            </select>
        )
    }
    if (props.type === 'radio') {
        return (
            <div>
                {props.options.map(option => (
                        <div key={`radio_${option.value}`}>
                        <input
                            type="radio"
                            name={props.name || "musicianStatus"}
                            id={`radio-${option.value}-${props.name}`}
                            defaultValue={option.value}
                            checked={props.value === option.value}
                            onChange={e => props.onChange(e.target.value)}
                        />
                        <label htmlFor={`radio-${option.value}-${props.name}`}> {option.label}</label>
                        <br />
                        </div>
                ))}
            </div>
        )
    }
    if (props.type === 'textarea') {
        return (
            <div key={`textarea_${props.value}`}>
                <textarea
                    rows={props.rows}
                    className={props.className}
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                    placeholder={props.placeholder}
                    name={props.name}
                    required={props.required}
                    readOnly={props.readonly}
                    style={props.style}
                />
            </div>
        )
    }
};

Input.defaultProps = {
    type: 'text',
    className: 'dataInput',
    value: '',
    required: 'required',
    error: "",
};

function InputWrapperDiv({ addClass, children }) {
    addClass = (addClass===undefined ? '' : ' '+addClass);
    return (
        <div className={'col'+ addClass}>
            {children}
        </div>
    )
};

const styleStaffClass = {
    outDiv: "d-flex flex-row align-items-center my-1",
    inputDiv: "ms-2",
};

const styleStaffStyle = {
    labelDiv: {width:'60%'},
    inputDiv: {width:'50%'},
    inputAttr: {width:'100%', maxWidth:'70px'},
};

const styleStaff = {
    outDiv: {
        class: styleStaffClass.outDiv,
    },
    labelDiv: {
        style: styleStaffStyle.labelDiv,
    },
    inputDiv: {
        class: styleStaffClass.inputDiv,
        style: styleStaffStyle.inputDiv
    },
    inputAttr: {
        class: '',
        style: styleStaffStyle.inputAttr
    }
};


function InputBlock ({fieldName, handler, defaultValue, t}) {

    return (
        <InputWrapperStyleDiv styleStaff={styleStaff} labelText={t(fieldName.label)} defaultValue={defaultValue} >
            <Input
                name={fieldName.name}
                value={fieldName.value}
                onChange={val => handler(val, fieldName.name)}
                style={styleStaff.inputAttr.style}
                className={''}
                defaultValue={defaultValue}
            />
        </InputWrapperStyleDiv>
    )
};
function InputWrapperStyleDiv({ styleStaff, labelText, children }) {
    return (
        <div className={styleStaff.outDiv.class}>
            <div style={styleStaff.labelDiv.style}>
                {labelText}
            </div>
            <div style={styleStaff.inputDiv.style} className={styleStaff.inputDiv.class}>
                {children}
            </div>
        </div>
    )
};

export { InputWrapperDiv, InputWrapperStyleDiv, InputBlock }
