import DarkCard from "../../../../../../components/card/DarkCard";
import {useTranslation} from "react-i18next";
import PersonalDataForm from "./PersonalDataForm";
import BankDetailsForm from "./BankDetailsForm";
import React, {useEffect, useState} from "react";
import {defaultFormPD, defaultFormBank, defaultFormInstr} from "../../../../../../helpers/components/user/registrationMapData";
import { personal_data_to_formular, bank_details_to_formular, instruments_to_formular } from "../../../../../../helpers/account/profile";
import InstrumentsForm from "./InstrumentsForm";
import {Button} from "../../../../../../components/ui/Button";
import {
    getInstrumentsListAPI,
    getProfileDraftAPI,
    profileToDraftAPI,
    draftToProfileAPI
} from "../../../../../../services/api/user";
import {useNavigate} from "react-router-dom";
import {languagesListAPI} from "../../../../../../helpers/getLanguages";
import Breadcrumbs from "../../../../../../components/Breadcrumbs";
import PopupMessage from "../../../../../../components/ui/PopupMessage";
import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";


const EditView = () => {
    const { t } = useTranslation();
    const [profile, setProfile] = useState();
    const [languages, setLanguages] = useState([]);
    const [formPersonalData, setFormPersonalData] = useState(defaultFormPD);
    const [formBD, setFormBD] = useState(defaultFormBank);
    const [formInstr, setFormInstr] = useState(defaultFormInstr);
    const [instrumentsList, setinstrumentsList] = useState([]);
    const [breadCrumbs, setBreadCrumbs] = useState(["Profile", "Personal Data", "Edit"]);
    const [popup, setPopup] = useState(false);
    const [popupDecision, setPopupDecision] = useState(null); /* null|false|true */
    const [draftData, setDraftData] = useState(null);

    const navigate = useNavigate();
    // const location = useLocation();

    const handleData = (type) => {
        if(draftData === null) {
            if (type === 'PersonalData') {
                return formPersonalData;
            } else if (type === 'BankDetails') {
                return formBD
            }
            return formInstr
        } else {
            if(type === 'PersonalData') {
                return personal_data_to_formular(draftData);
            } else if(type === 'BankDetails') {
                return bank_details_to_formular(draftData['bank_details']);
            }
            return instruments_to_formular(draftData['instruments']);
        }
    }

    useEffect(() => {
        const loadProfileData = async () => {
            let draftDataResponse = {};
            try {
                const profileDraftResponse = await getProfileDraftAPI(/* z request bierzemy user */);
                draftDataResponse = profileDraftResponse.data;
                const containsDraft = draftDataResponse !== '';

                if(containsDraft) {
                    setPopup(true);
                    setDraftData(draftDataResponse);
                    return;
                } else {
                    const profile_to_draft = await profileToDraftAPI();
                    // wrzuc dane do formularza z profiu

                    setDraftData(profile_to_draft.data);
                }

            } catch(ex) {
                console.error("Nie udalo sie pobrac draftu...");
                return
            };

        }

        languagesListAPI().then(resp => {
            setLanguages(resp);
        });

        getInstrumentsListAPI().then(resp => {
            setinstrumentsList(resp.data);
        });

        loadProfileData()
    }, []);


    useEffect(() => {
        const handleDraft = async () => {
            if (draftData !== '' && !popup && popupDecision) { // pobierz z draftProfile
                // console.log('DRAFT DATA nie popup i popupDecision na Yes ', popup, popupDecision);
            } else if (!popup && popupDecision === false) { // pobierz z profilu
                const profile_to_draft = await profileToDraftAPI();
                setDraftData(profile_to_draft.data);
            };
        };

        handleDraft();
     }, [popup, popupDecision]);

    const handleSave = async () => {
        // const js_data = jsonProfileSave(formPersonalData, formBD, formInstr);
        try {
            const response = await draftToProfileAPI();
            // setEditProfile(false);
            navigate('/profile/personal-data');
        } catch(ex) {
            console.error("Nie udalo sie pobrac draftu...");
            return
        }
    };

    const handlePopup = (obj) => {
        setPopup(obj.show);
        setPopupDecision(obj.decision);
    }

    return (<>
        { popup
            ? <PopupMessage
                setPopup={handlePopup}
                variant='error'
                title={t("popups.import_data.title")}
                message={t("popups.import_data.message")}
                leftBtn={t("common.boolean.no")}
                rightBtn={t("common.boolean.yes")}
            />
            : <>
            <Breadcrumbs
                breadCrumbs={breadCrumbs}
                setBreadCrumbs={setBreadCrumbs}
                editProfile={true}
            />
            <DarkCard className='container-fluid'>
                <div className='row align-items-center'>
                    <div className='col-xxl-8'>
                        <RedHeader title={t("registration.subcategories.personal_data")}/>
                        <PersonalDataForm form={handleData('PersonalData')} setForm={setFormPersonalData} />
                    </div>
                </div>
                <div className='row align-items-center'>
                    <div className='col-xxl-8'>
                        <RedHeader title={t("registration.subcategories.bank_details")}/>
                        <BankDetailsForm form={handleData('BankDetails')} setForm={setFormBD} />
                    </div>
                </div>
                <div className='row align-items-center'>
                    <div className='col-xxl-8'>
                        <RedHeader title={t("registration.subcategories.instruments")}/>
                        <InstrumentsForm form={handleData('Instruments')} setForm={setFormInstr} instrumentsList={instrumentsList}/>
                    </div>
                </div>
                <div className='row align-items-center'>
                    <div className='col-xxl-8 mt-4'>
                        <Button onClick={handleSave} >{t("common.button.save_changes")}</Button>
                    </div>
                </div>
            </DarkCard>
            </>
        }
    </>)
};

export default EditView;
