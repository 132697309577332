import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Accordion from "../../components/accordion/Accordion";
import { Button } from "../../components/ui/Button";
import { ErrorMessages } from "../../components/ui/Feedback_Message";
import acc_content from "./signUp_accordion_data";
import { postDataAPI } from "../../helpers/request";
import {jsonRegistrationData} from "./utils/registerFormToAPI";
// import {wait} from "@testing-library/user-event/dist/utils";
import importDataFromAPI from "./utils/registerImportFromAPI";
import {getInstrumentsListAPI} from "../../services/api/user";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";


export default function SignUp() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [auth, setAuth] = useAuth()
	const [filledState, setFilledState] = useState(false);
	const [filledPersonalData, setFilledPersonalData] = useState(false);
	const [filledBankData, setFilledBankData] = useState(false);
	const [filledInstrData, setFilledInstrData] = useState(false);
	const [formSaveState, setFormSaveState] = useState({});
	const [instrumentsData, setInstrumentsData] = useState([]);
	const params = useParams();

	useEffect(() => {
		if (auth) navigate("/dashboard");
	}, [auth]);

	useEffect(() => {
		if (params.key) {
			//pobierz dane z api do localStorage
			importDataFromAPI(params.key, navigate);
		}
	}, [params]);

	useEffect(() => {
		const getInstruments = async () => {
			try {
				const response = await getInstrumentsListAPI();

				if (response.data === false) {
					navigate("/network-error");
				}
				setInstrumentsData(response.data);

			} catch(ex) {
				console.error("Nie udalo sie pobrac listy instrumentow...");
				return
			};

		};
		getInstruments();
	}, []);

	useEffect(() => {
		setFilledState(filledPersonalData && filledBankData && filledInstrData);
	}, [filledPersonalData, filledBankData, filledInstrData]);

	const saveRegistrationData = async () => {
		if (!formSaveState.hasOwnProperty("messages")) {
			const js_data = jsonRegistrationData();

			const response = await postDataAPI("api/accounts/register", js_data);

			if (response.status !== 200 && Object.keys(response).length > 0) {
				setFormSaveState(response);
			} else if (response.status === 200) {
				navigate("/success-registration");
			}
		}
	};

	const handleSubmit = async e => {
		e.preventDefault();
		// setLoading(true);
		saveRegistrationData();
	};

	return (
		<div className=''>
			<h1 className='underline'>
				{t("registration.title")}
			</h1>

			<form onSubmit={handleSubmit}>
				<Accordion
					content={acc_content(t)}
					setFilledPersonalData={setFilledPersonalData}
					setFilledBankData={setFilledBankData}
					setFilledInstrData={setFilledInstrData}
					instrumentsData={instrumentsData}
					setInstrumentsData={setInstrumentsData}
				/>
				{Object.keys(formSaveState).length > 0 ? (
					<ErrorMessages formSaveState={formSaveState} />
				) : null}
				<Button
					type='submit'
					onClick={handleSubmit}
					bWidth='full'
					disabled={!filledState ? "disabled" : null}>
					{t("registration.btn_confirm_registration")}
				</Button>
			</form>
		</div>
	);
}
