import React from "react";
import {useTranslation, Trans} from "react-i18next";
import styled from "styled-components"
import { Colors } from "../../../../../../components/theme";

const types_sum = ['musicians','renting','transport','administration','archiving']

const TableHeader = styled.span`
    font-weight: bold;
    color: ${Colors.darkRed};
`

const Summary = ({typeSum, data}) => {
    return (
        <>
            {data.client_cost > 0 && (
            <tr>
                <td className="px-2">{typeSum}</td>
                <td className="px-2 txtRight">{parseFloat(data.client_cost).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €</td>
            </tr>
            )}
        </>
    )}


export default function QuoteBudget({projectData}) {
    const {t} = useTranslation()

    // Przechowujemy licznik i mapujemy typy na ich liczby
    let explanationCounter = 1;
    const typeWithSuperscript = {};

    // Tworzymy mapę typów z liczbami, ale tylko dla typów, które mają wartość client cost > 0
    types_sum.forEach(type => {
        const value = projectData.budget[type].client_cost;
        if ((type === "administration" || type === "renting") && value && value > 0) {
            typeWithSuperscript[type] = explanationCounter;
            explanationCounter++;
        }
    });

    return (<>
        <div>
            <table>
                <thead>
                    {projectData.showEstroGrant &&
                        <>
                        <tr>
                            <td className="px-2 py-2"><b>{t('offer.pdf_document.texts.real_project_costs')}</b></td>
                            <td className="px-2 py-2 txtRight"><b>{parseFloat(projectData.budget.total.estro_cost).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €</b></td>
                        </tr>
                        <tr>
                            <td className="px-2 py-2" style={{ fontSize: '14px', borderBottom:'1px solid #ccc' }} colSpan="2">
                                <Trans
                                    i18nKey="offer.pdf_document.texts.real_project_costs_desc"
                                    values={{ grantEstro: `${parseFloat(projectData.budget.grantEstroSum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}` }}
                                    components={{ strong: <strong /> }}
                                />
                            </td>
                        </tr>
                        </>
                    }
                    <tr>
                        <td className="px-2 py-2"><TableHeader>{t('project.service_title')}</TableHeader></td>
                        <td className="px-2 py-2 txtRight"><TableHeader>{t('project.client_costs_title')}</TableHeader></td>
                    </tr>
                </thead>
                <tbody>
                    {types_sum.map((type, index) => (<Summary
                        key={index}
                        typeSum={
                            typeWithSuperscript[type]
                            ? (
                                <>
                                    {t(`offer.pdf_document.texts.${type}`)}
                                    <sup> {typeWithSuperscript[type]}</sup>
                                </>
                            )
                            : t(`offer.pdf_document.texts.${type}`)
                        }
                        data={projectData.budget[type]}
                    />))}
                </tbody>
                <tfoot>
                <tr>
                    <td className="px-2 py-2"><b>{t('project.budget_total')}</b></td>
                    <td className="px-2 py-2 txtRight"><b>{parseFloat(projectData.budget.total.client_cost).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €</b></td>
                </tr>
                <tr>
                    <td className="px-2 pt-2" style={{ fontSize: '14px', borderTop:'1px solid #ccc' }} colSpan="2">
                        {typeWithSuperscript["renting"] && (
                            <>
                                <sup>{typeWithSuperscript["renting"]}</sup>
                                {t("offer.pdf_document.texts.renting_message")}
                                <br/>
                            </>
                        )}

                        {typeWithSuperscript["administration"] && (
                            <>
                                <sup>{typeWithSuperscript["administration"]}</sup> 
                                {t(`offer.pdf_document.texts.admin_message`)}
                            </>
                        )}
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    </>)
}