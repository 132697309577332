// Tutorial https://blog.logrocket.com/how-to-build-a-react-accordion-menu-from-scratch/

import styled from "styled-components"
import AccordionItem from "./Accordion_Item";
import { useState } from "react";

const UlComp = styled.ul`
    list-style: none;
    padding: 0;
`;

const Accordion = ({content,setFilledPersonalData,setFilledBankData,setFilledInstrData,instrumentsData}) => {
    const [clicked, setClicked] = useState(0);

    const handleToggle = (index, option="") => {
      if (option === "next") {
        setClicked(index);
      } else if (index < clicked) {
            setClicked(index)
        }
    };

    return (
      <UlComp className='container'>
        {content.map(content_item => (
          <AccordionItem
            key={content_item.id}
            goToNext={() => handleToggle(content_item.id + 1, "next")}
            last={content_item.id === content.length - 1}
            acc_section={content_item}
            onToggle={() => handleToggle(content_item.id)}
            active={clicked === content_item.id}
            setFilledPersonalData={setFilledPersonalData}
            setFilledBankData={setFilledBankData}
            setFilledInstrData={setFilledInstrData}
            instrumentsData={instrumentsData}
            clicked={clicked}
            itemId={content_item.id}
          />
        ))}
      </UlComp>
    );
  };

export default Accordion;