import { Input, InputWrapperDiv } from '../../../components/ui/Input';
import { validate } from './validations';
import React, { useEffect, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { storageBankToForm } from '../../../helpers/components/user/registrationMapData';
import { calculateNext } from '../utils/filledField';
import { useTranslation } from "react-i18next";


const BankDetails = ({ setValid, setFilledBankData }) => {
    const { t } = useTranslation();
    const localStorageData = localStorage.getItem('registrationBankData');
    const [formBD, setFormBD] = useState(storageBankToForm(localStorageData));

    const [bankStorageData, setStorageBankData] = useLocalStorageState("registrationBankData", formBD);

    useEffect(() => {
        setStorageBankData(formBD);
        const isValid = calculateNext(formBD);
        setValid(isValid);
        setFilledBankData(isValid);
    }, [formBD]);

    const changeHandler = (value, fieldName) => {
        const error = validate(formBD[fieldName].rules, value);

        const newEntry = {
            ...formBD,
            [fieldName]: {
                ...formBD[fieldName],
                value: value,
                showError: true,
                error
            },

        };
        setFormBD(newEntry);
    };

    return (
        <>
            <div className="row pb-2">
                <InputWrapperDiv><Input
                    // defaultName=""
                    placeholder={t("profile.bank.name")+' *'}
                    name="bankName"
                    value={formBD.bankName.value}
                    onChange={val => changeHandler(val, 'bankName')}
                />
                </InputWrapperDiv>
            </div>
            <div className="row pb-2">
                <InputWrapperDiv><Input
                    placeholder={t("profile.bank.account_no")+' *'}
                    name="accountNumber"
                    value={formBD.accountNumber.value}
                    onChange={val => changeHandler(val, 'accountNumber')}
                />
                </InputWrapperDiv>
            </div>
            <div className="row pb-2">
                <InputWrapperDiv><Input
                    placeholder={t("profile.bank.bic")+' *'}
                    name="bicNumber"
                    value={formBD.bicNumber.value}
                    onChange={val => changeHandler(val, 'bicNumber')}
                />
                </InputWrapperDiv>
            </div>
            <div className="row pb-2">
                <InputWrapperDiv><Input
                    placeholder={t("profile.bank.account_holder_name")+' *'}
                    name="accountHolder"
                    value={formBD.accountHolder.value}
                    onChange={val => changeHandler(val, 'accountHolder')}
                />
                </InputWrapperDiv>
            </div>
        </>
    )
};

export default BankDetails;