import {Link, useNavigate} from "react-router-dom";
import LordIcon from "../../../../../components/ui/LordIcon";
import {Colors} from "../../../../../components/theme";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getClientsListFirmNameAPI} from "../../../../../services/api/clients";
import DarkCard from "../../../../../components/card/DarkCard";
import {DetailsStyle, ElementStyle, FieldStyle, NoteParagraphStyle, NoteStyle} from "../../../../../layouts/styles";


const ClientsList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [clients, setClients] = useState([])

    useEffect(() => {
        const getData = async () => {
            try {
                const clientsData = await getClientsListFirmNameAPI()
                setClients(clientsData.data)
            } catch (e) {
                console.log("Failed to download clients list", e);
                navigate("/network-error");
            }
        }
        getData()
        sessionStorage.removeItem('client-data')
        sessionStorage.removeItem('offer-data')

    }, [])

    const actionHandler = (action, client_id) => {
        if (action === "create") {
            // sessionStorage.setItem("client-manager-session", JSON.stringify({
            //     action_name: "clients_contacts_add_contact",
            //     pathReturn: "/clients/contacts/details/",
            // }))
            navigate("create/")
            return
        }
        // sessionStorage.setItem("client-manager-session", JSON.stringify({
        //     action_name: "clients_contacts_edit_contact",
        //     pathReturn: "/clients/contacts/details/"+client_id,
        // }))
        navigate("details/"+client_id)
    }

    return(<>
        <DarkCard classNames='mb-3 row'>
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.clients",{ ns: 'menu' })}</span> &#9656; 
                <Link to={'/clients/contacts'}>{t("menu.contacts",{ ns: 'menu' })}</Link>
            </p>
        </DarkCard>

        <DarkCard classNames='mb-3 row'>
            <h1 className="mt-0 p-0">
                {t("clients.contacts_main_page_title")}
            </h1>

            <div className="row ps-0">
                <div className="col-sm-8">
                    <Link className="addGroup" to={'create'} onClick={() => actionHandler("create")}>
                        <LordIcon url='cross' color={`${Colors.red};`} rotate='45' target='.addGroup'/>
                        <span className="ps-2">{t("common.button.add_client")}</span>
                    </Link>
                </div>
                <div className="col-sm-4 mt-3 mt-sm-0">
                    <input style={{width: '100%'}} placeholder="Search" disabled></input>
                </div>
            </div>
            <div className="mt-4 ps-0">
                {clients.map(client =>
                    <ElementStyle className={"d-flex flex-column"} key={client.id}>
                        <DetailsStyle className="d-flex flex-row flex-wrap flex-md-nowrap" />
                        <div className="d-flex flex-row flex-wrap flex-md-nowrap py-1" style={{width: '95%'}}>
                            <FieldStyle width="70%" >
                                <a onClick={() => actionHandler("edit", client.id)}><b>{client.first_name} {client.last_name}</b></a>
                            </FieldStyle>
                            <FieldStyle width="70%">
                                <a href={"tel:" + client.phoneCountry + client.telephone}>{client.phoneCountry} {client.telephone}</a>
                            </FieldStyle>
                            <FieldStyle>
                                <a href={"mailto:"+ client.email}> {client.email} </a>
                            </FieldStyle>
                            <FieldStyle width="40%">
                                <ul className="my-0">
                                {client.firms.map(el =>
                                    <li key={el.pk} className="pe-2" style={{display:'inline'}}> {el.firm_name}</li>
                                )}
                                </ul>
                            </FieldStyle>
                            
                        </div>
                        {client.note && <NoteStyle>
                                            <NoteParagraphStyle>
                                                <b>{t("profile.note")}:</b> {client.note}
                                            </NoteParagraphStyle>
                                        </NoteStyle>
                        }
                    </ElementStyle>
                )}

            </div>
        </DarkCard>
    </>)
}

export default ClientsList