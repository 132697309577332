import Calculator from "./Calculator";

const CalculatorLogic = props => {

    return <Calculator />

}


export default CalculatorLogic
