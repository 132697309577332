import React, { useEffect, useState } from "react";
import { Input, InputWrapperDiv } from "../../../components/ui/Input";
import { validate } from "./validations";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr, de, pl, enUS } from "date-fns/locale";
import useLocalStorageState from "use-local-storage-state";
import { storageToForm } from "../../../helpers/components/user/registrationMapData";
import Select from "react-select";
//import makeAnimated from 'react-select/animated';
import { calculateNext } from "../utils/filledField";
// import { Colors } from "../../../components/theme";
import {
	countryList,
	genderList,
	languagesList,
	musician_status,
	phoneCountryList
} from "../../../helpers/dictionary/users";
import { useTranslation } from "react-i18next";
import select_styles from "../../../layouts/elements";


const PersonalData = ({ setValid, setFilledPersonalData }) => {
	const { t } = useTranslation();
	const localStorageData = localStorage.getItem("registrationData");
	const [formPD, setFormPD] = useState(storageToForm(localStorageData));
	const [registerData, setRegisterData] = useLocalStorageState(
		"registrationData",
		formPD
	);

	const phone_country = [
		{ value: "", label: t("profile.phone_country_code")+' *', isDisabled: true },
		...phoneCountryList,
	];

	useEffect(() => {
		setRegisterData(formPD);
		const isValid = calculateNext(formPD);
		setFilledPersonalData(isValid);
		setValid(isValid);

	}, [formPD]);

	const changeHandler = (value, fieldName) => {
		const error = validate(formPD[fieldName].rules, value);
		const newEntry = {
			...formPD,
			[fieldName]: {
				...formPD[fieldName],
				value: Array.isArray(value) ? value.map(x => x.value) : value,
				showError: true,
				error,
			},
		};
		setFormPD(newEntry);
	};

	return (
		<>
			<div className='row pb-2'>
				<InputWrapperDiv>
					<Input
						placeholder={t("profile.first_name")+' *'}
						name='first_name'
						value={formPD.firstName.value}
						onChange={val => changeHandler(val, "firstName")}
						readOnly={true}
					/>
				</InputWrapperDiv>
				<InputWrapperDiv>
					<Input
						placeholder={t("profile.last_name")+' *'}
						name='last_name'
						value={formPD.lastName.value}
						onChange={val => changeHandler(val, "lastName")}
					/>
				</InputWrapperDiv>
			</div>

			<div className='row pb-2'>
				<div className={`col`}>
					<Select
						// name='gender'
						options={genderList}
						placeholder={t("profile.gender.title")+' *'}
						value={formPD.gender.value}
						defaultValue={{ value: "placeholder", label: "Choose *" }}
						onChange={val => changeHandler(val, "gender")}
						styles={select_styles}
						unstyled
					/>
				</div>
			</div>

			<div className='row pb-2'>
				<InputWrapperDiv>
					<DatePicker
						name='birthDate'
						placeholderText={t("profile.birth_date")+' *'}
						selected={formPD.birthDate.value}
						onChange={val => changeHandler(val, "birthDate")}
						dateFormat='dd.MM.yyyy'
						maxDate={new Date()}
						// onChangeRaw={(event) => handleChangeRaw(event.target.value)}
						peekNextMonth
						showMonthDropdown
						showYearDropdown
						dropdownMode='select'
						locale={fr}
						wrapperClassName='dataInputWrapper'
						className='dataInput'
					/>
				</InputWrapperDiv>

				{/*1. Should we implement this:*/}
				{/*https://www.npmjs.com/package/react-input-calendar*/}
				{/*2. Don't know how to implement onfocus ...*/}
			</div>
			<div className='row pb-2'>
				<InputWrapperDiv className='col-5'>
					<div className='customSelectParent'>
						<Select
							placeholder={t("profile.dialling_code")+' *'}
							// name='phone_country'
							options={phone_country}
							value={formPD.phoneCountry.value}
							onChange={val => changeHandler(val, "phoneCountry")}
							styles={select_styles}
							unstyled
						/>
					</div>
				</InputWrapperDiv>
				<InputWrapperDiv className='col-7'>
					<Input
						placeholder={t("profile.phone_number")+' *'}
						name='phone_number'
						value={formPD.phoneNumber.value}
						onChange={val => changeHandler(val, "phoneNumber")}
					/>
				</InputWrapperDiv>
			</div>
			<div className='row pb-2'>
				<InputWrapperDiv>
					<Input
						placeholder={t("profile.email_address")+' *'}
						name='email_addess'
						type='email'
						value={formPD.email.value}
						onChange={val => changeHandler(val, "email")}
					/>
				</InputWrapperDiv>
			</div>
			<div className='container py-3 px-0'>
				<div className='row pb-2'>
					<InputWrapperDiv>
						<Input
							placeholder={t("profile.street")+' *'}
							name='street'
							value={formPD.street.value}
							onChange={val => changeHandler(val, "street")}
						/>
					</InputWrapperDiv>
					<InputWrapperDiv>
						<Input
							placeholder={t("profile.house_apartment_number")+' *'}
							name='house_number'
							value={formPD.houseNumber.value}
							onChange={val => changeHandler(val, "houseNumber")}
						/>
					</InputWrapperDiv>
				</div>
				<div className='row pb-2'>
					<InputWrapperDiv>
						<Input
							placeholder={t("profile.post_code")+' *'}
							name='postcode'
							value={formPD.postCode.value}
							onChange={val => changeHandler(val, "postCode")}
						/>
					</InputWrapperDiv>
					<InputWrapperDiv>
						<Input
							placeholder={t("profile.city")+' *'}
							name='city'
							value={formPD.city.value}
							onChange={val => changeHandler(val, "city")}
						/>
					</InputWrapperDiv>
				</div>
				<div className='row pb-2'>
					<div className={`col  `}>
						<div className='customSelectParent'>
							<Select
								name='country'
								options={countryList}
								placeholder={t("profile.country")+' *'}
								value={formPD.country.value}
								onChange={val => changeHandler(val, "country")}
								styles={select_styles}
								unstyled
							/>
						</div>
					</div>
				</div>
			</div>

			<div className='row pb-4'>
				<div className={`col `}>
					<p className='mt-0 mb-1'>{t("profile.musician_status")+' *'}</p>
					<Input
						type='radio'
						name='musicianStatus'
						options={musician_status}
						value={formPD.musicianStatus.value}
						onChange={val => changeHandler(val, "musicianStatus")}
					/>
				</div>
			</div>

			<div className='row pb-2'>
				<div className={`col  `}>
					<div className='langSelectParent'>
						<Select
							// name='main_language'
							options={languagesList}
							placeholder={t("profile.preferred_language")+' *'}
							value={formPD.mainLang.value}
							onChange={val => changeHandler(val, "mainLang")}
							styles={select_styles}
							unstyled
						/>
					</div>
				</div>
			</div>

			{/*   https://medium.com/geekculture/creating-multi-select-dropdown-with-checkbox-in-react-792ff2464ef3   */}
			{/* https://react-select.com/styles */}
			<div className='row pb-2'>
				<div className={`col`}>
					<div className='langSelectParent'>
						<Select
							placeholder={t("profile.other_languages")+' *'}
							closeMenuOnSelect={false}
							isMulti
							options={languagesList}
							value={languagesList.filter(obj =>
								formPD.additionalLang.value.includes(obj.value)
							)}
							onChange={val => changeHandler(val, "additionalLang")}
							styles={select_styles}
							unstyled
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default PersonalData;
