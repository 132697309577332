import { Text, View, Image } from "@react-pdf/renderer";
import estroLogo from "../../../assets/images/Logo_250px.png";
import React from "react";
import {styles} from "./Styles/Styles";


const HeaderPDF = ({business}) => {
    return(
        <View style={styles.header} fixed>
            <Image style={styles.logo} src={estroLogo} />
            <View style={[styles.rightContent,{paddingTop: "13px"}]}>
                <Text style={[styles.textItalic, {fontSize: "9px"}]}>
                    {business.name}
                </Text>
                <Text style={[styles.textItalic, {fontSize: "9px"}]}>
                    {business.number}, {business.street}
                </Text>
                <Text style={[styles.textItalic, {fontSize: "9px"}]}>
                    {business.post_code} {business.place}
                </Text>
            </View>
        </View>
    )
}

export default HeaderPDF


