import FullPageBackground from '../components/Full_Page_Background';
import backgroundImgLink from '../assets/images/reg-pw-bg-min.png';
import Logo from '../components/ui/Logo';
import {Link} from "react-router-dom";

const LayoutPwResetStyleSm = {
    maxWidth: '350px',
    padding: '150px 25px 25px 25px',
    marginLeft: '10%'
}

const LayoutPwResetStyle = {
    maxWidth: '650px',
    padding: '150px 25px 25px 25px',
    marginLeft: '10%'
}

const LogoStyle = {
    maxWidth: '200px',
    width: '100%',
    marginBottom: '55px'
}

export default function LayoutPwReset({variant, children}) {
    return (
        <>
            <div style={variant==='small'
                ? LayoutPwResetStyleSm : LayoutPwResetStyle}
                className="animate__animated animate__fadeInLeft">

                <Link to={"/login"}>
                    <Logo styling={LogoStyle}/>
                </Link>

                {children}

            </div>

            <FullPageBackground
                opacity='0.4'
                url={backgroundImgLink}
            />
        </>
    );
}