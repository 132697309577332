import {AuthenticationContext} from "../../contexts/AuthenticationContext";
import {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";

import MainAdmin from "../roles/admin/MainAdmin";
import MainMusician from "../roles/musician/MainMusician";
import MainTreasurer from "../roles/treasurer/MainTreasurer";
import MainSecretariat from "../roles/secretariat/MainSecretariat";

function Dashboard() {
    const context = useContext(AuthenticationContext);
    const navigate = useNavigate();

    useEffect(() => {
        if ((!context.tokenUser) || (!context.currentRole)) navigate("/login")
    });

    const MainView = {
        'admin': MainAdmin,
        'musician': MainMusician,
        'treasurer': MainTreasurer,
        'secretariat': MainSecretariat,
    }[context.currentRole];

    return context.tokenUser && context.currentRole && <MainView />

};

export default Dashboard;