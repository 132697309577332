import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Colors } from "../../../../../components/theme";

function ContactPersonDetails ({person}) {
    const { t } = useTranslation()

    return (
        <>
        {person &&
        <>
            <div key={person.id.toString()} className="col-md-4 mb-3">
             <p style={{fontWeight:'bold'}}>
                 {person.first_name} &nbsp;
                 
                 <span style={{textTransform: 'uppercase'}}>
                  {person.last_name}
                 </span>
             </p>
             <p>
                 Tel.: &nbsp;
                 <Link to={'tel:'+`${person.phone.replace(/ /g, "")}`} className='hover'>
                      {person.phone}
                 </Link>
                 <br/>
                 Mail: &nbsp;
                 <Link to={'mailto:'+`${person.email}`} className='hover'>
                      {person.email}
                 </Link>
             </p>
             <p>
                 <b style={{color:`${Colors.darkRed}`}}>{t("location.contact_person.responsibility")}:</b> <br/>
                 {person.responsible_for}
             </p>
             { person.note ?
                <p>
                    <b style={{color:`${Colors.darkRed}`}}>{t("location.contact_person.person_note")}:</b> <br/>
                    {person.note}
                </p>
                : null
             }
             
            </div>
        </>
        }
        </>
    )
}

export default ContactPersonDetails;