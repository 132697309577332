import React from "react";
import styled from "styled-components";
import {Colors} from "../theme";
import {displayType} from "../../pages/roles/admin/PageContent/Sales/helpers/func_helpers";

const RemoveIcon = styled.i`
	position: absolute;
	right: -8px;
	top: -12px;
	padding: 3px 5px 3px 6px;
	border-radius: 50%;
	height: 23px;
	width: 23px;
	font-size: 0.9rem;
	vertical-align: middle;
	color: white;
	background-color: ${Colors.darkestGray};
	&:hover {
		background-color: ${Colors.red};
	}
`;

const OtherCostsItem = ({setCost, item, amountType, amount, type, removeItem, archive}) => {

    const disabled_type_obj = displayType(item.display_type)

    return (
        <div className="d-flex flex-row align-items-center my-2">
            <div style={{width: amountType ? '30%' : (archive ? '30%' : '46%')}}>
                {<input
                    disabled={disabled_type_obj.description}
                    // readOnly={item.readOnly}
                    onChange={e => setCost(e.target.value, 'description', item)}
                    value={item.description}
                    style={{width:'100%'}}
                />}
            </div>
            {
                amountType != null &&
                <div style={{width:'20%'}} className="ms-2">
                    {
                        amountType &&
                        <input
                            disabled={disabled_type_obj.amount}
                            onChange={e => setCost(e.target.value, 'amount', item)}
                            value={amount}
                            style={{width:"60%", maxWidth:"80px"}}
                        />
                    }
                    &nbsp;{amountType}
                </div>
            }
            <div style={{width:'25%'}} className="ms-2">
                {<input
                    disabled={disabled_type_obj.client_cost}
                    onChange={e => setCost(e.target.value, 'client_cost', item)}
                    value={item.client_cost}
                    style={{width:'100%', maxWidth:'80px'}}
                />}
            </div>
            <div style={{width:'25%', position:'relative'}} className="ms-2">
                {<input
                    disabled={disabled_type_obj.estro_cost}
                    onChange={e => setCost(e.target.value, 'estro_cost', item)}
                    value={item.estro_cost}
                    style={{width:'100%', maxWidth:'80px'}}
                />}
            </div>

            <div
                className='customSelectParent'
                style={{ position: "relative" }}>
                {item.id !== '9999' &&
                    <RemoveIcon
                        className='fa fa-times'
                        onClick={() =>
                            removeItem(item.id, type)
                        }
                    />
                }
            </div>

        </div>
    )
};
export default OtherCostsItem;
        