import { useTranslation } from "react-i18next";

import MenuButton from "../../../../components/ui/Menu_Button";
import MenuHeader from "../../../../components/menu/MenuHeader";
import MenuFooter from "../../../../components/menu/MenuFooter";
import { Colors } from "../../../../components/theme";

import {AuthenticationContext} from "../../../../contexts/AuthenticationContext";
import {useContext} from "react";
import styled from "styled-components";
import MenuBody from "../../../../components/menu/MenuBody";
import {Link} from "react-router-dom";
import ChangeRole from "../../ChangeRole";

const ListElement = styled.li `
    padding: 10px 0px;
`

const SubMenuElement = styled.ul `
    padding-left: 35px;
    list-style-type: none;
    display: none;
    & > li {
        padding: 3px 0px;
    }
    & > li:hover {
        font-weight: bold;
    }
`

export default function Menu() {
    const context = useContext(AuthenticationContext);
    const userRoles = context.userRoles;
    const { t } = useTranslation(['menu']);

    return (
        <>
            <MenuHeader userName={context.userName} persFunction={userRoles}>
                <b>{context.userName}</b>
                <ChangeRole />
            </MenuHeader>

            <MenuBody>
                <ul className="mainMenu" style={{listStyleType:'none', padding:'0px'}}>
                    {/*<ListElement>*/}
                    {/*    <MenuButton type='home' target='.menuLink' to={'home'}>*/}
                    {/*        {t("menu.home")}*/}
                    {/*    </MenuButton>*/}
                    {/*</ListElement>*/}

                    {/*<ListElement>*/}
                    {/*    <MenuButton type='avatar' target='.menuLink'>*/}
                    {/*        {t("menu.profile")}*/}
                    {/*    </MenuButton>*/}
                    {/*    <SubMenuElement className="subMenu">*/}
                    {/*        <li><Link to={'profile/personal-data'}>{t("menu.pers_data")}</Link></li>*/}
                    {/*        <li><Link>{t("menu.pw_settings")}</Link></li>*/}
                    {/*        <li><Link>{t("menu.delete_acc")}</Link></li>*/}
                    {/*    </SubMenuElement>*/}
                    {/*</ListElement>*/}

                    <ListElement>
                        <MenuButton type='folder' target='.menuLink'>
                            {t("menu.projects")}
                        </MenuButton>
                        <SubMenuElement className="subMenu">
                            <li><Link to={'projects/in-preparation'}>{t("menu.inprep_projects")}</Link></li>
                            {/*<li><Link>{t("menu.current_projects")}</Link></li>*/}
                            {/*<li><Link>{t("menu.finished_projects")}</Link></li>*/}
                        </SubMenuElement>
                    </ListElement>
                    {/*<ListElement>*/}
                    {/*   <MenuButton type='musician' target='.menuLink'>*/}
                    {/*       {t("menu.musicians")}*/}
                    {/*   </MenuButton>*/}
                    {/*   <SubMenuElement className="subMenu">*/}
                    {/*       <li><Link to={'musicians/contacts'}>{t("menu.contacts")}</Link></li>*/}
                    {/*       <li><Link to={'musicians/map'}>{t("menu.map")}</Link></li>*/}
                    {/*   </SubMenuElement>*/}
                    {/*</ListElement>*/}
                    <ListElement>
                        <MenuButton type='avatar' target='.menuLink'>
                            {t("menu.clients")}
                        </MenuButton>
                        <SubMenuElement className="subMenu">
                            <li><Link to={'clients/contacts'}>{t("menu.contacts")}</Link></li>
                            <li><Link to={'clients/companies'}>{t("menu.companies")}</Link></li>
                        </SubMenuElement>
                    </ListElement>
                    <ListElement>
                        <MenuButton type='location' target='.menuLink' to={'locations'}>
                            {t("menu.locations")}
                        </MenuButton>
                    </ListElement>
                    <ListElement>
                        <MenuButton type='sale' target='.menuLink'>
                            {t("menu.sales")}   
                        </MenuButton>
                        <SubMenuElement className="subMenu">
                            <li><Link to={'sales/calculator'}>{t("menu.calculator")}</Link></li>
                        </SubMenuElement>
                        <SubMenuElement className="subMenu">
                            <li><Link to={'sales/saved_calculations'}>{t("menu.saved_calculations")}</Link></li>
                        </SubMenuElement>
                        {/* 
                        <SubMenuElement className="subMenu">
                            <li><Link to={'projects/offer'}>{t("menu.estimates")}</Link></li>
                        </SubMenuElement>
                        <SubMenuElement className="subMenu">
                            <li><Link to={'projects/offer'}>{t("menu.quotes")}</Link></li>
                        </SubMenuElement>
                        <SubMenuElement className="subMenu">
                            <li><Link to={'projects/offer'}>{t("menu.invoices")}</Link></li>
                        </SubMenuElement>
                        */}
                    </ListElement>
                    <ListElement>
                        <MenuButton type='instrument' target='.menuLink' to={'instruments'}>
                            {t("menu.instruments")}
                        </MenuButton>
                    </ListElement>
                    <ListElement>
                        <MenuButton type='asbl' target='.menuLink'>
                            {t("menu.asbl")}
                        </MenuButton>
                        <SubMenuElement className="subMenu">
                            {/*<li><Link>{t("menu.members")}</Link></li>*/}
                            <li><Link to={'/asbl/association'}>{t("menu.management")}</Link></li>
                            <li><Link to={'/asbl/business-data'}>{t("menu.business_data")}</Link></li>
                            {/*<li><Link>{t("menu.downloads")}</Link></li>*/}
                            <li><Link to={'asbl/settings'}>{t("menu.calc_settings")}</Link></li>
                        </SubMenuElement>
                    </ListElement>
                    {/*<ListElement>*/}
                    {/*    <MenuButton type='settings' target='.menuLink' to={'settings'}>*/}
                    {/*        {t("menu.settings")}*/}
                    {/*    </MenuButton>*/}
                    {/*</ListElement>*/}
                    {/*<ListElement>*/}
                    {/*    <MenuButton type='bell' target='.menuLink'>*/}
                    {/*        {t("menu.notifications")}*/}
                    {/*    </MenuButton>*/}
                    {/*</ListElement>*/}
                </ul>
            </MenuBody>

            <MenuFooter>
                <MenuButton type='arrow' target='.menuLink' rotate='90' onClick={context.onLogout}>
                    {t("menu.log_out")}
                </MenuButton>
            </MenuFooter>
        </>
    )

}