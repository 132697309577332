import {useTranslation} from "react-i18next";
import React from "react";
import {TextStyle, TitleStyle} from "../../../../../../layouts/styles";
import Address from "../Offer/Address";


const ClientData = ({details}) => {
    const { t } = useTranslation()

    return (<>
        <div>
            <div className="row px-1">
                <div className="col-6">
                    <p style={TitleStyle}>
                        {t("profile.first_name")}
                    </p>
                    <p style={TextStyle}>
                        {details.first_name}
                    </p>
                </div>
                <div className="col-6">
                    <p style={TitleStyle}>
                        {t("profile.last_name")}
                    </p>
                    <p style={TextStyle}>
                        {details.last_name}
                    </p>
                </div>
            </div>
            <div className="row px-1">
                <div className="col-6">
                    <p style={TitleStyle}>
                        {t("profile.phone_number")}
                    </p>
                    <p style={TextStyle}>
                        {details.phoneCountry} {details.telephone}
                    </p>
                </div>

                <div className="col-6">
                    <p style={TitleStyle}>
                        {t("profile.email_address")}
                    </p>
                    <p style={TextStyle}>
                        {details.email}
                    </p>
                </div>
            </div>
            <Address details={details} />
        </div>
    </>)
}

export default ClientData
