import {calculationDetailAPI} from "../../../services/api/calculator";
import {archiving_entry_revers} from "../../../pages/roles/admin/PageContent/Sales/helpers/func_helpers";
import {data} from "./tools";

// na razie wykorzystano dla Offer

// const projectData = {
//     calculationID: "",
//     staff: {},
//     events: [],
//     details: {},
//     eventDetail: [],
//     staffSum: "",
//     clientSum: "",
//     estroSum: "0.00",
//     grantEstroSum: '0.00',
//     totalSum: "0.00",
// }

const get_calculation_api = async (pk) => {
    if (!pk) {
        return
    }

    const getCalculationDetail = async () => {
        try {
            const resp_calculation = await calculationDetailAPI(pk);
            const resp_details = resp_calculation.data.data;
            data.staff = resp_details.staff;
            data.events = resp_details.events;

            const event_detail = resp_details.eventDetail.map(el => {
                return {
                    ...el,
                    eventDate: new Date(el.eventDate),
                };
            });
            data.eventDetail = event_detail;
            data.details = resp_details.details;
            data.administration = resp_details.administration;

            const repear_archiving = resp_details.archiving.map(el => {
                if (el.display_type !== "X") {
                    return {
                        ...el,
                        ["display_type"]: "archivro",
                        ["cost_type"]: "archiving",
                        ["readOnly"]: true,
                        ["edit"]: false,
                        ["name"]: archiving_entry_revers(el.description),
                    };
                }
                return el;
            });

            data.archiving = repear_archiving;
            data.clientKzm = resp_details.clientKzm;
            data.clientMus = resp_details.clientMus;
            data.conductor = resp_details.conductor;
            data.estroKzm = resp_details.estroKzm;
            data.estroMus = resp_details.estroMus;
            data.eventDuration = resp_details.eventDuration;
            data.kzmProject = resp_details.kzmProject;
            data.renting = resp_details.renting;
            data.project30kmAway = resp_details.project30kmAway;
            data.conductor30kmAway = resp_details.conductor30kmAway;
            data.transp30km = resp_details.transp30km;
            data.transport = resp_details.transport;
            data.calculationName = resp_calculation.data.name;
            data.calculNameEditInput = {
                state: false,
                name: resp_calculation.data.name,
            };
            data.program = resp_calculation.data.program
            data.totaling = resp_details.totaling;
        } catch (e) {
            console.error(e);
            return
        }
    }
    await getCalculationDetail()
    return data
}

export default get_calculation_api

