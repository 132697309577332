const LoginResponseErrors = {
    // pobierz tlumaczenie dla obiektu
    ProvidedCredentials: "ProvidedCredentials",
    NotProvidedUsernameOrPassword: "Not provided username or password",
    NotToken: "Not token",
    UnknownError: "unknown error",
    InactiveAccount: "InactiveAccount",
};

const PasswordResetErrors = {
    notUsernameOrNotActivated: "notUsernameOrNotActivated",
    resetPasswordSend: "resetPasswordSend"
};

export {
    LoginResponseErrors,
    PasswordResetErrors
};