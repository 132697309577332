import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import React from "react";
import {useTranslation} from "react-i18next";
import EstimateItem from "./EstimateItem";


export default function Estimate({estimates, grantEstroSum, project, clientAndFirm, redirect_to}) {
    const {t} = useTranslation()

    return (<>
        <RedHeader title={t('project.estimates_title')}/>
        <div className='row mx-0'>
            {estimates.map((estimate, index) =>
                <EstimateItem
                    key={index}
                    est={estimate}
                    index={index+1}
                    grantEstroSum={grantEstroSum}
                    project={project}
                    clientAndFirm={clientAndFirm}
                    redirect_to={redirect_to}
                />
            )}
        </div>
    </>)
}