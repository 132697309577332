import {DetailsStyle, ElementStyle, FieldStyle, NoteParagraphStyle, NoteStyle} from "../../../../../../layouts/styles";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import { Colors } from "../../../../../../components/theme";

const titleField = {
    id: "project.id_title",
    name: "project.project_name_title",
    start_date: "project.project_start_date_title",
    project_cost: "project.project_project_cost_title",
    profit_loss: "project.project_project_profit_loss_title",
}

const ProfitLoss = styled.span `
    color: #fff;
    font-weight: bold;
    background-color: ${props => props.profit_loss > 0 ? '#69b41f' : props.profit_loss == 0 ? '#636363' : '#b41f23'};
    border-radius: 50px 50px;
    padding: 2px 12px;
`

const TxtTitles = styled.p`
	font-size: 0.7em;
    text-transform: uppercase;
	width: 100%;
    padding: 0 0 3px 0;
    margin: 0;
    color: ${Colors.darkRed};
`;

export function ProjectItem({project}) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const handleClick = (pk) => {navigate("/projects/in-preparation/"+pk)}
    const profit_loss =  project.totaling.clientSum - project.totaling.estroSum

    return(<>
        <ElementStyle className={"d-flex flex-column"} key={project.id}>
            <div className="d-flex flex-row flex-wrap flex-md-nowrap py-1" style={{width: '95%'}}>
                <FieldStyle width="13%" className="p-1">
                    <div>
                        <TxtTitles>{t(titleField.id)}</TxtTitles>
                        <a onClick={() =>{handleClick(project.id)}}><b>P{project.id}</b></a>
                    </div>
                </FieldStyle>

                <FieldStyle className="p-1">
                    <div>
                        <TxtTitles>{t(titleField.name)}</TxtTitles>
                        <a onClick={() =>{handleClick(project.id)}}><b>{project.name}</b></a>
                    </div>
                </FieldStyle>

                <FieldStyle className="p-1">
                    <div>
                        <TxtTitles>{t(titleField.start_date)}</TxtTitles>
                        {project.start_date ? project.start_date : "-----"}
                    </div>
                </FieldStyle>

                <FieldStyle className="p-1">
                    <div>
                        <TxtTitles>{t(titleField.project_cost)}</TxtTitles>
                        {project.totaling.totalSum.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
                    </div>
                </FieldStyle>

                <FieldStyle className="p-1">
                    <div>
                        {/* <TxtTitles>{t(titleField.profit_loss)}</TxtTitles> */}
                        
                        <ProfitLoss profit_loss={profit_loss} >
                            {profit_loss.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
                        </ProfitLoss>
                    </div>
                </FieldStyle>
            </div>
        </ElementStyle>
    </>)
}