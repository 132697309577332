import styled from "styled-components";
import { useTranslation } from "react-i18next";
import DarkCard from "../../../../../components/card/DarkCard";
import LightCard from "../../../../../components/card/LightCard";
import RedHeader from "../../../../../components/ui/RedBgTitleHeader";

const GroupElementStyle = styled.div `
  position: relative;
  margin-right: 30px;
`

function MusicianProfile () {
    const { t } = useTranslation();

    return (
        <>
            <DarkCard>
                Musician Profile Data Validated - if musician not approved transfer to MusicianApproval
            </DarkCard>
            <DarkCard>
                <h1>fName lName</h1>

                <LightCard classNames='mb-5'>
                    Profile Data
                </LightCard>

                <LightCard classNames='mb-5'>
                    <RedHeader title={t("project.projects_title")} />

                </LightCard>
                <LightCard>
                    <RedHeader title={t("payouts.payouts_title")} />

                </LightCard>
            </DarkCard>
            
        </>
    )
};


export default MusicianProfile;