import {useTranslation} from "react-i18next";
import {EditButton} from "../../../../../../components/ui/EditButton";
// import Address from "./Address";
import React from "react";

const TestButton = ({firmSelected, handleNewEdit}) => {
    const { t } = useTranslation()

    return (<>
        <div className="row">
            <EditButton
                gotoName={"/clients/companies/edit/"+firmSelected}
                handleClickProps={() => handleNewEdit(true)}
            />
        </div>
        <div className="row px-1">
            <div className="col-7">
                {/*<p style={TitleStyle}>*/}
                {/*    {t("firstName")}<span style={TextStyle}>: {firstName}</span>*/}
                {/*</p>*/}
                {/*<p style={TitleStyle}>*/}
                {/*    {t("lastName")}<span style={TextStyle}>: {lastName}</span>*/}
                {/*</p>*/}
            </div>
        </div>
    </>)

}

export default TestButton



const aaa = {
        "estroSum": "538.00",
        "staffSum": 21,
        "totalSum": "721.60",
        "clientSum": "721.60",
        "grantEstroSum": "0.00"
}