import { useTranslation } from "react-i18next";

export function getWeekDay(dateStr, t) {
    // Convert Date in a format, which can be read by Date ("yyyy-mm-dd")
    const [day, month, year] = dateStr.split('.');
    const dateObj = new Date(`${year}-${month}-${day}`);
  
    // Find out week day (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const daysOfWeek = [
        t("common.week_days.sun"),
        t("common.week_days.mon"),
        t("common.week_days.tue"),
        t("common.week_days.wed"),
        t("common.week_days.thu"),
        t("common.week_days.fri"),
        t("common.week_days.sat")
    ];
    const dayOfWeek = daysOfWeek[dateObj.getDay()];
  
    return dayOfWeek;
}