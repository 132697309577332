import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import React from "react";
import {useTranslation} from "react-i18next";
import WhiteCard from "../../../../../../components/card/WhiteCard";
import dateFormat from "dateformat";
import styled from "styled-components";
import { Colors } from "../../../../../../components/theme";
import { getWeekDay } from "../../../../../../helpers/weekDay";

const TxtTitles = styled.p`
	font-size: 0.7em;
    text-transform: uppercase;
	width: 100%;
    padding: 0;
    margin: 0;
    color: ${Colors.darkRed};
`;

const EventItem = ({event, index}) => {
    const {t} = useTranslation()

    return (<>
        <WhiteCard classNames='mt-3 d-flex flex-row flex-wrap flex-md-nowrap align-items-center p-3'>
            <div style={{width: '15%'}}>
                <TxtTitles>Weekday</TxtTitles>
                {getWeekDay(dateFormat(event.eventDate, "dd.mm.yyyy"), t)}
            </div>
            <div style={{width: '15%'}}>
                <TxtTitles>Date</TxtTitles>
                {dateFormat(event.eventDate, "dd.mm.yyyy")}
            </div>
            <div style={{width: '20%'}}>
                <TxtTitles>Start - End</TxtTitles>
                {event.startTime} - {event.endTime}
            </div>
            <div style={{width: '15%'}}>
                <TxtTitles>Type</TxtTitles>
                {t('project.events.'+event.type)}
            </div>
            <div style={{width: '20%'}}>
                <TxtTitles>Composition</TxtTitles>
                {/*{event.composition.map((el, i) => <span key={i} className="me-2">{el}</span>)}*/}

                {event.composition.map((el, i) => (
                    <React.Fragment key={i}>
                        <span className="me-2">{el}</span>
                        {(i + 1) % 3 === 0 && <br />}
                    </React.Fragment>
                ))}
            </div>
            <div style={{width: '15%'}}>
                <TxtTitles>Location</TxtTitles>
                {event.location.room && event.location.room+", "}{event.location.name ? event.location.name : t('location.to_define')}
            </div>

            <div style={{width: '15%'}}>
                <TxtTitles>Map</TxtTitles>
                {event.location.map_url ?
                    <a href={event.location.map_url} target="_blank" rel="noreferrer">{t('events.open_map')}</a>
                    : t('location.to_define')}
            </div>

           {/**  {dateFormat(event.eventDate, "dd.mm.yyyy")} || {event.startTime} - {event.endTime} || {event.type}
            || {event.composition.map((el, i) => <span key={i}>{el}</span>)}
            || {event.location.name} || {event.location.map_url}*/}
        </WhiteCard>

    </>)
}

export default function Events({eventDetails, estimationID, events}) {
    const {t} = useTranslation()

    const titleSummary = estimationID ? estimationID : ""

    const eventsView = () => {
        if (eventDetails.length > 0) {
            return eventDetails.map((box, index) =>
                <EventItem key={index} event={box} index={index}/>
            )
        }
        return (<>
            <p className="mb-1 mt-1 ms-2">{t('offer.pdf_document.events.rehearsals')} {events.rehearsals}</p>
            <p className="mb-1 mt-1 ms-2">{t('offer.pdf_document.events.raccords')} {events.raccords}</p>
            <p className="mb-1 mt-1 ms-2">{t('offer.pdf_document.events.concerts')} {events.concerts}</p>
        </>)

    }

    return (<>
        <RedHeader title={t('project.events_title') + titleSummary}/>

        <div className=''>
            {eventsView()}
        </div>

    </>)
}