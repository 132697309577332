import styled from "styled-components";
import { Colors } from "./theme";

const BodyStyle = styled.div `
    top: 98px;
    position: relative;
    @media (min-height: 651px) and (min-width: 880px) {
        top: 98px;
    }
`

const SideMenu = styled.div `
    display: none;
    position: sticky;
    top: 98px;
    z-index: 0;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 30px 30px;
    color: ${Colors.white};
    @media (min-width: 880px) {
        width: 235px;
        display: block;
        background-color: ${Colors.red};
    }
`;

const PageContentStyle = styled.div `
    margin: 0px 10px;
    padding-bottom: 5px;
    @media (min-width: 880px) {
        margin: 0px 30px;
    }
`

function ContentBodyStyle({menu, routes}) {
    return (
        <BodyStyle className="container-fluid">
            <div className="row">
                <div className="col px-0 d-none d-lg-block" style={{maxWidth:"235px"}}>
                    <SideMenu>
                        {menu}
                    </SideMenu>
                </div>                    
                <div className="col">
                    <PageContentStyle>
                        {routes}
                    </PageContentStyle>
                </div>                    
            </div>
        </BodyStyle>
    )
};

export default ContentBodyStyle;