const popupSuccess = {
    show: true,
    variant: 'success',
    okBtn: 'OK',
    title: 'Success!',
    message: "Data saved successfully."
}

const popupError = {
    show: true,
    variant: 'error',
    okBtn: 'OK',
    title: 'Error!',
    message: "Please try letter again."
}

export {
    popupError,
    popupSuccess,
}