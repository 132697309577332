import { useTranslation } from "react-i18next";
import styled from "styled-components"
import { Colors } from "../theme";
import { Button } from "./Button";
import LordIcon from "./LordIcon";
import {useEffect, useState} from "react";

// CALCULATION SAVED POPUP

const PopupMessageStyle = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    z-index: 10;
`

const PopupMessageContent = styled.div`
    position: absolute;
    max-width: 400px;
    width: 100%;
    min-height: 150px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 45px 25px 25px 25px;
    text-align: center;
    background-color: ${Colors.white};
`

const PopupMessageIcon = styled.div`
    border-radius: 50%;
    border: 5px solid ${Colors.white};
    height: 70px;
    width: 70px;
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${Colors.white};
`

const PopupMessageTitle = styled.div`
    font-size: 1.7rem;
    font-weight: bold;
    color: ${({variant}) =>
    variant === 'error' && `${Colors.red}` ||
    variant === 'success' && `${Colors.green}` ||
    variant === 'editF' && `${Colors.orange}` ||
    `${Colors.black}`
};
`

const PopupMessageDescription = styled.div`
    font-size: 1.1rem;
    margin: 10px 0px;
`

/**
 @param {{
    variant: "error" | "success";
    title: title of popup
    message: what text do you want to show
    leftBtn: light gray bordered button content
    rightBtn: filled red button content
    okBtn: ok button
 }} props
 */

const PopupForm = ({handlePopup, variant, title, message, leftBtn, rightBtn, okBtn, context}) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState({})

    useEffect(() => {
        setInputValue(context)
    }, [])

    var colorType;

    if(variant==='error'){
        colorType = `${Colors.red}`;
    }
    else if (variant==='success'){
        colorType = `${Colors.green}`;
    }
    else if (variant==='editF'){
        colorType = `${Colors.orange}`;
    }
    else if (variant==='loading'){
        colorType = `${Colors.info}`
    }
    else {
        colorType = `${Colors.black}`;
    };

    const handleChange = (val, fieldName) => {
        setInputValue({
            ...inputValue,
            [fieldName]: val
        })
    }

    const handleClickFactory = (e) => {
        const buttonName = e.target.name

        if (buttonName === 'save') {
            return handlePopup({
                show: true,
                type: "save",
                inputValue
            })
        }

        if (buttonName === 'cancel') {
            return handlePopup({show: false, type: "cancel"})
        }

        if (buttonName === 'ok') {
            return handlePopup({show: false, type: "ok"})
        }

    };

    return (
        <PopupMessageStyle>
            <PopupMessageContent className="popup">
                <PopupMessageIcon variant={variant}>
                    <LordIcon url={variant} color={colorType} target='.popup' size='60px'/>
                </PopupMessageIcon>

                <PopupMessageTitle variant={variant}>
                    {title}
                </PopupMessageTitle>

                <PopupMessageDescription>
                    {message}
                    {context
                        ? <div className="mt-3 mb-1">
                            <input
                                name="calculation-name"
                                style={{minWidth:'250px', maxWidth:'100%', height:'40px'}}
                                type={'text'}
                                value={inputValue.value}
                                onChange={e => handleChange(e.target.value, 'calculation_name')}
                            />
                        </div>
                        : null
                    }
                </PopupMessageDescription>
                {okBtn ? (<Button name="ok" onClick={(e) => handleClickFactory(e)} variant="round" bWidth="small" styling="plain" className='mx-3' proposueButton="green">{okBtn}</Button>):('')}
                {leftBtn ? (<Button name="save" onClick={(e) => handleClickFactory(e)} variant="round" bWidth="small" styling="plain" className='mx-3' proposueButton="green">{leftBtn}</Button>):null}
                {rightBtn ? (<Button name="cancel" onClick={(e) => handleClickFactory(e)} variant="round" bWidth="small" styling="bordered" className='mx-3'>{rightBtn}</Button>):null}
            </PopupMessageContent>
        </PopupMessageStyle>
    )
};

export default PopupForm;
