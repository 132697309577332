import MenuButton from "../../components/ui/Menu_Button";
import MenuHeader from "../../components/menu/MenuHeader";
import MenuBody from "../../components/menu/MenuBody";
import MenuFooter from "../../components/menu/MenuFooter";
import { Link } from "react-router-dom";
import { Colors } from "../../components/theme";

import {AuthenticationContext} from "../../contexts/AuthenticationContext";
import {useContext} from "react";
import styled from "styled-components";
import {Roles} from "../roles/Roles";


const ListElement = styled.li `
    padding: 10px 0px;
`

const SubMenuElement = styled.ul `
    padding-left: 35px;
    list-style-type: none;
    display: none;
    & > li {
        padding: 3px 0px;
    }
    & > li:hover {
        font-weight: bold;
    }
    & > li a {
        color: ${Colors.white};
    }    
`

export default function _ToDelete_DashboardMenu() {
    const context = useContext(AuthenticationContext);
    const role = Roles(context.currentRole)
    const userRoles = context.userRoles;

    return (
        <>
            <MenuHeader
                userName={context.userName}
                persFunction={userRoles}
            />                

            <MenuBody>
                <ul className="mainMenu" style={{listStyleType:'none', padding:'0px'}}>
                    <ListElement>
                        <MenuButton type='home' target='.menuLink'>
                            Home
                        </MenuButton>
                    </ListElement>
                    <ListElement>
                        <MenuButton type='avatar' target='.menuLink'>
                            Profile
                        </MenuButton>
                        <SubMenuElement className="subMenu">
                            <li><Link>Personal data</Link></li>
                            <li><Link>Password settings</Link></li>
                            <li><Link>Delete account</Link></li>
                        </SubMenuElement>
                    </ListElement>
                    <ListElement>
                        <MenuButton type='folder' target='.menuLink'>
                            Projects
                        </MenuButton>
                        <SubMenuElement className="subMenu">
                            <li><Link>Current projects</Link></li>
                            <li><Link>Past projects</Link></li>
                        </SubMenuElement>
                    </ListElement>
                    <ListElement>
                        <MenuButton type='wallet' target='.menuLink'>
                            Payouts
                        </MenuButton>
                        <SubMenuElement className="subMenu">
                            <li><Link>All payouts</Link></li>
                            <li><Link>Download report</Link></li>
                        </SubMenuElement>
                    </ListElement>
                    <ListElement>
                        <MenuButton type='doc' target='.menuLink'>
                            Contracts
                        </MenuButton>
                    </ListElement>
                    <ListElement>
                        <MenuButton type='settings' target='.menuLink'>
                            Settings
                        </MenuButton>
                    </ListElement>
                    <ListElement>
                        <MenuButton type='bell' target='.menuLink'>
                            Notifications
                        </MenuButton>
                    </ListElement>
                </ul>
            </MenuBody>

            <MenuFooter>
                <MenuButton type='arrow' target='.menuLink' rotate='90' onClick={context.onLogout}>
                    Log out
                </MenuButton>
            </MenuFooter>
        </>
    )
}