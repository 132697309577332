const MenuBodyStyle = {
    padding: '0px 10px',
}

export default function MenuBody({children}) {
    return (
        <div style={MenuBodyStyle}>
            {children}
        </div>
    )
}