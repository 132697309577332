import { useTranslation } from "react-i18next";
import OtherCostsItem from "./OtherCostsItem";
import { Link } from "react-router-dom";
import LordIcon from "../ui/LordIcon";
import { Colors } from "../theme";
import React, {useEffect} from "react";
import { v4 as uuidv4 } from 'uuid';
import CountingUp from "../../helpers/components/CountingUp";
import {name_list_archiving, staffValueSum} from "../../pages/roles/admin/PageContent/Sales/helpers/func_helpers";
import {getInstrumentsStaffListAPI} from "../../services/api/user";

const rentingNewEntry = ({
                             id=uuidv4(),
                             description="",
                             client_cost="0.00",
                             estro_cost="0.00",
                             cost_type="renting",
                             display_type="X"
                         }) => {

    return {
        id: id,
        description: description,
        client_cost: client_cost,
        estro_cost: estro_cost,
        cost_type: cost_type,
        display_type: display_type
    }
}

function RentingCosts ({cost, setCost, typeCost, staff, setStaff}) {
    const { t } = useTranslation();
    // renting = [
    //    {id: 48, description: 'Renting A', client_cost: '8.00', estro_cost: '9.00', cost_type: 'renting'},
    //    {id: 49, description: 'Renting B', client_cost: '10.00', estro_cost: '11.00', cost_type: 'renting'}
    //  ]

    const addMoreChandler = () => {
        const newItem = rentingNewEntry({})
        setCost([...cost, newItem])
    };

    const removeItem = (itemKey) => {
        const newItem = structuredClone(cost);
        const indexDel = newItem.findIndex(el => el.id === itemKey);
        newItem.splice(indexDel, 1);

        setCost(newItem)
    };

    const changeHandler = (val, cost_type, item) => {
        // {id: 'tube', description: 'tube', client_cost: 56, estro_cost: 58, cost_type: 'renting', edit: true}
        const costClone = structuredClone(cost);
        const indexDel = costClone.findIndex(el => (el.cost_type=typeCost && el.id === item.id));
        const newItem = {
            id: item.id,
            description: cost_type === 'description' ? val : item.description,
            client_cost: cost_type === 'client_cost' ? val : item.client_cost,
            estro_cost: cost_type === 'estro_cost' ? val : item.estro_cost,
            cost_type: typeCost,
            display_type: item.display_type
        }

        costClone.splice(indexDel, 1, newItem);
        setCost(costClone)
    }

    const handleReset = async () => {
        // zaktualizowac kwoty renting w staff
        // zaktualizowac kwoty renting w renting w oparciu o wszystkie wybrane instrumenty w staff dla kwot renting
        try {
            const responseInstrumentsStaff = await getInstrumentsStaffListAPI()
            const newStaff = responseInstrumentsStaff.data
            const currentStaff = structuredClone(staff)

            const violinOld = newStaff['Violin']
            newStaff['Violin1'] = {...violinOld, name: 'Violin1', label: 'effectif.Violin1'}
            newStaff['Violin2'] = {...violinOld, name: 'Violin2', label: 'effectif.Violin2'}
            delete newStaff['Violin']

            Object.entries(currentStaff).forEach(([key, value]) => {
                const new_client_cost = newStaff[key].renting_client_cost
                const new_estro_cost = newStaff[key].renting_estro_cost
                value.renting_client_cost = new_client_cost
                value.renting_estro_cost = new_estro_cost
            })

            const selected_staff = Object.keys(currentStaff).reduce((acc, key) => {
                const staffKey = staff[key]
                if ((+staffKey.value > 0) && (+staffKey.renting_client_cost >0 || +staffKey.renting_estro_cost >0)) {
                    acc.push(currentStaff[key])
                }
                return acc
            }, []) // zwroc tylko wybrane i dla renting cost

            const rentingNew = selected_staff.map(el => {
                const obj = {
                    id: el.name,
                    description: `${el.value}x ${el.name}`,  // or label
                    client_cost: parseFloat(el.value * el.renting_client_cost).toFixed(2),
                    estro_cost: parseFloat(el.value * el.renting_estro_cost).toFixed(2),
                    cost_type: false,
                    display_type: "descro",
                }
                return obj
            })
            const name_list_renting = rentingNew.map(el => el.id)
            const rest_renting_entry = cost.filter(el => !name_list_renting.includes(el.id))
            rentingNew.push(...rest_renting_entry)

            setStaff(currentStaff)
            setCost(rentingNew)
        } catch (ex) {
            console.info("Failed to download settings")
        }
        // renting = [
        //    {id: 48, description: 'Renting A', client_cost: '8.00', estro_cost: '9.00', cost_type: 'renting'},
        //    {id: 49, description: 'Renting B', client_cost: '10.00', estro_cost: '11.00', cost_type: 'renting'}
        //    {id: 'tube', description: 'tube', client_cost: 56, estro_cost: 58, cost_type: 'renting', edit: true}
        //  ]
    }

    return (
        <>
            <div className="row px-2">
                <div className="col-12">
                    <div className="d-flex flex-row align-items-center my-2">
                        <div style={{width:'46%'}}>
                            <b>{t("common.titles.description")}</b>
                        </div>
                        <div style={{width:'25%'}} className="ms-2">
                            <b>{t("rates.clients_costs")}</b>
                        </div>
                        <div style={{width:'25%'}} className="ms-2">
                            <b>{t("rates.estros_costs")}</b>
                        </div>
                    </div>
                    {cost && cost.map(el => (
                        <OtherCostsItem
                            key={el.id}
                            item={el}
                            cost={cost}
                            setCost={changeHandler}
                            type={typeCost}
                            removeItem={removeItem}
                        />
                        )
                    )}
                    <CountingUp entry={cost} blockAmount={3} />
                </div>
            </div>
            <div className='row px-2 mt-2'>
                <p className="mt-3 mb-0" style={{textAlign:'right'}}>
                    <a onClick={handleReset}>{t("rates.reset_to_primary_values")}</a>
                </p>
                <div className="col-12">
                    <Link className="addGroup pt-1">
                        <LordIcon url='cross' color={`${Colors.red};`} rotate='45' target='.addGroup' changeActive={addMoreChandler}/>
                        <span className="ps-2" onClick={addMoreChandler}>{t("common.button.add_more")}</span>
                    </Link>
                </div>
            </div>
        </>
    )
};
export default RentingCosts;
        