import { useTranslation } from "react-i18next";
import { Colors } from "../../../../../components/theme";
import { Button } from "../../../../../components/ui/Button";
import { Link } from "react-router-dom";
import React from "react";

const ImageStyle = {
    width: '100%',
    height: 'auto',
};

function RoomDetails ({room, locationId}) {
    const { t } = useTranslation();

    return (
        <div className="row mx-0 mb-3" style={{backgroundColor: `${Colors.white}`, borderRadius:'3px'}}>
            <div className="col-md-6">
                {(room.image) &&
                        <img
                            src={room.image}
                            alt=''
                            style={ImageStyle}
                        />
                }
            </div>
            <div className="col-md-6">
                <h3 className="mt-3 mb-2">
                    {room.name}
                </h3>
                <p className="my-1">
                    {t("location.rooms.details.how_many_musicians")}:
                    {room.how_many_musicians}
                </p>
                <p className="my-1">
                    {t("location.rooms.details.sound_quality")}:
                    {room.acoustics_quality}
                </p>
                <p className="my-1">
                    {t("location.rooms.details.temp")}:
                    {room.temperature}
                </p>
                <Link to={`edit/room/${room.id}`}>
                    <Button styling="bordered" variant="round" style={{padding:'3px', minWidth:'80px'}}>{t("common.button.edit")}</Button>
                </Link>
            </div>
        </div>
)}

export default RoomDetails;