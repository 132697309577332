// "common" // Things that are reused everywhere, like "Confirm" and "Cancel" on buttons
// "validation" // All validation text, like "email address not valid" in a form
// "glossary" // Words we want to be reused consistently, like key words in your app


import i18n from "i18next";
// import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";

i18n
    
    .use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        backend: {
            //loadPath: '/locales/account/{{lng}}/{{ns}}.json'
            loadPath: '/locales/test/{{lng}}/{{ns}}.json'
        },
        debug: false,
        fallbackLng: 'en',
        // saveMissing: true,
        ns: ['translation', 'menu'],
        defaultNS: 'translation',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            // format: (value, format, lng) => { // legacy usage
            //   if (value instanceof Date) {
            //     return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format])
            //   }
            //   return value;
            // }.
        },
        react: {
            useSuspense: false, // Set to true if you want to use suspense with react
        },
    });
export default i18n;


// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";

// import { TRANSLATIONS_EN } from "./en/translations";
// import { TRANSLATIONS_DE } from "./de/translations";
// import { TRANSLATIONS_FR } from "./fr/translations";
// // import Backend from "i18next-http-backend";

// const resources = {
//     en: {
//         translation: TRANSLATIONS_EN
//     },
//     de: {
//         translation: TRANSLATIONS_DE
//     },
//     fr: {
//         translation: TRANSLATIONS_FR
//     },

// }

// i18n
//     .use(LanguageDetector)
//     .use(initReactI18next)
//     // .use(Backend)
//     .init({
//         debug: true,
//         fallbackLng: 'fr',
//         // saveMissing: true,
//         resources,
//     });

// // i18n.changeLanguage("en");

// export default i18n;
