import FirmData from "./FirmData";
import ClientData from "./ClientData";
import * as PropTypes from "prop-types";
import React from "react";
import PersonContact from "./PersonContact";


PersonContact.propTypes = {details: PropTypes.any};

const ClientOrFirmCard = ({details}, props) => {

    if (details.firm) {
        return (
            <FirmData details={details.firm}>
                <PersonContact details={details.client}/>
            </FirmData>
        )

    } else {
        return <ClientData details={details.client} />
    }
}

export default ClientOrFirmCard;
// ClientOrFirmCard.propTypes = {}