import React from "react";
import styled from "styled-components"
import { Colors } from "./theme";
import { EditButton } from "./ui/EditButton";

const BreadcrumbStyle = styled.div `
    margin: 0px 0px 40px 0px;
    background-color: ${Colors.mediumBG};
    border-radius: 10px;
    padding: 7px 20px;
    width: 100%;
`

const CrumbList = styled.ul `
    margin: 0;
`

const CrumbListElement = styled.li `
    display: inline;
    &:first-child {
        padding-left: 0px;
    };
`

const CrumbListIcon = styled.li `
    display: inline;
    padding: 0px 10px;
`

const IconStyle = {
    width: '25px',
    height: '25px',
    verticalAlign: 'top',
    transform: 'rotate(0deg)',
};

/**
 @param {{
    crumbs: array with containing crumb list
    button: "edit"
 }} props
*/

function Breadcrumbs({editProfile, breadCrumbs, setBreadCrumbs}) {

    // if (crumbs) {
        const listElement = breadCrumbs.map((element, i) => {
            if (i === breadCrumbs.length-1) {
                return <CrumbListElement key={Math.random()}> {element} </CrumbListElement>
            }
            else {
                return (
                <React.Fragment key={Math.random()}>
                    <CrumbListElement> {element} </CrumbListElement>
                    <CrumbListIcon> &#10095; </CrumbListIcon>
                </React.Fragment>
                )
            }
        })
    // }

    return (
        <BreadcrumbStyle className="row">
            <div className="col-9 p-0">
                <CrumbList className="p-0">
                    {listElement}
                </CrumbList>
            </div>

            <div className="col-3 p-0 d-flex justify-content-end">
                { !editProfile
                    ?
                    <EditButton
                        // setEditProfile={setEditProfile}
                        breadCrumbs={breadCrumbs}
                        setBreadCrumbs={setBreadCrumbs}
                    />
                    :
                    null
                }
            </div>
        </BreadcrumbStyle>
    )
};

export default Breadcrumbs;