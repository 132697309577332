import LightCard from "../../../../../../components/card/LightCard";
import Staff from "./Staff";
import React, {useEffect, useState} from "react";
import {quoteDataAPI, quoteDeleteAPI} from "../../../../../../services/api/project";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import Events from "./Events";
import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import {useTranslation} from "react-i18next";
import QuoteBudget from "./QuoteBudget";
import DarkCard from "../../../../../../components/card/DarkCard";
import {TxtTitles} from "../../../../../../helpers/breadCrumbs";
import {format_datetime_convert, parametersSessionINIT} from "../../../../../../helpers/tools";
import {Button} from "../../../../../../components/ui/Button";
import { Colors } from "../../../../../../components/theme";
import Logo from "../../../../../../components/ui/Logo";
import LanguageButtons from "../../../../../../components/ui/LanguageButtons";
import {popupInit} from "../../../../../../helpers/PDFSupport/offers/tools";
import Popup from "../../../../../../components/ui/Popup/Popup";
// import {el} from "date-fns/locale";

const LogoStyle = {
    maxWidth: '140px',
    width: '100%',
}

// const textAcceptance = (textChildren, t) => (
//     <LightCard>
//         <RedHeader title={t('project.quote_acceptance_isAccepted_title')}/>
//         {textChildren}
//     </LightCard>
// )

const QuoteClientForm = ({children}) => {
    const [data, setData] = useState(null);
    const params = useParams();
    const {t} = useTranslation();
    const [sessionData, setSessionData] = useState(parametersSessionINIT);
    const navigate = useNavigate()
    const location = useLocation();
    // const [showEstroGrant, setShowEstroGrant] = useState(false);
    const [popup, setPopup] = useState(popupInit);

    useEffect(() => {
        const hanndleRouterChange = () => {
            sessionStorage.removeItem('project_quote_formular')
        }
        return () => {
            hanndleRouterChange()
        }
    }, [location])

    useEffect(() => {
        const quote_data = async () => {
            const response = await quoteDataAPI(params.quote_link)
            if (!response || response.status !== 200) {
                navigate('/project/quote-link/not-active')
            }
            if (response.data) {
                setData(response.data)
                const get_sessionData = JSON.parse(sessionStorage.getItem("project_quote_formular"));
                if (get_sessionData) {
                    setSessionData(get_sessionData);
                } else {
                    setSessionData({
                        quoteID: response.data.id,
                        projectID: response.data.project,
                        projectName: response.data.projectName,
                        estimateID: response.data.estimate,
                        clientView: true,
                    })
                }
            }
        }
        quote_data()
    }, [])


    const handleDelete = async () => {
        setPopup({
            ...popupInit,
            show: true,
            stepOK: false,
            variant: "editF",
            title: t('popups.generate_pdf.title'),
            message: t('popups.quote.delete_message'),
        });
    }

    const handlePopup = async (e) => {
        const buttonName = e.target.name

        if (buttonName === 'delete') {
            const response = await quoteDeleteAPI(data.id)
            if (response.status !== 200) {
                console.log("Nie powiodło się usunięcie quote...")
                return
            }
            setPopup({...popup, stepOK: true, message: t('Quote została usunięta...')})
        }
        if (buttonName === 'OK') {
            navigate("/projects/in-preparation/"+sessionData.projectID)
        }
    }

    if (popup.show) {
        return (<Popup message={popup.message} title={popup.title} variant={popup.variant} >
            {popup.stepOK
                ? <Button
                        name="OK"
                        onClick={(e) => handlePopup(e)}
                        variant="round"
                        bWidth="small"
                        styling="bordered"
                        className='mx-3 mt-4'>
                        {t("OK")}
                    </Button>
                : <> <Button
                        name="delete"
                        onClick={(e) => handlePopup(e)}
                        variant="round"
                        bWidth="small"
                        styling="plain"
                        className='me-3 mt-4'
                        style={{backgroundColor: `${Colors.green}`}}>
                        {t("Delete")}
                    </Button>
                    <Button
                        name="cancel"
                        onClick={(e) => handlePopup(e)}
                        variant="round"
                        bWidth="small"
                        styling="bordered"
                        className='mx-3 mt-4'>
                        {t("Cancel")}
                    </Button> </>
            }
        </Popup>)
    }

    const pageContent = (
        <DarkCard classNames='mb-3 row'>
            {data
                ? <>
                    <div className="row">
                        <div className="col-6 ps-0">
                            <TxtTitles>{t("project.project_name_title")}</TxtTitles>
                            <h1 className='mb-0 p-0' style={{marginTop:'-5px'}}>
                                {sessionData.projectName}
                            </h1>
                            <p className="mt-3 px-0" style={{fontSize: '14px'}}>
                                {t("project.project_id_title")} P{sessionData.projectID} <br/>
                                {t("project.quote_id_title")} Q{data.id}
                            </p>
                        </div>
                        <div className="col-6 pe-0">
                            <TxtTitles style={{textAlign:"right"}}>{t("project.quote_client_title")}</TxtTitles>
                            {data.firm ?
                                <p className="mt-0 p-0" style={{textAlign:"right"}}>
                                    {data.firm.name} <br/>
                                    {data.client.first_name} {data.client.last_name}<br/>
                                    {data.firm.number} {data.firm.street}<br/>
                                    {data.firm.post_code} {data.firm.place}<br/>
                                    {data.firm.country}
                                    <br/><br/>
                                    {data.client.telephone} <br/>
                                    {data.client.email}
                                </p>
                            : 
                                <p className="mt-0 p-0" style={{textAlign:"right"}}>
                                    {data.client.first_name} {data.client.last_name}<br/>
                                    {data.client.number} {data.client.street}<br/>
                                    {data.client.post_code} {data.client.place}<br/>
                                    {data.client.country}
                                    <br/><br/>
                                    {data.client.telephone}<br/>
                                    {data.client.email}
                                </p>
                            }
                        </div>
                    </div>

                    <LightCard classNames='mb-3'>
                        <Staff staff={data.staff} kzmProject={true} titleSummary={""}/>
                    </LightCard>

                    <LightCard classNames='mb-4'>
                        <Events
                            eventDetails={data.eventDetails}
                            //estimationID={" (Q"+data.id+")"}
                            estimationID={""}
                            events={data.current_events}
                        />
                    </LightCard>

                    {data.comment ?
                        <LightCard classNames='mb-4'>
                            <RedHeader title={t("offer.pdf_document.texts.additional_infos_title")}/>
                            
                            <p className="mb-0 ps-2">
                                {data.comment}
                            </p>
                        </LightCard>
                        : null
                    }

                    <div className="col-6 ps-0">
                        <LightCard classNames='mb-3'>
                            <RedHeader title={t('offer.create_offer.project_program_title')}/>
                            <p className="my-0 ps-2">{data.program}</p>
                        </LightCard>
                    </div>

                    <div className="col-6 pe-0">
                        <LightCard classNames='mb-3'>
                            <RedHeader title={t('project.budget_title')}/>
                            <QuoteBudget projectData={data}/>
                        </LightCard>
                    </div>

                    {!sessionData.clientView ?
                        <div className="row">
                            {data.status && (
                                <p className="mb-0">
                                    {t("offer.pdf_document.status.title")}
                                </p>
                            )}
                            {data.status === 'new' && (
                                <p style={{color: `${Colors.skyBlue}`}}>
                                    {t("offer.pdf_document.status.new_message")}
                                </p>
                            )}
                            {data.status === 'waiting' && (
                                <p style={{color: `${Colors.darkOrange}`}}>
                                    {t("offer.pdf_document.status.waiting_message")}
                                </p>
                            )}
                            {data.status === 'accepted' && (
                                <p style={{color: `${Colors.darkGreen}`}}>
                                    {t(
                                        "offer.pdf_document.status.accepted_message",
                                        {person: `${data.acceptance_cn}`}
                                    )}
                                    <br/>
                                    {t(
                                        "offer.pdf_document.status.signature_date",
                                        {dateTime: format_datetime_convert(new Date(data.acceptance_datetime))}
                                    )}
                                </p>
                            )}
                            {data.status === 'rejected' && (
                                <p style={{color: `${Colors.darkRed}`}}>
                                    {t(
                                        "offer.pdf_document.status.rejected_message",
                                        {person: `${data.acceptance_cn}`}
                                    )}
                                    <br/>
                                    {t(
                                        "offer.pdf_document.status.signature_date",
                                        {dateTime: format_datetime_convert(new Date(data.acceptance_datetime))}
                                    )}
                                    <br/>
                                    {t("offer.pdf_document.status.reason_title")}
                                    <br/>
                                    {data.acceptance_reason}
                                </p>
                            )}
                        </div>
                        : null
                    }

                    {!sessionData.clientView
                        ? <div style={{overflow: 'hidden'}}>
                            <Button
                                onClick={handleDelete}
                                variant="round"
                                bWidth="normal"
                                type="submit"
                                style={{backgroundColor: `${Colors.red}`, float: 'right'}}
                                className="mx-2">
                                {t("common.button.delete_quote")}
                            </Button>
                        </div>
                        : null
                    }

                    {children}
                </>
                : null
            }
        </DarkCard>
    )

    console.log("DATA:::::", data)

    return (<>
        {!sessionData.clientView ?
            <DarkCard classNames='mb-3 row'>
                <p className="mt-0 mb-0 breadcrumbs">
                    <span className="txt">{t("menu.projects", {ns: 'menu'})}</span> &#9656;
                    <Link to={'/projects/in-preparation'}>{t("menu.inprep_projects",{ ns: 'menu' })}</Link> &#9656;
                    <Link to={'/projects/in-preparation/'+sessionData.projectID}>{t("breadcrumbs.project_title")} P{sessionData.projectID}</Link> &#9656;
                    <span className="txt">{t("breadcrumbs.public_view")}</span>
                </p>
            </DarkCard>
        : null
        }

        {!sessionData.clientView ? 
            pageContent 
        :  <div className="py-4" style={{backgroundColor:'#f7f4f1', marginTop: '-44px', marginBottom:'-85px'}}>
                <div className="container">
                    <div className='row align-items-center mb-3'>
                        <div className='col px-0'>
                            <Logo styling={LogoStyle}/>
                        </div>
                        <div className='col px-0'>
                            <div style={{maxWidth:'120px', marginLeft:'5px', marginRight:'5px0', float:'right'}}>
                                <LanguageButtons />
                            </div>
                        </div>
                    </div>
                    {pageContent}
                </div>
            </div>
        }
        
    </>)
}

export default QuoteClientForm