import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {Input} from "../../../../../../components/ui/Input";
import {Button} from "../../../../../../components/ui/Button";
import {Colors} from "../../../../../../components/theme";

const ProjectName = ({isVisible, handleSave, handleCancel, project, onSaveProjectName}) => {
    const { t } = useTranslation()
    const [projectName, setProjectName] = useState("");

    if (!isVisible) {
        return (
        <>
            <p className="mb-0">{t("offer.create_offer.project_name")}:</p>
            <Input
                name="editNameCalculation"
                value={projectName}
                type={"text"} style={{minWidth: "250px", width: "50%", marginLeft:"10px"}}
                onChange={(val) => setProjectName(val)}
                required
            />
            <Button
                onClick={handleCancel}
                variant="round"
                bWidth="small"
                styling="bordered"
                className="mx-2">
                {t("common.button.cancel")}
            </Button>
            {projectName
            ? <Button
                onClick={() => onSaveProjectName(projectName)}
                variant="round"
                bWidth="small"
                className="mx-2"
                style={{backgroundColor: `${Colors.green}`}}
              >
                {t("common.button.save")}
              </Button>
            : null
            }

            <p className="mb-0"><i>{t("offer.create_offer.project_name_note")}</i></p>
        </>)
    } else {
        return (
            <>
                <div className="col-2">
                    <p>Project ID: <b style={{color:`${Colors.darkGray}`}}>P{project.id}</b></p>
                </div>
                <div className="col-3">
                    <p>Project Name: <b style={{color:`${Colors.darkGray}`}}>{project.name}</b></p>
                </div>
            </>
        )
    }
}

export default ProjectName
