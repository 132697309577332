import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../ui/Button";

const EventsTypesCount = ({evValue, type, changeHandler, moreDetails}) => {
    const { t } = useTranslation();
    const evCounter = `${parseInt(evValue) + 1}`

    return (
        moreDetails
        ?
            <Button
                type="button" proposueButton={type} onClick={() => changeHandler(evCounter, type)}
            >
                {t("common.button.add")}
            </Button>
        :
            <input
                type="number"
                min={0}
                style={{width:'100%', maxWidth:'80px'}}
                value={evValue}
                onChange={val => changeHandler(val.target.value, type)}
            />
    )
};

export {EventsTypesCount,};
