import {
    firmCreateAPI, firmDetailAPI,
    firmUpdateAPI,
    typesFirmListAPI
} from "../../../../../services/api/clients";
import {popupData} from "../../../../../helpers/dictionary/users";
import React, {useEffect, useReducer} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {validate} from "../../../../sign_up/components/validations";
import {routesClientsBreadCrumbs} from "../../../../../helpers/breadCrumbs";
import FirmNewEditView from "./FirmNewEditView";
import {useSessionStorage} from "usehooks-ts";
import {actionTypes} from "../Clients/ReducerClient";
// import {isObjectEmpty} from "../../../../../helpers/tools";

const field_list = {
    id: 0,
    name: "",
    type: {label: "", value: ""},
    note: "",
    street: "",
    number: "",
    post_code: "",
    place: "",
    country: "",
}

const firmSave = async (data, id) => {
    let response
    try {
        if (id) {
            response = await firmUpdateAPI(data, id)
        } else {
            response = await firmCreateAPI(data)
        }
        if (response.status === 201 || response.status === 204) {
            return {popupData: popupData.saveOK, id: response.data.id}
        }
    } catch (e) {
        console.log("Failed to download firm list", e);
        return {popupData: popupData.saveError}
    }
}

const firmDetailsAPI = async (params) => {
    try {
        if (params) {
            const [firmsTypesData, firmDetailData] = await Promise.all([typesFirmListAPI(), firmDetailAPI(params)])
            return {firmsTypesData: firmsTypesData.data, firmDetailData: firmDetailData.data}
        }
        const response = await typesFirmListAPI()
        return {firmsTypesData: response.data, firmDetailData: {type: {label: "", value: 0}}}
    } catch (e) {
        console.log("Failed to download firms types list", e);
    }
}

const validationRules = {
    name: ["required"],
    type: ["required"],
    street: ["required"],
    number: ["required"],
    post_code: ["required"],
    place: ["required"],
    country: ["required"],
    note: [],
}

const INITIAL_DOC = {
    action: null,
    firmData: field_list,
    typesFirm: [{label: "", value: 0}, ],
    popup: {show: false},
    breadCrumbsParams: [],
    pathReturn: null,
}


function firmReducer(prevDoc, action) {
    switch (action.type) {
        case "SET_ACTION":
            return {...prevDoc, action: action.action}
            break
        case "SET_BREADCRUMBS":
            return {...prevDoc, breadCrumbsParams: action.params_list}
            break;
        case "SET_FIRM_DATA":
            return {...prevDoc, firmData: {...prevDoc.firmData, [action.data.fieldName]: action.data.value}}
            break;
        case "INIT_FIRM_DATA":
            return {...prevDoc, firmData: action.data.firmDetailData, typesFirm: action.data.firmsTypesData}
            break
        case "SET_TYPES_FIRM":
            return {...prevDoc, typesFirm: action.data}
            break
        case "SET_POPUP":

            return {...prevDoc, popup: action.popup}
            break
        case "POST_SAVE":
            return {...prevDoc, action: action.action, popup: action.popup}
            break
        case "SET_ACTION_BREADCRUMBS":
            return {...prevDoc, action: action.action, breadCrumbsParams: action.breadCrumbsParams}
            break
        case "SET_PATH_RETURN":
            return {...prevDoc, pathReturn: action.pathReturn}
            break
        default:
            return prevDoc;
    }
    throw Error('Unknown action: ' + action);
}


const FirmManager = () => {
    const [state, dispatch] = useReducer(firmReducer, INITIAL_DOC, undefined)
    const params = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const [storageData, setStorageData] = useSessionStorage(
        "client-data", JSON.parse(localStorage.getItem("client-data"))
    );
    const [offerSessionData, setOfferSessionData] = useSessionStorage(
        "offer-data", JSON.parse(localStorage.getItem("offer-data"))
    );

    useEffect(() => {
        const importDataState = async () => {
            const dataResult = await firmDetailsAPI(params.key)
            dispatch({type: 'INIT_FIRM_DATA', data: dataResult})

            if (storageData && storageData.pathReturn.length > 0) {
                const path_return = storageData.pathReturn[storageData.pathReturn.length -1]
                dispatch({type: "SET_PATH_RETURN", pathReturn: path_return})
            }
        }
        importDataState()

        if (storageData) {
            dispatch({type: "SET_ACTION_BREADCRUMBS", breadCrumbsParams: storageData.breadCrumbsParams, action: storageData.action})
        } else if (offerSessionData) {
            dispatch({type: "SET_ACTION_BREADCRUMBS", breadCrumbsParams: offerSessionData.breadCrumbsParams, action: offerSessionData.action})
        }
        else {
            if (params.action === 'create') {
                dispatch({type: "SET_ACTION_BREADCRUMBS", breadCrumbsParams: ['clients_companies_add_company'], action: "create"})
            } else {
                dispatch({type: "SET_ACTION_BREADCRUMBS", breadCrumbsParams: ['clients_companies_edit_company', 0,0, params.key], action: "edit"})
            }
        }

    }, [])

    useEffect(() => {
        if (!state.action) return
        if (state.action === actionTypes.list) navigate('/clients/companies')
        if (state.action === actionTypes.details) navigate('/clients/companies/details/'+state.firmData.id)
        if (state.action === actionTypes.pathReturn) {
            if (storageData && storageData.pathReturn.length > 0) {
                const lastPathReturn = storageData.pathReturn.pop()

                if (storageData.breadCrumbsLevelUp) {
                    setStorageData({...storageData, breadCrumbsParams: storageData.breadCrumbsLevelUp})
}
                navigate(lastPathReturn)
            }
            else if (offerSessionData && offerSessionData.pathReturn.length > 0) {
                const lastPathReturn = offerSessionData.pathReturn.pop()

                navigate(lastPathReturn)
            }
            else navigate('/clients/companies')
        }
    }, [state.action])

    useEffect(() => {
        const removeLastPath = async () => {
            if (storageData) {
                if (storageData.pathReturn.length === 0) {
                    await sessionStorage.removeItem('client-data')
                } else {
                    const storedDataCopy = structuredClone(storageData)
                    storedDataCopy.pathReturn.pop()
                    setStorageData(storedDataCopy)
                }
            }
            else if (offerSessionData) {
                if (offerSessionData.pathReturn.length === 0) {
                    await sessionStorage.removeItem('offer-data')
                } else {
                    const storedDataCopy = structuredClone(offerSessionData)
                    storedDataCopy.pathReturn.pop()
                    setOfferSessionData(storedDataCopy)
                }
            }
        }
        return () => removeLastPath()
    }, [location])

    const handleSave = async (event) => {
        event.preventDefault()
        const error = Object.entries(state.firmData).some(([key, value]) => {
            const test = validate(validationRules[key], value);
            if (test) {
                return true
            }
        })
        if (error) return

        const formData = {...state.firmData, 'type': state.firmData.type.value}
        const response = await firmSave(formData, params.key)

        if (response.popupData.variant === 'success') {
            if (state.action === actionTypes.create) {
                await dispatch({type: "SET_FIRM_DATA", data: {value: response.id, fieldName: 'id'}})
            }
            // add new firm to formData.firms
            if (storageData && storageData.formData) {
                const formDataCopy = structuredClone(storageData.formData)
                const add_data = {
                    info: "",
                    firm_name: state.firmData.name,
                    firm_pk: response.id,
                }
                formDataCopy.firms.push(add_data)
                setStorageData({...storageData, formData: formDataCopy})
            }
            dispatch({type: 'SET_POPUP', popup: response.popupData})
        }
    }

    const breadCrumbsManager = () => {
        return routesClientsBreadCrumbs(t, ...state.breadCrumbsParams)
    }

    return (<FirmNewEditView
        dispatch={dispatch}
        handleSave={handleSave}
        breadCrumbs={breadCrumbsManager}
        popup={state.popup}
        params={params}
        firmData={state.firmData}
        typesFirm={state.typesFirm}
        pathReturn={state.pathReturn}
        isOfferSession={!!offerSessionData}
    />)
}


export default FirmManager

