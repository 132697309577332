import {useTranslation} from "react-i18next";
import {DetailsStyle, ElementStyle, FieldStyle, NoteParagraphStyle, NoteStyle} from "../../../../../../layouts/styles";
import {Colors} from "../../../../../../components/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import Popup from "../../../../../../components/ui/Popup/Popup";
import {Button} from "../../../../../../components/ui/Button";
import ParametersPdfForm from "./ParametersPdfForm";
import {languagesList} from "../../../../../../helpers/dictionary/users";
import {toInteger} from "lodash";
import get_calculation_api from "../../../../../../helpers/PDFSupport/offers/get_calculation_api";
import {popupError} from "../../../../../../components/ui/Popup/feedBack";
import {RenderDataToPDF} from "../../../../../../helpers/PDFSupport/RenderDataToPDF";
import styled from "styled-components";
import dateFormat from "dateformat";
import {INITIAL_renderDataToPDF, popupInit, popupInitial} from "../../../../../../helpers/PDFSupport/offers/tools";
import {save_parametersAPI} from "../../../../../../services/api/project";


const TxtTitles = styled.p`
	font-size: 0.7em;
    text-transform: uppercase;
	width: 100%;
    padding: 0 0 3px 0;
    margin: 0;
    color: ${Colors.darkRed};
`;

const processName = 'estimate'

const EstimateItem = ({est, grantEstroSum, index, project, clientAndFirm, redirect_to}) => {
    const [createDate, setCreateDate] = useState(new Date());
    const [language, setLanguage] = useState({ value: "4", label: "English" });
    const [showEstroGrant, setShowEstroGrant] = useState(false);
    const [isGeneratePDF, setIsGeneratePDF] = useState(false)
    const [comment, setComment] = useState("")
    const [pdfData, setPdfData] = useState(INITIAL_renderDataToPDF);
    const {t} = useTranslation()
    const [popup, setPopup] = useState(popupInit);

    useEffect(() => {
        if (est.pdf_date) {
            setCreateDate(new Date(est.pdf_date))
        } else if (est.date) {
            setCreateDate(new Date(est.date))
        }
        setShowEstroGrant(est.showEstroGrant)

        if (est.language) {
            const lang = languagesList.find(
                item => toInteger(item.value) === est.language
            );
            setLanguage(lang)
        }
        if (est.comment) setComment(est.comment)
        if (est.program) setPdfData({...pdfData, program: est.program});

    }, [])

    const handlePopup = (e) => {
        const buttonName = e.target.name

        if (buttonName === 'save') {
            // czy pola wypelnione
            // if (pdfData.language.value === 0) return
            const getCalculationAPI = async () => {
                // zapis parametrow z form do DB
                const data = {
                    pdf_date: createDate.toISOString().slice(0,10),
                    language: language.value,
                    showEstroGrant: showEstroGrant,
                    comment: comment,
                    type: "estimate",
                }
                await save_parametersAPI(est.id, data)

                const response = await get_calculation_api(est.calculation__pk)

                console.log("estimateItem response:", response, est.calculation__pk)

                const dataEst = {
                    ...pdfData,
                    calculation: response,
                    project: project,
                    client: {client_data: clientAndFirm.client},
                    firm: clientAndFirm.firm,
                    estimate: est.id,
                    estimateDate: createDate, //.toLocaleDateString("de-DE"),
                    comment: comment,
                    typeData: "estimate",
                    showEstroGrant: showEstroGrant,
                    language: language.value,
                    documentDate: createDate,
                }
                console.log("dataEst", dataEst)
                setPdfData(dataEst)
                setPopup({show: false})
                setIsGeneratePDF(true)
            }
            getCalculationAPI()
        } else {
            setPopup(popupInit);
        }
    }

    const handlePDFClick = (id) => {
        setPopup({...popupInit, show: true, variant: "editF"});
    }

    const handleChangeData = (data) => {
        if (data.type === "SET_DATE") {
            setCreateDate(data.value)
        }
        if (data.type === "SET_SHOW_ESTRO_GRANT") {
            setShowEstroGrant(data.value)
        }
        if (data.type === "SET_LANGUAGE") {
            setLanguage(data.value)
        }
        if (data.type === "SET_ESTIMATE_COMMENT") {
            setComment(data.value)
        }
    }

    console.info("EstimateItem est: ", est)
    console.info("EstimateItem pdfData: ", pdfData)

    if (popup.show) {
        return (<Popup message={popup.message} title={t(popup.title)} variant={popup.variant} >
            <ParametersPdfForm
                formData={{
                    createDate: createDate,
                    readOnly: false,
                    language: language,
                    showEstroGrant: showEstroGrant,
                    grantEstroSum: grantEstroSum,
                    comment: comment
                }}
                dispatch={handleChangeData}
            />
            <Button 
                name="save" 
                onClick={(e) => handlePopup(e)} 
                variant="round" 
                bWidth="small" 
                styling="plain" 
                className='me-3 mt-4'
                style={{backgroundColor: `${Colors.green}`}}>
                    {t("Generate")}
            </Button>
            <Button
                name="cancel" 
                onClick={(e) => handlePopup(e)} 
                variant="round" 
                bWidth="small" 
                styling="bordered" 
                className='mx-3 mt-4'>
                    {t("Cancel")}
            </Button>
        </Popup>)
    }

    return (<>
        <ElementStyle className={"d-flex flex-column"} style={{backgroundColor:'white', marginTop:'10px', marginBottom:'10px'}}>
            <DetailsStyle className="d-flex flex-row flex-wrap flex-md-nowrap">
                <div className="d-flex flex-row flex-wrap flex-md-nowrap" style={{width: '100%'}}>
                    <FieldStyle width='20px'>
                        {index}
                    </FieldStyle>
                    <FieldStyle>
                        <div>
                            <TxtTitles>{t("project.id_title")}</TxtTitles>
                            <a onClick={() => redirect_to(
                                "project",
                                est.calculation__pk,
                                est.id,
                                est.current
                            )}>
                                <b>E{est.id}</b> / {est.calculation__name}
                            </a>
                        </div>
                    </FieldStyle>
                    <FieldStyle>
                        <div>
                            <TxtTitles>{t("project.client_estimate_title")}</TxtTitles>
                            {clientAndFirm && clientAndFirm.firm && clientAndFirm.firm.name && (
                                clientAndFirm.firm.name + ", "
                            )}
                            {est.client__first_name} {est.client__last_name}
                        </div>
                    </FieldStyle>
                    <FieldStyle>
                        <div>
                            <TxtTitles>{t("project.creation_date")}</TxtTitles>
                            {dateFormat(est.date, "dd.mm.yyyy")}
                        </div>
                    </FieldStyle>
                    <FieldStyle>
                        {est.current
                            ?   <span style={{color:`${Colors.green}`, fontWeight:'bold'}}>
                                    <FontAwesomeIcon icon={faCheckCircle} className="me-2"/>
                                {t("project.selected_title")}
                                </span>
                            : '-' }
                    </FieldStyle>
                    <FieldStyle>
                        <a onClick={() => {handlePDFClick(est.id)}}>
                            <FontAwesomeIcon icon={faFilePdf} className="me-2"/>
                            {t("common.button.download_pdf")}
                        </a>
                    </FieldStyle>
                </div>
            </DetailsStyle>
            {est.calc_notes
                ?   <NoteStyle>
                    <NoteParagraphStyle>
                        <TxtTitles>{t("project.calculation_notes_intern_title")}</TxtTitles>
                        {est.calc_notes}
                    </NoteParagraphStyle>
                </NoteStyle>
                : null
            }
        </ElementStyle>

        {isGeneratePDF
            ? <RenderDataToPDF
                dataToRender={pdfData}
                processName={processName}
                dispatch={setIsGeneratePDF}
                popupError={popupError}
                popupInitial={popupInitial}
              />
            : null
        }
    </>)
}

export default EstimateItem;
