// import { format } from 'date-fns'
import {getDataAPI} from "../../../helpers/request";
import {
    bank_details_to_formular,
    instruments_to_formular,
    personal_data_to_formular
} from "../../../helpers/account/profile";

const getEditDataFromApi = async (edit_url, navigate) => {
    const data = await getDataAPI("/api/accounts/register-edit/" + edit_url);
    if (data === false) {
        navigate("/network-error");
    };

    return await data;
};
function importDataFromAPI (key_param, navigate) {
    const resp = getEditDataFromApi(key_param, navigate)
        .then(data => {
            localStorage.removeItem("registrationData");
            const personal_form = personal_data_to_formular(data);
            localStorage.setItem('registrationData', JSON.stringify(personal_form));

            localStorage.removeItem('registrationBankData');
            const bank_form = bank_details_to_formular(data.bank_details);
            localStorage.setItem('registrationBankData', JSON.stringify(bank_form));

            localStorage.removeItem('registrationInstrData');
            const instr_form = instruments_to_formular(data.instruments);
            localStorage.setItem('registrationInstrData', JSON.stringify(instr_form));

        })
        .catch(error => {
            // error.message;
            navigate("/network-error");
        })

};

export default importDataFromAPI;
