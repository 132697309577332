import lottie from 'lottie-web';
import { defineElement } from 'lord-icon-element';
import Logo from '../components/ui/Logo';
import FullPageBackground from '../components/Full_Page_Background';
import { Colors } from '../components/theme';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

defineElement(lottie.loadAnimation);

const ContentStyle = {
    width:'80%',
    maxWidth:'550px',
    textAlign:'center',
    fontSize: '1.18rem'
}

const LogoStyle = {
    maxWidth: '150px',
    width:'100%',
    margin: '55px auto',
    display: 'block'
}

export default function NetworkError() {
	const { t } = useTranslation();
    return (
        <>
            <div className='verti-hori-center' style={ContentStyle}>
                <lord-icon
                    src="https://cdn.lordicon.com/lfqzieho.json"
                    trigger="loop"
                    delay="5000"
                    colors="primary:#b41f23,secondary:#ccad61"
                    style={{width:'230px',height:'230px'}}>
                </lord-icon>

                <h1 className="underline">
                    {t("errors.network_page.title")}
                </h1>

                <p>
                    {t("errors.network_page.description")}      
                </p>
                <p>
                    {t("errors.common_msg.pls_contact_admin",{phone:"123"})}
                </p>
                <p>
                    {t("errors.common_msg.thx_for_understanding")}
                </p>
                <p>
                    <Link to={"/login"} style={{color:`${Colors.darkRed}`}}>
                        {t("errors.network_page.try_log_in")}
                    </Link>
                </p>

                <Link to={"/login"}>
                    <Logo styling={LogoStyle}/>
                </Link>
            </div>

            <FullPageBackground
                bgColor={Colors.mediumBG}
            />
        </>
    )
};