import axiosClient from "../services/api/api";
import {languages} from "../services/api/endpoints";

async function languagesListAPI() {
    const languages_api = await axiosClient().get(languages.languagesList)
        .then(({data}) => data);

    if (languages_api) {
        const languages = [];
        languages_api.forEach(el => {
            languages.push({'value': el.id, 'label': el.name})
        });
        return languages;
    } else {
        return []
    };
};

export {
    languagesListAPI,
};
