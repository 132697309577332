import { Link } from "react-router-dom";
import FullPageBackground from '../components/Full_Page_Background';
import backgroundImgLink from '../assets/images/Startimage-min.jpg';
import Logo from '../components/ui/Logo';
import { Colors } from '../components/theme';

const LoginSectionStyle = {
    maxWidth: '430px',
    width: '90%',
    backgroundColor: `${Colors.mediumBG}`, 
    padding: '65px 60px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 20px 30px',
}

const LogoStyle = {
    maxWidth: '200px',
    width:'100%',
    margin: '0px auto 55px auto',
    display: 'block'
}

export default function LayoutUserAuth({children}) {
    return (
        <>
            <div style={LoginSectionStyle} className="verti-hori-center">
                <Link to={"/login"}>
                    <Logo styling={LogoStyle}/>
                </Link>

                {children}
                
            </div>
            <FullPageBackground 
                opacity='0.4'
                url={backgroundImgLink}
            />
        </>
    );
}