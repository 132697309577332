const MenuFooterStyle = {
    padding: '20px 10px 10px 10px',
    borderTop: '1px solid white'
}

export default function MenuFooter({children}) {
    return (
        <div style={MenuFooterStyle}>
            {children}
        </div>
    )
}